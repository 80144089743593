/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

// * MUI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography } from '@mui/material';

// * Utils
import { useParams, useNavigate } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import paths from '../../paths/paths';
import { AppContext } from '../../components/AppContextProvider';
import { mapTopicCalls } from '../../components/TopicCallsDrawer/utils';

// * Components
import {
  Main,
  DrawerContainer,
  DrawerHeader,
  HeaderContainer,
  ScrollContainer,
} from '../../layouts/MainApp/components/styledComponents';
import CallDetailsSkeleton from '../../components/Skeletons/CallDetailsSkeleton';
import ResearchAppHeaderLayout from '../../layouts/ResearchAppHeaderLayout';
import {
  DrawerButton,
  DrawerMobile,
  HeaderMobile,
} from '../ResearchAppCalls/components/styledComponents';
import { HeaderSkeleton, CallListSkeleton } from '../../components';
import HeaderEmptyState from '../../components/EmptyStates/HeaderEmptyState';
import TabEmptyState from '../../components/EmptyStates/TabEmptyState';
import CreateTopic from '../../components/TopicCallsDrawer/CreateTopic';
import EditTopic from '../../components/TopicCallsDrawer/EditTopic';
import TopicList from '../../components/TopicList';
import TopicNotFound from '../../components/TopicNotFound';
import RenderTopic from './RenderTopic';

// * Icons
import { ReactComponent as AltArrowLeftIcon } from '../../assets/icons/altArrowLeftIcon.svg';

// * Queries
import { MY_TOPICS_QUERY, TOPIC_QUERY } from '../../queries/topics/topics';

// * Interfaces
import type { MyTopicsType, TopicType } from '../../shared/types/topics';
import type { Topic } from '../../components/TopicCallsDrawer/types';

const Topics = () => {
  const navigate = useNavigate();
  const { topicId } = useParams<{ topicId?: string }>();
  const [topicUuid, setTopicUuid] = useState<string | undefined>(topicId);
  const [topic, setTopic] = useState<Topic | undefined>();
  const [openDrawerMobile, setOpenDrawerMobile] = React.useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const onCreateNewTopic = (newTopic: Topic) => {
    setTopicUuid(newTopic.uuid);
    setTopic(newTopic);
  };
  const { setRefetchMyTopics, setRefetchCurrentTopic } = useContext(AppContext);

  const {
    data: { myTopics = { results: [], totalCount: 0 } } = {},
    loading: myTopicsLoading,
    refetch: refetchMyTopics,
  } = useQuery<MyTopicsType>(MY_TOPICS_QUERY, { fetchPolicy: 'network-only' });

  const [
    getTopic,
    { loading: topicLoading, error: isNotFound, refetch: refetchTopic },
  ] = useLazyQuery<{
    topic: TopicType
  }>(TOPIC_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: data => setTopic(mapTopicCalls(data.topic)),
  });

  useEffect(() => {
    setRefetchMyTopics(refetchMyTopics);
    setRefetchCurrentTopic(refetchTopic);
  }, [refetchMyTopics, setRefetchMyTopics, refetchTopic, setRefetchCurrentTopic]);

  // util to update topic screen from loading state
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (topic?.status === 'summarization in process') {
        refetchTopic();
      }
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [topic?.status]);

  useEffect(() => {
    if (topicUuid) {
      getTopic({ variables: { uuid: topicUuid } });
    }
  }, [getTopic, topicUuid]);

  useEffect(() => {
    if (!topicId && myTopics.results.length > 0) {
      const firstTopicUuid = myTopics.results[0].uuid;
      setTopicUuid(firstTopicUuid);
      navigate(paths.topics.reverse({ topicId: firstTopicUuid }));
    }
  }, [topicId, myTopics.results, navigate]);

  return (
    <ResearchAppHeaderLayout>
      {isMobile ? (
        <>
          <DrawerMobile anchor="left" open={openDrawerMobile}>
            <DrawerHeader>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h4"
                  color="colors.gray09"
                  mr="8px"
                  sx={{ fontSize: '24px', lineHeight: '32px' }}
                >
                  Topics
                </Typography>
                <CreateTopic onCreateTopic={onCreateNewTopic} />
              </Box>
            </DrawerHeader>
            <ScrollContainer id="scrollableReviewedTab">
              <Box display="flex" flexGrow={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  flexGrow="1"
                  pt="12px"
                >
                  <InfiniteScroll
                    // @ts-ignore
                    pageStart={0}
                    dataLength={myTopics.results.length}
                    next={() => {}}
                    hasMore={false}
                    loader={<p>Loading...</p>}
                    scrollableTarget="scrollableMyTopicsTab"
                  >
                    <TopicList
                      topics={myTopics.results}
                      selectedTopicUuid={topicUuid}
                      onSelect={setTopicUuid}
                      openDrawerMobile={openDrawerMobile}
                      setOpenDrawerMobile={setOpenDrawerMobile}
                    />
                  </InfiniteScroll>
                  {myTopicsLoading && <CallListSkeleton />}
                </Box>
              </Box>
            </ScrollContainer>
            {!myTopicsLoading && !myTopics.results.length && (
              <TabEmptyState isVisible title="No Topics" />
            )}
          </DrawerMobile>
          <Box sx={{ width: '100%' }}>
            {isNotFound ? (
              <TopicNotFound />
            ) : (
              <Box display="flex" flexDirection="column" pt="72px">
                <HeaderMobile>
                  <DrawerButton
                    aria-label="open drawer"
                    onClick={() => setOpenDrawerMobile(!openDrawerMobile)}
                  >
                    <AltArrowLeftIcon />
                    <Typography
                      variant="body1"
                      color="colors.gray05"
                      sx={{ marginLeft: '8px' }}
                    >
                      Back
                    </Typography>
                  </DrawerButton>
                </HeaderMobile>
                {topic && !topicLoading && (
                  <Box sx={{ padding: '16px 20px' }}>
                    <Box display="flex" alignItems="center">
                      <Typography
                        variant="h6"
                        color="colors.gray10"
                        sx={{ marginRight: '8px' }}
                      >
                        {topic.name}
                      </Typography>
                      <EditTopic topic={topic} setTopic={setTopic} />
                    </Box>
                  </Box>
                )}
                <Box display="flex" height="calc(100vh - 168px)">
                  {topic && !topicLoading ? (
                    <RenderTopic topic={topic} refetchTopic={refetchTopic} />
                  ) : (
                    <CallDetailsSkeleton />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <DrawerContainer variant="persistent" anchor="left" open>
            <DrawerHeader>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h4"
                  color="colors.gray09"
                  mr="8px"
                  sx={{ fontSize: '24px', lineHeight: '32px' }}
                >
                  Topics
                </Typography>
                <CreateTopic onCreateTopic={onCreateNewTopic} />
              </Box>
            </DrawerHeader>
            <ScrollContainer id="scrollableMyTopicsTab">
              <Box display="flex" flexGrow={1}>
                <Box
                  display="flex"
                  flexDirection="column"
                  flexGrow="1"
                  pt="12px"
                >
                  <InfiniteScroll
                    // @ts-ignore
                    pageStart={0}
                    dataLength={myTopics.results.length}
                    next={() => {}}
                    hasMore={false}
                    loader={<p>Loading...</p>}
                    scrollableTarget="scrollableMyTopicsTab"
                  >
                    <TopicList
                      topics={myTopics.results}
                      selectedTopicUuid={topicUuid}
                      onSelect={setTopicUuid}
                    />
                  </InfiniteScroll>
                  {myTopicsLoading && <CallListSkeleton />}
                </Box>
              </Box>
            </ScrollContainer>
            {!myTopicsLoading && !myTopics.results.length && (
              <TabEmptyState isVisible title="No Topics" />
            )}
          </DrawerContainer>
          <Main open>
            <Box display="flex" flexDirection="column">
              <HeaderContainer>
                {topic && !topicLoading && (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    flexGrow={1}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      gap="8px"
                    >
                      <Typography variant="h5" color="colors.gray10" sx={{ lineHeight: '36px' }}>
                        {topic.name}
                      </Typography>
                      <EditTopic topic={topic} setTopic={setTopic} />
                    </Box>
                  </Box>
                )}
                {!topicUuid && <HeaderEmptyState />}
                {topicLoading && <HeaderSkeleton />}
              </HeaderContainer>
              <Box display="flex" height="calc(100vh - 168px)">
                {topic && !topicLoading ? (
                  <RenderTopic topic={topic} refetchTopic={refetchTopic} />
                ) : (
                  <CallDetailsSkeleton />
                )}
              </Box>
            </Box>
          </Main>
        </>
      )}
    </ResearchAppHeaderLayout>
  );
};

export default Topics;
