import { styled } from '@mui/material/styles';
import {
  Box, IconButton, Drawer, TextField, Button, Radio, InputBase, Checkbox, Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import palette from '../../theme/palette';

export const DrawerContainer = styled(Drawer)({
  width: 850,
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: 850,
    boxSizing: 'border-box',
    boxShadow: '-11px 0px 60px rgba(0, 0, 0, 0.25)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
});

export const ScrollContainer = styled(Box)({
  height: '100%',
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const FooterBlock = styled(Box)({
  borderTop: '1px solid',
  borderTopColor: palette.colors.gray03,
  backgroundColor: palette.colors.gray01,
  padding: '24px 32px',
});

export const AddButton = styled(IconButton)({
  padding: 0,
  marginLeft: '8px',
});

export const CloseButton = styled(IconButton)({
  padding: 0,
  marginRight: '20px',
});

export const ImportFullButton = styled(Button)({
  backgroundColor: palette.colors.success03,
  borderRadius: '8px',
  height: 52,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '26px',
  letterSpacing: 0,
  padding: '12px',
  '&:hover': {
    backgroundColor: palette.colors.success04,
  },
  '&.Mui-disabled': {
    backgroundColor: palette.colors.success02,
  },
});

export const DatePickerField = styled(DatePicker)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.colors.gray03,
    },
  },
});

export const TimePickerField = styled(TimePicker)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.colors.gray03,
    },
  },
});

export const TextInputField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.colors.gray03,
    },
  },
});

export const DnDBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: palette.white,
  border: '1px dashed',
  borderColor: palette.primary.main,
  borderRadius: '8px',
  padding: '24px',
  cursor: 'default',
  '&.active': {
    border: '1px solid',
    borderColor: palette.primary.main,
    backgroundColor: palette.colors.gray02,
  },
  '&.dropped': {
    border: '1px solid',
    borderColor: palette.colors.gray04,
    backgroundColor: palette.white,
  },
  '&.error': {
    border: '1px dashed',
    borderColor: palette.colors.error03,
    backgroundColor: palette.colors.error01,
  },
});

export const BlurBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
});

export const CustomRadio = styled(Radio)({
  color: palette.colors.gray04,
});

export const SelectInput = styled(InputBase)({
  width: '100%',
  lineHeight: '29px',
  '& .MuiInputBase-input': {
    padding: '14px 15px 15px',
    border: '1px solid',
    borderColor: palette.colors.gray03,
    borderRadius: '8px',
    transition: 'all 250ms',
    '&:hover, &[aria-expanded="true"]': { borderColor: palette.primary.main },
  },
});

export const CustomCheckbox = styled(Checkbox)({
  padding: 0,
  paddingRight: '6px',
  '&:hover': { backgroundColor: 'transparent' },
});

export const NameContainer = styled(Typography)({
  fontSize: '14px',
  lineHeight: '32px',
  marginLeft: '4px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: '275px',
      width: '310px',
      border: '1px solid',
      borderColor: palette.primary.main,
      borderRadius: '8px',
      boxShadow: '0px 8px 9px rgba(226, 226, 226, 0.35)',
    },
  },
};
