import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  IconButton, Typography, Link,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import palette from '../../theme/palette';
import paths from '../../paths/paths';

const GoBackLink = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Link
      href={paths.home.reverse()}
      sx={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        marginTop: isMobile ? '30px' : '50px',
        marginLeft: isMobile ? '15px' : 0,
      }}
    >
      <IconButton size="small" sx={{ padding: 0, marginRight: '4px' }}>
        <ArrowBackIcon sx={{ color: palette.colors.gray05 }} />
      </IconButton>
      <Typography variant="body1" sx={{ textTransform: 'none', color: palette.colors.gray05 }}>
        Go back to home
      </Typography>
    </Link>
  );
};

export default GoBackLink;
