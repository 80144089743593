import { styled } from '@mui/material/styles';
import {
  Box, Button, TextField,
} from '@mui/material';
import palette from '../../theme/palette';

export const InterfaceContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: palette.colors.bgColor,
  borderBottom: '1px solid',
  borderBottomColor: palette.colors.gray03,
  '& .calls-chat': {
    height: 450,
    '& > div::before': {
      content: '""',
      display: 'block',
      marginTop: 'auto',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& button': {
      display: 'none',
    },
  },
  '& .topics-chat': {
    height: 'calc(100vh - 381px)',
    '& > div::before': {
      content: '""',
      display: 'block',
      marginTop: 'auto',
    },
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& button': {
      display: 'none',
    },
  },
});

export const InputField = styled(TextField)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    padding: '0 14px',
    lineHeight: '18px',
    '& .MuiOutlinedInput-input': {
      height: 20,
      padding: '12px 0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.colors.gray03,
    },
  },
});

export const StopIcon = styled(Box)({
  display: 'flex',
  height: 12,
  width: 12,
  backgroundColor: palette.colors.gray08,
  borderRadius: '1px',
  marginRight: '8px',
});

export const MessageItemContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '12px',
});

export const MessageItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  maxWidth: '70%',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  padding: '12px',
  borderRadius: '10px',
  marginBottom: '8px',
  backgroundColor: palette.white,
  color: palette.colors.gray08,
  [theme.breakpoints.down('md')]: {
    maxWidth: '80%',
    fontSize: '14px',
    lineHeight: '18px',
  },
  '&.user-message': {
    maxWidth: '40%',
    backgroundColor: palette.primary.main,
    color: palette.white,
    [theme.breakpoints.down('md')]: {
      maxWidth: '70%',
    },
  },
}));

export const MessageTime = styled(Box)({
  display: 'flex',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '12px',
  color: palette.colors.gray05,
});

export const StopButton = styled(Button)({
  borderColor: palette.colors.gray02,
  borderRadius: '4px',
  height: 36,
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '12px',
  color: palette.colors.gray08,
  letterSpacing: 0,
  padding: '11px 12px',
  marginTop: '12px',
  '&:hover': {
    borderColor: palette.colors.gray03,
    backgroundColor: 'transparent',
  },
});

export const SendButton = styled(Button)({
  backgroundColor: palette.primary.main,
  borderRadius: '8px',
  height: 44,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '18px',
  letterSpacing: 0,
  padding: '12px 24px',
  marginLeft: '12px',
  '&:hover': {
    backgroundColor: palette.primary.dark,
  },
});
