import { gql } from '@apollo/client';

export const ALL_ACA_IOI_ACCOUNTS = gql`
  query allAcaIoiAccounts {
    allAcaIoiAccounts {
      uuid
      name
      settings {
        avatarLogo
      }
    }
  }
`;

export default { ALL_ACA_IOI_ACCOUNTS };
