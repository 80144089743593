/* eslint-disable import/order */
import React, { useState } from 'react';

// * MUI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  FormControl,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  Select,
  Theme,
  Divider,
} from '@mui/material';
import type { SelectChangeEvent } from '@mui/material/Select';

// * Componentes
import { EditBtn, CancelButton, SubmitButton } from './styledComponents';
import palette from '../../theme/palette';

// * Icons
import { ReactComponent as LetterIcon } from '../../assets/icons/letterIcon.svg';

// * Interfaces
import type { AccountUsersType } from '../../shared/types/accountSettings';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  user: AccountUsersType,
  selectedUsers: string[],
  theme: Theme,
) {
  return {
    fontWeight: selectedUsers.includes(user.id)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

interface ResearchUsersDialogProps {
  researchUsers: AccountUsersType[]
  accountUsers: AccountUsersType[]
  // eslint-disable-next-line no-unused-vars
  updateResearchUsers: (userIds: string[]) => void
  editMode: boolean
}

const ResearchUsersDialog = ({
  researchUsers,
  accountUsers,
  updateResearchUsers,
  editMode,
}: ResearchUsersDialogProps) => {
  const [open, setOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedUsers(typeof value === 'string' ? [] : [...value]);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedUsers([]);
  };

  const handleSubmit = () => {
    updateResearchUsers(selectedUsers);
    handleClose();
  };

  const filteredUsers = accountUsers.filter(
    user => !researchUsers.some(u => u.id === user.id),
  );

  return (
    <>
      <EditBtn
        variant="outlined"
        disabled={!editMode}
        onClick={handleClickOpen}
      >
        Add User
      </EditBtn>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" sx={{ padding: '24px 29px 16px' }}>
          <Typography variant="h3">Add user</Typography>
        </DialogTitle>
        <Divider flexItem sx={{ mx: '29px', borderColor: 'colors.gray03' }} />
        <DialogContent sx={{ padding: '16px 29px 0' }}>
          <Box display="flex" alignItems="center" mb="12px">
            <LetterIcon />
            <Typography variant="subtitle1" color="colors.gray10" ml="12px">
              Send Transcript to
            </Typography>
          </Box>
          <FormControl fullWidth>
            <Select
              id="multiple-available-research-users"
              multiple
              displayEmpty
              fullWidth
              value={selectedUsers}
              onChange={handleChange}
              MenuProps={MenuProps}
              input={(
                <OutlinedInput
                  id="select-multiple-available-research-users"
                  sx={{ '& .MuiOutlinedInput-input': { height: 'auto' } }}
                />
              )}
              inputProps={{ 'aria-label': 'Without label' }}
              renderValue={selectedIds => {
                if (selectedIds.length === 0) {
                  return <Box color="colors.placeholder">Available users</Box>;
                }

                return (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {selectedIds.map(id => {
                      const user = accountUsers.find(u => u.id === id);
                      return (
                        <Chip
                          key={id}
                          label={`${user?.firstName} ${user?.lastName}`}
                          sx={{
                            backgroundColor: palette.colors.primary05,
                            color: palette.white,
                          }}
                        />
                      );
                    })}
                  </Box>
                );
              }}
            >
              {filteredUsers.length === 0 && (
                <MenuItem disabled>
                  <em>No users available</em>
                </MenuItem>
              )}
              {filteredUsers.map(user => (
                <MenuItem
                  key={user.id}
                  value={user.id}
                  style={getStyles(user, selectedUsers, theme)}
                >
                  {`${user.firstName} ${user.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', padding: '32px 29px 24px' }}>
          <CancelButton variant="outlined" onClick={handleClose}>
            Cancel
          </CancelButton>
          <SubmitButton variant="contained" onClick={() => handleSubmit()}>
            {`Add User${selectedUsers.length > 1 ? 's' : ''}`}
          </SubmitButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResearchUsersDialog;
