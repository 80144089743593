import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  Button, Box, Dialog,
  InputAdornment, Stack, TextField, Typography, CircularProgress,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LoginLogoImg from '../../assets/img/loginLogoImg.png';
import LoginLogoImgWebp from '../../assets/img/loginLogoImg.webp';
import LoginLogoMobImg from '../../assets/img/loginLogoMobImg.png';
import LoginLogoMobImgWebp from '../../assets/img/loginLogoMobImg.webp';
import LoginRingsLeftImg from '../../assets/img/loginRingsLeftImg.png';
import LoginRingsLeftImgWebp from '../../assets/img/loginRingsLeftImg.webp';
import LoginRingsLeftMobImg from '../../assets/img/loginRingsLeftMobImg.png';
import LoginRingsLeftMobImgWebp from '../../assets/img/loginRingsLeftMobImg.webp';
import LoginRingsRightImg from '../../assets/img/loginRingsRightImg.png';
import LoginRingsRightImgWebp from '../../assets/img/loginRingsRightImg.webp';
import LoginRingsRightMobImg from '../../assets/img/loginRingsRightMobImg.png';
import LoginRingsRightMobImgWebp from '../../assets/img/loginRingsRightMobImg.webp';
import LoginCirclesImg from '../../assets/img/loginСirсlesImg.png';
import LoginCirclesImgWebp from '../../assets/img/loginСirсlesImg.webp';
import LoginCirclesMobImg from '../../assets/img/loginCirclesMobImg.png';
import LoginCirclesMobImgWebp from '../../assets/img/loginCirclesMobImg.webp';
import paths from '../../paths/paths';
import { FORGOT_PASSWORD_MUTATION } from '../../queries/auth/account';

const ScreenBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    height: '100dvh',
  },
}));

const CardBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '55px 29px',
  marginTop: '32px',
  maxWidth: '410px',
  borderRadius: '15px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    padding: '40px 20px 0',
    marginTop: 0,
    maxWidth: '360px',
  },
}));

const schema = yup.object({
  email: yup.string().email('Invalid email').required('Email is a required field'),
}).required();

const ForgotPassword = () => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const {
    control, handleSubmit, resetField, formState: { isSubmitting, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: '' },
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetField('email');
    navigate(paths.signInEmail.pattern);
  };

  const [requestResetPasswordLink] = useMutation(FORGOT_PASSWORD_MUTATION, {
    onCompleted: () => {
      handleOpen();
    },
  });

  const onSubmit = (data: { email: string }) => {
    requestResetPasswordLink({
      variables: {
        email: data.email,
      },
    });
    handleOpen();
  };

  return (
    <ScreenBox sx={{ backgroundColor: 'background.paper' }}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" flexGrow={1}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            src={isMobile ? LoginLogoMobImg : LoginLogoImg}
            srcSet={isMobile ? LoginLogoMobImgWebp : LoginLogoImgWebp}
            alt="logo"
            loading="lazy"
            width={isMobile ? 197 : 266}
            height={isMobile ? 59 : 80}
          />
          <CardBox>
            <Typography variant="h4" color="colors.gray10" textAlign="center" mb={isMobile ? '18px' : '25px'}>
              Forgot Password
            </Typography>
            <Typography variant="body2" color="colors.gray05" textAlign="center" mb={isMobile ? '12px' : '36px'}>
              Please enter email to get reset link
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack alignItems="center" justifyContent="center">
                <Controller
                  control={control}
                  name="email"
                  render={({
                    field: {
                      onChange, value, name, ref,
                    },
                    fieldState: { error },
                  }) => (
                    <TextField
                      inputRef={ref}
                      name={name}
                      value={value}
                      onChange={onChange}
                      error={!!error}
                      helperText={error?.message}
                      variant="outlined"
                      placeholder="Your Email"
                      sx={{ width: isMobile ? 320 : 352, marginBottom: isMobile ? '12px' : '30px' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AlternateEmailIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                <Button
                  variant="contained"
                  size="large"
                  disabled={isSubmitting || !isDirty}
                  type="submit"
                  fullWidth
                  sx={{ marginBottom: isMobile ? '12px' : '31px' }}
                >
                  {!isSubmitting ? 'Reset Password' : <CircularProgress size={16} />}
                </Button>
                <Dialog open={open} onClose={handleClose}>
                  <Box display="flex" flexDirection="column" alignItems="center" padding={isMobile ? '30px 20px' : '55px 29px'}>
                    <Typography variant={isMobile ? 'h5' : 'h4'} color="colors.gray10" textAlign="center" mb={isMobile ? '18px' : '25px'}>
                      We’ve emailed you instructions for setting your password, if an account exists with the email
                      you entered. You should receive them shortly.
                    </Typography>
                    <Typography
                      variant={isMobile ? 'body1' : 'body2'}
                      color="colors.gray05"
                      textAlign="center"
                      mb={isMobile ? '18px' : '36px'}
                      sx={{ maxWidth: isMobile ? '320px' : '396px' }}
                    >
                      If you don’t receive an email, please make sure you’ve entered the address you registered with,
                      and check your spam folder.
                    </Typography>
                    <Button
                      variant="contained"
                      size="large"
                      href={paths.signInEmail.pattern}
                      sx={{ width: isMobile ? '280px' : '352px', textTransform: 'initial !important' }}
                    >
                      Go to sign in
                    </Button>
                  </Box>
                </Dialog>
                <Box display="flex">
                  <Typography variant="body1" color="colors.gray04" textAlign="center">
                    Go back to
                  </Typography>
                  <Button
                    variant="text"
                    href={paths.signInEmail.pattern}
                    sx={{ minWidth: '50px', textDecoration: 'underline' }}
                  >
                    Login
                  </Button>
                </Box>
              </Stack>
            </form>
          </CardBox>
        </Box>
        <Box display="flex" position="absolute" top={0} left={0}>
          <img
            src={isMobile ? LoginRingsLeftMobImg : LoginRingsLeftImg}
            srcSet={isMobile ? LoginRingsLeftMobImgWebp : LoginRingsLeftImgWebp}
            alt="img1"
            loading="lazy"
            width={isMobile ? 95 : 614}
            height={isMobile ? 95 : 614}
          />
        </Box>
        <Box display="flex" position="absolute" bottom={0} right={0}>
          <img
            src={isMobile ? LoginRingsRightMobImg : LoginRingsRightImg}
            srcSet={isMobile ? LoginRingsRightMobImgWebp : LoginRingsRightImgWebp}
            alt="img2"
            loading="lazy"
            width={isMobile ? 113 : 614}
            height={isMobile ? 113 : 614}
          />
        </Box>
        <Box display="flex" position="absolute" bottom={isMobile ? 20 : 80} left={isMobile ? 20 : 100}>
          <img
            src={isMobile ? LoginCirclesMobImg : LoginCirclesImg}
            srcSet={isMobile ? LoginCirclesMobImgWebp : LoginCirclesImgWebp}
            alt="img3"
            loading="lazy"
            width={isMobile ? 103 : 230}
            height={isMobile ? 62 : 140}
          />
        </Box>
      </Box>
    </ScreenBox>
  );
};

export default ForgotPassword;
