import React from 'react';

// * Components
import DownloadFile from '../DownloadFile';

// * Icons
import { ReactComponent as DownloadMP3Icon } from '../../../assets/icons/downloadMP3.svg';

const DownloadMP3 = ({ audioUrl }: { audioUrl: string }) => {
  const audioIsValid = audioUrl !== '';

  const handleDownloadAudio = async () => {
    const link = document.createElement('a');
    link.href = audioUrl;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    await new Promise(resolve => {
      setTimeout(resolve, 1000);
    });
  };

  return (
    <DownloadFile
      onDownload={handleDownloadAudio}
      IconComponent={DownloadMP3Icon}
      fileType="mp3"
      disabled={!audioIsValid}
    />
  );
};

export default DownloadMP3;
