import { gql } from '@apollo/client';

export const SHARE_CONTENT_MUTATION = gql`
  mutation shareContent(
    $email: String!
    $transcriptUuid: UUID!
    $subject: String
    $sendFullTranscript: Boolean
    $sendMeACopy: Boolean
    $sendNotes: Boolean
    $sendSummary: Boolean
  ) {
    shareContent(
      email: $email
      transcriptUuid: $transcriptUuid
      subject: $subject
      sendFullTranscript: $sendFullTranscript
      sendMeACopy: $sendMeACopy
      sendNotes: $sendNotes
      sendSummary: $sendSummary
    ) {
        status
        message
    }
  }
`;

export default { SHARE_CONTENT_MUTATION };
