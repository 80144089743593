import { gql } from '@apollo/client';

export const GET_LOGIN_LINK_MUTATION = gql`
  mutation requestAuthenticationLink($email: String!){
    requestAuthenticationLink(email: $email) {
      authenticationLink
    }
  }
`;

export const REQUEST_2FA_CODE = gql`
  mutation send2faCode($email: String!, $password: String!, $rememberMeToken: String) {
    send2faCode(email: $email, password: $password, rememberMeToken: $rememberMeToken) {
      success
      token
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation tokenAuth2fa($email: String!, $otpToken: String!, $rememberMe: Boolean!) {
    tokenAuth2fa(email: $email, otpToken: $otpToken, rememberMe: $rememberMe) {
      token
      rememberMeToken
    }
  }
`;

// export const LOGIN_MUTATION = gql`
//   mutation tokenAuthCustom($email: String!, $password: String!) {
//     tokenAuthCustom(email: $email, password: $password) {
//       payload,
//       token,
//       refreshExpiresIn
//     }
//   }
// `;

export const ME_QUERY = gql`
  {
    me {
      id
      uuid
      isStaff
      isAcaReviewer
      isResearchAnalyst
      isSuperuser
      allowSwitchRolesOnFrontend
      email
      firstName
      lastName
      timezone
      avatar
      sendNewExpertCallTranscriptEmail
      notificationsFrequency
      accountFullLogo
      accountAvatarLogo
      account {
        uuid
        name
        settings {
          fullLogo
          avatarLogo
        }
      }
      accounts {
        uuid
        name
        settings {
          fullLogo
          avatarLogo
        }
      }
    }
  }
`;

export const VALIDATE_RESET_PASSWORD_LINK_QUERY = gql`
    query passwordResetHash($linkHash: String) {
      passwordResetHash(linkHash: $linkHash) {
        id
        uuid
    }
  }
`;

export const ALL_USERS_QUERY = gql`
    query allUsers {
      allUsers {
        totalCount
        results {
          id
          uuid
          isStaff
          isSuperuser
          email
          firstName
          lastName
        }
    }
  }
`;

export const ALL_COMPLIANCE_REVIEWERS_QUERY = gql`
    query allComplianceReviewers {
      allComplianceReviewers {
        totalCount
        results {
          id
          uuid
          isStaff
          isSuperuser
          email
          firstName
          lastName
        }
    }
  }
`;

export const MY_ACCOUNT_SETTINGS = gql`
  query myAccountSettings {
    myAccountSettings {
      uuid
      updatedAt
      mnpi
      shadowTrading
      reluctance
      meeting
      contactInfo
      tippee
      dataLookup
      leadingQuestion
      preamble
      disclosurePrompt
      deleteTranscript
      transcriptRetentionPeriod {
        key
        value
        options {
          key
          value
        }
      }
      sampleSize
      requireComplianceReview
      transcribeAllCalls
      reviewTranscriptsWithNoHighlights
      includeAllExpertsFromPublicCompaniesInSample
      lastEmployed {
        key
        value
        options {
          key
          value
        }
      }
      confidenceLevel
      displayInResearchPortal
      allowSendTranscriptUponReview
      researchBoxAttachmentPreference
      fullLogo
      avatarLogo
      researchUsers {
        id
        firstName
        lastName
        email
      }
      complianceChecks {
        name
        type
        isActive
        description
      }
    }
  }
`;

export const ACCOUNT_USERS = gql`
  query accountUsers ($uuid: UUID!) {
    accountUsers (uuid: $uuid) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const ACCOUNT_SETTINGS_MUTATION = gql`
  mutation accountSettings($input: AccountSettingsMutationInput!) {
    accountSettings(input: $input) {
      accountSettings {
        uuid
        updatedAt
        mnpi
        shadowTrading
        reluctance
        meeting
        contactInfo
        tippee
        dataLookup
        leadingQuestion
        preamble
        confidenceLevel
        displayInResearchPortal
        disclosurePrompt
        deleteTranscript
        transcriptRetentionPeriod {
          key
          value
          options {
            key
            value
          }
        }
        sampleSize
        fullLogo
        avatarLogo
        researchUsers {
          id
          firstName
          lastName
          email
        }
        requireComplianceReview
        transcribeAllCalls
        reviewTranscriptsWithNoHighlights
        includeAllExpertsFromPublicCompaniesInSample
        lastEmployed {
          key
          value
          options {
            key
            value
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation requestResetPasswordLink($email: String!) {
    requestResetPasswordLink(email: $email) {
      message
      status
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($resetPasswordLinkUuid: String!, $password: String!) {
    resetPassword(resetPasswordLinkUuid: $resetPasswordLinkUuid, password: $password) {
      message
      status
    }
  }
`;

export const USER_SETTINGS_MUTATION = gql`
  mutation updateUserSettings($email: String, $firstName: String, $lastName: String, $password: String, $timezone: String, $sendNewExpertCallTranscriptEmail: Boolean, $notificationsFrequency: String) {
    updateUserSettings(email: $email, firstName: $firstName, lastName: $lastName, password: $password, timezone: $timezone, sendNewExpertCallTranscriptEmail: $sendNewExpertCallTranscriptEmail, notificationsFrequency: $notificationsFrequency) {
      message
      status
    }
  }
`;

export const UPLOAD_AVATAR = gql`
  mutation uploadAvatar($file: Upload!) {
    uploadAvatar(file: $file) {
      message
      status
    }
  }
`;

export const UPLOAD_ACCOUNT_LOGOS = gql`
  mutation uploadAccountLogos($fullLogo: Upload, $avatarLogo: Upload) {
    uploadAccountLogos(fullLogo: $fullLogo, avatarLogo: $avatarLogo) {
      accountSettings {
        fullLogo
        avatarLogo
      }
    }
  }
`;
