/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';

// * MUI
import {
  Box, Divider, Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// * Hooks & Utils
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// * Components
import {
  Drawer,
  UpdateTranscriptButton,
  TranscriptText,
  EditTextField,
  ScrollContainer,
  FooterBlock,
  CloseButton,
} from './styledComponents';

// * Icons
import { ReactComponent as CloseIcon } from '../../../assets/icons/closeIcon.svg';
import { UPDATE_CALL_SUMMARY_MUTATION } from '../../../queries/calls/mutations';
import { AppContext } from '../../../components/AppContextProvider';

const schema = yup.object({
  newSummary: yup.string().required('Summary is required').trim(),
}).required();

type EditSummaryDrawerProps = {
  drawerOpen: boolean;
  summary: string;
  callUuid: string;
  handleToggleDrawer: (open: boolean) => void;
};

const EditSummaryDrawer = ({
  drawerOpen,
  summary,
  handleToggleDrawer,
  callUuid,
}: EditSummaryDrawerProps) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, dirtyFields, errors },
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { newSummary: '' },
  });
  const { refetchTranscript } = React.useContext(AppContext);
  useEffect(() => {
    reset({ newSummary: summary });
  }, [summary, reset]);

  const [updateSummary] = useMutation(UPDATE_CALL_SUMMARY_MUTATION);

  const onSubmit = (data: { newSummary: string }) => {
    updateSummary({
      variables: {
        callUuid,
        newSummary: data.newSummary,
      },
      onCompleted: () => {
        refetchTranscript?.();
      },
    });
    handleToggleDrawer(false);
  };

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => handleToggleDrawer(false)}
      onOpen={e => {
        e.stopPropagation();
        handleToggleDrawer(true);
      }}
    >
      <ScrollContainer>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" sx={{ padding: '20px 36px' }}>
            <CloseButton onClick={() => handleToggleDrawer(false)}>
              <CloseIcon />
            </CloseButton>
            <Typography variant="h3" color="colors.gray10" sx={{ fontSize: '24px' }}>
              Edit Transcript
            </Typography>
          </Box>
          <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
          <Box display="flex" flexDirection="column" flexGrow={1} sx={{ padding: '24px 32px' }}>
            <Typography
              variant="caption"
              color="colors.gray06"
              mb="8px"
              sx={{ fontWeight: 500 }}
            >
              Original Transcription
            </Typography>
            <Box p="24px">
              <Box display="flex" minHeight={48}>
                <Box display="flex" flexDirection="column">
                  <TranscriptText>{summary || ''}</TranscriptText>
                </Box>
              </Box>
            </Box>
            <Divider flexItem sx={{ borderColor: 'colors.gray03', marginBottom: '24px' }} />
            <Typography variant="subtitle1" mb="12px">
              Updated Summary
            </Typography>
            <Box display="flex">
              <Controller
                control={control}
                name="newSummary"
                render={({
                  field: {
                    onChange, value, ref,
                  },
                  fieldState: { error },
                }) => (
                  <EditTextField
                    fullWidth
                    minRows={4}
                    multiline
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    variant="outlined"
                    placeholder="Enter your updated summary..."
                    inputProps={{
                      onKeyDown: event => {
                        if (event.key === 'Enter' && !event.shiftKey) {
                          console.log('Enter pressed');
                        }
                      },
                      style: { whiteSpace: 'pre-wrap', wordWrap: 'break-word' },
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </ScrollContainer>
      <FooterBlock>
        <UpdateTranscriptButton
          variant="contained"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          disabled={
            isSubmitting
            || !dirtyFields.newSummary
            || !!errors.newSummary
            || summary === watch('newSummary')
          }
        >
          <CheckCircleIcon style={{ marginRight: '12px' }} />
          Update Transcript
        </UpdateTranscriptButton>
      </FooterBlock>
    </Drawer>
  );
};

export default EditSummaryDrawer;
