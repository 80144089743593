import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Button,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import palette from '../../theme/palette';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: palette.colors.gray08,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.colors.gray08,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: palette.white,
    border: '1px solid',
    borderColor: palette.colors.gray08,
    borderRadius: '6px',
    padding: '5px 8px',
    maxWidth: 326,
  },
});

export const SuspendButton = styled(Button)({
  borderColor: palette.colors.error03,
  borderRadius: '8px',
  color: palette.colors.error04,
  height: 36,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: 0,
  padding: '10px',
  margin: '0 12px',
  '&:hover': {
    backgroundColor: palette.colors.error03,
    borderColor: palette.colors.error03,
    color: palette.white,
  },
});

export const ResumeButton = styled(Button)({
  borderColor: palette.primary.main,
  borderRadius: '8px',
  color: palette.primary.main,
  height: 36,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: 0,
  padding: '10px',
  margin: '0 12px',
  '&:hover': {
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
    color: palette.white,
  },
});

export const ReviewBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '12px',
  padding: '24px 24px 16px',
});

export const CancelButton = styled(Button)({
  borderColor: palette.colors.gray05,
  borderRadius: '15px',
  color: palette.colors.gray05,
  width: 200,
  height: 58,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '10px 12px',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: palette.colors.gray05,
    borderColor: palette.colors.gray05,
    color: palette.white,
  },
});

export const SubmitButton = styled(Button)({
  backgroundColor: palette.colors.error03,
  borderRadius: '15px',
  width: 200,
  height: 58,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '10px 12px',
  '&:hover': {
    backgroundColor: palette.colors.error04,
  },
});
