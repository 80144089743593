/* eslint-disable import/order */
// * MUI
import { PopoverOrigin } from '@mui/material/Popover';

// * Hooks & Utils
import palette from '../../../theme/palette';

// * Interfaces
import type { MenuProps as MUIMenuProps } from '@mui/material';

export const MenuProps: Partial<MUIMenuProps> = {
  autoFocus: false,
  anchorOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin,
  transformOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin,
  PaperProps: {
    style: {
      maxHeight: '275px',
      width: '310px',
      border: '1px solid',
      borderColor: palette.primary.main,
      borderRadius: '8px',
      boxShadow: '0px 8px 9px rgba(226, 226, 226, 0.35)',
    },
  },
};

export default {
  MenuProps,
};
