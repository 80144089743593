import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Button, Box, Dialog,
  InputAdornment, Stack, TextField, Typography, CircularProgress, IconButton,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoginLogoImg from '../../assets/img/loginLogoImg.png';
import LoginLogoImgWebp from '../../assets/img/loginLogoImg.webp';
import LoginLogoMobImg from '../../assets/img/loginLogoMobImg.png';
import LoginLogoMobImgWebp from '../../assets/img/loginLogoMobImg.webp';
import paths from '../../paths/paths';
import { RESET_PASSWORD_MUTATION } from '../../queries/auth/account';

const errorMessages = {
  oneUpper: 'Contain at least one upper-case letter',
  oneLower: 'Contain at least one lower-case letter',
  oneSpecial: 'Contain at least one special character',
  oneNumeric: 'Contain at least 1 numeric character',
  min: 'Contain at least 8 characters',
};

const schema = yup.object().shape({
  password1: yup.string()
    .test('oneUpper', errorMessages.oneUpper, value => /[A-Z]+/.test(value!))
    .test('oneLower', errorMessages.oneLower, value => /[a-z]+/.test(value!))
    .test('oneSpecial', errorMessages.oneSpecial, value => /[`~!@#$%^&*()_+\-={}|[\]\\:";'<>?,./]+/.test(value!))
    .test('oneNumeric', errorMessages.oneNumeric, value => /[0-9]+/.test(value!))
    .min(8, errorMessages.min)
    .required('Field is required'),
  password2: yup.string()
    .oneOf([yup.ref('password1')], 'Those passwords didn\'t match')
    .required('Field is required'),
});

type ResetPasswordProps = {
  title: string;
  subtitle: string;
  successMessage: string;
  buttonText: string;
};

const ResetPassword = ({
  title,
  subtitle,
  successMessage,
  buttonText,
}: ResetPasswordProps) => {
  const [open, setOpen] = React.useState(false);
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const { hashid } = useParams<{ hashid: string }>();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const triggerPassword1Visibility = () => {
    setShowPassword1(!showPassword1);
  };

  const triggerPassword2Visibility = () => {
    setShowPassword2(!showPassword2);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const {
    handleSubmit,
    watch,
    resetField,
    formState: {
      errors, isSubmitting, isDirty, isValid,
    },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const watchPassword1 = watch('password1');
  const watchPassword2 = watch('password2');
  const isButtonDisabled = !isDirty || !isValid;

  const handleClose = () => {
    setOpen(false);
    resetField('password1');
    resetField('password2');
    navigate(paths.signInEmail.pattern);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [resetPassword] = useMutation(RESET_PASSWORD_MUTATION, {
    onCompleted: () => {
      handleOpen();
    },
  });

  const onSubmit = (data: any) => {
    resetPassword({
      variables: {
        password: data.password2,
        resetPasswordLinkUuid: hashid,
      },
    });
  };

  const password1Check = (itemType: any) => {
    // @ts-ignore
    if (isEmpty(watchPassword1) || (errors?.password1?.types && errors.password1.types[itemType])) {
      return <CheckCircleOutlineIcon sx={{ color: '#E4E8EE' }} />;
    }
    return <CheckCircleOutlineIcon sx={{ color: '#23A566' }} />;
  };

  const password2Check = () => {
    if (isEmpty(watchPassword2) || (errors?.password2?.types) || !isValid) {
      return <CheckCircleOutlineIcon sx={{ color: '#E4E8EE' }} />;
    }
    return <CheckCircleOutlineIcon sx={{ color: '#23A566' }} />;
  };

  return (
    // layout container start
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'colors.gray01',
        height: isMobile ? 'auto' : '100vh',
        minHeight: '100vh',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: isMobile ? '30px 0' : 0,
          }}
        >
          <img
            src={isMobile ? LoginLogoMobImg : LoginLogoImg}
            srcSet={isMobile ? LoginLogoMobImgWebp : LoginLogoImgWebp}
            alt="logo"
            loading="lazy"
            width={isMobile ? 197 : 266}
            height={isMobile ? 59 : 80}
          />
          <Stack
            direction={isMobile ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
            spacing={isMobile ? '30px' : '90px'}
            sx={{
              marginTop: isMobile ? '30px' : '64px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: isMobile ? '35px 15px' : '55px 29px',
                maxWidth: isMobile ? '345px' : '410px',
                backgroundColor: 'background.paper',
                boxShadow: '0px 44px 65px rgba(176, 183, 195, 0.19)',
                borderRadius: '15px',
                zIndex: 1,
              }}
            >
              {/* layout container end */}
              <Typography
                variant="h4"
                color="colors.gray10"
                textAlign="center"
                mb={isMobile ? '20px' : '25px'}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                color="colors.gray05"
                textAlign="center"
                mb={isMobile ? '25px' : '36px'}
              >
                {subtitle}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack alignItems="center" justifyContent="center">
                  <Controller
                    control={control}
                    name="password1"
                    render={({
                      field: {
                        onChange, value, name, ref,
                      },
                    }) => (
                      <TextField
                        inputRef={ref}
                        name={name}
                        value={value}
                        onChange={onChange}
                        type={showPassword1 ? 'text' : 'password'}
                        variant="outlined"
                        placeholder="Set Password"
                        sx={{
                          width: isMobile ? '320px' : '352px',
                          marginBottom: isMobile ? '25px' : '30px',
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={triggerPassword1Visibility}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="password2"
                    render={({
                      field: {
                        onChange, value, name, ref,
                      },
                    }) => (
                      <TextField
                        inputRef={ref}
                        name={name}
                        value={value}
                        onChange={onChange}
                        type={showPassword2 ? 'text' : 'password'}
                        variant="outlined"
                        placeholder="Confirm Password"
                        sx={{
                          width: isMobile ? '320px' : '352px',
                          marginBottom: isMobile ? '25px' : '30px',
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <LockIcon />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={triggerPassword2Visibility}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Button
                    variant="contained"
                    size="large"
                    disabled={isButtonDisabled}
                    type="submit"
                    fullWidth
                  >
                    {!isSubmitting ? buttonText : <CircularProgress size={16} />}
                  </Button>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: isMobile ? '35px 15px' : '55px 29px',
                      }}
                    >
                      <Typography
                        variant="h3"
                        color="colors.gray10"
                        textAlign="center"
                        mb="25px"
                        sx={{
                          maxWidth: isMobile ? '352px' : '428px',
                        }}
                      >
                        {successMessage}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="colors.gray05"
                        textAlign="center"
                        mb="36px"
                      >
                        Please sign in to get started
                      </Typography>
                      <Button
                        variant="contained"
                        size="large"
                        href={paths.signInEmail.pattern}
                        sx={{
                          width: isMobile ? '100%' : '352px',
                          maxWidth: '352px',
                        }}
                      >
                        Sign In
                      </Button>
                    </Box>
                  </Dialog>
                </Stack>
              </form>
              {/* layout container start */}
            </Box>
            <Box>
              {Object.entries(errorMessages).map(([errorType, errorMessage]) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: '5px',
                  }}
                >
                  {password1Check(errorType)}
                  <Typography
                    variant="body1"
                    color="colors.gray10"
                    sx={{
                      lineHeight: '29px',
                      marginLeft: '10px',
                    }}
                  >
                    {errorMessage}
                  </Typography>
                </Box>
              ))}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginBottom: '5px',
                }}
              >
                {password2Check()}
                <Typography
                  variant="body1"
                  color="colors.gray10"
                  sx={{
                    lineHeight: '29px',
                    marginLeft: '10px',
                  }}
                >
                  Both password are a match
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
    // layout container end
  );
};

export default ResetPassword;
