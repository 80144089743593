import React from 'react';

// * MUI
import { Typography } from '@mui/material';

const DeletedParagraph = () => (
  <Typography variant="body1" color="colors.gray06" sx={{ fontWeight: 400 }}>
    {'<deleted>'}
  </Typography>
);

export default DeletedParagraph;
