import React from 'react';
import Box from '@mui/material/Box';

type TopicsSummaryTabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TopicsSummaryTabPanel = (props: TopicsSummaryTabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`topics-summary-tabpanel-${index}`}
      aria-labelledby={`topics-summary-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </Box>
  );
};

export default TopicsSummaryTabPanel;
