import React, { useState } from 'react';

// * MUI
import {
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material';

interface DownloadFileProps {
  onDownload: () => Promise<void>
  IconComponent: React.ElementType
  fileType: string
  disabled?: boolean
}

const DownloadFile = ({
  onDownload,
  IconComponent,
  fileType,
  disabled,
}: DownloadFileProps) => {
  const [downloading, setDownloading] = useState(false);

  const handleDownload = async (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    setDownloading(true);
    try {
      await onDownload();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error downloading the ${fileType}`, error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <MenuItem
      onClick={disabled ? undefined : handleDownload}
      disabled={disabled || downloading}
    >
      <ListItemIcon>
        {downloading ? <CircularProgress size={24} /> : <IconComponent />}
      </ListItemIcon>
      <ListItemText>
        {downloading ? 'Downloading...' : fileType.toUpperCase()}
      </ListItemText>
    </MenuItem>
  );
};

DownloadFile.defaultProps = {
  disabled: undefined,
};

export default DownloadFile;
