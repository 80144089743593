import React from 'react';
import TopicItemCard from '../Cards/TopicItemCard';
import { TopicType } from '../../shared/types/topics';

type TopicListProps = {
  topics: TopicType[];
  // eslint-disable-next-line
  onSelect: (uuid: string) => void;
  selectedTopicUuid?: string;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TopicList: React.FC<TopicListProps> = ({
  topics, onSelect, selectedTopicUuid,
  openDrawerMobile, setOpenDrawerMobile,
}) => (
  <>
    {topics.map(topic => (
      <TopicItemCard
        key={topic.uuid}
        uuid={topic.uuid}
        title={topic.name}
        callsCount={topic.callsCount}
        onClick={() => onSelect(topic.uuid)}
        selected={topic.uuid === selectedTopicUuid}
        openDrawerMobile={openDrawerMobile}
        setOpenDrawerMobile={setOpenDrawerMobile}
      />
    ))}
  </>
);

TopicList.defaultProps = {
  selectedTopicUuid: '',
  openDrawerMobile: false,
  setOpenDrawerMobile: () => {},
};

export default TopicList;
