import React from 'react';

// * MUI
import { Box, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const NoCalls = () => (
  <Box textAlign="center" p={5}>
    <InfoOutlinedIcon sx={{ fontSize: 60, color: 'primary.main' }} />
    <Typography variant="h4" gutterBottom>The topic must contain content</Typography>
    <Typography variant="subtitle1" gutterBottom>Please add content to the topic to proceed.</Typography>
  </Box>
);

export default NoCalls;
