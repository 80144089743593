import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Divider, Typography } from '@mui/material';

type CallDetailsOutlinedMoreItemProps = {
  children: any;
};

const CallDetailsOutlinedMoreItem = ({
  children,
}: CallDetailsOutlinedMoreItemProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Typography
        variant="body1"
        color="colors.gray08"
        sx={{ fontSize: isMobile ? '14px' : '16px', fontWeight: 400, lineHeight: '22px' }}
      >
        {children}
      </Typography>
      <Divider flexItem sx={{ margin: '16px 0', borderColor: 'colors.gray01' }} />
    </Box>
  );
};

export default CallDetailsOutlinedMoreItem;
