import * as React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { ReactComponent as TranscriptionFailedImg } from '../../assets/img/transcriptionFailedImg.svg';

const CallNotFound = () => (
  <Box display="flex" height="100%">
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: '50px 0',
      }}
    >
      <TranscriptionFailedImg />
      <Typography
        variant="h5"
        sx={{ marginTop: '27px' }}
      >
        Content Not Found
      </Typography>
      <Typography
        variant="body1"
        color="colors.gray06"
        align="center"
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
          marginTop: '8px',
        }}
      >
        <p>We apologies for your inconvenience.</p>
        <p>You can reach out to tech support.</p>
      </Typography>
      <Link
        href="mailto:support@encorecompliance.com"
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
          textTransform: 'none !important',
        }}
      >
        support@encorecompliance.com
      </Link>
    </Box>
  </Box>
);

export default CallNotFound;
