import React from 'react';

// * MUI
import { Box, Tabs, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

// * Utils
import palette from '../../theme/palette';

export const Main = styled('main')({
  flexGrow: 1,
  marginTop: '72px',
  backgroundColor: palette.colors.gray01,
  overflow: 'auto',
});

export const CardContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '32px',
  borderRadius: '32px',
  backgroundColor: palette.white,
});

export const CustomTabs = styled(Tabs)({
  marginTop: '24px',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});

interface StyledTabProps {
  label: string;
  disabled?: boolean;
}

export const CustomTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)({
  textTransform: 'none',
  minWidth: 0,
  minHeight: '32px',
  width: '132px',
  marginRight: '8px',
  borderRadius: '52px',
  color: 'rgba(0, 0, 0, 0.54)',
  '&.Mui-selected': {
    backgroundColor: palette.primary.main,
    color: palette.white,
  },
  '&:not(.Mui-selected)': {
    backgroundColor: 'transparent',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '&.Mui-selected.Mui-disabled': {
    backgroundColor: palette.colors.gray03,
    color: 'rgba(0, 0, 0, 0.26)',
  },
});
