import React from 'react';

// * Hooks
import { useMutation } from '@apollo/client';

// * Components
import DownloadFile from '../DownloadFile';

// * Icons
import { ReactComponent as DownloadPDFIcon } from '../../../assets/icons/downloadPDF.svg';

// * Mutations
import { GET_PDF_MUTATION } from '../../../queries/transcript/transcript';

const DownloadPDF = ({
  transcriptUuid,
  fileName,
}: {
  transcriptUuid: string
  fileName: string
}) => {
  const [getPDF] = useMutation(GET_PDF_MUTATION);

  const handleDownloadPDF = async () => getPDF({ variables: { transcriptUuid } }).then(response => {
    const { pdfBase64 } = response.data.getPdfTranscript;
    const link = document.createElement('a');
    link.href = `data:application/pdf;base64,${pdfBase64}`;
    link.download = `${fileName}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });

  return (
    <DownloadFile
      onDownload={handleDownloadPDF}
      IconComponent={DownloadPDFIcon}
      fileType="pdf"
    />
  );
};

export default DownloadPDF;
