import React from 'react';

// * Hooks
import { useMutation } from '@apollo/client';

// * Components
import DownloadFile from '../DownloadFile';

// * Icons
import { ReactComponent as DownloadJSONIcon } from '../../../assets/icons/downloadJSON.svg';

// * Mutations
import { GET_JSON_MUTATION } from '../../../queries/transcript/transcript';

const DownloadJSON = ({
  transcriptUuid,
  fileName,
}: {
  transcriptUuid: string
  fileName: string
}) => {
  const [getJSON] = useMutation(GET_JSON_MUTATION);

  const handleDownloadJSON = () => getJSON({ variables: { transcriptUuid } }).then(response => {
    const { jsonObject } = response.data.getJsonTranscript;
    const blob = new Blob([jsonObject], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  });

  return (
    <DownloadFile
      onDownload={handleDownloadJSON}
      IconComponent={DownloadJSONIcon}
      fileType="json"
    />
  );
};

export default DownloadJSON;
