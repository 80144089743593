/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// * MUI
import {
  Box, Divider, Typography, Table, TableBody, TableRow,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// * Hooks & Utils
import { useMutation } from '@apollo/client';

// * Components
import {
  DrawerContainer,
  ScrollContainer,
  FooterBlock,
  EditButton,
  CloseButton,
  ImportFullButton,
  TextInputField,
  StyledTableContainer,
  TitleTableCell,
  InputTableCell,
} from './styledComponents';

// * Icons
import { ReactComponent as PencilFilledIcon } from '../../assets/icons/pencilFilledIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeIcon.svg';

import { ParticipantType } from '../../shared/types/transcript';

// * Mutations
import { UPDATE_PARTICIPANTS_MUTATION } from '../../queries/transcript/transcript';

type EditSpeakersProps = {
  participants: ParticipantType[];
  handleEditParticipants: (updatedParticipants: ParticipantType[]) => void;
}

const EditSpeakers = ({ participants, handleEditParticipants }: EditSpeakersProps) => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [updatedNames, setUpdatedNames] = useState<{ [key: string]: string }>({});
  const [canUpdate, setCanUpdate] = useState(false);
  const [updateParticipants] = useMutation(UPDATE_PARTICIPANTS_MUTATION);

  const toggleDrawer = (open: boolean) => () => {
    setOpenSidebar(open);
  };

  const handleInputChange = (uuid: string, value: string) => {
    setUpdatedNames({ ...updatedNames, [uuid]: value });
  };

  const handleSubmit = async () => {
    const updates = participants.map(participant => ({
      participantUuid: participant.uuid,
      name: updatedNames[participant.uuid] || participant.name,
    }));

    const response = await updateParticipants({ variables: { updates } });
    if (!response.errors) {
      handleEditParticipants(participants.map(participant => ({
        ...participant,
        name: updatedNames[participant.uuid] || participant.name,
      })));
    }
    setOpenSidebar(false);
  };

  useEffect(() => {
    const initialNames = participants.reduce((acc, participant) => {
      acc[participant.uuid] = participant.name;
      return acc;
    }, {} as { [key: string]: string });

    setUpdatedNames(initialNames);
  }, [participants]);

  useEffect(() => {
    const allFieldsEmpty = Object.values(updatedNames).every(name => name.trim() === '');
    const anyFieldChanged = participants.some(participant => updatedNames[participant.uuid] !== participant.name);
    setCanUpdate(!allFieldsEmpty && anyFieldChanged);
  }, [updatedNames, participants]);

  return (
    <Box display="flex">
      <EditButton
        onClick={toggleDrawer(true)}
        startIcon={<PencilFilledIcon />}
      >
        Edit Speakers
      </EditButton>
      <DrawerContainer
        anchor="right"
        open={openSidebar}
        onClose={toggleDrawer(false)}
      >
        <ScrollContainer>
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              sx={{ padding: '20px 36px' }}
            >
              <CloseButton onClick={toggleDrawer(false)}>
                <CloseIcon />
              </CloseButton>
              <Typography
                variant="h3"
                color="colors.gray10"
                sx={{ fontSize: '24px' }}
              >
                Edit Speakers
              </Typography>
            </Box>
            <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
              sx={{ padding: '24px 32px' }}
            >
              <Typography variant="caption" color="colors.gray06" mb="12px">
                Speakers
              </Typography>
              <StyledTableContainer>
                <Table aria-label="speakers table">
                  <TableBody>
                    {participants.map(participant => (
                      <TableRow key={participant.uuid} sx={{ '&:last-child td': { borderBottom: 0 } }}>
                        <TitleTableCell>
                          {participant.originalName}
                        </TitleTableCell>
                        <InputTableCell>
                          <TextInputField
                            name={participant.uuid}
                            value={updatedNames[participant.uuid] || ''}
                            onChange={e => handleInputChange(participant.uuid, e.target.value)}
                            inputProps={{ maxLength: 20 }}
                            variant="outlined"
                            placeholder="Enter Name"
                          />
                        </InputTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </Box>
          </Box>
        </ScrollContainer>
        <FooterBlock>
          <ImportFullButton
            variant="contained"
            fullWidth
            onClick={handleSubmit}
            disabled={!canUpdate}
          >
            <CheckCircleIcon style={{ marginRight: '12px' }} />
            Update
          </ImportFullButton>
        </FooterBlock>
      </DrawerContainer>
    </Box>
  );
};

export default EditSpeakers;
