import { styled } from '@mui/material/styles';
import {
  Box, IconButton, Drawer, TextField, Button, TableContainer, TableCell,
} from '@mui/material';
import palette from '../../theme/palette';

export const DrawerContainer = styled(Drawer)({
  width: 850,
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: 850,
    boxSizing: 'border-box',
    boxShadow: '-11px 0px 60px rgba(0, 0, 0, 0.25)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
});

export const ScrollContainer = styled(Box)({
  height: '100%',
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const FooterBlock = styled(Box)({
  borderTop: '1px solid',
  borderTopColor: palette.colors.gray03,
  backgroundColor: palette.colors.gray01,
  padding: '24px 32px',
});

export const EditButton = styled(Button)({
  color: palette.primary.main,
  '&:hover': {
    color: palette.primary.dark,
    textDecoration: 'none',
  },
});

export const CloseButton = styled(IconButton)({
  padding: 0,
  marginRight: '20px',
});

export const ImportFullButton = styled(Button)({
  backgroundColor: palette.colors.success03,
  borderRadius: '8px',
  height: 52,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '26px',
  letterSpacing: 0,
  padding: '12px',
  '&:hover': {
    backgroundColor: palette.colors.success04,
  },
  '&.Mui-disabled': {
    backgroundColor: palette.colors.success02,
  },
});

export const TextInputField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '22px',
    padding: 0,
    borderRadius: 0,
    '& .MuiOutlinedInput-input': {
      height: 22,
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
      borderBottom: '1px solid',
      borderColor: 'transparent',
    },
  },
});

export const StyledTableContainer = styled(TableContainer)({
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '12px',
});

export const TitleTableCell = styled(TableCell)({
  borderBottom: '1px solid',
  borderRight: '1px solid',
  borderColor: palette.colors.gray03,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  padding: '12px',
});

export const InputTableCell = styled(TableCell)({
  borderBottom: '1px solid',
  borderColor: palette.colors.gray03,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  padding: '12px',
});
