import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Divider, Skeleton } from '@mui/material';

const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '12px 17px 12px 24px',
});

const ScrollContainer = styled(Box)({
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const CustomSkeleton = styled(Skeleton)({
  background: 'linear-gradient(90.12deg, rgba(202, 202, 202, 0.3) -0.85%, rgba(157, 180, 203, 0) 100.45%)',
  borderRadius: '12px',
  display: 'block',
});

const HighlightsSkeleton = () => (
  <MainContainer>
    <ScrollContainer>
      <Box display="flex" flexDirection="column">
        <CustomSkeleton
          variant="rectangular"
          width="100%"
          height={24}
        />
        <Divider flexItem sx={{ margin: '12px 0', borderColor: 'colors.gray03' }} />
        <CustomSkeleton
          variant="rectangular"
          width="100%"
          height={54}
        />
        <Divider flexItem sx={{ margin: '12px 0', borderColor: 'colors.gray03' }} />
        <CustomSkeleton
          variant="rectangular"
          width="100%"
          height={240}
          sx={{ marginBottom: '12px' }}
        />
        <CustomSkeleton
          variant="rectangular"
          width="100%"
          height={240}
          sx={{ marginBottom: '12px' }}
        />
        <CustomSkeleton
          variant="rectangular"
          width="100%"
          height={240}
          sx={{ marginBottom: '12px' }}
        />
        <CustomSkeleton
          variant="rectangular"
          width="100%"
          height={240}
        />
      </Box>
    </ScrollContainer>
  </MainContainer>
);

export default HighlightsSkeleton;
