/* eslint-disable import/order, no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';

// * MUI
import {
  Box, Typography, Divider, Grid, Tab,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// * Components
import { CallDetailsItem, ComplianceReviewer } from '../../../../components';
import TranscriptView from '../../../../components/TranscriptView';
import AudioPlayer from '../../../../components/AudioPlayer';
import MoveToTopButton from '../../../../components/MoveToTopButton';
import RelatedCalls from '../../../../components/RelatedCalls';
import CallsSummaryTabPanel from '../../../../components/Tabs/CallsSummaryTabPanel';
import ChatInterface from '../../../../components/ChatInterface';
import Notes from '../Notes';
import {
  MainContainer,
  ScrollContainer,
  StickyBox,
  AudioSmallBox,
  DrawerTabs,
  HighlightDot,
  HighlightStatus,
  callsSummaryA11yProps,
} from '../styledComponents';
import { AvatarLarge } from '../../../../components/AccountSwitcher/styledComponents';

// * Icons
import { ReactComponent as NoteIcon } from '../../../../assets/icons/noteIcon.svg';

// * Hooks & Utils
import { useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useMe } from '../../../../components/AppContextProvider';
import { formatDateTimeToUserTimezone } from '../../../../shared/utils/dateHelpers';

// * Mutations
import { MY_ACCOUNT_SETTINGS } from '../../../../queries/auth/account';
import { ASSIGN_USER_TO_REVIEW_MUTATION, TRANSCRIPT_QUERY } from '../../../../queries/transcript/transcript';

// * Interfaces
import type {
  ParagraphHistoryType, ParagraphType, TranscriptType, HighlightType,
  CallTypes,
} from '../../../../shared/types/transcript';

type ResearchCallDetailsProps = {
  transcript: TranscriptType;
  selectedHighlight: HighlightType | null;
  setSelectedHighlight: React.Dispatch<React.SetStateAction<HighlightType | null>>;
  isScheduledTab: boolean;
  accountName: string;
  accountAvatarLogo: string;
  audio: HTMLAudioElement | null;
  isPlayingMain: boolean;
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayingParagraph: boolean;
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>;
  onSelect: (uuid: string) => void;
};

const ResearchCallDetails = ({
  transcript,
  selectedHighlight,
  setSelectedHighlight,
  isScheduledTab,
  accountName,
  accountAvatarLogo,
  audio,
  isPlayingMain,
  setIsPlayingMain,
  isPlayingParagraph,
  setIsPlayingParagraph,
  onSelect,
}: ResearchCallDetailsProps) => {
  const { me } = useMe();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data: { myAccountSettings: settings = {} } = {} } = useQuery(MY_ACCOUNT_SETTINGS);

  const [callsSummaryTabIndex, setCallsSummaryTabIndex] = useState(0);
  const [transcriptParagraphs, setTranscriptParagraphs] = useState<ParagraphType[]>(transcript.paragraphs);

  const { isResearchAnalyst } = me;
  const { call } = transcript;
  const callStatus = call.status.value;
  const { isFileAText } = call.transcript;

  const isMeeting = call.type === 'meeting';
  const isCall = call.type === 'call';

  const updateParagraphText = useCallback((paragraphUuid: string, newLastHistory: ParagraphHistoryType) => {
    setTranscriptParagraphs(prevState => prevState.map(paragraph => {
      if (paragraph.uuid !== paragraphUuid) return paragraph;

      return { ...paragraph, text: newLastHistory.newText, lastHistory: newLastHistory };
    }));
  }, []);

  const handleScroll = () => {
    const headerHeight = 160;
    const anchor = document.getElementById('PlayerAnchor') as HTMLElement;
    const staticPlayer = document.getElementById('StaticAudioPlayer') as HTMLElement;
    const stickyPlayer = document.getElementById('StickyAudioPlayer') as HTMLElement;
    const mobileArrow = document.getElementById('MobileArrowMoveUp') as HTMLElement;
    let playerShouldSticky;
    let arrowShouldShow;
    if (isMobile && anchor && mobileArrow) {
      arrowShouldShow = anchor.getBoundingClientRect().top < headerHeight;
      if (arrowShouldShow) {
        mobileArrow.style.display = 'flex';
      } else {
        mobileArrow.style.display = 'none';
      }
    } else if (!isMobile && anchor && staticPlayer && stickyPlayer) {
      playerShouldSticky = anchor.getBoundingClientRect().top < headerHeight;
      if (playerShouldSticky) {
        staticPlayer.style.display = 'none';
        stickyPlayer.style.display = 'flex';
      } else {
        staticPlayer.style.display = 'flex';
        stickyPlayer.style.display = 'none';
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCallsSummaryTabIndex(newValue);
  };

  useEffect(() => {
    setTranscriptParagraphs(transcript.paragraphs);
  }, [transcript.paragraphs]);

  const [assignUser] = useMutation(ASSIGN_USER_TO_REVIEW_MUTATION, {
    onCompleted: ({ assignUserToReview }: {
      assignUserToReview: {
        message: string
        reviewer: TranscriptType['call']['review']['reviewer']
      }
    }) => {
      toast.success(assignUserToReview.message);
    },
    refetchQueries: [
      { query: TRANSCRIPT_QUERY, variables: { uuid: transcript.uuid } },
    ],
  });

  const typeLabel: Record<CallTypes, string> = {
    meeting: 'Meeting',
    call: 'Expert Call',
    document: 'Document',
  };

  return (
    <MainContainer>
      {isScheduledTab || (settings.requireComplianceReview && transcript.call.review.status.value !== 'Reviewed') ? '' : (
        <Box display="flex">
          {isMobile ? (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%">
              {!transcript.disableAudio && !isFileAText && (
                <AudioSmallBox>
                  <AudioPlayer
                    audio={audio}
                    isPlayingMain={isPlayingMain}
                    setIsPlayingMain={setIsPlayingMain}
                    isPlayingParagraph={isPlayingParagraph}
                    setIsPlayingParagraph={setIsPlayingParagraph}
                    stickyPlayer
                    playParagraph={false}
                  />
                </AudioSmallBox>
              )}
            </Box>
          ) : (
            <StickyBox id="StickyAudioPlayer">
              <Box display="flex" alignItems="center">
                <NoteIcon />
                <Typography variant="subtitle1" color="colors.grey10" sx={{ marginLeft: '12px' }}>
                  Transcript
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                {!transcript.disableAudio && !isFileAText && (
                  <AudioSmallBox>
                    <AudioPlayer
                      audio={audio}
                      isPlayingMain={isPlayingMain}
                      setIsPlayingMain={setIsPlayingMain}
                      isPlayingParagraph={isPlayingParagraph}
                      setIsPlayingParagraph={setIsPlayingParagraph}
                      stickyPlayer
                      playParagraph={false}
                    />
                  </AudioSmallBox>
                )}
                <MoveToTopButton />
              </Box>
            </StickyBox>
          )}
        </Box>
      )}
      <ScrollContainer onScroll={handleScroll} id="scrollableRightColumn" sx={{ padding: isMobile ? '12px 8px' : '24px 12px' }}>
        <Grid container rowSpacing={isMobile ? 0 : '6px'} columnSpacing={isMobile ? 0 : '28px'} sx={{ padding: '0 12px' }}>
          {!isScheduledTab && (!settings.requireComplianceReview || transcript.call.review.status.value === 'Reviewed') && (
            <Grid item xs={12}>
              <Notes call={call} />
            </Grid>
          )}
          {me.isAcaReviewer && (
            <Grid item xs={12}>
              <CallDetailsItem title="Client Name" sx={{ marginBottom: '12px' }}>
                <Box display="flex" alignItems="center" textTransform="capitalize">
                  {accountAvatarLogo && <AvatarLarge alt={accountName} src={accountAvatarLogo} />}
                  <Typography
                    variant="overline"
                    color="colors.gray08"
                    sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}
                  >
                    {accountName}
                  </Typography>
                </Box>
              </CallDetailsItem>
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <CallDetailsItem title="Date & Time" sx={{ marginBottom: '12px' }}>
              <Box display="flex" alignItems="center">
                {(isScheduledTab && (callStatus === 'In Progress' || callStatus === 'Canceled')) && (
                  <HighlightDot sx={{ backgroundColor: callStatus === 'Canceled' ? 'colors.warning04' : 'colors.success04' }} />
                )}
                <Typography
                  variant="overline"
                  color="colors.gray08"
                  sx={{ textTransform: 'none' }}
                >
                  {formatDateTimeToUserTimezone(call.datetime, me.timezone)}
                </Typography>
                {(isScheduledTab && (callStatus === 'In Progress' || callStatus === 'Canceled')) && (
                  <HighlightStatus
                    variant="body1"
                    color={callStatus === 'Canceled' ? 'colors.warning04' : 'colors.success04'}
                  >
                    {callStatus}
                  </HighlightStatus>
                )}
              </Box>
            </CallDetailsItem>
          </Grid>
          {isMeeting && (
            <Grid item xs={12} md={9}>
              <CallDetailsItem title="Meeting Participants" sx={{ marginBottom: '12px' }}>
                <Box display="flex" flexWrap="wrap">
                  {Array.isArray(transcript.meetingParticipants) && transcript.meetingParticipants.length > 0 ? (
                    [...transcript.meetingParticipants]
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((participant, index) => (
                        <Box key={participant.email} display="flex" flexWrap="nowrap" mr="4px">
                          <Typography variant="subtitle1" color="colors.gray08" mr="3px">
                            {`${participant.name} |`}
                          </Typography>
                          <Typography variant="subtitle1" color="colors.gray08" sx={{ fontWeight: 400 }}>
                            {participant.email}
                            {index < transcript.meetingParticipants.length - 1 && ','}
                          </Typography>
                        </Box>
                      ))
                  ) : (
                    <Typography variant="subtitle1" color="colors.gray08" sx={{ lineHeight: '36px' }}>
                      Not available
                    </Typography>
                  )}
                </Box>
              </CallDetailsItem>
            </Grid>
          )}
          {isCall && (
            <>
              <Grid item xs={12} md={3}>
                <CallDetailsItem title="Project ID" isTypography sx={{ marginBottom: '12px' }}>
                  {call.projectId || 'Not Available'}
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <CallDetailsItem title="Expert Name" isTypography sx={{ marginBottom: '12px' }}>
                  {call.expertName || 'Not Available'}
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <CallDetailsItem title="Expert Perspective" isTypography sx={{ marginBottom: '12px' }}>
                  {call.expertPerspective || 'Not Available'}
                </CallDetailsItem>
              </Grid>
            </>
          )}
          {(transcript.status.value === 'Completed' && !isScheduledTab) && (
            <Grid item xs={12} md={3}>
              <CallDetailsItem title="Compliance Reviewer" sx={{ marginBottom: '12px' }}>
                <ComplianceReviewer
                  assignUser={assignUser}
                  instanceUuid={call.review.uuid}
                  initialAssignee={call.review.reviewer}
                  signedInUserUuid={me.uuid}
                  placeholder="Unassigned"
                  disabled
                />
              </CallDetailsItem>
            </Grid>
          )}
          {isCall && (
            <>
              <Grid item xs={12} md={3}>
                <CallDetailsItem title="Expert Network" isTypography sx={{ marginBottom: '12px' }}>
                  {call.network.value}
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12} md={3}>
                <CallDetailsItem title="Analyst(s)" isTypography sx={{ marginBottom: '12px' }}>
                  {call.analystName || 'Not Available'}
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12}>
                <CallDetailsItem title="Employment History" sx={{ marginBottom: '12px' }}>
                  <Typography variant="body1" color="colors.gray08" sx={{ fontWeight: 400, whiteSpace: 'break-spaces' }}>
                    {call.expertEmploymentHistory || 'Not Available'}
                  </Typography>
                </CallDetailsItem>
              </Grid>
              <Grid item xs={12}>
                <CallDetailsItem title="Expert Consulting Summary" sx={{ marginBottom: '12px' }}>
                  <Typography variant="body1" color="colors.gray08" sx={{ fontWeight: 400, whiteSpace: 'break-spaces' }}>
                    {call.expertConsultingSummary || 'Not Available'}
                  </Typography>
                </CallDetailsItem>
              </Grid>
            </>
          )}
        </Grid>
        {isScheduledTab || (settings.requireComplianceReview && transcript.call.review.status.value !== 'Reviewed') ? (
          <Box mt="45px" />
        ) : (
          <>
            <Divider flexItem sx={{ margin: '10px 12px', borderColor: 'colors.gray03' }} />
            <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'colors.gray03', margin: isMobile ? '0 -8px' : '0 12px' }}>
              <DrawerTabs value={callsSummaryTabIndex} onChange={handleTabChange} aria-label="calls-summary-tabs">
                <Tab label="Summary" {...callsSummaryA11yProps(0)} />
                <Tab label="Chat" {...callsSummaryA11yProps(1)} />
              </DrawerTabs>
            </Box>
            <CallsSummaryTabPanel value={callsSummaryTabIndex} index={0}>
              <Box sx={{
                display: 'flex', flexWrap: 'wrap', flexDirection: isMobile ? 'column' : 'row', padding: '0 12px', marginTop: '24px',
              }}
              >
                <CallDetailsItem title="Topic(s)" sx={{ width: !isMobile ? '50%' : 'auto', marginBottom: '12px' }} isTypography>
                  {call.topic || 'Not Available'}
                </CallDetailsItem>
                <CallDetailsItem title="Sentiment" sx={{ width: !isMobile ? '50%' : 'auto', marginBottom: '12px' }} isTypography>
                  {call.sentiment || 'Not Available'}
                </CallDetailsItem>
              </Box>
              {call.summary && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '0 12px' }}>
                  <CallDetailsItem title={`${typeLabel[call.type]} Summary`}>
                    <Typography variant="body1" color="colors.gray08" mt="4px" sx={{ fontWeight: 400, whiteSpace: 'break-spaces' }}>
                      {call.summary}
                    </Typography>
                  </CallDetailsItem>
                </Box>
              )}
              {transcript.tickers && !!transcript.tickers.length && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', padding: '12px 12px' }}>
                  <CallDetailsItem title="Related Company(s)">
                    {transcript.tickers
                      .filter(ticker => ticker.isPrimary)
                      .map(ticker => {
                        const { title, companyName, exchange } = ticker.ticker;
                        return (
                          <Typography key={title} variant="subtitle1" color="colors.gray08" sx={{ textTransform: 'none' }}>
                            {exchange ? `${companyName} (${exchange}:${title})` : `${companyName} (${title})`}
                          </Typography>
                        );
                      })}
                  </CallDetailsItem>
                </Box>
              )}
            </CallsSummaryTabPanel>
            <CallsSummaryTabPanel value={callsSummaryTabIndex} index={1}>
              <ChatInterface conversationUuid={transcript.conversation?.uuid} chatStyle="calls-chat" />
            </CallsSummaryTabPanel>
            {(isResearchAnalyst && call.relatedCalls && !isEmpty(call.relatedCalls)) && (
              <Box sx={{ padding: isMobile ? 0 : '0 12px' }}>
                <RelatedCalls relatedCalls={call.relatedCalls} onSelect={onSelect} />
              </Box>
            )}
            {callStatus === 'Completed' && (
              <>
                <Box sx={{ padding: '0 12px' }}>
                  <Divider flexItem sx={{ margin: '25px 0 16px', borderColor: 'colors.gray03' }} />
                  <Box display="flex" mb={isMobile ? '16px' : '24px'}>
                    <NoteIcon />
                    <Typography variant="subtitle1" color="colors.grey10" sx={{ marginLeft: '12px' }}>
                      Transcript
                    </Typography>
                  </Box>
                  {isMobile && <Divider flexItem sx={{ margin: '0', borderColor: 'colors.gray03' }} />}
                </Box>
                <TranscriptView
                  transcript={transcript}
                  transcriptParagraphs={transcriptParagraphs}
                  updateParagraphText={updateParagraphText}
                  selectedHighlight={selectedHighlight}
                  setSelectedHighlight={setSelectedHighlight}
                  audio={audio}
                  isPlayingMain={isPlayingMain}
                  setIsPlayingMain={setIsPlayingMain}
                  isPlayingParagraph={isPlayingParagraph}
                  setIsPlayingParagraph={setIsPlayingParagraph}
                  isResearchAnalyst={isResearchAnalyst}
                />
              </>
            )}
          </>
        )}
      </ScrollContainer>
    </MainContainer>
  );
};

export default ResearchCallDetails;
