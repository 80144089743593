// * MUI
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

// * Hooks & Utils
import palette from '../../theme/palette';

export const MainContainer = styled(Box)({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  height: '100%',
});

export const ScrollContainer = styled(Box)({
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const StickyBox = styled(Box)({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  backgroundColor: palette.white,
  width: 'calc(100% + 7px)',
  zIndex: 1,
  padding: '12px 24px 12px 32px',
  marginLeft: '-7px',
  boxShadow: '0px 3px 2px 0px rgba(65, 65, 65, 0.14)',
});

export const AudioSmallBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid',
  color: palette.primary.main,
  borderColor: palette.primary.main,
  borderRadius: '24px',
  padding: '12px',
  height: '48px',
  marginRight: '4px',
});

export const HighlightDot = styled(Box)({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  marginRight: '8px',
});

export const HighlightStatus = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
  marginLeft: '8px',
});
