/* eslint-disable import/order, no-unused-vars */
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';

// * MUI
import { Box, Tab, Typography } from '@mui/material';

// * Components
import { HeaderBar } from '../../components';
import { AppContext, CallTabType, useMe } from '../../components/AppContextProvider';
import ScheduledCallList from './components/scheduledCallList';
import CallList from '../../components/CallList';
import CallListSkeleton from '../../components/Skeletons/CallListSkeleton';
import HeaderSkeleton from '../../components/Skeletons/HeaderSkeleton';
import HighlightsSkeleton from '../../components/Skeletons/HighlightsSkeleton';
import CallDetailsSkeleton from '../../components/Skeletons/CallDetailsSkeleton';
import TabPanel from '../../components/Tabs/TabPanel';
import TabEmptyState from '../../components/EmptyStates/TabEmptyState';
import HeaderEmptyState from '../../components/EmptyStates/HeaderEmptyState';
import CallNotFound from '../../components/CallNotFound';
import Download from '../../components/Download';
import DisplayInResearch from '../../components/DisplayInResearch';
import DisableAudio from '../../components/DisableAudio';
import {
  Main,
  DrawerContainer,
  ScrollContainer,
  DrawerButton,
  DrawerTabs,
  VerticalDivider,
  CustomSplit,
  a11yProps,
} from './components/styledComponents';
import DrawerHeader from './components/DrawerHeader';
import FiltersSection from './components/FiltersSection';

// * Pages
import Highlights from '../../pages/Highlights';
import CallDetails from '../../pages/CallDetails';

// * Icons
import { ReactComponent as DrawerIcon } from '../../assets/icons/draverIcon.svg';

// * Hooks & Utils
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import paths from '../../paths/paths';
import isFutureDate from '../../shared/utils/functions';
import { handleResizeWindow, calculateWidth } from '../../shared/utils/calculateWidth';
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';
import { filterHighlights, prepareFiltersForBackend } from './utils/filterHighligts';
import useFilterLogic from './filterLogic';

// * Queries
import {
  ALL_TRANSCRIPTS_QUERY,
  DISABLE_AUDIO_IN_RESEARCH_PORTAL_MUTATION,
  TRANSCRIPT_QUERY,
} from '../../queries/transcript/transcript';
import { ALL_USERS_QUERY } from '../../queries/auth/account';

// * Interfaces
import type {
  TranscriptsListType, TranscriptType, FilterDataType, HighlightType,
} from '../../shared/types/transcript';
import type { AllUsersQueryType } from '../../shared/types/user';

window.addEventListener('resize', handleResizeWindow);

const tabs = ['scheduled', 'completed', 'reviewed'];
const QUERY_INITIAL_VALUE = { results: [], totalCount: 0 };

type FetchMoreFunction = (options: {
  variables: {
    skip: number
  }
  updateQuery: (
    prev: TranscriptsListType,
    { fetchMoreResult }: { fetchMoreResult: TranscriptsListType }
  ) => TranscriptsListType
}) => void

const MainApp = () => {
  const { me } = useMe();
  const { transcriptId: transcriptUuid } = useParams<{ transcriptId?: string | undefined }>();
  const [transcriptId, setTranscriptId] = useState<string | undefined>(transcriptUuid);
  const navigate = useNavigate();

  const [open, setOpen] = useState(true);
  const [openDrawerMobile, setOpenDrawerMobile] = useState(true);
  const [selectedHighlight, setSelectedHighlight] = useState<HighlightType | null>(null);
  const [tabIndex, setSelectedTabIndex] = useState(1);
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
  const [isPlayingMain, setIsPlayingMain] = useState(false);
  const [isPlayingParagraph, setIsPlayingParagraph] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const filterLogic = useFilterLogic();

  const { data: { allUsers = QUERY_INITIAL_VALUE } = {} } = useQuery<AllUsersQueryType>(ALL_USERS_QUERY);

  const [highlightFilterData, setHighlightsFilterData] = useState<FilterDataType>({
    transcriptUuid: transcriptId ?? '', userUuids: ['-1', ...allUsers.results.map(u => u.uuid)], statuses: [],
  });

  const toggleDrawer = () => {
    setOpen(!open);
    setTimeout(() => {
      calculateWidth();
    }, 500);
  };

  const [getTranscript, {
    data: { transcript = {} } = {},
    loading: transcriptLoading,
    refetch: refetchTranscript,
    error: isNotFound,
  }] = useLazyQuery(TRANSCRIPT_QUERY, { fetchPolicy: 'network-only' });
  const transcriptWithType: TranscriptType = transcript;
  const audioUrl = transcriptWithType?.call?.transcript?.audioUrl;

  const {
    data: { allTranscripts: allScheduledTranscripts = QUERY_INITIAL_VALUE } = {},
    loading: sheduledLoading,
    fetchMore: fetchMoreScheduled,
    refetch: refetchScheduledTab,
  } = useQuery<TranscriptsListType>(ALL_TRANSCRIPTS_QUERY, {
    variables: {
      searchQuery,
      analysts: filterLogic.analysts.map(a => a.uuid),
      complianceReviewerUuids: filterLogic.complianceReviewers.map(c => c.uuid),
      acaIoiAccountsUuids: filterLogic.acaIoiAccounts.map(a => a.uuid),
      networks: filterLogic.networks.map(n => n.uuid),
      tickers: filterLogic.tickers.map(t => t.uuid),
      displayInResearchPortal: filterLogic.displayInResearchPortal,
      scheduledTab: true,
      dateRangeStart: filterLogic.dateRangeStart,
      dateRangeEnd: filterLogic.dateRangeEnd,
      first: 10,
      skip: 0,
    },
  });

  const {
    data: { allTranscripts: allReviewedTranscripts = QUERY_INITIAL_VALUE } = {},
    loading: reviewedLoading,
    fetchMore: fetchMoreReviewed,
    refetch: refetchReviewedTab,
  } = useQuery<TranscriptsListType>(ALL_TRANSCRIPTS_QUERY, {
    variables: {
      searchQuery,
      analysts: filterLogic.analysts.map(a => a.uuid),
      complianceReviewerUuids: filterLogic.complianceReviewers.map(c => c.uuid),
      acaIoiAccountsUuids: filterLogic.acaIoiAccounts.map(a => a.uuid),
      networks: filterLogic.networks.map(n => n.uuid),
      flags: filterLogic.flags.map(f => f.uuid),
      tickers: filterLogic.tickers.map(t => t.uuid),
      displayInResearchPortal: filterLogic.displayInResearchPortal,
      reviewStatus: 'reviewed',
      dateRangeStart: filterLogic.dateRangeStart,
      dateRangeEnd: filterLogic.dateRangeEnd,
      first: 10,
      skip: 0,
    },
    fetchPolicy: 'network-only',
  });

  const [completedTranscripts, setCompletedTranscripts] = useState<{
    totalCount: number
    results: TranscriptType[]
  }>(QUERY_INITIAL_VALUE);

  const updateCallComplianceReviewerInCompletedTranscripts = (
    updatedTranscriptUuid: TranscriptType['uuid'],
    updatedReviewer: TranscriptType['call']['review']['reviewer'],
  ) => {
    const updatedTranscripts = completedTranscripts.results.map(transc => {
      if (transc.uuid === updatedTranscriptUuid) {
        return {
          ...transc,
          call: {
            ...transc.call,
            review: {
              ...transc.call.review,
              reviewer: updatedReviewer,
            },
          },
        };
      }
      return transc;
    });

    setCompletedTranscripts({ ...completedTranscripts, results: updatedTranscripts });
  };

  const {
    loading: completedLoading,
    refetch: refetchCompletedTab,
    fetchMore,
  } = useQuery<TranscriptsListType>(ALL_TRANSCRIPTS_QUERY, {
    variables: {
      searchQuery,
      analysts: filterLogic.analysts.map(a => a.uuid),
      complianceReviewerUuids: filterLogic.complianceReviewers.map(c => c.uuid),
      acaIoiAccountsUuids: filterLogic.acaIoiAccounts.map(a => a.uuid),
      flags: filterLogic.flags.map(f => f.uuid),
      networks: filterLogic.networks.map(n => n.uuid),
      tickers: filterLogic.tickers.map(t => t.uuid),
      displayInResearchPortal: filterLogic.displayInResearchPortal,
      completedTab: true,
      dateRangeStart: filterLogic.dateRangeStart,
      dateRangeEnd: filterLogic.dateRangeEnd,
      first: 10,
      skip: 0,
    },
    onCompleted: data => setCompletedTranscripts(data.allTranscripts),
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [hasMoreCompleted, setHasMoreCompleted] = useState(false);
  const [hasMoreScheduled, setHasMoreScheduled] = useState(false);
  const [hasMoreReviewed, setHasMoreReviewed] = useState(false);

  useEffect(() => {
    setHasMoreScheduled(allScheduledTranscripts.results.length < allScheduledTranscripts.totalCount);
    setHasMoreReviewed(allReviewedTranscripts.results.length < allReviewedTranscripts.totalCount);
    setHasMoreCompleted(completedTranscripts.results.length < completedTranscripts.totalCount);
  }, [
    completedTranscripts.results,
    completedTranscripts.totalCount,
    allReviewedTranscripts.results,
    allReviewedTranscripts.totalCount,
    allScheduledTranscripts.results,
    allScheduledTranscripts.totalCount,
  ]);

  const loadMoreTranscripts = (
    fetchMoreFunction: FetchMoreFunction,
    hasMoreFlag: boolean,
    allTranscripts: TranscriptsListType['allTranscripts'],
  ) => {
    if (!hasMoreFlag) return;

    fetchMoreFunction({
      variables: { skip: allTranscripts.results.length },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const prevResults = prev.allTranscripts.results;
        const newResults = fetchMoreResult.allTranscripts.results;

        const combinedResults = [
          ...prevResults,
          ...newResults.filter(
            newResult => !prevResults.some(prevResult => prevResult.uuid === newResult.uuid),
          ),
        ];

        return {
          allTranscripts: {
            ...prev.allTranscripts,
            results: combinedResults,
          },
        };
      },
    });
  };

  const loadMoreCompleted = () => {
    loadMoreTranscripts(fetchMore, hasMoreCompleted, completedTranscripts);
  };

  const loadMoreScheduled = () => {
    loadMoreTranscripts(fetchMoreScheduled, hasMoreScheduled, allScheduledTranscripts);
  };

  const loadMoreReviewed = () => {
    loadMoreTranscripts(fetchMoreReviewed, hasMoreReviewed, allReviewedTranscripts);
  };

  const {
    setRefetchCompletedCalls,
    setRefetchReviewedCalls,
    setRefetchScheduledCalls,
    setRefetchTranscript,
    setCallsSelectedTabName,
  } = useContext(AppContext);

  useEffect(() => {
    setRefetchCompletedCalls(refetchCompletedTab);
    setRefetchReviewedCalls(refetchReviewedTab);
    setRefetchScheduledCalls(refetchScheduledTab);
    setRefetchTranscript(refetchTranscript);
  }, [
    setRefetchCompletedCalls,
    setRefetchReviewedCalls,
    setRefetchScheduledCalls,
    refetchReviewedTab,
    refetchCompletedTab,
    refetchScheduledTab,
    setRefetchTranscript,
    refetchTranscript,
  ]);

  useEffect(() => {
    const newAudio = new Audio(audioUrl);
    setAudio(newAudio);

    return () => {
      newAudio.pause();
      newAudio.src = '';
      setIsPlayingMain(false);
      setAudio(null);
    };
  }, [audioUrl]);

  const statusToExclude = ['Scheduled', 'Created', 'In Progress', 'Canceled'];

  // autoselect tab where transcript should be
  useEffect(() => {
    calculateWidth();
    if (!transcriptLoading && !isEmpty(transcriptWithType)) {
      if (transcriptWithType.call?.review.status.key === 'reviewed') {
        setSelectedTabIndex(2);
      } else if (transcriptWithType.call?.review.status.key !== 'reviewed'
        && transcriptWithType.call.status.value !== 'Suspended'
          && !statusToExclude.includes(transcriptWithType.call.status.value)) {
        setSelectedTabIndex(1);
      } else {
        setSelectedTabIndex(0);
      }
    }
    // eslint-disable-next-line
  }, [transcriptWithType, transcriptLoading]);

  useEffect(() => {
    setCallsSelectedTabName(tabs[tabIndex] as CallTabType);
    calculateWidth();
    // eslint-disable-next-line
  }, [tabIndex]);

  // get transcript if transcript uuid changed or select first if there is no transcript in URL
  useEffect(() => {
    if ((transcriptId && !transcript) || (transcript && transcriptId && transcript.uuid !== transcriptId)) {
      getTranscript({
        variables: {
          uuid: transcriptId,
        },
      });
    }
    if (!transcriptUuid && completedTranscripts.results.length > 0) {
      navigate(completedTranscripts.results[0].uuid);
      setTranscriptId(completedTranscripts.results[0].uuid);
    }
  // eslint-disable-next-line
  }, [transcriptId, transcriptUuid, completedTranscripts.results, allReviewedTranscripts.results]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);

    const handleNavigation = (transcripts: TranscriptType[], loading: boolean) => {
      if (loading) return;

      if (transcripts.length === 0) {
        navigate(paths.home.reverse({ transcriptId: 'not-found' }));
        setTranscriptId('not-found');
        return;
      }

      navigate(paths.home.reverse({ transcriptId: transcripts[0]?.uuid }));
      setTranscriptId(transcripts[0]?.uuid);
    };

    switch (newValue) {
      case 0:
        handleNavigation(allScheduledTranscripts.results, sheduledLoading);
        break;
      case 1:
        handleNavigation(completedTranscripts.results, completedLoading);
        break;
      case 2:
        handleNavigation(allReviewedTranscripts.results, reviewedLoading);
        break;
      default:
        break;
    }
  };

  const filteredHighlights = filterHighlights(
    isNotFound ? [] : transcriptWithType.highlights,
    prepareFiltersForBackend(highlightFilterData).statuses,
  );

  const handleOnSearch = useCallback((search: string) => {
    setSearchQuery(search);
  }, []);

  const handleOnBack = async () => {
    setSearchQuery('');
  };

  // const [isCheckedDisplayInResearch, setIsCheckedDisplayInResearch] = useState(transcriptWithType?.call?.inResearch);
  const [isCheckedDisableAudio, setIsCheckedDisableAudio] = useState(transcriptWithType.disableAudio);

  const [disableAudioInResearch] = useMutation(DISABLE_AUDIO_IN_RESEARCH_PORTAL_MUTATION);
  const handleDisableAudioCheckboxChange = () => {
    disableAudioInResearch({
      variables: {
        transcriptUuid: transcriptWithType.uuid,
        disableAudio: !isCheckedDisableAudio,
      },
      onCompleted: () => {
        setIsCheckedDisableAudio(!isCheckedDisableAudio);
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <HeaderBar />
      <DrawerContainer variant="persistent" anchor="left" open={open}>
        <DrawerHeader
          isNotFound={isNotFound}
          toggleDrawer={toggleDrawer}
          onSearch={handleOnSearch}
          onBack={handleOnBack}
          isSearching={sheduledLoading || completedLoading || reviewedLoading}
        />
        <FiltersSection {...filterLogic} tabIndex={tabIndex} />
        <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'colors.gray03' }}>
          <DrawerTabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" aria-label="sidebar tabs">
            <Tab label={`Scheduled (${allScheduledTranscripts.totalCount || 0})`} {...a11yProps(0)} />
            <Tab label={`Pending Review (${completedTranscripts.totalCount || 0})`} {...a11yProps(1)} />
            <Tab label={`Reviewed (${allReviewedTranscripts.totalCount || 0})`} {...a11yProps(2)} />
          </DrawerTabs>
        </Box>
        <ScrollContainer id="scrollableScheduledTab">
          <TabPanel value={tabIndex} index={0}>
            {sheduledLoading && <CallListSkeleton />}
            <InfiniteScroll
              // @ts-ignore
              pageStart={0}
              dataLength={allScheduledTranscripts.results.length}
              next={loadMoreScheduled}
              hasMore={hasMoreScheduled}
              loader={<p>Loading...</p>}
              scrollableTarget="scrollableScheduledTab"
            >
              <ScheduledCallList
                transcripts={allScheduledTranscripts.results}
                onSelect={setTranscriptId}
                selectedTranscriptId={transcriptId}
              />
            </InfiniteScroll>
          </TabPanel>
        </ScrollContainer>
        <ScrollContainer id="scrollableCompletedTab">
          <TabPanel value={tabIndex} index={1}>
            <InfiniteScroll
              // @ts-ignore
              pageStart={0}
              dataLength={completedTranscripts.results && completedTranscripts.results.length}
              next={loadMoreCompleted}
              hasMore={hasMoreCompleted}
              loader={<>...</>}
              scrollableTarget="scrollableCompletedTab"
            >
              <CallList
                transcripts={completedTranscripts.results}
                onSelect={setTranscriptId}
                selectedTranscriptId={transcriptId}
                openDrawerMobile={openDrawerMobile}
                setOpenDrawerMobile={setOpenDrawerMobile}
              />
            </InfiniteScroll>
            {completedLoading && <CallListSkeleton />}
          </TabPanel>
        </ScrollContainer>
        <ScrollContainer id="scrollableReviewedTab">
          <TabPanel value={tabIndex} index={2}>
            <InfiniteScroll
              // @ts-ignore
              pageStart={0}
              dataLength={allReviewedTranscripts.results.length}
              next={loadMoreReviewed}
              hasMore={hasMoreReviewed}
              loader={<p>Loading...</p>}
              scrollableTarget="scrollableReviewedTab"
            >
              <CallList
                transcripts={allReviewedTranscripts.results}
                onSelect={setTranscriptId}
                selectedTranscriptId={transcriptId}
                openDrawerMobile={openDrawerMobile}
                setOpenDrawerMobile={setOpenDrawerMobile}
              />
            </InfiniteScroll>
            {reviewedLoading && <CallListSkeleton />}
          </TabPanel>
        </ScrollContainer>
        {(!sheduledLoading && !allScheduledTranscripts.totalCount) && (
          <TabEmptyState isVisible={tabIndex === 0} title="No Scheduled Calls" />
        )}
        {(!completedLoading && !completedTranscripts.results.length) && (
          <TabEmptyState isVisible={tabIndex === 1} title="No Completed Calls" />
        )}
        {(!reviewedLoading && !allReviewedTranscripts.results.length) && (
          <TabEmptyState isVisible={tabIndex === 2} title="No Reviewed Calls" />
        )}
      </DrawerContainer>
      <Main open={open}>
        {isNotFound ? (
          <CallNotFound />
        ) : (
          <Box display="flex" flexDirection="column">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '22px 18px',
                borderBottom: '1px solid',
                borderBottomColor: 'colors.gray03',
              }}
            >
              <Box display="flex" sx={{ ...(open && { display: 'none' }) }}>
                <DrawerButton aria-label="open drawer" onClick={toggleDrawer}>
                  <DrawerIcon />
                </DrawerButton>
                <VerticalDivider orientation="vertical" flexItem />
              </Box>
              {!!transcriptId && !transcriptLoading && (
                <Box display="flex" alignItems="center" justifyContent="space-between" flexGrow={1}>
                  <Box mr="8px">
                    <Typography
                      variant={tabIndex !== 0 ? 'h5' : 'h3'}
                      color="colors.gray10"
                      sx={{ marginBottom: tabIndex !== 0 ? '5px' : 0 }}
                    >
                      {!!transcriptWithType && transcriptWithType.call?.name}
                    </Typography>
                    {tabIndex !== 0 && (
                      <Typography variant="body1" color="colors.gray06" sx={{ fontSize: '14px', lineHeight: '22px' }}>
                        {transcriptWithType?.call?.type === 'meeting' ? 'Meeting date:' : 'Call date:'}
                        &nbsp;
                        {formatDateTimeToUserTimezone(transcriptWithType?.call?.datetime, me.timezone)}
                      </Typography>
                    )}
                  </Box>
                  {tabIndex !== 0 && (
                    <Box display="flex" alignItems="center">
                      <DisplayInResearch
                        transcriptUuid={transcriptWithType.uuid}
                        inResearch={transcriptWithType?.call?.inResearch}
                      />
                      <DisableAudio
                        transcriptUuid={transcriptWithType.uuid}
                        disableAudio={transcriptWithType?.disableAudio}
                      />
                      {!!transcriptWithType && !!transcriptWithType.call?.transcript && transcriptWithType.call?.transcript?.status?.value === 'Completed' && (
                        <Download transcript={transcriptWithType} />
                      )}
                    </Box>
                  )}
                </Box>
              )}
              {!transcriptId && <HeaderEmptyState />}
              {transcriptLoading && <HeaderSkeleton />}
            </Box>
            {(tabIndex !== 0 || !isEmpty(transcriptWithType.call?.review?.reviewer))
              && !isFutureDate(transcriptWithType.call?.datetime) && transcriptWithType.call?.inSample ? (
                <CustomSplit
                  sizes={[45, 55]}
                  minSize={600}
                  expandToMin={false}
                  gutterSize={8}
                  gutterAlign="center"
                  snapOffset={30}
                  dragInterval={1}
                  direction="horizontal"
                  cursor="col-resize"
                  onDrag={calculateWidth}
                >
                  <Box>
                    {transcriptWithType?.call ? (
                      <Highlights
                        selectedTab={tabs[tabIndex]}
                        loading={false}
                        setHighlightsFilterData={setHighlightsFilterData}
                        analysts={transcript.analysts}
                        experts={transcript.experts}
                        transcriptionStatus={transcript.status.value}
                        transcriptId={transcriptWithType.uuid}
                        reviewUuid={transcriptWithType.call?.review?.uuid}
                        highlights={filteredHighlights}
                        selectedHighlight={selectedHighlight}
                        setSelectedHighlight={setSelectedHighlight}
                        pendingCount={transcript.pendingCount}
                        resolvedCount={transcript.resolvedCount}
                        aiResolvedCount={transcript.aiResolvedCount}
                        isFileAText={transcriptWithType.call?.transcript?.isFileAText}
                        callTopic={transcriptWithType.call?.topic}
                        audio={audio}
                        isPlayingMain={isPlayingMain}
                        setIsPlayingMain={setIsPlayingMain}
                        isPlayingParagraph={isPlayingParagraph}
                        setIsPlayingParagraph={setIsPlayingParagraph}
                        disableAudio={transcriptWithType?.disableAudio}
                        displayInResearchPortal={transcriptWithType.call.inResearch}
                        hasFullTranscriptReviewer={!isEmpty(transcriptWithType.call.review.fullTranscriptReviewer)}
                        hasLiveChaperoneReviewer={!isEmpty(transcriptWithType.call.review.liveChaperoneReviewer)}
                        setFirstCompletedUuid={() => {
                          // get the UUID of the first completed transcript
                          const firstCompletedUuid = completedTranscripts.results[0]?.uuid;
                          // get the UUID of the second completed transcript
                          const secondCompletedUuid = completedTranscripts.results[1]?.uuid;
                          setTranscriptId(
                            transcriptWithType.uuid === firstCompletedUuid ? secondCompletedUuid : firstCompletedUuid,
                          );
                        }}
                        isDocument={transcriptWithType.call?.type === 'document'}
                        callType={transcriptWithType.call.type}
                      />
                    ) : (
                      <HighlightsSkeleton />
                    )}
                  </Box>
                  <Box id="CallDetailsColumn">
                    {transcriptWithType?.call ? (
                      <CallDetails
                        transcript={transcriptWithType}
                        selectedHighlight={selectedHighlight}
                        setSelectedHighlight={setSelectedHighlight}
                        isScheduledTab={false}
                        accountName={transcript.call.account.name}
                        accountAvatarLogo={transcript.call.account.settings.avatarLogo}
                        audio={audio}
                        isPlayingMain={isPlayingMain}
                        setIsPlayingMain={setIsPlayingMain}
                        isPlayingParagraph={isPlayingParagraph}
                        setIsPlayingParagraph={setIsPlayingParagraph}
                        inSample={transcriptWithType?.call.inSample}
                        onSelect={setTranscriptId}
                        updateCallComplianceReviewerInCompletedTranscripts={
                          updateCallComplianceReviewerInCompletedTranscripts
                        }
                      />
                    ) : (
                      <CallDetailsSkeleton />
                    )}
                  </Box>
                </CustomSplit>
              ) : (
                <Box display="flex" height="calc(100vh - 168px)">
                  <Box id="CallDetailsColumn" width="100%" maxWidth="100%">
                    {transcriptWithType?.call ? (
                      <CallDetails
                        transcript={transcriptWithType}
                        selectedHighlight={selectedHighlight}
                        setSelectedHighlight={setSelectedHighlight}
                        isScheduledTab={tabIndex === 0}
                        accountName={transcript.call.account.name}
                        accountAvatarLogo={transcript.call.account.settings.avatarLogo}
                        audio={audio}
                        isPlayingMain={isPlayingMain}
                        setIsPlayingMain={setIsPlayingMain}
                        isPlayingParagraph={isPlayingParagraph}
                        setIsPlayingParagraph={setIsPlayingParagraph}
                        inSample={transcriptWithType?.call.inSample}
                        onSelect={setTranscriptId}
                        updateCallComplianceReviewerInCompletedTranscripts={
                          updateCallComplianceReviewerInCompletedTranscripts
                        }
                      />
                    ) : (
                      <CallDetailsSkeleton />
                    )}
                  </Box>
                </Box>
              )}
          </Box>
        )}
      </Main>
    </Box>
  );
};

export default MainApp;
