import * as yup from 'yup';

export const TIMEZONES = [
  'UTC',
  'US/Eastern',
  'US/Pacific',
  'America/Chicago',
  'America/Denver',
  'America/Detroit',
  'America/Edmonton',
  'America/Indianapolis',
  'America/Los_Angeles',
  'America/Montreal',
  'America/Toronto',
  'America/Vancouver',
  'America/Winnipeg',
  'Asia/Baghdad',
  'Asia/Baku',
  'Asia/Damascus',
  'Asia/Istanbul',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Yerevan',
  'Europe/Athens',
  'Europe/Berlin',
  'Europe/Chisinau',
  'Europe/Copenhagen',
  'Europe/Helsinki',
  'Europe/Kiev',
  'Europe/Lisbon',
  'Europe/London',
  'Europe/Madrid',
  'Europe/Minsk',
  'Europe/Moscow',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Riga',
  'Europe/Rome',
  'Europe/Stockholm',
  'Europe/Tallinn',
  'Europe/Vilnius',
  'Europe/Warsaw',
];

export const errorMessages = {
  oneUpper: 'Contain at least one upper-case letter',
  oneLower: 'Contain at least one lower-case letter',
  oneSpecial: 'Contain at least one special character',
  oneNumeric: 'Contain at least 1 numeric character',
  min: 'Contain at least 8 characters',
};

export const schema = yup
  .object()
  .shape({
    firstName: yup.string().min(1, 'Not empty'),
    lastName: yup.string().min(1, 'Not empty'),
    email: yup.string().email('Email must be a valid email'),
    timezone: yup.string().required(),
    password1: yup.string().min(8, errorMessages.min),
    password2: yup
      .string()
      .test('oneUpper', errorMessages.oneUpper, value => /[A-Z]+/.test(value!))
      .test('oneLower', errorMessages.oneLower, value => /[a-z]+/.test(value!))
      .test('oneSpecial', errorMessages.oneSpecial, value => /[`~!@#$%^&*()_+\-={}|[\]\\:";'<>?,./]+/.test(value!))
      .test('oneNumeric', errorMessages.oneNumeric, value => /[0-9]+/.test(value!))
      .min(8, errorMessages.min),
    password3: yup
      .string()
      .oneOf([yup.ref('password2'), undefined], 'Those passwords didn\'t match'),
  })
  .required();

export const a11yProps = (index: number) => ({
  id: `user-tab-${index}`,
  'aria-controls': `user-tabpanel-${index}`,
});
