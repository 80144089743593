import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import EmptyCallListImg from '../../assets/img/emptyCallListImg.png';
import EmptyCallListImgWebp from '../../assets/img/emptyCallListImg.webp';
import EmptyCallListSmallImg from '../../assets/img/emptyCallListSmallImg.png';
import EmptyCallListSmallImgWebp from '../../assets/img/emptyCallListSmallImg.webp';

const StyledEmptyState = styled(Box)({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(225, 228, 231, 0.3)',
  marginBottom: '32px',
  borderRadius: '16px',
});

const LargeImg = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: '30px',
  [theme.breakpoints.down(1600)]: {
    display: 'none',
  },
}));

const SmallImg = styled(Box)(({ theme }) => ({
  display: 'none',
  marginBottom: '20px',
  [theme.breakpoints.down(1600)]: {
    display: 'flex',
  },
  [theme.breakpoints.down(1200)]: {
    display: 'none',
  },
}));

type TabEmptyStateProps = {
  isVisible: boolean;
  title: string;
}

const TabEmptyState = ({ isVisible, title }: TabEmptyStateProps) => (
  <StyledEmptyState
    display={!isVisible ? 'none' : 'flex'}
    flexGrow={!isVisible ? '0' : '1'}
  >
    <LargeImg>
      <img
        src={EmptyCallListImg}
        srcSet={EmptyCallListImgWebp}
        alt="emptyImg1"
        loading="lazy"
        width="169"
        height="158"
      />
    </LargeImg>
    <SmallImg>
      <img
        src={EmptyCallListSmallImg}
        srcSet={EmptyCallListSmallImgWebp}
        alt="emptyImg2"
        loading="lazy"
        width="102"
        height="102"
      />
    </SmallImg>
    <Typography
      variant="body1"
      color="colors.gray10"
      sx={{
        fontSize: '14px',
        lineHeight: '16px',
      }}
    >
      {title}
    </Typography>
  </StyledEmptyState>
);

export default TabEmptyState;
