import { gql } from '@apollo/client';

export const ALL_NETWORKS_QUERY = gql`
  query allNetworks {
    allNetworks {
      key
      value
    }
  }
`;

export default { ALL_NETWORKS_QUERY };
