import { gql } from '@apollo/client';

const GET_REVIEWED_CALLS_REPORT = gql`
  mutation generateReviewedCallsReport($dateRangeStart: String!, $dateRangeEnd: String!, $showNotes: Boolean) {
    generateReviewedCallsReport(dateRangeStart: $dateRangeStart, dateRangeEnd: $dateRangeEnd, showNotes: $showNotes) {
      b64File
    }
  }
`;

export default GET_REVIEWED_CALLS_REPORT;
