/* eslint-disable import/order */
import React from 'react';

// * MUI
import { FormControl, MenuItem, Select } from '@mui/material';

// * Components
import { FilterInput, NameContainer } from './styledComponents';

// * Hooks & Utils
import { MenuProps as CommonMenuProps } from './utils';

// * Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// * Interfaces
import type { SelectChangeEvent } from '@mui/material';
import type { BooleanOptionType } from '../filterLogic';

interface FiltersBooleanProps {
  selectedValue: BooleanOptionType;
  // eslint-disable-next-line no-unused-vars
  handleSelect: (value: BooleanOptionType) => void;
  blockWidth?: number;
}

const options: Record<string, string> = {
  All: 'undefined',
  Yes: 'true',
  No: 'false',
};

const FiltersBoolean = ({
  selectedValue,
  handleSelect,
  blockWidth,
}: FiltersBooleanProps) => {
  const handleChange = (event: SelectChangeEvent<string>) => {
    const { target: { value } } = event;
    const newValue = value === 'undefined' ? undefined : value === 'true';
    handleSelect(newValue);
  };

  const MenuProps = {
    ...CommonMenuProps,
    PaperProps: {
      ...CommonMenuProps.PaperProps,
      style: {
        ...CommonMenuProps.PaperProps?.style,
        minWidth: '100px',
        width: '100px',
      },
    },
  };

  return (
    <FormControl sx={{ minWidth: blockWidth }}>
      <Select
        value={selectedValue === undefined ? 'undefined' : selectedValue.toString()}
        onChange={handleChange}
        input={<FilterInput />}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={ExpandMoreIcon}
        MenuProps={MenuProps}
      >
        {Object.entries(options).map(([key, value]) => (
          <MenuItem key={key} sx={{ padding: '6px 12px' }} value={value}>
            <NameContainer variant="body1" color="colors.gray08">
              {key}
            </NameContainer>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FiltersBoolean.defaultProps = {
  blockWidth: 70,
};

export default FiltersBoolean;
