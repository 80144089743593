/* eslint-disable no-unused-vars */
import React, {
  useMemo, useCallback, useReducer,
  useEffect,
} from 'react';

// * MUI
import { Box, IconButton, FormControl } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';

// * Icons
import { ReactComponent as CloseIcon } from '../../../../assets/icons/closeIcon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/editIcon.svg';

// * Components
import { EditBtn, HeaderContainer } from '../styledComponents';
import EditModeTextField from './EditModeTextField';
import MessageDisplay from './MessageDisplay';
import ViewModeText from './ViewModeText';

// * Interfaces
import type { Topic } from '../../types';

type State = {
  isEditing: boolean
  editedName: string
  message?: {
    severity: 'error' | 'success'
    text: string
    timer?: number
  }
}

type Action =
  | { type: 'TOGGLE_EDIT'; isEditing: State['isEditing'] }
  | { type: 'SET_EDITED_NAME'; editedName: State['editedName'] }
  | { type: 'SET_MESSAGE'; message: State['message'] }

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'TOGGLE_EDIT':
      return { ...state, isEditing: action.isEditing };
    case 'SET_EDITED_NAME':
      return { ...state, editedName: action.editedName };
    case 'SET_MESSAGE':
      return { ...state, message: action.message };
    default:
      return state;
  }
}

const initializeState = (topic?: Topic) => ({
  isEditing: !topic,
  editedName: topic?.name || '',
  message: undefined,
});

interface HeaderProps {
  topic: Topic | undefined
  loading: boolean
  drawerState: boolean
  validateUniqueName: (name: string) => Promise<boolean>
  onClose: React.MouseEventHandler<HTMLButtonElement>
  onSave: (name: string) => Promise<{ created: boolean }>
  onCreateTopic?: (newTopic: Topic) => void
}

const Header = ({
  topic,
  loading,
  drawerState,
  validateUniqueName,
  onClose,
  onSave,
  onCreateTopic,
}: HeaderProps) => {
  const [state, dispatch] = useReducer(reducer, topic, initializeState);

  useEffect(() => {
    dispatch({ type: 'SET_EDITED_NAME', editedName: topic?.name || '' });
    dispatch({ type: 'TOGGLE_EDIT', isEditing: !topic });
    dispatch({ type: 'SET_MESSAGE', message: undefined });
  }, [drawerState, topic]);

  const handleSave = useCallback(async () => {
    if (!state.editedName.trim()) {
      dispatch({
        type: 'SET_MESSAGE',
        message: {
          text: 'Please enter a valid topic name.',
          severity: 'error',
        },
      });
      return;
    }

    if (state.editedName === topic?.name) {
      dispatch({ type: 'TOGGLE_EDIT', isEditing: false });
      return;
    }

    const isUnique = await validateUniqueName(state.editedName);
    if (!isUnique) {
      dispatch({
        type: 'SET_MESSAGE',
        message: {
          text: 'This topic name already exists.',
          severity: 'error',
        },
      });
      return;
    }

    const { created } = await onSave(state.editedName);
    dispatch({
      type: 'SET_MESSAGE',
      message: {
        text: `Topic ${created ? 'created' : 'updated'} successfully.`,
        severity: 'success',
        timer: 3000,
      },
    });
    dispatch({ type: 'TOGGLE_EDIT', isEditing: false });

    if (created && onCreateTopic) {
      onCreateTopic(topic as Topic);
    }
  }, [topic, state.editedName, onSave, validateUniqueName, onCreateTopic]);

  const handleKeyDown = useCallback(
    async (event: React.KeyboardEvent<HTMLDivElement>) => {
      // If the user presses Enter, save the topic
      if (event.key === 'Enter') {
        event.preventDefault();
        await handleSave();
        return;
      }

      // If the user presses Escape, exit edit mode
      if (event.key === 'Escape') {
        event.stopPropagation();
        dispatch({
          type: 'SET_EDITED_NAME',
          editedName: topic?.name || '',
        });
        dispatch({ type: 'SET_MESSAGE', message: undefined });
        dispatch({ type: 'TOGGLE_EDIT', isEditing: false });
      }
    },
    [topic?.name, handleSave],
  );

  const textFieldStyles = useMemo(
    (): SxProps<Theme> => ({
      height: '40px',
      ...(state.message
        && !state.editedName && {
        '& .MuiInputBase-input::placeholder': { color: 'red' },
        '& .MuiOutlinedInput-notchedOutline': { borderColor: 'red' },
      }),
    }),
    [state.message, state.editedName],
  );

  const placeholder = 'Enter your topic name here...';

  return (
    <HeaderContainer>
      <Box sx={{ display: 'flex' }}>
        <IconButton
          onClick={onClose}
          aria-label="Close"
          sx={{ padding: 0, marginRight: '16px' }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
          <FormControl
            variant="outlined"
            error={state.message?.severity === 'error'}
            sx={{ minWidth: 'auto', width: 'auto' }}
          >
            {state.isEditing ? (
              <EditModeTextField
                editedName={state.editedName}
                setEditedName={newName => dispatch({ type: 'SET_EDITED_NAME', editedName: newName })}
                handleKeyDown={handleKeyDown}
                handleSave={handleSave}
                textFieldStyles={textFieldStyles}
                placeholder={placeholder}
                loading={loading}
              />
            ) : (
              <ViewModeText
                topic={topic}
                setEditing={() => dispatch({ type: 'TOGGLE_EDIT', isEditing: true })}
                placeholder={placeholder}
              />
            )}
          </FormControl>
          {!state.isEditing && (
            <EditBtn
              onClick={() => dispatch({ type: 'TOGGLE_EDIT', isEditing: true })}
              aria-label="Edit"
              sx={{ marginLeft: '16px' }}
            >
              <EditIcon />
            </EditBtn>
          )}
        </Box>
      </Box>
      <MessageDisplay
        message={state.message}
        clearMessage={() => dispatch({ type: 'SET_MESSAGE', message: undefined })}
      />
    </HeaderContainer>
  );
};

Header.defaultProps = {
  onCreateTopic: undefined,
};

export default Header;
