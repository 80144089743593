import {
  parseISO, format, formatISO, formatDistanceToNow,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

export const dateFormat = 'd MMM yy';
export const dateShortFormat = 'MM/dd/yy';
export const timeFormat = 'hh:mm a';
export const dateTimeFormat = 'd MMM yy, hh:mma';
export const dateNowFormat = 'HH:mm';

const handleFormating = (time: any, formatString: string) => {
  if (!time) return '';

  let t = time;
  if (typeof time === 'string') {
    t = parseISO(time);
  }
  return format(t, formatString);
};

export const formatDate = (time: any) => handleFormating(time, dateFormat);
export const formatDateShort = (time: any) => handleFormating(time, dateShortFormat);
export const formatIsoDate = (time: any) => time && formatISO(time, { representation: 'date' });
export const formatTime = (time: any) => handleFormating(time, timeFormat);
export const formatDateTime = (time: any) => handleFormating(time, dateTimeFormat);

export const formatDateTimeToUserTimezone = (time: string | Date, userTimezone: string) => {
  if (!time) return '';

  const date = typeof time === 'string' ? parseISO(time) : new Date(time);
  return formatInTimeZone(date, userTimezone, 'd MMM yy, hh:mma');
};

export function formatDateToNow(dateString: any) {
  if (dateString) {
    const date = new Date(dateString);
    const formattedTime = format(date, dateNowFormat);
    const formattedDate = formatDistanceToNow(date, { addSuffix: true });
    return `${formattedTime} ${formattedDate}`;
  }
  return '';
}
