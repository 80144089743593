import React from 'react';

// * Utils
import { escapeRegExp } from 'lodash';
import palette from '../../theme/palette';

// * Interfaces
import type { HighlightType } from '../../shared/types/transcript';

const statusList = [
  { status: 'pending', color: palette.colors.warning02 },
  { status: 'resolved', color: palette.colors.success02 },
  { status: 'ai resolved', color: palette.colors.gray03 },
];

type HighlightedParagraphProps = {
  highlight: HighlightType
}

const HighlightedParagraph = ({ highlight }: HighlightedParagraphProps) => {
  const statusItem = statusList.find(
    item => highlight.status.value.toLowerCase() === item.status,
  );
  const phraseColor = statusItem ? statusItem.color : 'transparent';

  const regex = new RegExp(escapeRegExp(highlight.phrase), 'i');
  const parts = highlight.paragraph.text.split(regex);

  return (
    <div>
      {parts.map((part, index) => (index > 0 ? (
        <React.Fragment key={highlight.uuid}>
          <span
            id={`highlight-text-${highlight.uuid}`}
            style={{ backgroundColor: phraseColor, boxShadow: '0 1px 2px #C7C7C7' }}
          >
            {highlight.phrase}
          </span>
          {part}
        </React.Fragment>
      ) : (
        part
      )))}
    </div>
  );
};

export default HighlightedParagraph;
