import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { useMutation } from '@apollo/client';
import {
  ChangeRoleContainer, VerticalLine, HorisontalLine, Switcher, SwitcherDisabled,
} from './styledComponents';
import palette from '../../theme/palette';
import { useMe } from '../AppContextProvider';
import { REVERSE_USER_ROLE_MUTATION } from '../../queries/auth/reverseUserRoleMutation';

interface PortalSwitcherProps {
  isResearch: boolean
}

const PortalSwitcher = ({ isResearch }: PortalSwitcherProps) => {
  const { refetchMe } = useMe();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [reverseUserRole] = useMutation(REVERSE_USER_ROLE_MUTATION);

  const handleReverseRole = async () => {
    await reverseUserRole();
    refetchMe();
    navigate('/');
  };

  if (isMobile && isResearch) {
    return (
      <Box display="flex" alignItems="center" mb="24px" onClick={handleReverseRole}>
        <AccountCircleRoundedIcon sx={{ color: palette.white }} />
        <Typography variant="subtitle1" color="white" ml="16px">
          Change to Compliance
        </Typography>
      </Box>
    );
  }

  if (isMobile && !isResearch) {
    return (
      <ListItem key="changeRole" onClick={handleReverseRole} disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <AccountCircleRoundedIcon sx={{ color: palette.primary.main }} />
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant="body1"
              color="colors.gray08"
              sx={{
                fontSize: '14px',
                lineHeight: '24px',
                marginLeft: '4px',
              }}
            >
              Change to Research
            </Typography>
          </ListItemText>
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ChangeRoleContainer>
      <Box display="flex" p="6px 0">
        <VerticalLine />
        <Box display="flex" flexDirection={isResearch ? 'column-reverse' : 'column'} width="100%">
          <SwitcherDisabled>
            <Box display="flex" alignItems="center">
              <HorisontalLine />
              <Box>{isResearch ? 'Research Portal' : 'Compliance Portal'}</Box>
            </Box>
            <CheckCircleRoundedIcon sx={{ fontSize: '16px', color: palette.colors.gray05 }} />
          </SwitcherDisabled>
          <Box mb="8px" />
          <Switcher onClick={handleReverseRole}>
            <HorisontalLine />
            <Box>{isResearch ? 'Compliance Portal' : 'Research Portal'}</Box>
          </Switcher>
        </Box>
      </Box>
    </ChangeRoleContainer>
  );
};

export default PortalSwitcher;
