import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box, Divider, Typography, FormControl,
  FormGroup, Select, MenuItem, FormControlLabel, Checkbox,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import palette from '../../theme/palette';

const CardContainer = styled(Box)({
  display: 'flex',
  width: 900,
  flexDirection: 'column',
  marginBottom: '32px',
  borderRadius: '32px',
  backgroundColor: palette.white,
});

const CardHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 48px',
});

const CustomCheckbox = styled(Checkbox)({
  padding: '4px',
  marginRight: '12px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-checked.Mui-disabled': {
    color: palette.primary.main,
    opacity: 0.5,
  },
});

const SelectControl = styled(FormControl)({
  '& .MuiInputBase-input': {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
  },
});

type NotificationSettingsPropsType = {
  sendNewExpertCallTranscriptEmail: boolean;
  notificationsFrequency: string;
  updateUserSettings: any;
};

const NotificationsSettings = ({
  sendNewExpertCallTranscriptEmail,
  notificationsFrequency,
  // eslint-disable-next-line
  updateUserSettings,
}: NotificationSettingsPropsType) => {
  const defaultValues = {
    sendEmail: sendNewExpertCallTranscriptEmail,
    notificationOptions: notificationsFrequency,
  };

  const {
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  });

  const sendEmailCheckbox = watch('sendEmail');
  const notificationOptions = watch('notificationOptions');

  useEffect(() => {
    if (sendEmailCheckbox !== sendNewExpertCallTranscriptEmail || notificationOptions !== notificationsFrequency) {
      updateUserSettings({
        variables: {
          sendNewExpertCallTranscriptEmail: sendEmailCheckbox,
          notificationsFrequency: notificationOptions,
        },
      });
    }
    // eslint-disable-next-line
  }, [sendEmailCheckbox, notificationOptions]);

  return (
    <CardContainer>
      <CardHeader>
        <Typography variant="h4" sx={{ fontSize: '24px', lineHeight: '44px' }}>
          Notification Settings
        </Typography>
      </CardHeader>
      <Divider flexItem sx={{ margin: '0 0 32px', borderColor: 'colors.gray03' }} />
      <Box display="flex" flexDirection="column" padding="0 48px 64px">
        <Typography variant="h6" mb="4px">
          Receive Notification
        </Typography>
        <Typography variant="caption" color="colors.gray06" sx={{ maxWidth: 592 }}>
          If you enable this option you will be receiving emails based on the notification options selected below
        </Typography>
        <Divider flexItem sx={{ margin: '12px 0', borderColor: 'colors.gray03' }} />
        <form>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <FormGroup sx={{ flexDirection: 'column' }}>
              <FormControlLabel
                sx={{ alignItems: 'flex-start', marginLeft: 0 }}
                control={(
                  <Controller
                    name="sendEmail"
                    control={control}
                    render={({ field: { onChange, value, ref } }) => (
                      <CustomCheckbox onChange={onChange} checked={value} inputRef={ref} />
                    )}
                  />
                )}
                label={(
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">Call ready for review</Typography>
                    <Typography variant="caption" color="colors.gray06">
                      Send email notification when new calls are ready for review
                    </Typography>
                  </Box>
                )}
              />
            </FormGroup>
            <Controller
              name="notificationOptions"
              control={control}
              render={({
                field: {
                  onChange, value, name, ref,
                },
              }) => (
                <SelectControl>
                  <Select
                    inputRef={ref}
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={!sendEmailCheckbox}
                    IconComponent={ExpandMoreIcon}
                    sx={{ height: 58, width: 200 }}
                  >
                    <MenuItem key="0" value="Immediately">Immediately</MenuItem>
                    <MenuItem key="1" value="Daily Summary">Daily Summary</MenuItem>
                  </Select>
                </SelectControl>
              )}
            />
          </Box>
        </form>
      </Box>
    </CardContainer>
  );
};

export default NotificationsSettings;
