/* eslint-disable no-unused-vars */
import React from 'react';

// * MUI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from '@mui/material';

// * Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/deleteIcon.svg';

// * Components
import { ActionButton, FooterContainer } from '../styledComponents';

// * Interfaces
import type { Topic } from '../../types';

interface FooterProps {
  topic: Topic | undefined
  onDelete: () => void
  onClose: React.MouseEventHandler<HTMLButtonElement>
  onProceed: () => Promise<void>
  enableProceed: boolean
  loadingAction: boolean
}

const Footer = ({
  topic,
  onDelete,
  onClose,
  onProceed,
  enableProceed,
  loadingAction,
}: FooterProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <FooterContainer>
      {topic && (
        <ActionButton
          variant="outlined"
          color="error"
          onClick={onDelete}
          startIcon={<DeleteIcon />}
          disabled={loadingAction}
        >
          Delete
        </ActionButton>
      )}
      <Box sx={{ flex: 1 }} />
      <ActionButton
        variant="outlined"
        onClick={onClose}
        disabled={loadingAction}
        sx={{ marginRight: isMobile ? 1 : 2 }}
      >
        Close
      </ActionButton>
      <ActionButton
        variant="contained"
        color="primary"
        startIcon={<CheckCircleIcon />}
        onClick={onProceed}
        disabled={loadingAction || !enableProceed}
      >
        Update Topic
      </ActionButton>
    </FooterContainer>
  );
};

export default Footer;
