/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';

// * MUI
import { Box } from '@mui/material';

// * Hooks
import { useTopicOperations } from './hooks';

// * Components
import TopicCallsDrawer from '.';
import { ReactComponent as PlusCircleIcon } from '../../assets/icons/plusCircleIcon.svg';

// * Interfaces
import type { Topic } from './types';
import { AppContext } from '../AppContextProvider';

interface CreateTopicProps {
  onCreateTopic: (newTopic: Topic) => void
}

const CreateTopic = ({ onCreateTopic }: CreateTopicProps) => {
  const [topic, setTopic] = useState<Topic | undefined>();
  const { refetchMyTopics, refetchCurrentTopic } = useContext(AppContext);

  const {
    state, toggleDrawer, handleProceed, clearState, ...topicOperations
  } = useTopicOperations('create', topic, setTopic);

  const onProceed = async () => {
    await handleProceed();
    toggleDrawer(false)({} as React.MouseEvent);
    // @ts-ignore
    await refetchMyTopics();
    // @ts-ignore
    await refetchCurrentTopic();
    clearState();
  };

  const drawerProps = {
    topic,
    state,
    toggleDrawer,
    onProceed,
    onCreateTopic,
    ...topicOperations,
  };

  useEffect(() => {
    if (!state) {
      clearState(setTopic);
    }
  }, [state, clearState]);

  return (
    <>
      <Box
        display="flex"
        onClick={toggleDrawer(true)}
        sx={{ cursor: 'pointer' }}
      >
        <PlusCircleIcon />
      </Box>

      <TopicCallsDrawer {...drawerProps} />
    </>
  );
};

export default CreateTopic;
