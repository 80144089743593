/* eslint-disable no-unused-vars */
import React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as AltSearchIcon } from '../../../assets/icons/altSearchIcon.svg';
import palette from '../../../theme/palette';

const SearchContainer = styled('div')({
  border: '1px solid',
  borderColor: palette.colors.gray02,
  borderRadius: '4px',
  backgroundColor: palette.colors.gray01,
  width: 'auto',
  padding: '8px 12px',
  margin: '12px 12px 6px',
});

const StyledInputBase = styled(InputBase)({
  width: '100%',
  '& input': {
    fontSize: '14px',
    height: 24,
    color: palette.colors.gray06,
    padding: 0,
  },
  '& .MuiInputAdornment-positionEnd': {
    marginRight: 0,
  },
});

interface FiltersSearchProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown: (event: React.KeyboardEvent) => void
}

const FiltersSearch = ({ onChange, onKeyDown }: FiltersSearchProps) => (
  <SearchContainer>
    <StyledInputBase
      placeholder="Search"
      inputProps={{ 'aria-label': 'search' }}
      onChange={onChange}
      onKeyDown={onKeyDown}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton type="submit" aria-label="search">
            <AltSearchIcon />
          </IconButton>
        </InputAdornment>
      )}
    />
  </SearchContainer>
);

export default FiltersSearch;
