/* eslint-disable import/order */
import React, { useState, useEffect } from 'react';

// * MUI
import { Typography } from '@mui/material';

// * Components
import { NotesField } from './styledComponents';

// * Utils
import { Controller } from 'react-hook-form';

// * Interfaces
import type { Control, FieldErrors } from 'react-hook-form';
import type { AccountSettingsSubmitType, AccountSettingsType } from '../../shared/types/accountSettings';

const disclosurePlaceholder = 'Your standard compliance preamble language...';

interface DisclosureProps {
  formControl: Control<AccountSettingsSubmitType>
  settings: AccountSettingsType
  editMode: boolean
  preambleWatch: boolean
  errors: FieldErrors<AccountSettingsSubmitType>
}

const Disclosure = ({
  formControl,
  settings,
  editMode,
  preambleWatch,
  errors,
}: DisclosureProps) => {
  const [disabledDisclosurePrompt, setDisabledDisclosurePrompt] = useState(
    !settings.preamble,
  );

  useEffect(() => {
    if (editMode && preambleWatch) {
      setDisabledDisclosurePrompt(false);
    } else {
      setDisabledDisclosurePrompt(true);
    }
  }, [editMode, preambleWatch]);

  const renderDisclosurePrompt = () => {
    if (!editMode || disabledDisclosurePrompt) {
      return (
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{ fontWeight: 400 }}
        >
          {settings.disclosurePrompt || disclosurePlaceholder}
        </Typography>
      );
    }

    return (
      <Controller
        control={formControl}
        name="disclosurePrompt"
        render={({
          field: {
            onChange, value, name, ref,
          },
        }) => (
          <NotesField
            disabled={disabledDisclosurePrompt}
            inputRef={ref}
            name={name}
            value={value}
            onChange={onChange}
            multiline
            type="text"
            rows={6}
            variant="outlined"
            error={Boolean(errors.disclosurePrompt?.message)}
            helperText={errors.disclosurePrompt?.message}
            placeholder={disclosurePlaceholder}
          />
        )}
      />
    );
  };

  return <>{renderDisclosurePrompt()}</>;
};

export default Disclosure;
