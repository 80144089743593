import React from 'react';
import Box from '@mui/material/Box';

type UserTabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
}

const UserTabPanel = (props: UserTabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`user-tabpanel-${index}`}
      aria-labelledby={`user-tab-${index}`}
      {...other}
      display="flex"
    >
      {value === index && children}
    </Box>
  );
};

export default UserTabPanel;
