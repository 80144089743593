/* eslint-disable no-unused-vars */
import React from 'react';

// * MUI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Divider } from '@mui/material';

// * Components
import ContentSection from '../ContentSection';
import { ContentContainer } from '../styledComponents';

// * Interfaces
import type { Call, Topic } from '../../types';

interface ContentProps {
  topic: Topic | undefined
  topicCalls: {
    calls: Call[]
    onDelete: (call: Call) => void
  }
  availableCalls: {
    calls: Call[]
    loading: boolean
    hasMore: boolean
    loadMore: () => Promise<void>
    onAdd: (call: Call) => void
  }
  totalCallCount: number
  search: {
    searchTerm: string
    onSearch: (query: string) => void
  }
}

const Content = ({
  topic,
  topicCalls,
  availableCalls,
  totalCallCount,
  search,
}: ContentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <ContentContainer>
      <ContentSection
        topic={topic}
        calls={topicCalls.calls}
        totalCallCount={totalCallCount}
        hasMoreCalls={false}
        loadMoreCalls={() => Promise.resolve()}
        title="Included Content"
        action="delete"
        handleActionClick={topicCalls.onDelete}
        enableSearch={false}
        searchTerm={search.searchTerm}
      />
      <Divider flexItem sx={{ margin: isMobile ? '12px 0' : '24px 0', borderColor: 'colors.gray03' }} />
      <ContentSection
        topic={topic}
        calls={availableCalls.calls}
        totalCallCount={totalCallCount}
        hasMoreCalls={availableCalls.hasMore}
        loadMoreCalls={availableCalls.loadMore}
        loading={availableCalls.loading}
        title="Available Content"
        action="add"
        handleActionClick={availableCalls.onAdd}
        onSearch={search.onSearch}
        enableSearch
        searchTerm={search.searchTerm}
      />
    </ContentContainer>
  );
};

export default Content;
