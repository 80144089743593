// * MUI
import { styled } from '@mui/material/styles';
import { Box, Typography, Checkbox } from '@mui/material';

// * Utils
import palette from '../../theme/palette';

export const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '12px 17px 12px 24px',
});

export const ScrollContainer = styled(Box)({
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const CustomCheckbox = styled(Checkbox)({
  padding: 0,
  paddingRight: '7px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-checked.Mui-disabled': {
    color: palette.primary.main,
  },
});

export const HighlightDot = styled(Box)({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
});

export const HighlightStatus = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  marginLeft: '8px',
});
