import { gql } from '@apollo/client';

export const GET_TOPIC = gql`
  query ($uuid: UUID!) {
    topic(uuid: $uuid) {
      id
      uuid
      status {
        key
      }
      createdAt
      updatedAt
      isDeleted
      uid
      name
      topicCalls {
        id
        uuid
        createdAt
        updatedAt
        call {
          id
          datetime
          topic
          expertName
          type
          network {
            value
          }
        }
      }
      relatedCompanies {
        isPrimary
        ticker {
          exchange
          title
          companyName
        }
      }
    }
  }
`;

export default { GET_TOPIC };
