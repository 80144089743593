import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import palette from '../../theme/palette';

const emails = {
  0: 'john.doe@email.com',
  1: 'stella.tromp@email.com',
  2: 'irvin.baumbach@email.com',
  3: 'stanley.schumm@email.com',
  4: 'tommy.spencer@email.com',
  5: 'shannon.ernser@email.com',
  6: 'jessica.born@email.com',
};

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.background.paper,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '22px',
    color: palette.colors.gray08,
    border: '1px solid',
    borderColor: palette.primary.main,
    borderRadius: '8px',
    padding: '12px',
  },
});

const More = styled(Box)({
  color: palette.primary.main,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '22px',
  cursor: 'pointer',
  marginLeft: '4px',
});

const MoreList = (count: number) => {
  if (count <= 1) {
    return null;
  }
  const moreEmails = `+${count - 1} more`;
  return (
    <CustomTooltip
      title={(
        <>
          {Object.entries(emails).slice(1).map(([key, email]) => (
            <Box mb="11px" key={key}>{email}</Box>
          ))}
        </>
      )}
    >
      <More>{moreEmails}</More>
    </CustomTooltip>
  );
};

const ParticipantsList = () => {
  let ellipsis = false;
  const count = Object.keys(emails).length;

  if (count > 1) {
    ellipsis = true;
  }

  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="overline"
        color="colors.gray08"
        sx={{ textTransform: 'none' }}
      >
        {emails['0']}
        {ellipsis && '..'}
      </Typography>
      {MoreList(count)}
    </Box>
  );
};

export default ParticipantsList;
