import { gql } from '@apollo/client';

export const UPDATE_TOPIC_NAME = gql`
  mutation updateTopicName($uuid: UUID!, $name: String!) {
    updateTopicName(uuid: $uuid, name: $name) {
      topic {
        uuid
        name
      }
    }
  }
`;

export default { UPDATE_TOPIC_NAME };
