/* eslint-disable import/order */
import React, { useState } from 'react';

// * Utils
import { generateFileName } from './utils';

// * Components
import { DownloadBtn, StyledMenu } from './styledComponents';
import DownloadJSON from './JSON';
import DownloadPDF from './PDF';
import DownloadMP3 from './MP3';
import DownloadDOCX from './DOCX';

// * Icons
import { ReactComponent as ReceiveSquareIcon } from '../../assets/icons/receiveSquareIcon.svg';

// * Interfaces
import type { TranscriptType } from '../../shared/types/transcript';

interface DownloadProps {
  transcript: TranscriptType
}

const Download = ({ transcript }: DownloadProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fileName = generateFileName(transcript);

  return (
    <div>
      <DownloadBtn
        id="download-button"
        aria-controls={open ? 'download-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleClick}
        startIcon={<ReceiveSquareIcon />}
      >
        Download
      </DownloadBtn>
      <StyledMenu
        id="download-menu"
        MenuListProps={{ 'aria-labelledby': 'download-button' }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <DownloadJSON transcriptUuid={transcript.uuid} fileName={fileName} />
        <DownloadPDF transcriptUuid={transcript.uuid} fileName={fileName} />
        <DownloadDOCX transcriptUuid={transcript.uuid} fileName={fileName} />
        <DownloadMP3 audioUrl={transcript.call.transcript.audioUrl} />
      </StyledMenu>
    </div>
  );
};

export default Download;
