/* eslint-disable import/order */
import React from 'react';

// * MUI
import {
  Box, Typography, FormGroup, FormControlLabel,
} from '@mui/material';

// * Components
import { CustomCheckbox } from './styledComponents';

// * Utils
import { Controller } from 'react-hook-form';

// * Interfaces
import type {
  AccountSettingsSubmitType,
  ComplianceCheckItem,
} from '../../shared/types/accountSettings';
import type { Control } from 'react-hook-form';

interface CustomComplianceChecksProps {
  complianceChecks: ComplianceCheckItem[]
  editMode: boolean
  formControl: Control<AccountSettingsSubmitType>
}

const renderComplianceChecks = (
  complianceChecks: ComplianceCheckItem[],
  type: ComplianceCheckItem['type'],
  formControl: Control<AccountSettingsSubmitType>,
  disabled: boolean,
) => {
  const checks = complianceChecks.filter(check => check.type === type);

  const displayNames: Record<ComplianceCheckItem['type'], string> = {
    call: 'Calls',
    meeting: 'Meetings',
    document: 'Documents',
  };

  return (
    <FormGroup sx={{ flexDirection: 'column' }}>
      <Typography variant="h6" mb="16px">
        {displayNames[type]}
      </Typography>
      {checks.length > 0 ? (
        checks.map(complianceCheck => (
          <FormControlLabel
            key={`${type}-${complianceCheck.name}`}
            disabled={disabled}
            sx={{ alignItems: 'flex-start', marginLeft: 0, marginBottom: '16px' }}
            control={(
              <Controller
                name={`customComplianceChecks.${type}.${complianceCheck.name}`}
                control={formControl}
                render={({ field: { onChange, value, ref } }) => (
                  <CustomCheckbox disabled={disabled} onChange={onChange} checked={value} inputRef={ref} />
                )}
              />
            )}
            label={(
              <Box display="flex" flexDirection="column">
                <Typography variant="body1" sx={{ fontWeight: 400 }}>
                  {complianceCheck.name}
                </Typography>
                <Typography variant="subtitle2" color="colors.gray06" sx={{ fontWeight: 500 }}>
                  {complianceCheck.description}
                </Typography>
              </Box>
            )}
          />
        ))
      ) : (
        <Typography variant="subtitle2" color="colors.gray06">
          No
          {' '}
          {displayNames[type]}
          {' '}
          compliance checks available.
        </Typography>
      )}
    </FormGroup>
  );
};

const CustomComplianceChecks = ({
  complianceChecks,
  editMode,
  formControl,
}: CustomComplianceChecksProps) => {
  const disabled = !editMode;

  const types: ComplianceCheckItem['type'][] = ['call', 'meeting', 'document'];

  return (
    <Box width="50%" pl="50px">
      <Typography variant="h6" mb="2px">
        Custom Policies
      </Typography>
      <Typography variant="subtitle2" color="colors.gray06" mb="16px" sx={{ fontWeight: 500 }}>
        Contact support to add custom policies.
      </Typography>
      {types.map(type => renderComplianceChecks(complianceChecks, type, formControl, disabled))}
    </Box>
  );
};

export default CustomComplianceChecks;
