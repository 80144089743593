/* eslint-disable import/order */
import React, { useContext, useEffect, useState } from 'react';

// * MUI
import { Box, Divider, ClickAwayListener } from '@mui/material';

// * Components
import HighlightDetails from '../../HighlightDetails';
import ComplianceReviewer from '../../ComplianceReviewer';
import {
  HighlightItem,
  HighlightDot,
  HighlightStatus,
  CustomChip,
  ConfidenceTypo,
  PercentTypo,
  TranscriptText,
  Reason,
  ReasonTooltip,
  ReasonTitle,
  ReadMore,
} from './styledComponents';

// * Hooks & Utils
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { AppContext, useMe } from '../../AppContextProvider';
import palette from '../../../theme/palette';

// * Mutations
import { ASSIGN_USER_TO_HIGHLIGHT_MUTATION } from '../../../queries/transcript/transcript';

// * Interfaces
import type { HighlightType } from '../../../shared/types/transcript';

type HighlightItemCardProps = {
  highlight: HighlightType;
  selected: boolean;
  setSelectedHighlight: React.Dispatch<React.SetStateAction<HighlightType | null>>;
  highlightedParagraph: React.ReactNode;
  pendingFilter: boolean;
  resolvedFilter: boolean;
  aiResolvedFilter: boolean;
  isFileAText: boolean;
  audio: HTMLAudioElement | null;
  analysts: string[];
  experts: string[];
  callTopic?: string;
  isPlayingMain: boolean;
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayingParagraph: boolean;
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>;
  pendingCount: number;
  setFirstCompletedUuid: any;
  disableReassign: boolean;
  isDocument: boolean;
};

const statusList = [
  { status: 'pending', color: 'colors.warning04', label: 'Pending' },
  { status: 'resolved', color: 'colors.success03', label: 'Resolved' },
  { status: 'ai resolved', color: 'colors.gray04', label: 'AI Resolved' },
];

const HighlightItemCard = ({
  highlight,
  selected,
  setSelectedHighlight,
  highlightedParagraph,
  pendingFilter,
  resolvedFilter,
  aiResolvedFilter,
  isFileAText,
  audio,
  analysts,
  experts,
  callTopic,
  isPlayingMain,
  setIsPlayingMain,
  isPlayingParagraph,
  setIsPlayingParagraph,
  pendingCount,
  setFirstCompletedUuid,
  disableReassign,
  isDocument,
}: HighlightItemCardProps) => {
  const { me } = useMe();
  const status = highlight.status.value.toLocaleLowerCase();
  const [open, setOpen] = useState(false);
  const { refetchCompletedCalls, refetchReviewedCalls, refetchTranscript } = useContext(AppContext);

  const [assignUser] = useMutation(ASSIGN_USER_TO_HIGHLIGHT_MUTATION, {
    onCompleted: ({ assignHighlightReviewer }) => {
      const { message } = assignHighlightReviewer;
      if (refetchCompletedCalls && refetchReviewedCalls && refetchTranscript) {
        refetchCompletedCalls();
        refetchReviewedCalls();
        refetchTranscript();
        toast.success(message);
      }
    },
  });

  const handleSelectHighlight = () => {
    setSelectedHighlight(selected ? null : highlight);
    if (selected) return;

    const element = document.getElementById(`highlight-text-${highlight.uuid}`);
    if (!element) return;

    const elementRect = element.getBoundingClientRect();
    const elementIsVisible = (
      elementRect.top >= 0
          && elementRect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
    if (elementIsVisible) return;

    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen(true);
  };

  useEffect(() => {
    const reasonContainer = document.getElementById(`reason-${highlight.uuid}`);
    if (reasonContainer && reasonContainer?.clientHeight > 60) {
      const reasonText = reasonContainer.querySelector('.reason-text') as HTMLElement;
      if (reasonText) {
        reasonText.style.webkitLineClamp = '3';
      }
      const readMore = reasonContainer.querySelector('.reason-more-btn') as HTMLElement;
      if (readMore) {
        readMore.style.display = 'flex';
      }
    }
  // eslint-disable-next-line
  }, []);

  return (
    <HighlightItem
      id={`highlight-${highlight.uuid}`}
      onClick={() => handleSelectHighlight()}
      className={selected ? 'selected-highlight-item' : ''}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ComplianceReviewer
          assignUser={assignUser}
          instanceUuid={highlight.uuid}
          initialAssignee={highlight.reviewer || null}
          signedInUserUuid={me.uuid}
          placeholder="Unassigned"
          disabled={disableReassign}
        />
        <Box display="flex" alignItems="center" height={36}>
          <Box display="flex" alignItems="center" mr="12px">
            <ConfidenceTypo>Confidence:</ConfidenceTypo>
            <PercentTypo>
              {highlight.score}
              %
            </PercentTypo>
          </Box>
          {statusList.map(item => (status === item.status && (
            <Box display="flex" alignItems="center" key={item.status}>
              <HighlightDot sx={{ backgroundColor: item.color }} />
              <HighlightStatus variant="body1" color="colors.gray08">
                {item.label}
              </HighlightStatus>
            </Box>
          )))}
        </Box>
      </Box>
      <Divider flexItem sx={{ marginTop: '8px', marginBottom: '8px', borderColor: 'colors.gray03' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          {highlight.categories.map(category => (<CustomChip key={category} label={category} />))}
        </Box>
      </Box>
      {!!highlight.paragraph && (
        <Divider flexItem sx={{ marginTop: '8px', marginBottom: '10px', borderColor: 'colors.gray03' }} />
      )}
      <TranscriptText>{highlight.phrase}</TranscriptText>
      {!!highlight.reason && (
        <>
          <Divider flexItem sx={{ marginTop: '10px', marginBottom: '10px', borderColor: 'colors.gray03' }} />
          <Box id={`reason-${highlight.uuid}`} position="relative">
            <Reason className="reason-text">
              <span style={{ fontWeight: 700, color: palette.colors.gray07 }}>Reason: </span>
              {highlight.reason}
            </Reason>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <ReasonTooltip
                  PopperProps={{ disablePortal: true }}
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={(
                    <>
                      <ReasonTitle>Reason:</ReasonTitle>
                      <span>{highlight.reason}</span>
                    </>
                  )}
                  placement="bottom"
                  arrow
                >
                  <ReadMore onClick={handleTooltipOpen} className="reason-more-btn">
                    Read More..
                  </ReadMore>
                </ReasonTooltip>
              </div>
            </ClickAwayListener>
          </Box>
        </>
      )}
      <Divider flexItem sx={{ marginTop: '10px', marginBottom: '8px', borderColor: 'colors.gray03' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <HighlightDetails
          callTopic={callTopic}
          notesIconActions
          highlight={highlight}
          highlightedParagraph={highlightedParagraph}
          pendingFilter={pendingFilter}
          resolvedFilter={resolvedFilter}
          aiResolvedFilter={aiResolvedFilter}
          isFileAText={isFileAText}
          audio={audio}
          analysts={analysts}
          experts={experts}
          isPlayingMain={isPlayingMain}
          setIsPlayingMain={setIsPlayingMain}
          isPlayingParagraph={isPlayingParagraph}
          setIsPlayingParagraph={setIsPlayingParagraph}
          pendingCount={pendingCount}
          setFirstCompletedUuid={setFirstCompletedUuid}
          isDocument={isDocument}
        />
        <Box
          display={selected ? 'flex' : 'none'}
          alignItems="center"
          className="buttons-on-hover"
          sx={{ transition: 'display 250ms' }}
        >
          <HighlightDetails
            callTopic={callTopic}
            notesIconActions={false}
            highlight={highlight}
            highlightedParagraph={highlightedParagraph}
            pendingFilter={pendingFilter}
            resolvedFilter={resolvedFilter}
            aiResolvedFilter={aiResolvedFilter}
            isFileAText={isFileAText}
            audio={audio}
            analysts={analysts}
            experts={experts}
            isPlayingMain={isPlayingMain}
            setIsPlayingMain={setIsPlayingMain}
            isPlayingParagraph={isPlayingParagraph}
            setIsPlayingParagraph={setIsPlayingParagraph}
            pendingCount={pendingCount}
            setFirstCompletedUuid={setFirstCompletedUuid}
            isDocument={isDocument}
          />
        </Box>
      </Box>
    </HighlightItem>
  );
};

HighlightItemCard.defaultProps = {
  callTopic: '',
};

export default HighlightItemCard;
