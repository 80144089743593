import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Button, Box, TextField, Typography, CircularProgress, InputAdornment,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ApolloError, useMutation } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { GET_LOGIN_LINK_MUTATION } from '../../queries/auth/account';
import LoginLogoImg from '../../assets/img/loginLogoImg.png';
import LoginLogoImgWebp from '../../assets/img/loginLogoImg.webp';
import LoginLogoMobImg from '../../assets/img/loginLogoMobImg.png';
import LoginLogoMobImgWebp from '../../assets/img/loginLogoMobImg.webp';
import LoginRingsLeftImg from '../../assets/img/loginRingsLeftImg.png';
import LoginRingsLeftImgWebp from '../../assets/img/loginRingsLeftImg.webp';
import LoginRingsLeftMobImg from '../../assets/img/loginRingsLeftMobImg.png';
import LoginRingsLeftMobImgWebp from '../../assets/img/loginRingsLeftMobImg.webp';
import LoginRingsRightImg from '../../assets/img/loginRingsRightImg.png';
import LoginRingsRightImgWebp from '../../assets/img/loginRingsRightImg.webp';
import LoginRingsRightMobImg from '../../assets/img/loginRingsRightMobImg.png';
import LoginRingsRightMobImgWebp from '../../assets/img/loginRingsRightMobImg.webp';
import LoginCirclesImg from '../../assets/img/loginСirсlesImg.png';
import LoginCirclesImgWebp from '../../assets/img/loginСirсlesImg.webp';
import LoginCirclesMobImg from '../../assets/img/loginCirclesMobImg.png';
import LoginCirclesMobImgWebp from '../../assets/img/loginCirclesMobImg.webp';

const ScreenBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
}));

const CardBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '55px 29px',
  marginTop: '32px',
  maxWidth: '410px',
  borderRadius: '15px',
  zIndex: 1,
  [theme.breakpoints.down('md')]: {
    padding: '40px 20px 0',
    marginTop: 0,
    maxWidth: '360px',
  },
}));

const schema = yup.object({
  email: yup.string().email('Email must be a valid email').required('Email is a required field'),
}).required();

type SubmitType = {
  email: string;
};

const SignInEmail = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control, handleSubmit, formState: { dirtyFields, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { email: '' },
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [formErrors, setFormErrors] = useState<string | undefined>(undefined);
  const login = (authenticationLink: string) => {
    window.location.href = authenticationLink;
  };

  const [getLoginLink] = useMutation(GET_LOGIN_LINK_MUTATION, {
    onCompleted: data => {
      const { authenticationLink } = data.requestAuthenticationLink;
      if (authenticationLink) {
        login(authenticationLink);
      } else {
        setIsSubmitting(false);
        setFormErrors('Invalid email');
      }
    },
    onError: (error: ApolloError) => {
      setIsSubmitting(false);
      setFormErrors(error.message);
    },
  });

  const onSubmit = (data: SubmitType) => {
    setIsSubmitting(true);
    getLoginLink({
      variables: {
        email: data.email,
      },
    });
  };

  return (
    <ScreenBox sx={{ backgroundColor: 'background.paper' }}>
      <Box display="flex" justifyContent="center" alignItems="center" height="100%" flexGrow={1}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <img
            src={isMobile ? LoginLogoMobImg : LoginLogoImg}
            srcSet={isMobile ? LoginLogoMobImgWebp : LoginLogoImgWebp}
            alt="logo"
            loading="lazy"
            width={isMobile ? 197 : 266}
            height={isMobile ? 59 : 80}
          />
          <CardBox>
            <Typography variant="h4" color="colors.gray10" textAlign="center" mb={isMobile ? '18px' : '36px'}>
              Sign In
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="email"
                render={({
                  field: {
                    onChange, value, name, ref,
                  },
                }) => (
                  <TextField
                    inputRef={ref}
                    name={name}
                    value={value}
                    onChange={onChange}
                    error={!isEmpty(errors) || !!formErrors}
                    helperText={(!isEmpty(errors) && 'Invalid email') || (formErrors && formErrors)}
                    variant="outlined"
                    placeholder="Your Email"
                    sx={{ width: isMobile ? 320 : 352, marginBottom: isMobile ? '12px' : '30px' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AlternateEmailIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Button
                variant="contained"
                size="large"
                disabled={isSubmitting || !dirtyFields.email}
                type="submit"
                fullWidth
                sx={{ marginBottom: isMobile ? '12px' : '31px' }}
              >
                {!isSubmitting ? 'Next' : <CircularProgress size={29} />}
              </Button>
            </form>
          </CardBox>
        </Box>
        <Box display="flex" position="absolute" top={0} left={0}>
          <img
            src={isMobile ? LoginRingsLeftMobImg : LoginRingsLeftImg}
            srcSet={isMobile ? LoginRingsLeftMobImgWebp : LoginRingsLeftImgWebp}
            alt="img1"
            loading="lazy"
            width={isMobile ? 95 : 614}
            height={isMobile ? 95 : 614}
          />
        </Box>
        <Box display="flex" position="absolute" bottom={0} right={0}>
          <img
            src={isMobile ? LoginRingsRightMobImg : LoginRingsRightImg}
            srcSet={isMobile ? LoginRingsRightMobImgWebp : LoginRingsRightImgWebp}
            alt="img2"
            loading="lazy"
            width={isMobile ? 113 : 614}
            height={isMobile ? 113 : 614}
          />
        </Box>
        <Box display="flex" position="absolute" bottom={isMobile ? 20 : 80} left={isMobile ? 20 : 100}>
          <img
            src={isMobile ? LoginCirclesMobImg : LoginCirclesImg}
            srcSet={isMobile ? LoginCirclesMobImgWebp : LoginCirclesImgWebp}
            alt="img3"
            loading="lazy"
            width={isMobile ? 103 : 230}
            height={isMobile ? 62 : 140}
          />
        </Box>
      </Box>
    </ScreenBox>
  );
};

export default SignInEmail;
