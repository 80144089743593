import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { HeaderBarRP } from '../../components';

interface ResearchAppHeaderLayoutProps {
  children: ReactNode;
}

const ResearchAppHeaderLayout: React.FC<ResearchAppHeaderLayoutProps> = ({ children }) => (
  <>
    <HeaderBarRP />
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      {children}
    </Box>
  </>
);

export default ResearchAppHeaderLayout;
