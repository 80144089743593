import * as React from 'react';
import {
  Box, Button, Checkbox, FormControlLabel, FormGroup, Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import { useMutation } from '@apollo/client';
import palette from '../../theme/palette';
import { ReactComponent as CompleteReviewWithNoHighlightsImg } from '../../assets/img/completeReviewWithNoHighlightsImg.svg';
import { AppContext } from '../AppContextProvider';
import {
  COMPLETE_COMPLIANCE_REVIEW_MUTATION,
  DISABLE_AUDIO_IN_RESEARCH_PORTAL_MUTATION,
  DISPLAY_IN_RESEARCH_PORTAL_MUTATION,
  TRANSCRIPT_QUERY,
  UPDATE_FULL_TRANSCRIPT_REVIEWER_MUTATION,
  UPDATE_LIVE_CHAPERONE_REVIEWER_MUTATION,
} from '../../queries/transcript/transcript';

const CheckboxBlock = styled(Box)({
  display: 'flex',
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '12px',
  padding: '24px 32px',
  margin: '16px 0',
});

const CustomCheckbox = styled(Checkbox)({
  padding: 0,
  paddingRight: '7px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-checked': {
    color: palette.colors.success03,
  },
});

const CompleteBtn = styled(Button)({
  padding: '12px 16px',
  borderRadius: '6px',
  backgroundColor: palette.colors.success03,
  fontWeight: 600,
  '& .MuiButton-startIcon svg': {
    fontSize: '24px',
  },
  '&:hover': {
    backgroundColor: palette.colors.success04,
    color: palette.white,
  },
});

type CompleteReviewWithNoHighlightsProps = {
  transcriptId: string;
  reviewUuid: string;
  disableAudio: boolean;
  displayInResearchPortal: boolean;
  setFirstCompletedUuid: any;
  hasFullTranscriptReviewer: boolean;
  hasLiveChaperoneReviewer: boolean;
  callType: string;
};

const CompleteReviewWithNoHighlights = ({
  transcriptId,
  reviewUuid,
  disableAudio,
  displayInResearchPortal,
  hasFullTranscriptReviewer,
  hasLiveChaperoneReviewer,
  setFirstCompletedUuid,
  callType,
}: CompleteReviewWithNoHighlightsProps) => {
  const { refetchCompletedCalls, refetchReviewedCalls, refetchTranscript } = React.useContext(AppContext);

  const [mutateDisableAudioInResearch] = useMutation(DISABLE_AUDIO_IN_RESEARCH_PORTAL_MUTATION, {
    onCompleted: () => refetchTranscript?.(),
  });

  const [mutateDisplayInResearch] = useMutation(DISPLAY_IN_RESEARCH_PORTAL_MUTATION, {
    onCompleted: () => refetchTranscript?.(),
  });

  const [mutateLiveChaperoneReviewer] = useMutation(UPDATE_LIVE_CHAPERONE_REVIEWER_MUTATION, {
    onCompleted: () => refetchTranscript?.(),
  });

  const [mutateFullTranscriptReviewer] = useMutation(UPDATE_FULL_TRANSCRIPT_REVIEWER_MUTATION, {
    onCompleted: () => refetchTranscript?.(),
  });

  const [completeReview] = useMutation(COMPLETE_COMPLIANCE_REVIEW_MUTATION, {
    onCompleted: () => {
      refetchCompletedCalls?.();
      refetchReviewedCalls?.();
      setFirstCompletedUuid();
    },
    refetchQueries: [
      { query: TRANSCRIPT_QUERY, variables: { uuid: transcriptId } },
    ],
  });

  // Local states for checkboxes
  const [isDisplayInResearch, setIsDisplayInResearch] = React.useState(displayInResearchPortal);
  const [isDisableAudio, setIsDisableAudio] = React.useState(disableAudio);
  const [isLiveChaperone, setIsLiveChaperone] = React.useState(hasLiveChaperoneReviewer);
  const [isFullTranscript, setIsFullTranscript] = React.useState(hasFullTranscriptReviewer);

  // Update local states when props change
  React.useEffect(() => {
    setIsDisplayInResearch(displayInResearchPortal);
    setIsDisableAudio(disableAudio);
    setIsLiveChaperone(hasLiveChaperoneReviewer);
    setIsFullTranscript(hasFullTranscriptReviewer);
  }, [displayInResearchPortal, disableAudio, hasLiveChaperoneReviewer, hasFullTranscriptReviewer]);

  // Handlers for mutations
  const handleDisplayInResearch = (isChecked: boolean) => {
    mutateDisplayInResearch({
      variables: {
        transcriptUuid: transcriptId,
        inResearch: isChecked,
      },
    });
  };

  const handleDisableAudio = (isChecked: boolean) => {
    mutateDisableAudioInResearch({
      variables: {
        transcriptUuid: transcriptId,
        disableAudio: isChecked,
      },
    });
  };

  const handleLiveChaperone = (isChecked: boolean) => {
    mutateLiveChaperoneReviewer({
      variables: {
        reviewUuid,
        assignReviewer: isChecked,
      },
    });
  };

  const handleFullTranscript = (isChecked: boolean) => {
    mutateFullTranscriptReviewer({
      variables: {
        reviewUuid,
        assignReviewer: isChecked,
      },
    });
  };

  // Handle checkbox change
  const handleCheckboxChange = (name: string, isChecked: boolean) => {
    switch (name) {
      case 'displayInResearchCheckbox':
        setIsDisplayInResearch(isChecked);
        handleDisplayInResearch(isChecked);
        break;
      case 'disableAudioCheckbox':
        setIsDisableAudio(isChecked);
        handleDisableAudio(isChecked);
        break;
      case 'liveChaperoneCheckbox':
        setIsLiveChaperone(isChecked);
        handleLiveChaperone(isChecked);
        break;
      case 'fullTranscriptCheckbox':
        setIsFullTranscript(isChecked);
        handleFullTranscript(isChecked);
        break;
      default:
        break;
    }
  };

  const handleCompleteReview = () => {
    completeReview({ variables: { reviewUuid } });
  };

  // Determine which checkboxes to display based on callType
  const showDisplayInResearch = true; // Display in Research is always shown
  const showDisableAudio = callType === 'call' || callType === 'meeting';
  const showLiveChaperone = callType === 'call';
  const showFullTranscript = callType === 'call' || callType === 'document' || callType === 'meeting';

  return (
    <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <CompleteReviewWithNoHighlightsImg />
        <Typography
          variant="subtitle2"
          color="colors.gray10"
          align="center"
          sx={{ fontWeight: 400, marginTop: '21px' }}
        >
          Press the Complete Review button to move to the Reviewed tab.
        </Typography>
        <CheckboxBlock>
          <form>
            <FormGroup>
              <Box display="flex" flexDirection="column" gap="16px">
                {showDisplayInResearch && (
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={(
                      <CustomCheckbox
                        name="displayInResearchCheckbox"
                        onChange={e => handleCheckboxChange('displayInResearchCheckbox', e.target.checked)}
                        checked={isDisplayInResearch}
                      />
                    )}
                    label="Display in Research Portal"
                  />
                )}
                {showDisableAudio && (
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={(
                      <CustomCheckbox
                        name="disableAudioCheckbox"
                        onChange={e => handleCheckboxChange('disableAudioCheckbox', e.target.checked)}
                        checked={isDisableAudio}
                      />
                    )}
                    label="Disable Audio"
                  />
                )}
                {showLiveChaperone && (
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={(
                      <CustomCheckbox
                        name="liveChaperoneCheckbox"
                        onChange={e => handleCheckboxChange('liveChaperoneCheckbox', e.target.checked)}
                        checked={isLiveChaperone}
                      />
                    )}
                    label="Live Chaperone"
                  />
                )}
                {showFullTranscript && (
                  <FormControlLabel
                    sx={{ margin: 0 }}
                    control={(
                      <CustomCheckbox
                        name="fullTranscriptCheckbox"
                        onChange={e => handleCheckboxChange('fullTranscriptCheckbox', e.target.checked)}
                        checked={isFullTranscript}
                      />
                    )}
                    label="Full Transcript Review"
                  />
                )}
              </Box>
            </FormGroup>
          </form>
        </CheckboxBlock>
        <CompleteBtn
          variant="contained"
          startIcon={<CheckCircleIcon />}
          onClick={handleCompleteReview}
        >
          Complete Review
        </CompleteBtn>
      </Box>
    </Box>
  );
};

export default CompleteReviewWithNoHighlights;
