import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import palette from '../../theme/palette';
import paths from '../../paths/paths';

const TopicItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '12px',
  backgroundColor: palette.white,
  padding: '18px 20px 12px',
  marginBottom: '12px',
  cursor: 'pointer',
  position: 'relative',
  transition: 'all 250ms',
  '&:hover': {
    borderColor: '#70BDFF',
    boxShadow: '0px 4px 13px rgba(188, 188, 188, 0.25)',
  },
  '&.selected-call-item': {
    borderColor: '#509CF1',
    background: 'linear-gradient(0deg, rgba(80, 156, 241, 0.05) 0%, rgba(80, 156, 241, 0.05) 100%), #FFFFFF',
    boxShadow: '0px 4px 13px 0px rgba(188, 188, 188, 0.25)',
  },
});

const TopicTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  height: '48px',
  maxHeight: '48px',
  lineHeight: '24px',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
});

type TopicItemCardProps = {
  uuid: string;
  title: string;
  callsCount: number;
  selected: boolean;
  onClick: React.Dispatch<React.SetStateAction<string | undefined>>;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TopicItemCard = ({
  uuid,
  title,
  callsCount,
  selected,
  onClick,
  openDrawerMobile,
  setOpenDrawerMobile,
}: TopicItemCardProps) => {
  const navigate = useNavigate();

  const handleSelectTopic = () => {
    onClick(uuid);
    setOpenDrawerMobile?.(!openDrawerMobile);
    navigate(paths.topics.reverse({ topicId: uuid }));
  };

  return (
    <TopicItem onClick={handleSelectTopic} className={selected ? 'selected-call-item' : ''}>
      <TopicTitle variant="body2" color="colors.gray10">
        {title}
      </TopicTitle>
      <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />
      <Typography
        variant="body1"
        color="colors.gray08"
        sx={{ fontSize: '14px', fontWeight: 400, lineHeight: '20px' }}
      >
        {`Content: ${callsCount}`}
      </Typography>
    </TopicItem>
  );
};

TopicItemCard.defaultProps = {
  openDrawerMobile: false,
  setOpenDrawerMobile: false,
};

export default TopicItemCard;
