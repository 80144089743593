import * as React from 'react';
import {
  Box, Divider, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  CallItem, NetworkLabel, NetworkValue, CallTitle,
} from './styledComponents';
import { formatDateTimeToUserTimezone } from '../../../shared/utils/dateHelpers';
import paths from '../../../paths/paths';
import { useMe } from '../../AppContextProvider';
import type { CallTypes, StringOptionType } from '../../../shared/types/transcript';

type RelatedCallItemProps = {
  uuid: string;
  name: string;
  dateTime: string;
  expertName: string;
  network: StringOptionType;
  type: CallTypes;
  onClick: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const RelatedCallItem = ({
  uuid, name, dateTime, expertName, network, type, onClick,
}: RelatedCallItemProps) => {
  const navigate = useNavigate();
  const { me } = useMe();

  const handleSelectCall = () => {
    onClick(uuid);
    navigate(paths.home.reverse({ transcriptId: uuid }));
  };

  const isCall = type === 'call';

  return (
    <CallItem onClick={handleSelectCall}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          variant="body1"
          color="colors.gray06"
          sx={{ fontSize: '12px', lineHeight: '18px' }}
        >
          {formatDateTimeToUserTimezone(dateTime, me.timezone)}
        </Typography>
      </Box>
      <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />
      <Box display="flex" alignItems="center">
        <Box display="flex" flexDirection="column" width="50%" mr="2px">
          <NetworkLabel variant="body1" color="colors.gray08">
            Expert Name
          </NetworkLabel>
          <NetworkValue variant="body1" color="colors.gray08">
            {expertName || 'Not Available'}
          </NetworkValue>
        </Box>
        {isCall && (
          <Box display="flex" flexDirection="column" width="50%" ml="2px">
            <NetworkLabel variant="body1" color="colors.gray08">
              Expert Network
            </NetworkLabel>
            <NetworkValue variant="body1" color="colors.gray08">
              {network.value}
            </NetworkValue>
          </Box>
        )}
      </Box>
      <Divider flexItem sx={{ margin: '8px 0', borderColor: 'colors.gray03' }} />
      <CallTitle variant="body2" color="colors.gray10">
        {name}
      </CallTitle>
    </CallItem>
  );
};

export default RelatedCallItem;
