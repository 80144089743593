import React from 'react';
import { ApolloProvider } from '@apollo/client';
import LogRocket from 'logrocket';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LicenseInfo } from '@mui/x-license';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import client from './client';
import paths from './paths/paths';
import theme from './theme';
import './assets/scss/index.scss';
import ProtectedRoute from './shared/utils/protectedRoute';
import ValidateResetPasswordLinkGuard from './components/ResetPasswordLinkValidator';
import { AppContextProvider, useMe, UserContextProvider } from './components/AppContextProvider';
import MainApp from './layouts/MainApp';
import ResearchAppCalls from './pages/ResearchAppCalls';
import VerifyToken from './pages/VerifyToken';
import SignInEmail from './pages/SignInEmail';
import SignInPassword from './pages/SignInPassword';
import Page404 from './pages/404';
import UserSettings from './pages/UserSettings';
import OrganizationSettings from './pages/OrganizationSettings';
import Reports from './pages/Reports';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Topics from './pages/Topics';

// @ts-ignore
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY);

LogRocket.init('k4v8ph/encore-compliance');

const Integrations = () => {
  const { me } = useMe();

  if (process.env.REACT_APP_TARGET === 'prod') {
    LogRocket.identify(me ? me.id : 'None', {
      name: `${me.firstName} ${me.lastName}` || '',
      email: me.email || '',
      id: me.id || '',
    });
  }

  return null;
};

const MainLayoutSwitch = () => {
  const { me } = useMe();
  const { isResearchAnalyst } = me;

  return isResearchAnalyst ? <ResearchAppCalls /> : <MainApp />;
};
// eslint-disable-next-line
const AppRoutes = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <UserContextProvider>
          <AppContextProvider>
            <DndProvider backend={HTML5Backend}>
              <Routes>
                <Route element={<ProtectedRoute />}>
                  <Route path="/" element={<Navigate to={paths.home.reverse()} />} />
                  <Route path={paths.home.pattern} element={<MainLayoutSwitch />} />
                  <Route path={paths.topics.pattern} element={<Topics />} />
                  <Route path={paths.userSettings.pattern} element={<UserSettings />} />
                  <Route path={paths.organizationSettings.pattern} element={<OrganizationSettings />} />
                  <Route path={paths.reports.pattern} element={<Reports />} />
                </Route>
                <Route path={paths.forgotPassword.pattern} element={<ForgotPassword />} />
                <Route path={paths.signInEmail.pattern} element={<SignInEmail />} />
                <Route path={paths.signInPassword.pattern} element={<SignInPassword />} />
                <Route path={paths.verifyToken.pattern} element={<VerifyToken />} />
                <Route
                  path={paths.firstLoginPassword.pattern}
                  element={(
                    <ValidateResetPasswordLinkGuard>
                      <ResetPassword
                        title="Welcome to Encore"
                        subtitle="Please set up your password"
                        successMessage="Password set successfully"
                        buttonText="Set password"
                      />
                    </ValidateResetPasswordLinkGuard>
                )}
                />
                <Route
                  path={paths.resetPassword.pattern}
                  element={(
                    <ValidateResetPasswordLinkGuard>
                      <ResetPassword
                        title="Reset your password"
                        subtitle="Enter your password below"
                        successMessage="Password reset successfully"
                        buttonText="Reset password"
                      />
                    </ValidateResetPasswordLinkGuard>
                )}
                />
                <Route path="*" element={<Page404 />} />
              </Routes>
              <Integrations />
            </DndProvider>
          </AppContextProvider>
        </UserContextProvider>
      </ThemeProvider>
    </ApolloProvider>
  </LocalizationProvider>
);

// eslint-disable-next-line
const App = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;
