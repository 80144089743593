import { styled } from '@mui/material/styles';
import {
  Box, IconButton, Drawer, TextField, Button, Checkbox,
} from '@mui/material';
import palette from '../../theme/palette';

export const DrawerContainer = styled(Drawer)(({ theme }) => ({
  width: 850,
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: 850,
    boxSizing: 'border-box',
    boxShadow: '-11px 0px 60px rgba(0, 0, 0, 0.25)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '& .MuiDrawer-paper': {
      width: '100%',
      boxSizing: 'border-box',
      boxShadow: '-11px 0px 60px rgba(0, 0, 0, 0.25)',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
}));

export const ScrollContainer = styled(Box)({
  height: '100%',
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const FooterBlock = styled(Box)({
  borderTop: '1px solid',
  borderTopColor: palette.colors.gray03,
  backgroundColor: palette.colors.gray01,
  padding: '24px 32px',
});

export const ShareButton = styled(Button)({
  borderColor: palette.primary.main,
  borderRadius: '6px',
  color: palette.primary.main,
  height: 48,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: 0,
  padding: '12px 16px',
  whiteSpace: 'nowrap',
  '& svg path': { transition: '250ms' },
  '&:hover': {
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
    color: palette.white,
    '& svg path': { fill: palette.white },
  },
});

export const CloseButton = styled(IconButton)({
  padding: 0,
  marginRight: '20px',
});

export const ImportFullButton = styled(Button)({
  backgroundColor: palette.colors.success03,
  borderRadius: '8px',
  height: 52,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '26px',
  letterSpacing: 0,
  padding: '12px',
  '&:hover': {
    backgroundColor: palette.colors.success04,
  },
  '&.Mui-disabled': {
    backgroundColor: palette.colors.success02,
  },
});

export const TextInputField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.colors.gray03,
    },
  },
});

export const CustomCheckbox = styled(Checkbox)({
  padding: 0,
  paddingRight: '12px',
  '&:hover': { backgroundColor: 'transparent' },
});

export const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: '275px',
      width: '310px',
      border: '1px solid',
      borderColor: palette.primary.main,
      borderRadius: '8px',
      boxShadow: '0px 8px 9px rgba(226, 226, 226, 0.35)',
    },
  },
};
