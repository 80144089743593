/* eslint-disable import/order */
import React, { useRef, useState } from 'react';

// * MUI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Avatar, Box, Divider, IconButton, InputAdornment,
  MenuItem, Select, TextField, Typography, Dialog, Snackbar, Alert, AlertTitle,
  Tab, CircularProgress,
} from '@mui/material';

// * Components
import GoBackLink from '../../components/GoBackLink';
import UserTabPanel from '../../components/Tabs/UserTabPanel';
import { HeaderBar, HeaderBarRP } from '../../components';
import NotificationsSettings from '../../components/NotificationsSettings';
import {
  Main,
  CardContainer,
  CardHeader,
  CancelBtn,
  UpdateBtn,
  EditBtn,
  LabelBox,
  DataBox,
  CameraBtn,
  SelectControl,
  CancelButton,
  SubmitButton,
  UserTabs,
} from './components';

// * Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as InfoCircleIcon } from '../../assets/icons/infoCircleIcon.svg';
import { ReactComponent as CameraIcon } from '../../assets/icons/cameraIcon.svg';
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrowLeftIcon.svg';

// * Hooks & Utils
import { Controller, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { useMe } from '../../components/AppContextProvider';
import palette from '../../theme/palette';
import {
  TIMEZONES, schema, errorMessages, a11yProps,
} from './utils';

// * Mutations
import { UPLOAD_AVATAR, USER_SETTINGS_MUTATION } from '../../queries/auth/account';

const UserSettings = () => {
  const { me, refetchMe, loading } = useMe();
  const [updateUserSettings] = useMutation(USER_SETTINGS_MUTATION, {
    onCompleted: () => {
      refetchMe();
    },
  });
  const [tabValue, setTabValue] = React.useState(0);
  const [editMode, setEditMode] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [textAlert, setTextAlert] = useState('');
  const [showPassword1, setShowPassword1] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [showPassword3, setShowPassword3] = React.useState(false);
  // eslint-disable-next-line
  const isResearchAnalyst = me.isResearchAnalyst;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const [headerTabIndex, setHeaderTabIndex] = React.useState(0);

  const triggerPassword1Visibility = () => {
    setShowPassword1(!showPassword1);
  };

  const triggerPassword2Visibility = () => {
    setShowPassword2(!showPassword2);
  };

  const triggerPassword3Visibility = () => {
    setShowPassword3(!showPassword3);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const defaultValues = {
    firstName: me.firstName,
    lastName: me.lastName,
    email: me.email,
    timezone: me.timezone,
    password1: undefined,
    password2: undefined,
    password3: undefined,
  };

  const {
    control,
    watch,
    reset,
    getValues,
    formState: {
      isDirty,
      isValid,
      errors,
    },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues,
  });

  const handleUpdate = () => {
    const formData = getValues();
    updateUserSettings({
      variables: {
        firstName: formData.firstName,
        lastName: formData.lastName,
        timezone: formData.timezone,
        password: formData.password3,
      },
    });
    setOpenDialog(false);
    setEditMode(!editMode);
  };

  const handleEditMode = () => {
    reset(defaultValues);
    setEditMode(!editMode);
  };

  const watchPassword1 = watch('password1');
  const watchPassword2 = watch('password2');
  const watchPassword3 = watch('password3');

  const password2Check = (itemType: any) => {
    // @ts-ignore
    if (isEmpty(watchPassword2) || (errors?.password2?.types && errors.password2.types[itemType])) {
      return <CheckCircleOutlineIcon sx={{ color: '#E4E8EE' }} />;
    }
    return <CheckCircleOutlineIcon sx={{ color: '#23A566' }} />;
  };

  const password3Check = () => {
    if (isEmpty(watchPassword3) || errors?.password3?.types) {
      return <CheckCircleOutlineIcon sx={{ color: '#E4E8EE' }} />;
    }
    return <CheckCircleOutlineIcon sx={{ color: '#23A566' }} />;
  };

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };

  const [uploadAvatar, { loading: avatarLoading = true }] = useMutation(UPLOAD_AVATAR, {
    onCompleted: () => {
      refetchMe();
    },
  });
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };
  const uploadFile = (fObj: any) => {
    const maxSizeInBytes = 5 * 1024 * 1024;
    if (fObj.size > maxSizeInBytes) {
      setOpenAlert(true);
      setTextAlert('Maximum file size allowed is 5MB');
      return;
    }
    if (fObj.type !== 'image/jpeg' && fObj.type !== 'image/jpg' && fObj.type !== 'image/png') {
      setOpenAlert(true);
      setTextAlert('Supported type: .jpg, .jpeg or .png format only.');
      return;
    }
    uploadAvatar({ variables: { file: fObj } });
  };

  const isEmptyUserErrors = !isEmpty(errors.firstName)
    || !isEmpty(errors.lastName)
    || !isEmpty(errors.email)
    || !isEmpty(errors.timezone);

  const updatingPassword = (isEmpty(watchPassword1) && isEmpty(watchPassword2) && isEmpty(watchPassword3))
    ? false : !isValid;

  const disableUpdateBtn = updatingPassword || isEmptyUserErrors || !isDirty || watchPassword2 !== watchPassword3;

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {isResearchAnalyst ? <HeaderBarRP /> : <HeaderBar />}
      <Main>
        <Box display="flex" justifyContent="center" flexGrow={1}>
          <Box maxWidth={isMobile ? '100%' : 1096}>
            {!loading && !editMode && <GoBackLink />}
            <Box display="flex" sx={{ marginTop: editMode ? '32px' : '28px' }}>
              {!loading && editMode && (
                <CardContainer>
                  <CardHeader>
                    <CancelBtn onClick={handleEditMode}>
                      <ArrowLeftIcon />
                      <Typography variant="h6" color="colors.gray07" sx={{ marginLeft: '12px' }}>
                        Cancel
                      </Typography>
                    </CancelBtn>
                    <Typography display={isMobile ? 'none' : 'block'} variant="h4" sx={{ fontSize: '24px', lineHeight: '32px' }}>
                      Edit User Settings
                    </Typography>
                    <UpdateBtn
                      variant="contained"
                      startIcon={<CheckCircleIcon />}
                      onClick={handleDialog}
                      disabled={disableUpdateBtn}
                    >
                      Update
                    </UpdateBtn>
                    <Dialog open={openDialog} onClose={handleDialog}>
                      <Box display="flex" flexDirection="column" alignItems="center" p="55px 29px">
                        <Box display="flex" mb="8px">
                          <InfoCircleIcon />
                        </Box>
                        <Typography variant="h3" color="colors.gray10" textAlign="center" mb="25px">
                          Update Information
                        </Typography>
                        <Typography variant="body1" color="colors.gray10" textAlign="center" sx={{ fontWeight: 400 }}>
                          Are you sure you want to update your profile information?
                        </Typography>
                        <Box display="flex" flexDirection={isMobile ? 'column-reverse' : 'row'} justifyContent="center" mt="36px">
                          <CancelButton variant="outlined" onClick={handleDialog}>
                            Cancel
                          </CancelButton>
                          <SubmitButton variant="contained" onClick={handleUpdate}>
                            Yes, Update
                          </SubmitButton>
                        </Box>
                      </Box>
                    </Dialog>
                  </CardHeader>
                  {isMobile && (
                    <Box p="0 15px 16px">
                      <Typography variant="h4" sx={{ fontSize: '24px', lineHeight: '32px' }}>
                        Edit User Settings
                      </Typography>
                    </Box>
                  )}
                  <Divider flexItem sx={{ margin: isMobile ? '0 0 16px' : '0 0 32px', borderColor: 'colors.gray03' }} />
                  <Box display="flex" flexDirection="column" padding={isMobile ? '0 15px 25px' : '0 48px 48px'}>
                    <Typography variant="body1" sx={{ lineHeight: '29px', marginBottom: '16px' }}>
                      Profile Picture
                    </Typography>
                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" mb="24px">
                      <Box display="flex" position="relative" mr={isMobile ? 0 : '24px'} mb={isMobile ? '16px' : 0}>
                        <Box position="relative" display="inline-flex">
                          <Avatar alt={`${me.firstName} ${me.lastName}`} src={me.avatar} sx={{ height: 120, width: 120, fontSize: '48px' }} />
                          {avatarLoading && (
                            <CircularProgress
                              size={120}
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,
                              }}
                            />
                          )}
                        </Box>
                        {!avatarLoading && (
                          <>
                            <input
                              ref={fileInputRef}
                              type="file"
                              hidden
                              // @ts-ignore
                              onChange={e => uploadFile(e.target.files[0])}
                              accept="image/*"
                            />
                            <CameraBtn onClick={handleButtonClick}>
                              <CameraIcon />
                            </CameraBtn>
                          </>
                        )}
                        <Snackbar
                          open={openAlert}
                          autoHideDuration={5000}
                          onClose={handleClose}
                        >
                          <Alert severity="error">
                            <AlertTitle>Error</AlertTitle>
                            <Box sx={{ fontSize: '14px' }}>{textAlert}</Box>
                          </Alert>
                        </Snackbar>
                      </Box>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center">
                          <CircleIcon sx={{ color: palette.colors.gray05, height: 6, width: 6 }} />
                          <Typography variant="body1" color="colors.gray05" ml="8px" sx={{ fontSize: isMobile ? '12px' : '14px' }}>
                            Supported type: .jpg, .jpeg or .png format only.
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <CircleIcon sx={{ color: palette.colors.gray05, height: 6, width: 6 }} />
                          <Typography variant="body1" color="colors.gray05" ml="8px" sx={{ fontSize: isMobile ? '12px' : '14px' }}>
                            Maximum file size allowed is 5MB.
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <CircleIcon sx={{ color: palette.colors.gray05, height: 6, width: 6 }} />
                          <Typography variant="body1" color="colors.gray05" ml="8px" sx={{ fontSize: isMobile ? '12px' : '14px' }}>
                            Suggested Resolution: 500x500 pixels.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap="32px" mb="24px">
                      <Box display="flex" flexGrow={1} flexDirection="column">
                        <Typography variant="body1" mb="6px">
                          First Name
                        </Typography>
                        <Controller
                          control={control}
                          name="firstName"
                          defaultValue={me.firstName}
                          render={({
                            field: {
                              onChange, value, name, ref,
                            },
                            fieldState: { error },
                          }) => (
                            <TextField
                              inputRef={ref}
                              name={name}
                              value={value}
                              error={!!error}
                              onChange={onChange}
                              variant="outlined"
                              placeholder="Your First Name"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineOutlinedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        <Typography variant="body1" mt="24px" mb="6px">
                          Email
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={me.email}
                          name="email"
                          render={({
                            field: {
                              onChange, value, name, ref,
                            },
                            fieldState: { error },
                          }) => (
                            <TextField
                              inputRef={ref}
                              name={name}
                              value={value}
                              error={!!error}
                              onChange={onChange}
                              variant="outlined"
                              placeholder="Your Email"
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AlternateEmailIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Box>
                      <Box display="flex" flexGrow={1} flexDirection="column">
                        <Typography variant="body1" mb="6px">
                          Last Name
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={me.lastName}
                          name="lastName"
                          render={({
                            field: {
                              onChange, value, name, ref,
                            },
                            fieldState: { error },
                          }) => (
                            <TextField
                              inputRef={ref}
                              name={name}
                              value={value}
                              error={!!error}
                              onChange={onChange}
                              variant="outlined"
                              placeholder="Your Last Name"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PersonOutlineOutlinedIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                        <Typography variant="body1" mt="24px" mb="6px">
                          Time zone
                        </Typography>
                        <Controller
                          control={control}
                          defaultValue={me.timezone}
                          name="timezone"
                          render={({
                            field: {
                              onChange, value, name, ref,
                            },
                            fieldState: { error },
                          }) => (
                            <SelectControl>
                              <Select
                                inputRef={ref}
                                name={name}
                                value={value}
                                error={!!error}
                                onChange={onChange}
                                displayEmpty
                                IconComponent={ExpandMoreIcon}
                                sx={{
                                  height: 58,
                                }}
                                renderValue={selected => {
                                  if (selected === undefined) {
                                    return (
                                      <Box display="flex" alignItems="center">
                                        <AlternateEmailIcon sx={{ color: palette.icon, marginRight: '12px' }} />
                                        <Typography variant="body1" color="colors.placeholder">
                                          Your Time Zone
                                        </Typography>
                                      </Box>
                                    );
                                  }
                                  return (
                                    <Box display="flex" alignItems="center">
                                      <AlternateEmailIcon sx={{ color: palette.icon, marginRight: '12px' }} />
                                      <Typography variant="body1">
                                        {selected}
                                      </Typography>
                                    </Box>
                                  );
                                }}
                              >
                                {TIMEZONES.map(t => <MenuItem key={t} value={t}>{t}</MenuItem>)}
                              </Select>
                            </SelectControl>
                          )}
                        />
                      </Box>
                    </Box>
                    <Typography variant="h4" mb="16px" sx={{ fontSize: '24px', lineHeight: '30px' }}>
                      Change Password
                    </Typography>
                    <Typography variant="body1" color="colors.gray06" mb="24px" sx={{ fontWeight: 400 }}>
                      Please enter your old password, for security’s sake, and then enter your new password so
                      we can verify you typed it in correctly:
                    </Typography>
                    <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} gap="32px" mb="24px">
                      <Controller
                        control={control}
                        name="password1"
                        render={({
                          field: {
                            onChange, value, name, ref,
                          },
                        }) => (
                          <TextField
                            inputRef={ref}
                            name={name}
                            value={value}
                            onChange={onChange}
                            type={showPassword1 ? 'text' : 'password'}
                            variant="outlined"
                            placeholder="Current Password"
                            sx={{
                              width: isMobile ? '320px' : '352px',
                              display: 'none',
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={triggerPassword1Visibility}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="password2"
                        render={({
                          field: {
                            onChange, value, name, ref,
                          },
                        }) => (
                          <TextField
                            inputRef={ref}
                            name={name}
                            value={value}
                            onChange={onChange}
                            type={showPassword2 ? 'text' : 'password'}
                            variant="outlined"
                            placeholder="New Password"
                            sx={{
                              /* width: isMobile ? '320px' : '352px', */
                              width: '100%',
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={triggerPassword2Visibility}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="password3"
                        render={({
                          field: {
                            onChange, value, name, ref,
                          },
                        }) => (
                          <TextField
                            inputRef={ref}
                            name={name}
                            value={value}
                            onChange={onChange}
                            type={showPassword3 ? 'text' : 'password'}
                            variant="outlined"
                            placeholder="Confirm Password"
                            sx={{
                              /* width: isMobile ? '320px' : '352px', */
                              width: '100%',
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LockIcon />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={triggerPassword3Visibility}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword3 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Box>
                    <Box>
                      {Object.entries(errorMessages).map(([errorType, errorMessage]) => (
                        <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }} key={errorType}>
                          {password2Check(errorType)}
                          <Typography
                            variant="body1"
                            color="colors.gray10"
                            sx={{
                              lineHeight: '29px',
                              marginLeft: '10px',
                            }}
                          >
                            {errorMessage}
                          </Typography>
                        </Box>
                      ))}
                      <Box sx={{ display: 'flex', flexDirection: 'row', marginBottom: '5px' }}>
                        {password3Check()}
                        <Typography
                          variant="body1"
                          color="colors.gray10"
                          sx={{
                            lineHeight: '29px',
                            marginLeft: '10px',
                          }}
                        >
                          Both password are a match
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </CardContainer>
              )}
              {!loading && !editMode && (
                <>
                  {!isMobile && (
                    <UserTabs
                      orientation="vertical"
                      value={tabValue}
                      onChange={handleChangeTab}
                      aria-label="user-tabs"
                    >
                      <Tab label="General" {...a11yProps(0)} />
                      {!isResearchAnalyst && (
                        <Tab label="Notifications" {...a11yProps(1)} />
                      )}
                    </UserTabs>
                  )}
                  <UserTabPanel value={tabValue} index={0}>
                    <CardContainer>
                      <CardHeader>
                        <Typography variant="h4" sx={{ fontSize: '24px', lineHeight: '32px' }}>
                          User Settings
                        </Typography>
                        <EditBtn
                          variant="outlined"
                          startIcon={<BorderColorOutlinedIcon />}
                          onClick={handleEditMode}
                        >
                          Edit
                        </EditBtn>
                      </CardHeader>
                      <Divider flexItem sx={{ margin: '0 0 32px', borderColor: 'colors.gray03' }} />
                      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" padding={isMobile ? '0 15px 25px' : '0 48px 64px'}>
                        <Box position="relative" display="inline-flex">
                          <Avatar alt={`${me.firstName} ${me.lastName}`} src={me.avatar} sx={{ height: 120, width: 120, fontSize: '48px' }} />
                          {avatarLoading && (
                            <CircularProgress
                              size={120}
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                zIndex: 1,
                              }}
                            />
                          )}
                        </Box>
                        <Typography variant="h3" sx={{ margin: '32px 0 12px' }}>
                          {`${me.firstName} ${me.lastName}`}
                        </Typography>
                        <Typography variant="h5" color="primary.main">
                          {isResearchAnalyst ? 'Research' : 'Compliance'}
                        </Typography>
                        <Divider flexItem sx={{ margin: '32px 0', borderColor: 'colors.gray03' }} />
                        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" width="100%" mb="32px">
                          <LabelBox>Email Address</LabelBox>
                          <Box display={isMobile ? 'none' : 'flex'} minWidth={24} mx="20px">
                            <AlternateEmailIcon sx={{ color: palette.colors.gray04 }} />
                          </Box>
                          <DataBox>
                            {me.email}
                          </DataBox>
                        </Box>
                        <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" width="100%">
                          <LabelBox>Time zone</LabelBox>
                          <Box display={isMobile ? 'none' : 'flex'} minWidth={24} mx="20px">
                            <AccessTimeIcon sx={{ color: palette.colors.gray04 }} />
                          </Box>
                          <DataBox>
                            {me.timezone}
                          </DataBox>
                        </Box>
                        <Divider flexItem sx={{ margin: '32px 0', borderColor: 'colors.gray03' }} />
                        <EditBtn
                          variant="outlined"
                          startIcon={<LockOutlinedIcon />}
                          onClick={handleEditMode}
                        >
                          Change Password
                        </EditBtn>
                      </Box>
                    </CardContainer>
                  </UserTabPanel>
                  {!isMobile && (
                    <UserTabPanel value={tabValue} index={1}>
                      <NotificationsSettings
                        sendNewExpertCallTranscriptEmail={me.sendNewExpertCallTranscriptEmail}
                        notificationsFrequency={me.notificationsFrequency}
                        updateUserSettings={updateUserSettings}
                      />
                    </UserTabPanel>
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Main>
    </Box>
  );
};

export default UserSettings;
