import * as React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { ReactComponent as ExpertIcon } from '../../../assets/icons/expertIcon.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/peopleIcon.svg';
import { ReactComponent as DocumentTextIcon } from '../../../assets/icons/documentTextIcon.svg';
import palette from '../../../theme/palette';

// * Interfaces
import type{ CallTypes } from '../../../shared/types/transcript';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: palette.colors.gray08,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.colors.gray08,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: palette.white,
    border: '1px solid',
    borderColor: palette.colors.gray08,
    borderRadius: '6px',
    padding: '5px 8px',
    maxWidth: 326,
  },
});

type IndicateTypeProps = {
  type: CallTypes;
};

const IndicateType = ({ type }: IndicateTypeProps) => (
  <Box display="flex" alignItems="center" mr="4px">
    {type === 'call' && (
      <CustomTooltip title="Expert Call" arrow>
        <ExpertIcon />
      </CustomTooltip>
    )}
    {type === 'meeting' && (
      <CustomTooltip title="Meeting Call" arrow>
        <PeopleIcon />
      </CustomTooltip>
    )}
    {type === 'document' && (
      <CustomTooltip title="Document" arrow>
        <DocumentTextIcon />
      </CustomTooltip>
    )}
  </Box>
);

export default IndicateType;
