import React from 'react';

// * Components
import AudioPlayer from '../../AudioPlayer';

export type RenderAudioPlayerProps = {
  audio: HTMLAudioElement | null
  isPlayingMain: boolean
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>
  isPlayingParagraph: boolean
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>
  activeId: string | null
  setActiveId: React.Dispatch<React.SetStateAction<string | null>>
  paragraph: {
    uuid: string
    startTime?: number
    endTime?: number
  }
}

const RenderAudioPlayer = ({
  audio,
  isPlayingMain,
  setIsPlayingMain,
  isPlayingParagraph,
  setIsPlayingParagraph,
  activeId,
  setActiveId,
  paragraph,
}: RenderAudioPlayerProps) => (
  <AudioPlayer
    audio={audio}
    isPlayingMain={isPlayingMain}
    setIsPlayingMain={setIsPlayingMain}
    isPlayingParagraph={isPlayingParagraph}
    setIsPlayingParagraph={setIsPlayingParagraph}
    isPlaying={activeId === paragraph.uuid}
    setIsPlaying={() => setActiveId(paragraph.uuid)}
    setIsStopping={() => setActiveId(null)}
    startTime={paragraph.startTime}
    endTime={paragraph.endTime}
    playParagraph
  />
);

export default RenderAudioPlayer;
