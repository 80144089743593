import { gql } from '@apollo/client';

export const CHANGE_USER_ACCOUNT_MUTATION = gql`
  mutation changeUserAccount($accountUuid: UUID!) {
    changeUserAccount(accountUuid: $accountUuid) {
        status
        message
    }
  }
`;

export default { CHANGE_USER_ACCOUNT_MUTATION };
