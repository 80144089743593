import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  AppBar, Box, Button, Toolbar, Typography, Avatar, MenuItem,
  List, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { isEmpty } from 'lodash';
import SettingIcon from '../../assets/icons/settingIcon.png';
import SettingIconWebp from '../../assets/icons/settingIcon.webp';
import BuildingIcon from '../../assets/icons/buildingIcon.png';
import BuildingIconWebp from '../../assets/icons/buildingIcon.webp';
import ChartIcon from '../../assets/icons/chartIcon.png';
import ChartIconWebp from '../../assets/icons/chartIcon.webp';
import LogoutIcon from '../../assets/icons/logoutIcon.png';
import LogoutIconWebp from '../../assets/icons/logoutIcon.webp';
import { StyledMenu, DropdownButton, Drawer } from './styledComponents';
import paths from '../../paths/paths';
import palette from '../../theme/palette';
import { useMe } from '../AppContextProvider';
import PortalSwitcher from '../PortalSwitcher';
import DefaultFullLogoImg from '../../assets/img/defaultFullLogo.svg';
import AccountSwitcher from '../AccountSwitcher';

const HeaderBar = () => {
  const { me, error } = useMe();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleHomePage = () => {
    navigate(paths.home.reverse());
  };

  const handleProfileSettings = () => {
    handleCloseUserMenu();
    navigate(paths.userSettings.pattern);
  };

  const handleOrganizationSettings = () => {
    handleCloseUserMenu();
    navigate(paths.organizationSettings.pattern);
  };

  const handleReports = () => {
    handleCloseUserMenu();
    navigate(paths.reports.pattern);
  };

  const handleSignOut = () => {
    handleCloseUserMenu();
    localStorage.removeItem('token');
    navigate(paths.signInEmail.reverse());
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        height: 72,
        justifyContent: 'center',
        backgroundColor: palette.white,
        borderBottom: '1px solid',
        borderColor: palette.colors.gray03,
        boxShadow: '0px 6px 20px 0px rgba(176, 183, 195, 0.20)',
        padding: isMobile ? '0 28px' : '0 32px',
      }}
    >
      <Toolbar
        disableGutters
        sx={{ justifyContent: 'space-between' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={150}
          height={64}
          sx={{ cursor: 'pointer' }}
          onClick={handleHomePage}
        >
          <img
            src={me.accountFullLogo || DefaultFullLogoImg}
            alt="logo"
            loading="lazy"
            style={{ maxWidth: '100%', maxHeight: '100%' }}
            /*
            width={me.accountFullLogo ? '100%' : 136}
            height={me.accountFullLogo ? 'auto' : 42}
            */
          />
        </Box>
        {isMobile ? (
          <Box>
            <Button
              onClick={toggleDrawer(true)}
              sx={{
                '& .MuiSvgIcon-root': {
                  color: 'colors.gray04',
                },
              }}
              endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            >
              <Avatar alt={(!isEmpty(me) && !error) ? `${me.firstName} ${me.lastName}` : 'User'} src={me.avatar} />
            </Button>
            <Drawer
              anchor="top"
              open={open}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              disableSwipeToOpen={false}
              ModalProps={{
                keepMounted: true,
              }}
            >
              <Box>
                <List>
                  {me.allowSwitchRolesOnFrontend && <PortalSwitcher isResearch={false} />}
                  <ListItem key="userSettings" onClick={handleProfileSettings} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <img
                          src={SettingIcon}
                          srcSet={SettingIconWebp}
                          alt="icon1"
                          loading="lazy"
                          width="24"
                          height="24"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="body1"
                          color="colors.gray08"
                          sx={{
                            fontSize: '14px',
                            lineHeight: '24px',
                            marginLeft: '4px',
                          }}
                        >
                          User Settings
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                  <ListItem key="signOut" onClick={handleSignOut} disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <img
                          src={LogoutIcon}
                          srcSet={LogoutIconWebp}
                          alt="icon4"
                          loading="lazy"
                          width="24"
                          height="24"
                        />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography
                          variant="body1"
                          color="colors.gray08"
                          sx={{
                            fontSize: '14px',
                            lineHeight: '24px',
                            marginLeft: '4px',
                          }}
                        >
                          Sign Out
                        </Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </Box>
        ) : (
          <Box>
            <DropdownButton
              onClick={handleOpenUserMenu}
              variant="text"
              endIcon={anchorElUser ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            >
              <Box display="flex">
                <Avatar alt={(!isEmpty(me) && !error) ? `${me.firstName} ${me.lastName}` : 'User'} src={me.avatar} />
                <Box display="flex" flexDirection="column" alignItems="flex-start" ml="11px">
                  <Box
                    mb="4px"
                    color="colors.gray09"
                    sx={{ fontSize: '16px', fontWeight: 700, lineHeight: '19px' }}
                  >
                    {(!isEmpty(me) && !error) ? `${me.firstName} ${me.lastName}` : 'User'}
                  </Box>
                  <Box sx={{
                    fontSize: '12px', fontWeight: 400, lineHeight: '14px', color: palette.primary.main,
                  }}
                  >
                    Compliance Portal
                  </Box>
                </Box>
              </Box>
            </DropdownButton>
            <StyledMenu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {!isEmpty(me) && (
                <AccountSwitcher
                  account={me.account}
                  accounts={me.accounts}
                />
              )}
              {me.allowSwitchRolesOnFrontend && <PortalSwitcher isResearch={false} />}
              <MenuItem key="userSettings" onClick={handleProfileSettings}>
                <img
                  src={SettingIcon}
                  srcSet={SettingIconWebp}
                  alt="icon1"
                  loading="lazy"
                  width="24"
                  height="24"
                />
                <Typography
                  variant="body1"
                  color="colors.gray08"
                  sx={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    marginLeft: '4px',
                  }}
                >
                  User Settings
                </Typography>
              </MenuItem>
              {(isEmpty(me) || !me.isAcaReviewer) && (
              <div>
                <MenuItem key="organizationSettings" onClick={handleOrganizationSettings}>
                  <img
                    src={BuildingIcon}
                    srcSet={BuildingIconWebp}
                    alt="icon2"
                    loading="lazy"
                    width="24"
                    height="24"
                  />
                  <Typography
                    variant="body1"
                    color="colors.gray08"
                    sx={{
                      fontSize: '14px',
                      lineHeight: '24px',
                      marginLeft: '4px',
                    }}
                  >
                    Organization Settings
                  </Typography>
                </MenuItem>
                <MenuItem key="reports" onClick={handleReports}>
                  <img
                    src={ChartIcon}
                    srcSet={ChartIconWebp}
                    alt="icon3"
                    loading="lazy"
                    width="24"
                    height="24"
                  />
                  <Typography
                    variant="body1"
                    color="colors.gray08"
                    sx={{
                      fontSize: '14px',
                      lineHeight: '24px',
                      marginLeft: '4px',
                    }}
                  >
                    Reports
                  </Typography>
                </MenuItem>
              </div>
              )}
              <MenuItem key="signOut" onClick={handleSignOut}>
                <img
                  src={LogoutIcon}
                  srcSet={LogoutIconWebp}
                  alt="icon4"
                  loading="lazy"
                  width="24"
                  height="24"
                />
                <Typography
                  variant="body1"
                  color="colors.gray08"
                  sx={{
                    fontSize: '14px',
                    lineHeight: '24px',
                    marginLeft: '4px',
                  }}
                >
                  Sign Out
                </Typography>
              </MenuItem>
            </StyledMenu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default HeaderBar;
