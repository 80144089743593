/* eslint-disable import/order */
import React from 'react';

// * MUI
import { Box, FormGroup, FormControlLabel } from '@mui/material';

// * Components
import { CustomCheckbox, CustomTooltip } from './styledComponents';

// * Hooks
import { useMutation } from '@apollo/client';

// * Mutations
import { DISPLAY_IN_RESEARCH_PORTAL_MUTATION } from '../../queries/transcript/transcript';
import { AppContext } from '../AppContextProvider';

const tooltipText = 'Uncheck to prevent this call from being seen by Research.';

type DisplayInResearchProps = {
  transcriptUuid: string
  inResearch: boolean
}

const DisplayInResearch = ({
  transcriptUuid,
  inResearch,
}: DisplayInResearchProps) => {
  const { refetchTranscript } = React.useContext(AppContext);
  const [displayInResearch] = useMutation(DISPLAY_IN_RESEARCH_PORTAL_MUTATION, {
    onCompleted: () => {
      refetchTranscript?.();
    },
  });

  // Local state to control checkbox value
  const [isInResearchChecked, setIsInResearchChecked] = React.useState(inResearch);

  // Sync local state with prop changes
  React.useEffect(() => {
    setIsInResearchChecked(inResearch);
  }, [inResearch]);

  const handleCheckboxChange = (isChecked: boolean) => {
    setIsInResearchChecked(isChecked); // Update local state immediately for UI response

    displayInResearch({
      variables: { transcriptUuid, inResearch: isChecked },
    });
  };

  return (
    <form>
      <FormGroup>
        <CustomTooltip title={tooltipText} arrow>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              sx={{ marginLeft: 0, marginRight: '24px' }}
              control={(
                <CustomCheckbox
                  name="displayCheckbox"
                  onChange={e => handleCheckboxChange(e.target.checked)}
                  checked={isInResearchChecked} // Use controlled component approach
                />
              )}
              label={(
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ fontWeight: 400, whiteSpace: 'nowrap' }}
                >
                  Display in Research Portal
                </Box>
              )}
            />
          </Box>
        </CustomTooltip>
      </FormGroup>
    </form>
  );
};

export default DisplayInResearch;
