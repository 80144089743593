import React from 'react';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import palette from '../../theme/palette';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: palette.colors.gray08,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.colors.gray08,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: palette.white,
    border: '1px solid',
    borderColor: palette.colors.gray08,
    borderRadius: '6px',
    padding: '5px 8px',
    maxWidth: 326,
  },
});

export const AddButton = styled(Button)({
  borderColor: palette.primary.main,
  borderRadius: '8px',
  color: palette.primary.main,
  height: 36,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '22px',
  letterSpacing: 0,
  padding: '10px',
  margin: '0 12px',
  '&:hover': {
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
    color: palette.white,
  },
});
