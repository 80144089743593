import React from 'react';
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
} from 'docx';

// * Hooks
import { useMutation } from '@apollo/client';

// * Components
import DownloadFile from '../DownloadFile';

// * Icons
import { ReactComponent as DownloadPDFIcon } from '../../../assets/icons/downloadPDF.svg';

// * Mutations
import { GET_DOCX_MUTATION } from '../../../queries/transcript/transcript';

// eslint-disable-next-line
const formatDocStringForOutlook = (docString: string) => {
  // eslint-disable-next-line
  return docString.split('\n\n').map((paragraphText) => {
    const lines = paragraphText.split('\n').map(line => new TextRun({
      text: line.replace(/\t/g, ' '),
      break: 1,
    }));

    return new Paragraph({
      children: lines,
      spacing: { after: 100 },
    });
  });
};

const DownloadDOCX = ({
  transcriptUuid,
  fileName,
}: {
  transcriptUuid: string
  fileName: string
}) => {
  const [getDocxTranscript] = useMutation(GET_DOCX_MUTATION);

  const handleDownloadDocx = async () => {
    try {
      const response = await getDocxTranscript({ variables: { transcriptUuid } });
      const { docString } = response.data.getDocxTranscript;

      const doc = new Document({
        sections: [
          {
            properties: {},
            children: formatDocStringForOutlook(docString),
          },
        ],
      });

      const buffer = await Packer.toBlob(doc);
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = URL.createObjectURL(blob);

      // Запускаем скачивание файла
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}.docx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating DOCX:', error);
    }
  };

  return (
    <DownloadFile
      onDownload={handleDownloadDocx}
      IconComponent={DownloadPDFIcon}
      fileType="docx"
    />
  );
};

export default DownloadDOCX;
