/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';

// * MUI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { ReactComponent as AltNotesIcon } from '../../../../assets/icons/altNotesIcon.svg';
import { ReactComponent as PostIcon } from '../../../../assets/icons/postIcon.svg';
import { ReactComponent as TrashIcon } from '../../../../assets/icons/trashIcon.svg';
import { ReactComponent as CloseCircleIcon } from '../../../../assets/icons/closeCircleIcon.svg';

// * Components
import {
  NotesBox,
  NoteField,
  NoNotesBox,
  Comment,
  NoteItem,
  NotesAvatar,
  CancelButton,
  SubmitButton,
} from '../styledComponents';

// * Hooks & Utils
import { formatDateTimeToUserTimezone } from '../../../../shared/utils/dateHelpers';
import { AppContext, useMe } from '../../../../components/AppContextProvider';
import paths from '../../../../paths/paths';

// * Interfaces
import type { CallType } from '../../../../shared/types/transcript';
import { CREATE_CALL_NOTE_MUTATION, REMOVE_CALL_NOTE_MUTATION } from '../../../../queries/transcript/transcript';

type RemoveBtnProps = {
  uuid: string;
}

const RemoveBtn = ({ uuid }: RemoveBtnProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openDialog, setOpenDialog] = useState(false);
  const { refetchCompletedCalls, refetchReviewedCalls, refetchTranscript } = useContext(AppContext);

  const [removeCallNote] = useMutation(REMOVE_CALL_NOTE_MUTATION, {
    onCompleted: () => {
      if (refetchCompletedCalls && refetchReviewedCalls && refetchTranscript) {
        refetchCompletedCalls();
        refetchReviewedCalls();
        refetchTranscript?.();
      }
    },
  });

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleRemove = (noteUuid: string) => {
    removeCallNote({
      variables: {
        noteUuid,
      },
    });
    handleOpenDialog();
  };

  return (
    <>
      <IconButton style={{ marginLeft: '24px', padding: 0 }} onClick={handleOpenDialog}>
        <TrashIcon />
      </IconButton>
      <Dialog open={openDialog} onClose={handleOpenDialog}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: isMobile ? '25px' : '55px 29px',
          }}
        >
          <Box display="flex" mb="8px">
            <CloseCircleIcon />
          </Box>
          <Typography
            variant="h3"
            color="colors.gray10"
            textAlign="center"
            mb="25px"
          >
            Are you sure you want to delete note?
          </Typography>
          <Typography
            variant="body1"
            color="colors.gray10"
            textAlign="center"
            sx={{ fontWeight: 400 }}
          >
            Once deleted, the note will be removed from the system, this action can not be reversed.
          </Typography>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="center" mt="36px" gap="20px">
            <CancelButton variant="outlined" onClick={handleOpenDialog}>
              No, Cancel
            </CancelButton>
            <SubmitButton
              variant="contained"
              type="submit"
              onClick={() => handleRemove(uuid)}
            >
              Yes, Delete
            </SubmitButton>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

type NotesProps = {
  call: CallType;
}

const Notes = ({
  call,
}: NotesProps) => {
  const { me } = useMe();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [notes, setNotes] = useState('');
  const { refetchCompletedCalls, refetchReviewedCalls, refetchTranscript } = useContext(AppContext);

  const [postCallNote] = useMutation(CREATE_CALL_NOTE_MUTATION, {
    onCompleted: () => {
      if (refetchCompletedCalls && refetchReviewedCalls && refetchTranscript) {
        refetchCompletedCalls();
        refetchReviewedCalls();
        refetchTranscript?.();
      }
    },
  });

  const handlePost = (text: string) => {
    postCallNote({
      variables: {
        text, callUuid: call.uuid,
      },
    });
    setNotes('');
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && notes.length > 0) {
      e.preventDefault();
      handlePost(notes);
    }
  };

  return (
    <NotesBox>
      <Box display="flex" alignItems="center" mb="24px">
        <AltNotesIcon />
        <Typography variant="subtitle1" color="colors.gray10" ml="12px">
          Notes
        </Typography>
      </Box>
      <Box display="flex" mb="26px">
        <form style={{ width: '100%' }}>
          <Box display="flex" alignItems="center" flexDirection={isMobile ? 'column' : 'row'} mb="4px" gap="12px">
            <NoteField
              id="NotesField"
              name="notes"
              value={notes}
              onChange={e => setNotes(e.target.value)}
              onKeyDown={handleKeyDown}
              type="text"
              variant="outlined"
              placeholder="Add your notes here.."
              autoComplete="off"
            />
            <Button
              variant="contained"
              startIcon={<PostIcon />}
              sx={{ width: isMobile ? '100%' : 120, height: 52 }}
              disabled={notes.length === 0}
              onClick={() => handlePost(notes)}
            >
              Post
            </Button>
          </Box>
        </form>
      </Box>
      {call?.callNotes.length === 0 && (
        <NoNotesBox>No notes are added.</NoNotesBox>
      )}
      <Box className={call?.callNotes.length > 3 ? 'scrolledNotes' : ''}>
        {!!call && [...call.callNotes]
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          .map(note => (
            <>
              <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
              <NoteItem key={note.uuid}>
                <Box display="flex" alignItems={isMobile ? 'start' : 'center'} justifyContent="space-between">
                  <Box display="flex" alignItems={isMobile ? 'start' : 'center'}>
                    {note.addedBy && (
                      <NotesAvatar alt={`${note.addedBy.firstName} ${note.addedBy.lastName}`} src={paths.home.reverse()} />
                    )}
                    <Box display="flex" flexDirection="column">
                      <Typography variant="subtitle1" color="colors.gray07" sx={{ fontSize: '14px', lineHeight: '24px', marginRight: '7px' }}>
                        {note.addedBy && `${note.addedBy.firstName} ${note.addedBy.lastName}`}
                      </Typography>
                      {isMobile && (
                        <Typography variant="subtitle1" color="colors.gray06" sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '24px' }}>
                          {formatDateTimeToUserTimezone(note.createdAt, me.timezone)}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {!isMobile && (
                      <Typography variant="subtitle1" color="colors.gray06" sx={{ fontSize: '12px', fontWeight: 500, lineHeight: '24px' }}>
                        {formatDateTimeToUserTimezone(note.createdAt, me.timezone)}
                      </Typography>
                    )}
                    <RemoveBtn uuid={note.uuid} />
                  </Box>
                </Box>
                <Comment sx={{ padding: isMobile ? '10px 0 0' : '0 31px' }}>{note.text}</Comment>
              </NoteItem>
            </>
          ))}
      </Box>
    </NotesBox>
  );
};

export default Notes;
