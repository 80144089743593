import { FilterDataType, HighlightType } from '../../../shared/types/transcript';

export const prepareFiltersForBackend = (inputObj: FilterDataType) => {
  const { statuses, ...rest } = inputObj;

  const updatedStatuses = statuses.map(status => status.split('_').join(' '));

  return {
    statuses: updatedStatuses,
    ...rest,
  };
};

export const filterHighlights = (
  highlights: HighlightType[],
  statusList?: string[],
): HighlightType[] => {
  let res = highlights || [];

  if (statusList && statusList.length > 0) {
    res = res.filter(highlight => statusList.includes(highlight.status.key));
  } else {
    res = [];
  }

  return res.sort((a, b) => (a.paragraph?.id || 0) - (b.paragraph?.id || 0));
};
