/* eslint-disable import/order, no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

// * MUI
import {
  TextField, InputAdornment, IconButton, styled,
} from '@mui/material';

// * Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// * Utils
import { debounce } from 'lodash';
import palette from '../../../theme/palette';

// * Interfaces
import type { DebouncedFunc } from 'lodash';

const SearchField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    backgroundColor: palette.colors.gray01,
    '& fieldset': {
      borderColor: palette.colors.gray02,
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&.Mui-focused fieldset': {
      borderColor: palette.colors.gray02,
    },
  },
});

interface SearchBarProps {
  onBack: () => void
  onSearch: (search: string) => void
  disabled: boolean
}

const SearchBar = ({ onBack, onSearch, disabled }: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchRef = useRef<DebouncedFunc<(query: string) => void>>();

  useEffect(() => {
    debouncedSearchRef.current = debounce((query: string) => {
      onSearch(query);
    }, 700);

    return () => {
      debouncedSearchRef.current?.cancel();
    };
  }, [onSearch]);

  const handleSearch = (query: string) => {
    debouncedSearchRef.current?.(query);
  };

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = event => {
    setSearchValue(event.target.value);
    handleSearch(event.target.value);
  };

  const handleOnBack = () => {
    onBack();
  };

  const startAdornment = (
    <InputAdornment position="start">
      <IconButton
        onClick={() => (disabled ? undefined : handleOnBack())}
        disabled={disabled}
      >
        <ArrowBackIcon />
      </IconButton>
    </InputAdornment>
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      debouncedSearchRef.current?.cancel();
      onSearch(searchValue);
      event.preventDefault();
    }

    if (event.key === 'Escape') {
      handleOnBack();
    }
  };

  return (
    <SearchField
      fullWidth
      autoComplete="off"
      autoFocus
      variant="outlined"
      placeholder="Search by Call, Expert, Project ID..."
      value={searchValue}
      onChange={handleChange}
      InputProps={{ startAdornment }}
      onKeyDown={handleKeyDown}
    />
  );
};

export default SearchBar;
