import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import palette from '../../theme/palette';

export const ChangeRoleContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 235,
  borderBottom: '1px solid',
  borderColor: palette.colors.gray02,
  margin: '0 -24px 12px',
  padding: '0 16px 12px',
});

export const VerticalLine = styled(Box)({
  display: 'flex',
  width: 13,
  borderRight: '1px dashed',
  borderRightColor: palette.colors.gray03,
  marginBottom: '10px',
});

export const HorisontalLine = styled(Box)({
  display: 'flex',
  width: 12,
  borderTop: '1px dashed',
  borderTopColor: palette.colors.gray03,
  marginRight: '8px',
});

export const Switcher = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '22px',
  color: palette.primary.main,
  cursor: 'pointer',
  '&:hover': {
    color: palette.primary.dark,
  },
});

export const SwitcherDisabled = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '22px',
  color: palette.colors.gray05,
  cursor: 'default',
});
