import { styled } from '@mui/material/styles';
import {
  Avatar, Box, InputBase, MenuItem, Typography,
} from '@mui/material';
import palette from '../../theme/palette';

export const ReviewerInput = styled(InputBase)({
  lineHeight: '22px',
  border: 'none',
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .MuiSelect-icon': {
    color: palette.colors.gray08,
  },
  '&.Mui-disabled': {
    '& .MuiInputBase-input': {
      paddingRight: '0 !important',
      '-webkit-text-fill-color': 'unset',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
    '& .MuiTypography-root': {
      color: palette.colors.gray08,
    },
  },
});

export const ReviewerMenuItem = styled(MenuItem)({
  padding: '6px 12px',
});

export const UserAvatarContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const UserNameContainer = styled(Typography)({
  fontSize: '14px',
  lineHeight: '32px',
  marginLeft: '4px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const UserAvatar = styled(Avatar)({
  color: palette.white,
  backgroundColor: palette.secondary.main,
});
