import React from 'react';
import Box from '@mui/material/Box';

const HeaderEmptyState = () => (
  <Box
    sx={{
      height: '51px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(225, 228, 231, 0.3)',
      borderRadius: '16px',
    }}
  />
);

export default HeaderEmptyState;
