/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';

// * Hooks & Utils
import { useTopicOperations } from './hooks';
import { AppContext } from '../AppContextProvider';

// * Components
import TopicCallsDrawer from '.';
import { EditBtn } from './components/styledComponents';

// * Icons
import { ReactComponent as EditIcon } from '../../assets/icons/editIcon.svg';

// * Interfaces
import type { Topic } from './types';

interface RenderEditButtonProps {
  topic: Topic
  onClick: (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => void
}

const RenderEditButton = ({ topic, onClick }: RenderEditButtonProps) => {
  if (topic.status === 'summarization in process') {
    return null;
  }

  return (
    <EditBtn onClick={onClick}>
      <EditIcon />
    </EditBtn>
  );
};

interface EditTopicProps {
  topic: Topic
  setTopic: (topic?: Topic) => void
}

const EditTopic = ({ topic, setTopic }: EditTopicProps) => {
  const {
    toggleDrawer, handleProceed, clearState, ...topicOperations
  } = useTopicOperations('edit', topic, setTopic);

  const { refetchMyTopics, refetchCurrentTopic } = useContext(AppContext);

  const onProceed = async () => {
    await handleProceed();
    toggleDrawer(false)({} as React.MouseEvent);
    // @ts-ignore
    await refetchMyTopics();
    // @ts-ignore
    await refetchCurrentTopic();
    clearState();
  };

  const drawerProps = {
    topic,
    toggleDrawer,
    onProceed,
    ...topicOperations,
  };

  return (
    <>
      <RenderEditButton
        topic={topic}
        onClick={toggleDrawer(true, topic.uuid)}
      />

      <TopicCallsDrawer {...drawerProps} />
    </>
  );
};

export default EditTopic;
