import React from 'react';
import Box from '@mui/material/Box';

type MoreDetailsTabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
}

const MoreDetailsTabPanel = (props: MoreDetailsTabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`more-details-tabpanel-${index}`}
      aria-labelledby={`more-details-tab-${index}`}
      {...other}
      display="flex"
      flexGrow={value !== index ? '0' : '1'}
    >
      {value === index && (
        <Box display="flex" flexDirection="column" flexGrow="1">{children}</Box>
      )}
    </Box>
  );
};

export default MoreDetailsTabPanel;
