/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

// * MUI
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box, Button, Chip, Menu, Typography,
} from '@mui/material';
import {
  DateRange, DesktopDateRangePicker, MobileDateRangePicker, SingleInputDateRangeField,
} from '@mui/x-date-pickers-pro';
import { LocalizationProvider, PickersShortcutsProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// * Components
import FiltersBoolean from './FiltersBoolean';
import FiltersChoice from './FiltersChoice';

// * Hooks & Utils
import dayjs from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import utc from 'dayjs/plugin/utc';
import palette from '../../../theme/palette';
import shortcutsItems from '../../../components/CallsFilter/constants';

// * Icons
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { ReactComponent as AltCalendarPickerIcon } from '../../../assets/icons/altCalendarPickerIcon.svg';

// * Interfaces
import type { ControllerRenderProps } from 'react-hook-form';
import type { MenuProps } from '@mui/material/Menu';
import type { Dayjs } from 'dayjs';
import type { FilterProps } from '../filterLogic';
import { useMe } from '../../../components/AppContextProvider';

dayjs.extend(utc);

const FilterBtn = styled(Button)({
  border: '1px solid',
  borderRadius: '8px',
  padding: '8px',
  '&:hover': { textDecoration: 'none', borderColor: palette.primary.main },
});

const AppliedCount = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 24,
  minWidth: 24,
  backgroundColor: palette.colors.primary05,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '14px',
  color: palette.white,
  borderRadius: '12px',
  padding: '5px',
  marginLeft: '4px',
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    {...props}
  />
))({
  '& .MuiPaper-root': {
    width: '275px',
    border: '1px solid',
    borderColor: palette.primary.main,
    borderRadius: '8px',
    padding: '20px',
    marginTop: '4px',
    boxShadow: '0px 8px 9px rgba(226, 226, 226, 0.35)',
    '& .MuiMenu-list': { padding: 0 },
  },
});

const DropdownSectionHeader = ({ title }: { title: string }) => (
  <Typography
    variant="body1"
    color="colors.gray06"
    sx={{ fontSize: '12px', lineHeight: '18px', marginBottom: '2px' }}
  >
    {title}
  </Typography>
);

const CustomRangeShortcuts = (props: PickersShortcutsProps<DateRange<Dayjs>>) => {
  const {
    items, onChange, isValid, changeImportance,
  } = props;

  if (items == null || items.length === 0) {
    return null;
  }

  const resolvedItems = items.map(item => {
    const newValue = item.getValue({ isValid });

    return {
      label: item.label,
      onClick: () => {
        onChange(newValue, changeImportance ?? 'accept', item);
      },
      disabled: !isValid(newValue),
    };
  });

  return (
    <Box sx={{ gridRow: 3, gridColumn: 2 }}>
      <Box display="flex" flexWrap="wrap" sx={{ padding: '0 16px 8px 16px' }}>
        {resolvedItems.map(item => (
          <Box key={item.label} sx={{ padding: '0 8px 8px 0' }}>
            <Chip {...item} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

interface FiltersDropdownProps extends FilterProps {
  tabIndex: number;
  sectionsToHide?: string[];
}

const FiltersDropdown = ({
  acaIoiAccounts,
  setAcaIoiAccounts,
  allAcaIoiAccounts,
  allAnalysts,
  analysts,
  setAnalysts,
  complianceReviewers,
  setComplianceReviewers,
  allComplianceReviewers,
  allNetworks,
  networks,
  setNetworks,
  allFlags,
  flags,
  setFlags,
  allTickers,
  tickers,
  displayInResearchPortal,
  setDisplayInResearchPortal,
  setTickers,
  setDateRange,
  tabIndex,
  sectionsToHide = [],
}: FiltersDropdownProps) => {
  const { me } = useMe();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [controlledDateRange, setControlledDateRange] = useState<DateRange<Dayjs>>([null, null]);

  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const filtersApplied = analysts.length
    + acaIoiAccounts.length
    + complianceReviewers.length
    + networks.length
    + tickers.length
    + flags.length
    + (displayInResearchPortal !== undefined ? 1 : 0)
    + (controlledDateRange[0] !== null ? 1 : 0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { control } = useForm({
    mode: 'onChange',
    defaultValues: { dateRange: [null, null] },
  });

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  const handleResetFilters = () => {
    setAnalysts([]);
    setNetworks([]);
    setFlags([]);
    setTickers([]);
    setComplianceReviewers([]);
    setAcaIoiAccounts([]);
    setDisplayInResearchPortal(undefined);
    setControlledDateRange([null, null]);
  };

  const renderController = ({ field }: { field: ControllerRenderProps<{ dateRange: null[] }, 'dateRange'> }) => {
    const Picker = isMobile ? MobileDateRangePicker : DesktopDateRangePicker;

    const commonProps = {
      ...field,
      value: controlledDateRange,
      onChange: (newValue: DateRange<dayjs.Dayjs>) => setControlledDateRange(newValue),
      slotProps: {
        textField: {
          InputProps: {
            endAdornment: <AltCalendarPickerIcon />,
            onBeforeInput: disableKeyboardEntry,
            onKeyDown: disableKeyboardEntry,
          },
        },
        shortcuts: { items: shortcutsItems },
        actionBar: { actions: [] },
      },
    };

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Picker
          {...commonProps}
          slots={{
            field: SingleInputDateRangeField,
            ...(isMobile ? { shortcuts: CustomRangeShortcuts } : {}),
          }}
          calendars={isMobile ? 1 : 2}
          format="MM/DD/YY"
          closeOnSelect
          sx={{ ...(isMobile ? {} : { width: '100%' }) }}
        />
      </LocalizationProvider>
    );
  };

  useEffect(() => {
    const start = controlledDateRange[0] ? controlledDateRange[0].startOf('day').utc().toDate() : undefined;
    const end = controlledDateRange[1] ? controlledDateRange[1].endOf('day').utc().toDate() : undefined;

    setDateRange({
      dateRangeStart: start,
      dateRangeEnd: end,
    });
  }, [controlledDateRange, setDateRange]);

  return (
    <>
      <FilterBtn
        id="filter-button"
        aria-controls={open ? 'main-filters-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ borderColor: open ? palette.primary.main : palette.colors.gray03 }}
      >
        <Box display="flex" alignItems="center">
          <FilterListRoundedIcon sx={{ color: open ? palette.primary.main : palette.colors.gray05, marginRight: '8px' }} />
          <Typography
            variant="body1"
            color="colors.gray08"
            sx={{ fontSize: '14px', fontWeight: 400, textTransform: 'none' }}
          >
            {filtersApplied ? 'Filter Applied' : 'Select from list'}
          </Typography>
          {filtersApplied ? <AppliedCount>{filtersApplied}</AppliedCount> : null}
          <ExpandMoreRoundedIcon sx={{ color: open ? palette.primary.main : palette.colors.gray05, transform: open ? 'rotate(180deg)' : undefined, marginLeft: '8px' }} />
        </Box>
      </FilterBtn>
      <StyledMenu
        id="main-filters-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'filter-button' }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="16px">
          <Typography variant="subtitle1">
            Filter Calls
          </Typography>
          <Typography variant="caption" color="colors.primary05" onClick={handleResetFilters} sx={{ cursor: 'pointer' }}>
            Clear all
          </Typography>
        </Box>
        <form>
          {
            me.isAcaReviewer && !sectionsToHide.includes('acaIoiAccountsUuids') && (
              <>
                <DropdownSectionHeader title="Client Name" />
                <Box display="flex" mb="16px">
                  <FiltersChoice
                    key="acaIoiAccountsUuids"
                    data={allAcaIoiAccounts.map(account => ({ uuid: account.uuid, label: account.name }))}
                    selectedValue={acaIoiAccounts}
                    handleSelect={value => setAcaIoiAccounts(value)}
                    getAvatarSrc={(uuid: string) => {
                      const account = allAcaIoiAccounts.find(acc => acc.uuid === uuid);
                      if (!account) return null;
                      return account.settings.avatarLogo;
                    }}
                    isUserIcon
                    withIcon
                    blockWidth={233}
                  />
                </Box>
              </>
            )
          }
          {tabIndex !== 0 && !sectionsToHide.includes('complianceReviewerUuids') && (
            <>
              <DropdownSectionHeader title="Compliance Reviewer" />
              <Box display="flex" mb="16px">
                <FiltersChoice
                  key="complianceReviewerUuids"
                  data={allComplianceReviewers.map(reviever => ({ uuid: reviever.uuid, label: `${reviever.firstName} ${reviever.lastName}` }))}
                  selectedValue={complianceReviewers}
                  handleSelect={value => setComplianceReviewers(value)}
                  isUserIcon
                  withIcon
                  blockWidth={233}
                />
              </Box>
            </>
          )}
          {tabIndex !== 0 && !sectionsToHide.includes('analyst') && (
            <>
              <DropdownSectionHeader title="Analyst Name" />
              <Box display="flex" mb="16px">
                <FiltersChoice
                  key="analyst"
                  data={allAnalysts.map(analyst => ({ uuid: analyst, label: analyst }))}
                  selectedValue={analysts}
                  handleSelect={value => setAnalysts(value)}
                  isUserIcon
                  withIcon
                  blockWidth={233}
                />
              </Box>
            </>
          )}
          {tabIndex !== 0 && !sectionsToHide.includes('expert-networks') && (
            <>
              <DropdownSectionHeader title="Expert Network" />
              <Box display="flex" mb="16px">
                <FiltersChoice
                  key="expert-networks"
                  data={allNetworks.map(network => ({ uuid: network.key, label: network.value }))}
                  selectedValue={networks}
                  handleSelect={value => setNetworks(value)}
                  isUserIcon
                  withIcon={false}
                  blockWidth={233}
                />
              </Box>
            </>
          )}
          {tabIndex !== 0 && !sectionsToHide.includes('company-ticker') && (
            <>
              <DropdownSectionHeader title="Company/Ticker" />
              <Box display="flex" mb="16px">
                <FiltersChoice
                  key="company-ticker"
                  data={allTickers.map(ticker => ({ uuid: ticker.uuid, label: ticker.title }))}
                  selectedValue={tickers}
                  handleSelect={value => setTickers(value)}
                  isUserIcon={false}
                  withIcon={false}
                  blockWidth={233}
                />
              </Box>
            </>
          )}
          {tabIndex !== 0 && !sectionsToHide.includes('flags') && (
            <>
              <DropdownSectionHeader title="Flags" />
              <Box display="flex" mb="16px">
                <FiltersChoice
                  key="flags"
                  data={allFlags.map(flag => ({ uuid: flag.uuid, label: flag.label }))}
                  selectedValue={flags}
                  handleSelect={value => setFlags(value)}
                  isUserIcon={false}
                  withIcon={false}
                  showSearch={false}
                  blockWidth={233}
                />
              </Box>
            </>
          )}
          {!sectionsToHide.includes('display-in-research-portal') && (
            <>
              <DropdownSectionHeader title="Display in Research Portal" />
              <Box display="flex" mb="16px">
                <FiltersBoolean
                  key="display-in-research-portal"
                  selectedValue={displayInResearchPortal}
                  handleSelect={value => setDisplayInResearchPortal(value)}
                  blockWidth={233}
                />
              </Box>
            </>
          )}
          {!sectionsToHide.includes('date-range-filter-section') && (
            <>
              <DropdownSectionHeader title="Call Date" />
              <Box className="date-range-filter-section">
                <Controller control={control} name="dateRange" render={renderController} />
              </Box>
            </>
          )}
        </form>
      </StyledMenu>
    </>
  );
};

FiltersDropdown.defaultProps = {
  sectionsToHide: [],
};

export default FiltersDropdown;
