/* eslint-disable import/order */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { toast } from 'react-toastify';

// * MUI
import { Box, Divider, Typography } from '@mui/material';

// * Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import { ReactComponent as UndoIcon } from '../../assets/icons/undoIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeIcon.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copyIcon.svg';

// * Components
import ParagraphView from '../ParagraphView';
import CallDetailsItem from '../CallDetailsItem';
import ComplianceReviewer from '../ComplianceReviewer';
import Notes from './Notes';
import { ReasonTooltip } from '../Cards/HighlightItemCard/styledComponents';

// * Styled Components
import {
  CustomChip, NotesButton, ReopenButton, DrawerContainer, CloseButton,
  HighlightDot, HighlightStatus, FooterBlock, ResolveFullButton, ReopenFullButton,
  CustomNoteButton, CopyButton, ScrollContainer, PredictiveTooltip,
} from './styledComponents';

// * Hooks & Utils
import { useMutation } from '@apollo/client';
import palette from '../../theme/palette';
import { AppContext, useMe } from '../AppContextProvider';

// * Queries
import { POST_NOTE_MUTATION, REOPEN_HIGHLIGHT_MUTATION } from '../../queries/transcript/transcript';

// * Interfaces
import { HighlightType } from '../../shared/types/transcript';

type HighlightDetailsProps = {
  callTopic?: string;
  notesIconActions: boolean;
  highlight: HighlightType;
  highlightedParagraph: React.ReactNode;
  pendingFilter: boolean;
  resolvedFilter: boolean;
  aiResolvedFilter: boolean;
  isFileAText: boolean;
  audio: HTMLAudioElement | null;
  analysts: string[];
  experts: string[];
  isPlayingMain: boolean;
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayingParagraph: boolean;
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>;
  pendingCount: number;
  setFirstCompletedUuid: any;
  isDocument: boolean;
};

const statusList = [
  { status: 'pending', color: 'colors.warning04', label: 'Pending' },
  { status: 'resolved', color: 'colors.success03', label: 'Resolved' },
  { status: 'ai resolved', color: 'colors.gray04', label: 'AI Resolved' },
];

const HighlightDetails = ({
  callTopic,
  notesIconActions,
  highlight,
  highlightedParagraph,
  pendingFilter,
  resolvedFilter,
  aiResolvedFilter,
  isFileAText,
  audio,
  analysts,
  experts,
  isPlayingMain,
  setIsPlayingMain,
  isPlayingParagraph,
  setIsPlayingParagraph,
  // eslint-disable-next-line
  pendingCount,
  // eslint-disable-next-line
  setFirstCompletedUuid,
  isDocument,
}: HighlightDetailsProps) => {
  const { me } = useMe();
  const status = highlight.status.value.toLocaleLowerCase();

  const { refetchCompletedCalls, refetchReviewedCalls, refetchTranscript } = useContext(AppContext);
  const [notes, setNotes] = useState('');

  const [postNote] = useMutation(POST_NOTE_MUTATION, {
    onCompleted: () => {
      if (refetchCompletedCalls && refetchReviewedCalls && refetchTranscript) {
        refetchCompletedCalls();
        refetchReviewedCalls();
      }
      // if (pendingCount <= 1) {
      //   setFirstCompletedUuid();
      // } else {
      refetchTranscript?.();
      // }
    },
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const addFadeToHighlightCard = () => {
    const highlightCard = document.getElementById(`highlight-${highlight.uuid}`);
    if (highlightCard && !resolvedFilter && !aiResolvedFilter) {
      highlightCard.classList.add('fade');
      setTimeout(() => {
        highlightCard.style.display = 'none';
      }, 700);
    }
  };

  const handlePost = (text: string, file: File | null, resolve: boolean = false) => {
    postNote({
      variables: {
        text, highlightUuid: highlight.uuid, file, resolve,
      },
    });
    if (resolve) {
      addFadeToHighlightCard();
    }
    setNotes('');
    setSelectedFile(null);
  };

  const handleResolve = () => {
    handlePost(notes, selectedFile, true);
  };

  const handlePredictiveResolve = (predictive: string) => {
    handlePost(predictive, null, true);
  };

  const [isOpenDetailsSidebar, setIsOpenDetailsSidebar] = useState(false);

  const [reopenHighlight] = useMutation(REOPEN_HIGHLIGHT_MUTATION, {
    onCompleted: () => {
      if (refetchCompletedCalls && refetchReviewedCalls && refetchTranscript) {
        refetchCompletedCalls();
        refetchReviewedCalls();
        refetchTranscript();
      }
    },
  });

  const stopClick = (e: React.KeyboardEvent | React.MouseEvent) => {
    e.stopPropagation();
  };

  const toggleDrawer = (isDrawerOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    event.stopPropagation();
    setIsOpenDetailsSidebar(isDrawerOpen);
    setTimeout(() => {
      const notesField = document.getElementById('NotesField');
      if (isDrawerOpen && notesField) {
        notesField.focus();
      }
    }, 300);
  };

  const handleReopenHighlight = () => {
    reopenHighlight({ variables: { highlightUuid: highlight.uuid } });
    const highlightCard = document.getElementById(`highlight-${highlight.uuid}`);
    if (highlightCard && !pendingFilter) {
      highlightCard.classList.add('fade');
      setTimeout(() => {
        highlightCard.style.display = 'none';
      }, 700);
    }
    toggleDrawer(false);
  };

  useEffect(() => {
    if (!isOpenDetailsSidebar) {
      setNotes('');
    }
  }, [isOpenDetailsSidebar]);

  const copyMessage = 'Copy a prompt to the clipboard that can be pasted into ChatGPT, Perplexity or Copilot to check if the information in this item of interest is public.';
  const showCopyButton = useMemo(() => highlight.categories.includes('MNPI') || highlight.categories.includes('Shadow Trading'), [highlight]);

  const copyToClipboard = () => {
    const prompt = `Check to see if the potential MNPI in this "text" with the corresponding "reason" about “topic” has been disclosed publicly. Return the result in JSON with the following fields: (1) public - Yes or No, (2) explanation, (3) references (source, link, quote, accessed). Only return the JSON, no additional comments.
    {
      "Text": "${highlight.phrase}"
      "Reason": "${highlight.reason}",
      "Topic": "${callTopic}"
    }`;
    navigator.clipboard.writeText(prompt);
    toast.success('Prompt successfully copied to clipboard.');
  };

  return (
    <Box display="flex" onClick={stopClick}>
      {notesIconActions && (
        <Box display="flex" flexDirection="row" justifyContent="start" alignItems="center" gap="6px">
          {showCopyButton && (
          <ReasonTooltip title={copyMessage} placement="top-start" arrow>
            <CopyButton variant="outlined" onClick={copyToClipboard} startIcon={<CopyIcon />} />
          </ReasonTooltip>
          )}
          <CustomNoteButton variant="outlined" onClick={toggleDrawer(true)}>
            {(status === 'resolved' || status === 'ai resolved') ? 'View Notes' : 'Custom Note'}
          </CustomNoteButton>
        </Box>
      )}
      {(!notesIconActions && (status === 'resolved' || status === 'ai resolved')) && (
        <ReopenButton variant="contained" startIcon={<UndoIcon />} onClick={toggleDrawer(true)}>
          Reopen
        </ReopenButton>
      )}
      {(!notesIconActions && status !== 'resolved' && status !== 'ai resolved') && (highlight.suggestions.slice(0, 2).map(suggestion => (
        <PredictiveTooltip
          key={suggestion}
          title={suggestion}
          disableHoverListener={suggestion.length < 20}
          disableFocusListener
          disableTouchListener
          placement="top"
          arrow
        >
          <NotesButton variant="outlined" onClick={() => handlePredictiveResolve(suggestion)}>
            <Box overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" textTransform="initial">
              {suggestion}
            </Box>
          </NotesButton>
        </PredictiveTooltip>
      )))}
      <DrawerContainer
        anchor="right"
        open={isOpenDetailsSidebar}
        onClose={toggleDrawer(false)}
      >
        <ScrollContainer>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" sx={{ padding: '20px 36px' }}>
              <CloseButton onClick={toggleDrawer(false)}>
                <CloseIcon />
              </CloseButton>
              <Typography variant="h3" color="colors.gray10" sx={{ fontSize: '24px' }}>
                Item of Interest Details
              </Typography>
            </Box>
            <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
            <Box display="flex" flexDirection="column" flexGrow={1} sx={{ padding: '24px 32px' }}>
              {!!highlight.paragraph && (
                <>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="caption" color="colors.gray06">
                      Transcript
                    </Typography>
                    <Typography variant="caption" color="colors.info05" sx={{ fontWeight: 400, fontStyle: 'italic' }}>
                      Detected by
                      {highlight.createdBy ? ' User' : ' Encore AI'}
                    </Typography>
                  </Box>
                  <ParagraphView
                    paragraphId={highlight.paragraph?.uuid}
                    speaker={highlight.paragraph?.speaker}
                    startTime={highlight.paragraph.startTime}
                    endTime={highlight.paragraph.endTime}
                    highlightedParagraph={highlightedParagraph}
                    isFileAText={isFileAText}
                    audio={audio}
                    analysts={analysts}
                    experts={experts}
                    participant={highlight.paragraph.participant}
                    isPlayingMain={isPlayingMain}
                    setIsPlayingMain={setIsPlayingMain}
                    isPlayingParagraph={isPlayingParagraph}
                    setIsPlayingParagraph={setIsPlayingParagraph}
                    isDocument={isDocument}
                  />
                </>
              )}
              {!!highlight.reason && (
                <>
                  {!!highlight.paragraph && (
                    <Divider flexItem sx={{ margin: '25px 0 18px', borderColor: 'colors.gray03' }} />
                  )}
                  <Box>
                    <Box display="flex" alignItems="center" mb="6px">
                      <HelpIcon sx={{ color: palette.colors.primary05, fontSize: '20px' }} />
                      <Typography variant="caption" color="colors.gray06" sx={{ marginLeft: '4px' }}>
                        Reason
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="colors.gray10" sx={{ fontWeight: 400 }}>
                      {highlight.reason}
                    </Typography>
                  </Box>
                </>
              )}
              <Divider flexItem sx={{ margin: '24px 0 18px', borderColor: 'colors.gray03' }} />
              <Box display="flex" flexWrap="wrap">
                <CallDetailsItem title="Category" sx={{ marginRight: '64px', marginBottom: '12px' }}>
                  <Box display="flex" alignItems="center" mt="7px">
                    {
                      highlight.categories.map(category => (
                        <CustomChip key={category} label={category} />
                      ))
                    }
                  </Box>
                </CallDetailsItem>
              </Box>
              <Box display="flex" flexWrap="wrap" justifyContent="space-between">
                <CallDetailsItem title="Confidence" sx={{ marginRight: '64px', marginBottom: '12px' }}>
                  <Typography variant="overline" color="colors.success03" sx={{ fontWeight: 700 }}>
                    {highlight.score}
                    %
                  </Typography>
                </CallDetailsItem>
                <CallDetailsItem title="Status" sx={{ marginRight: '64px', marginBottom: '12px' }}>
                  {statusList.map(
                    item => status === item.status && (
                    <Box key={item.status} display="flex" alignItems="center">
                      <HighlightDot sx={{ backgroundColor: item.color }} />
                      <HighlightStatus variant="overline" color="colors.gray08">
                        {item.label}
                      </HighlightStatus>
                    </Box>
                    ),
                  )}
                </CallDetailsItem>
                <CallDetailsItem title="Compliance Reviewer" sx={{ marginBottom: '12px' }}>
                  <ComplianceReviewer
                    assignUser={() => {}}
                    instanceUuid={highlight.uuid}
                    initialAssignee={highlight.reviewer || null}
                    signedInUserUuid={me.uuid}
                    disabled
                    placeholder="Unassigned"
                  />
                </CallDetailsItem>
              </Box>
              <Divider flexItem sx={{ marginBottom: '18px', borderColor: 'colors.gray03' }} />
              <Notes
                highlight={highlight}
                selectedFile={selectedFile}
                setSelectedFile={setSelectedFile}
                notes={notes}
                setNotes={setNotes}
                handlePost={handlePost}
              />
            </Box>
          </Box>
        </ScrollContainer>
        <FooterBlock>
          {status === 'resolved' || status === 'ai resolved' ? (
            <ReopenFullButton variant="outlined" startIcon={<UndoIcon />} onClick={handleReopenHighlight} fullWidth>
              Reopen Item of Interest
            </ReopenFullButton>
          ) : (
            <ResolveFullButton variant="contained" fullWidth onClick={handleResolve}>
              <CheckCircleIcon sx={{ marginRight: '12px' }} />
              {notes.length > 0 ? 'Post & Resolve' : 'Resolve'}
            </ResolveFullButton>
          )}
        </FooterBlock>
      </DrawerContainer>
    </Box>
  );
};

HighlightDetails.defaultProps = {
  callTopic: '',
};

export default HighlightDetails;
