import {
  styled, Box, Drawer, IconButton, Tabs, Divider, InputBase, Typography,
} from '@mui/material';
import Split from 'react-split';
import palette from '../../../theme/palette';

const drawerWidth = 459;
const headerHeight = 72;

export const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  marginTop: `${headerHeight}px`,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  maxWidth: '100vw',
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
  }),
}));

export const DrawerContainer = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    backgroundColor: '#F9F9F9',
    borderRightColor: palette.colors.gray03,
    padding: '104px 32px 0',
    boxSizing: 'border-box',
    overflowY: 'unset',
  },
});

export const ScrollContainer = styled(Box)({
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const DrawerHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
  marginBottom: '15px',
});

export const DrawerButton = styled(IconButton)({
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const DrawerTabs = styled(Tabs)({
  '& .MuiTab-root': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0,
    padding: '16px 6px',
    color: palette.colors.gray07,
    '&.Mui-selected': {
      color: palette.primary.main,
    },
    '&:nth-child(odd)': {
      maxWidth: 120,
    },
  },
  '& .MuiTabs-indicator': {
    height: '4px',
  },
});

export const HeaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '22px 18px',
  borderBottom: '1px solid',
  borderBottomColor: palette.colors.gray03,
});

export const VerticalDivider = styled(Divider)({
  height: '51px',
  margin: '0 12px',
  borderColor: palette.colors.gray03,
});

export const CustomSplit = styled(Split)({
  display: 'flex',
  height: 'calc(100vh - 168px)',
});

export const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export const FilterInput = styled(InputBase)({
  lineHeight: '22px',
  border: 'none',
  '& .MuiInputBase-input': { padding: 0 },
  '& .MuiSelect-icon': { color: palette.colors.gray06 },
});

export const NameContainer = styled(Typography)({
  fontSize: '14px',
  lineHeight: '32px',
  marginLeft: '4px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
