import React from 'react';

// * MUI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

// * Components
import { ActionButton } from '../styledComponents';

interface DialogConfirmActionProps {
  openDialog: boolean
  handleCloseDialog: () => void
  handleConfirmAction: () => Promise<void>
  title: string
  message: string
  actionLoading: boolean
}

const DialogConfirmAction = ({
  openDialog,
  handleCloseDialog,
  handleConfirmAction,
  title,
  message,
  actionLoading,
}: DialogConfirmActionProps) => (
  <Dialog
    open={openDialog}
    sx={{ zIndex: 1401 }}
    disableEscapeKeyDown={actionLoading}
  >
    <DialogTitle>{title}</DialogTitle>

    <DialogContent dividers>
      <DialogContentText
        sx={theme => ({ color: theme.palette.text.secondary })}
      >
        {message}
      </DialogContentText>
    </DialogContent>

    <DialogActions sx={theme => ({ padding: theme.spacing(3) })}>
      <ActionButton
        onClick={handleCloseDialog}
        variant="outlined"
        color="error"
        disabled={actionLoading}
      >
        Cancel
      </ActionButton>

      <ActionButton
        onClick={handleConfirmAction}
        variant="contained"
        color="primary"
        disabled={actionLoading}
      >
        Confirm
      </ActionButton>
    </DialogActions>
  </Dialog>
);

export default DialogConfirmAction;
