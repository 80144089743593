const isFutureDate = (timeString: string): boolean => {
  const targetTime = new Date(timeString);
  targetTime.setMinutes(targetTime.getMinutes() - 1);
  const currentTime = new Date();
  if (targetTime >= currentTime) {
    return true;
  }
  return false;
};

export default isFutureDate;
