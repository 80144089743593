import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs, { Dayjs } from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import {
  Box, Button, CircularProgress, Divider, FormControl, MenuItem, Select, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { styled } from '@mui/material/styles';
import { ReactComponent as CalendarPickerIcon } from '../../assets/icons/calendarPickerIcon.svg';
import palette from '../../theme/palette';
import { HeaderBar } from '../../components';
import GET_REVIEWED_CALLS_REPORT from '../../queries/reports/reports';
import GoBackLink from '../../components/GoBackLink';
import 'dayjs/locale/es-us';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'This Year',
    getValue: () => {
      const today = dayjs().utc();
      const thisYearStart = today.startOf('year');
      const thisYearEnd = today.endOf('year').startOf('day');
      return [thisYearStart, thisYearEnd];
    },
  },
  {
    label: 'Last Year',
    getValue: () => {
      const today = dayjs().utc();
      const lastYearStart = today.subtract(1, 'year').startOf('year');
      const lastYearEnd = today.subtract(1, 'year').endOf('year').startOf('day');
      return [lastYearStart, lastYearEnd];
    },
  },
  {
    label: 'This Quarter',
    getValue: () => {
      const today = dayjs().utc();
      const thisQuarterStart = today.startOf('quarter');
      const thisQuarterEnd = today.endOf('quarter').startOf('day');
      return [thisQuarterStart, thisQuarterEnd];
    },
  },
  {
    label: 'Last Quarter',
    getValue: () => {
      const today = dayjs().utc();
      const lastQuarterStart = today.subtract(1, 'quarter').startOf('quarter');
      const lastQuarterEnd = today.subtract(1, 'quarter').endOf('quarter').startOf('day');
      return [lastQuarterStart, lastQuarterEnd];
    },
  },
  {
    label: 'This Month',
    getValue: () => {
      const today = dayjs().utc();
      const thisMonthStart = today.startOf('month');
      const thisMonthEnd = today.endOf('month').startOf('day');
      return [thisMonthStart, thisMonthEnd];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs().utc();
      const lastMonthStart = today.subtract(1, 'month').startOf('month');
      const lastMonthEnd = today.subtract(1, 'month').endOf('month').startOf('day');
      return [lastMonthStart, lastMonthEnd];
    },
  },
];

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Call ID', width: 100 },
  { field: 'title', headerName: 'Call Title', width: 200 },
  { field: 'expert', headerName: 'Expert', width: 150 },
  { field: 'callTime', headerName: 'Call Time', width: 100 },
  { field: 'reviewer', headerName: 'Reviewer', width: 150 },
];

const rows = [
  {
    id: '#H68D798',
    title: 'Call with ChatGPT Expert, Topic : Effect on Search Engines',
    expert: 'Sieu Dema Nacht',
    callTime: '03:56PM',
    reviewer: 'Jessica Born',
  },
  {
    id: '#H68D800',
    title: 'Tempora tempora sed consequatur omnis natus excepturi nihil enim.',
    expert: 'Sieu Dema Nacht',
    callTime: '03:56PM',
    reviewer: 'Jessica Born',
  },
];

const headerHeight = 72;

const Main = styled('main')({
  flexGrow: 1,
  backgroundColor: palette.colors.gray01,
  marginTop: `${headerHeight}px`,
});

const CardContainer = styled(Box)({
  display: 'flex',
  width: 1096,
  flexDirection: 'column',
  marginBottom: '32px',
  borderRadius: '32px',
  backgroundColor: palette.white,
});

const CardHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 48px',
});

const SelectControl = styled(FormControl)({
  '& .MuiInputBase-input': {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 300,
  },
});

const DownloadButton = styled(Button)({
  backgroundColor: palette.primary.main,
  borderRadius: '15px',
  width: 200,
  height: 58,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '10px 12px',
  '&:hover': {
    backgroundColor: palette.primary.dark,
  },
});

const Reports = () => {
  const today = dayjs().utc();

  const {
    control,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      reportSelect: undefined,
      dateRange: [today.subtract(30, 'day').startOf('day'), today.endOf('day')],
    },
  });

  const watchReportSelect = watch('reportSelect');
  const formData = watch();

  const dateRangeStart = watch('dateRange')[0];
  const dateRangeEnd = watch('dateRange')[1];

  // eslint-disable-next-line
  const [getReport, { loading, error }] = useMutation(GET_REVIEWED_CALLS_REPORT);

  const handleDownloadXLSX = async () => {
    try {
      const { data } = await getReport({
        variables: {
          showNotes: false,
          dateRangeStart,
          dateRangeEnd,
        },
      });

      const { b64File } = data.generateReviewedCallsReport;
      const byteArray = new Uint8Array(atob(b64File).split('').map(char => char.charCodeAt(0)));
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${watchReportSelect}.xlsx`;

      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      // eslint-disable-next-line
      console.error('Error downloading XLSX:', err);
    }
  };

  const [dateValidationError, setDateValidationError] = useState(false);

  useEffect(() => {
    if (dateRangeStart > dateRangeEnd) {
      setDateValidationError(true);
    } else if (!dateRangeStart || !dateRangeEnd || !dateRangeEnd.isValid() || !dateRangeStart.isValid()) {
      setDateValidationError(true);
    } else {
      setDateValidationError(false);
    }
  }, [dateRangeStart, dateRangeEnd]);

  const disableKeyboardEntry = (e: any) => {
    if (e?.preventDefault) {
      e?.preventDefault();
      e?.stopPropagation();
    }
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <HeaderBar />
      <Main>
        <Box display="flex" justifyContent="center" flexGrow={1}>
          <Box maxWidth={1096}>
            <GoBackLink />
            <Box display="flex" mt="28px">
              <CardContainer>
                <CardHeader>
                  <Typography variant="h4" sx={{ fontSize: '24px', lineHeight: '44px' }}>
                    Reports
                  </Typography>
                </CardHeader>
                <Divider flexItem sx={{ margin: '0 0 32px', borderColor: 'colors.gray03' }} />
                <Box display="flex" flexDirection="column" padding="0 48px 24px">
                  <form>
                    <Box display="flex" alignItems="center">
                      <Box display="flex" flexDirection="column" mr="12px">
                        <Typography variant="subtitle2" mb="4px">
                          Reports
                        </Typography>
                        <Controller
                          name="reportSelect"
                          control={control}
                          render={({
                            field: {
                              onChange, value, name, ref,
                            },
                          }) => (
                            <SelectControl>
                              <Select
                                inputRef={ref}
                                name={name}
                                value={value}
                                onChange={onChange}
                                displayEmpty
                                IconComponent={ExpandMoreIcon}
                                sx={{ height: 58, width: 300 }}
                                renderValue={selected => {
                                  if (selected === undefined) {
                                    return (
                                      <Box display="flex" alignItems="center">
                                        <Typography variant="body1" color="colors.placeholder">
                                          Select a report
                                        </Typography>
                                      </Box>
                                    );
                                  }
                                  return (
                                    <Box display="flex" alignItems="center">
                                      <Typography variant="body1">
                                        {selected}
                                      </Typography>
                                    </Box>
                                  );
                                }}
                              >
                                <MenuItem key="0" value="Calls Reviewed">Calls Reviewed</MenuItem>
                                {/* <MenuItem key="1" value="Call Details">Call Details</MenuItem> */}
                              </Select>
                            </SelectControl>
                          )}
                        />
                      </Box>
                      <Box display={watchReportSelect ? 'flex' : 'none'} flexDirection="column">
                        <Typography variant="subtitle2" mb="4px">
                          Date
                        </Typography>
                        <Controller
                          control={control}
                          name="dateRange"
                          render={({ field }) => (
                            <DateRangePicker
                              {...field}
                              // @ts-ignore
                              value={formData.dateRange}
                              // maxDate={dayjs().add(1, 'day')}
                              onChange={newValue => {
                                // @ts-ignore
                                setValue('dateRange', newValue);
                              }}
                              slots={{ field: SingleInputDateRangeField }}
                              slotProps={{
                                textField: {
                                  InputProps: {
                                    endAdornment: <CalendarPickerIcon />,
                                    onBeforeInput: disableKeyboardEntry,
                                    onKeyDown: disableKeyboardEntry,
                                  },
                                },
                                shortcuts: {
                                  items: shortcutsItems,
                                },
                                actionBar: { actions: [] },
                              }}
                              calendars={2}
                              sx={{ width: '300px' }}
                            />
                          )}
                        />
                      </Box>
                    </Box>
                    <Box display={watchReportSelect === 'Call Details' ? 'flex' : 'none'} flexDirection="column">
                      <Divider flexItem sx={{ margin: '26px 0 0', borderColor: 'colors.gray01' }} />
                      <Typography variant="h6" my="21px">
                        Select a call
                      </Typography>
                      <DataGridPro
                        rows={rows}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                        sx={{ fontSize: '14px' }}
                      />
                    </Box>
                    <Box display="flex" justifyContent="center" width="100%" mt="26px">
                      <DownloadButton
                        variant="contained"
                        disabled={!watchReportSelect || loading || dateValidationError}
                        onClick={handleDownloadXLSX}
                      >
                        {loading ? (
                          <>
                            <CircularProgress size={16} sx={{ color: palette.white, marginRight: '8px' }} />
                            Downloading..
                          </>
                        ) : 'Download'}
                      </DownloadButton>
                    </Box>
                    {/* <Typography variant="subtitle1" color="colors.success04" textAlign="center" mt="12px">
                      Report downloaded
                    </Typography> */}
                    { !!error && (
                      <Typography variant="subtitle1" color="colors.error04" textAlign="center" mt="12px">
                        Report generation failed, please retry
                      </Typography>
                    )}
                    { dateValidationError && (
                      <Typography variant="subtitle1" color="colors.error04" textAlign="center" mt="12px">
                        Please, enter valid Date Range
                      </Typography>
                    )}
                  </form>
                </Box>
              </CardContainer>
            </Box>
          </Box>
        </Box>
      </Main>
    </Box>
  );
};

export default Reports;
