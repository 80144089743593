import React from 'react';

// * Components
import { TopicViewField } from '../styledComponents';

// * Interfaces
import { Topic } from '../../types';

interface ViewModeTextProps {
  topic: Topic | undefined
  setEditing: React.Dispatch<React.SetStateAction<boolean>>
  placeholder: string
}

const ViewModeText = ({ topic, setEditing, placeholder }: ViewModeTextProps) => (
  <TopicViewField
    onClick={() => setEditing(true)}
    title={topic?.name || undefined}
  >
    {topic?.name || placeholder}
  </TopicViewField>
);

export default ViewModeText;
