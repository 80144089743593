import React from 'react';
import {
  MessageItemContainer, MessageItem, MessageTime,
} from '../styledComponents';
import { formatTime } from '../../../shared/utils/dateHelpers';

interface MessageBulbProps {
  isUser: boolean;
  context: string;
  sentAt: Date | string;
}

const MessageBulb = ({ isUser, context, sentAt }: MessageBulbProps) => (
  <MessageItemContainer sx={{ alignItems: isUser ? 'flex-end' : 'flex-start' }}>
    <MessageItem className={isUser ? 'user-message' : ''}>
      {context}
    </MessageItem>
    <MessageTime>{formatTime(sentAt)}</MessageTime>
  </MessageItemContainer>
);

export default MessageBulb;
