import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';

const HeaderSkeleton = () => (
  <Skeleton
    sx={{
      background: 'linear-gradient(90.12deg, rgba(202, 202, 202, 0.3) -0.85%, rgba(157, 180, 203, 0) 100.45%)',
      borderRadius: '12px',
      display: 'block',
    }}
    variant="rectangular"
    width="100%"
    height={51}
  />
);

export default HeaderSkeleton;
