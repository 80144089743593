import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import paths from '../../paths/paths';

const VerifyToken = () => {
  const params = useParams<{ token: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.token) {
      localStorage.setItem('token', params.token);
    }
    navigate(paths.home.reverse());
  // eslint-disable-next-line
  }, [params.token]);

  return <p>redirecting...</p>;
};

export default VerifyToken;
