/* eslint-disable no-unused-vars */
import React from 'react';

// * Utils
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';

// * Components
import { EditedByText } from './styledComponents';
import HighlightedTranscript from '../HighlightedTranscript';
import DeletedParagraph from './components/DeletedParagraph';

// * Icons
import { ReactComponent as PencilOutlinedIcon } from '../../assets/icons/pencilOutlinedIcon.svg';

// * Interfaces
import type { ParagraphType, TranscriptType } from '../../shared/types/transcript';
import type { UserType } from '../../shared/types/user';
import { CallTabType } from '../AppContextProvider';

export const formatTime = (time: number): string => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export const getTranscriptContent = (
  paragraph: ParagraphType,
  highlights: TranscriptType['highlights'],
  isResearchAnalyst: boolean,
  handleSelectHighlight: any,
  selectedTabName: CallTabType,
) => {
  if (paragraph.text === '') return <DeletedParagraph />;
  if (highlights.length > 0 && !isResearchAnalyst) {
    return (
      <HighlightedTranscript
        paragraph={paragraph.text}
        highlights={highlights}
        handleSelectHighlight={handleSelectHighlight}
        selectedTabName={selectedTabName}
      />
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{paragraph.text}</>;
};

export const identifySpeakers = (
  analysts: string[],
  experts: string[],
  value: string,
) => {
  if (analysts.includes(value)) {
    return analysts.length > 1
      ? `Analyst ${analysts.indexOf(value) + 1}`
      : 'Analyst';
  }
  if (experts.includes(value)) {
    return experts.length > 1
      ? `Expert ${experts.indexOf(value) + 1}`
      : 'Expert';
  }
  return 'Operator';
};

export const getEditedByText = (
  paragraph: ParagraphType,
  isResearchAnalyst: boolean,
  me: UserType,
) => {
  const { lastHistory } = paragraph;
  if (!isResearchAnalyst && lastHistory) {
    return (
      <EditedByText>
        <PencilOutlinedIcon />
        Edited by
        {' '}
        {lastHistory.editor.firstName}
        {' '}
        {lastHistory.editor.lastName}
        {' '}
        on
        {' '}
        {formatDateTimeToUserTimezone(lastHistory.createdAt, me.timezone)}
      </EditedByText>
    );
  }

  return null;
};
