import * as React from 'react';
import { Box, Typography } from '@mui/material';

type CallDetailsItemProps = {
  title: string;
  isTypography?: boolean;
  sx?: any;
  children: any;
};

const CallDetailsItem = ({
  title, isTypography, sx, children,
}: CallDetailsItemProps) => (
  <Box sx={sx}>
    <Typography
      variant="caption"
      color="colors.gray06"
      sx={{ marginBottom: '4px' }}
    >
      {title}
    </Typography>
    <Box>
      {isTypography ? (
        <Box display="flex" alignItems="center" minHeight={36}>
          <Typography
            variant="subtitle2"
            color="colors.gray08"
            sx={{ fontSize: '16px', textTransform: 'none' }}
          >
            {children}
          </Typography>
        </Box>
      ) : children}
    </Box>
  </Box>
);

CallDetailsItem.defaultProps = {
  isTypography: false,
  sx: null,
};

export default CallDetailsItem;
