import React from 'react';

// * MUI
import { Box, Typography } from '@mui/material';

// * Components
import { TranscriptText, Paragraph, Time } from '../styledComponents';

// * Utils
import palette from '../../../theme/palette';

// * Interfaces
import type { ParticipantType } from '../../../shared/types/transcript';

type RenderParagraphMobileViewProps = {
  isResearchAnalyst: boolean
  isTranslated: boolean
  originalText: string
  isEmptyParagraph: boolean
  disableAudio: boolean
  speaker: string
  startTime: string
  endTime: string
  participant: ParticipantType | null
  isFileAText: boolean
  AudioPlayer: JSX.Element
  transcriptContent: JSX.Element
  isDocument: boolean
}

const RenderParagraphMobileView = ({
  isResearchAnalyst,
  isTranslated,
  originalText,
  isEmptyParagraph,
  disableAudio,
  speaker,
  startTime,
  endTime,
  participant,
  isFileAText,
  AudioPlayer,
  transcriptContent,
  isDocument,
}: RenderParagraphMobileViewProps) => (
  <Paragraph flexDirection="column">
    <Box display="flex" alignItems="center" justifyContent="space-between" mb="12px">
      <Box display="flex" alignItems="center">
        {(isResearchAnalyst && disableAudio) || isFileAText ? (
          <Box />
        ) : (
          <Box display="flex" mr="8px">
            {AudioPlayer}
          </Box>
        )}
        {!isDocument && (
        <Box minWidth="60px">
          <Typography variant="subtitle2" sx={{ whiteSpace: 'nowrap' }}>
            {participant?.name || speaker}
          </Typography>
        </Box>
        )}
      </Box>
      {!isFileAText && (
        <Box display="flex" alignItems="center">
          <Time pr="6px" borderRight="1px dashed">
            {startTime}
          </Time>
          <Time pl="6px">{endTime}</Time>
        </Box>
      )}
    </Box>
    <Box display="flex" flexDirection="column">
      {isTranslated && !isEmptyParagraph && (
        <TranscriptText sx={{ marginBottom: '12px' }}>
          {originalText}
        </TranscriptText>
      )}
      <TranscriptText sx={{ color: isTranslated ? palette.primary.main : palette.colors.gray09 }}>
        {transcriptContent}
      </TranscriptText>
    </Box>
  </Paragraph>
);

export default RenderParagraphMobileView;
