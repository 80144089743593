import type { TranscriptType } from '../../shared/types/transcript';

export const generateFileName = (transcript: TranscriptType) => {
  const pattern = '{topic} - {expertName} - {datetime}';
  const topic = transcript.call.name;
  const expertName = transcript.call.expertName || 'NA';
  const datetime = new Date(transcript.call.datetime).toLocaleDateString(
    'en-GB',
    {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    },
  );
  return pattern
    .replace('{topic}', topic)
    .replace('{expertName}', expertName)
    .replace('{datetime}', datetime)
    .replace(/\s/g, '_');
};

export default { generateFileName };
