import { gql } from '@apollo/client';

export const ALL_TICKERS_QUERY = gql`
  query allTickers {
    allTickers {
      uuid
      title
    }
  }
`;

export default { ALL_TICKERS_QUERY };
