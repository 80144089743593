import React from 'react';

// * MUI
import { Box, Typography, CircularProgress } from '@mui/material';

const SummaryInProcess = () => (
  <Box textAlign="center" p={5}>
    <CircularProgress size={60} sx={{ mb: 2 }} />
    <Typography variant="h6" gutterBottom>
      Summary in Progress
    </Typography>
    <Typography variant="subtitle1">
      Your summary is being generated. This may take a few minutes.
    </Typography>
  </Box>
);

export default SummaryInProcess;
