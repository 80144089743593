import { styled } from '@mui/material/styles';
import {
  Avatar as MUIAvatar, Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails,
} from '@mui/material';
import palette from '../../theme/palette';

export const ChangeAccountContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  minWidth: 235,
  backgroundColor: palette.colors.primary01,
  margin: '-12px -24px 0',
  padding: '12px 16px',
  [theme.breakpoints.down('md')]: {
    backgroundColor: 'transparent',
    margin: 0,
    padding: '12px',
  },
}));

export const AccountBtn = styled(Button)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const Avatar = styled(MUIAvatar)({
  width: '24px',
  height: '24px',
  fontSize: '12px',
  color: palette.white,
  backgroundColor: palette.secondary.main,
  marginRight: '4px',
});

export const AvatarSmall = styled(MUIAvatar)({
  width: '22px',
  height: '22px',
  fontSize: '11px',
  color: palette.white,
  backgroundColor: palette.secondary.main,
  marginRight: '4px',
});

export const AvatarLarge = styled(MUIAvatar)({
  width: '30px',
  height: '30px',
  fontSize: '14px',
  color: palette.white,
  backgroundColor: palette.secondary.main,
  marginRight: '8px',
});

export const SelectedTypography = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const AccountAccordion = styled(Accordion)({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  color: palette.white,
});

export const AccountSummary = styled(AccordionSummary)({
  backgroundColor: 'transparent',
  padding: '0 12px',
  borderRadius: '8px',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  '&.Mui-expanded': {
    backgroundColor: palette.colors.secondaryHover,
    minHeight: '48px',
  },
});

export const AccountDetails = styled(AccordionDetails)({
  backgroundColor: 'transparent',
  padding: '12px 0 0',
  maxHeight: 156,
  overflowY: 'auto',
  '& .disabled': {
    pointerEvents: 'none',
    cursor: 'default',
  },
});
