/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';

// * MUI
import {
  Box, Divider, Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// * Hooks & Utils
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { formatTime } from '../utils';

// * Components
import {
  Drawer,
  UpdateTranscriptButton,
  TranscriptText,
  TimestampBlock,
  DashedLine,
  StartTime,
  EndTime,
  EditTextField,
  ScrollContainer,
  FooterBlock,
  CloseButton, MemberTypography,
} from '../styledComponents';
import RenderAudioPlayer from './RenderAudioPlayer';

// * Icons
import { ReactComponent as CloseIcon } from '../../../assets/icons/closeIcon.svg';
import { ReactComponent as EllipseIcon } from '../../../assets/icons/ellipseIcon.svg';

// * Mutations
import { UPDATE_PARAGRAPH_MUTATION } from '../../../queries/transcript/transcript';

// * Interfaces
import type { ParagraphHistoryType, ParticipantType } from '../../../shared/types/transcript';
import type { RenderAudioPlayerProps } from './RenderAudioPlayer';

const schema = yup.object({ paragraphText: yup.string().trim() }).required();

export type ParagraphToEdit = {
  uuid: string
  startTime: number
  endTime: number
  speaker: string
  text: string
  cleanedOriginalText: string
  participant: ParticipantType | null
}

type EditTranscriptDrawerProps = {
  drawerOpen: boolean
  paragraphToEdit: ParagraphToEdit | null
  handleToggleDrawer: (open: boolean) => void
  updateParagraphText: (paragraphUuid: string, newLastHistory: ParagraphHistoryType) => void
  isFileAText: boolean
  isDocument: boolean
} & Omit<RenderAudioPlayerProps, 'paragraph'>;

const EditTranscriptDrawer = ({
  drawerOpen,
  paragraphToEdit,
  handleToggleDrawer,
  updateParagraphText,
  audio,
  activeId,
  setActiveId,
  isPlayingMain,
  setIsPlayingMain,
  isPlayingParagraph,
  setIsPlayingParagraph,
  isFileAText,
  isDocument,
}: EditTranscriptDrawerProps) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, dirtyFields, errors },
  } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
    resolver: yupResolver(schema),
    defaultValues: { paragraphText: '' },
  });

  useEffect(() => {
    if (paragraphToEdit) {
      reset({ paragraphText: paragraphToEdit.text });
    }
  }, [paragraphToEdit, reset]);

  const [updateParagraph, { loading: updateParagraphLoading }] = useMutation<{
    updateParagraph: { lastHistory: ParagraphHistoryType }
  }>(UPDATE_PARAGRAPH_MUTATION);

  const onSubmit = async (data: { paragraphText: string }) => {
    if (!paragraphToEdit) return;

    const updateResponse = await updateParagraph({
      variables: {
        paragraphUuid: paragraphToEdit.uuid,
        newText: data.paragraphText,
      },
    });

    if (updateResponse.data?.updateParagraph.lastHistory) {
      updateParagraphText(
        paragraphToEdit.uuid,
        updateResponse.data.updateParagraph.lastHistory,
      );
      handleToggleDrawer(false);
    }
  };

  const handleKeyDown = (event: { key: string; preventDefault: () => void; }) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => handleToggleDrawer(false)}
      onOpen={e => {
        e.stopPropagation();
        handleToggleDrawer(true);
      }}
    >
      <ScrollContainer>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" sx={{ padding: '20px 36px' }}>
            <CloseButton onClick={() => handleToggleDrawer(false)}>
              <CloseIcon />
            </CloseButton>
            <Typography variant="h3" color="colors.gray10" sx={{ fontSize: '24px' }}>
              Edit Transcript
            </Typography>
          </Box>
          <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
          <Box display="flex" flexDirection="column" flexGrow={1} sx={{ padding: '24px 32px' }}>
            <Typography
              variant="caption"
              color="colors.gray06"
              mb="8px"
              sx={{ fontWeight: 500 }}
            >
              Original Transcription
            </Typography>
            <Box p="24px">
              <Box display="flex" minHeight={48}>
                {!isDocument && (
                  <Box minWidth="90px" maxWidth="90px" mr="4px">
                    <MemberTypography variant="subtitle2">
                      {paragraphToEdit?.participant?.name || paragraphToEdit?.speaker || 'Speaker'}
                    </MemberTypography>
                  </Box>
                )}
                {!isFileAText && (
                  <>
                    <Box sx={{ marginRight: '24px' }}>
                      <Box display="flex">
                        <RenderAudioPlayer
                          audio={audio}
                          isPlayingMain={isPlayingMain}
                          setIsPlayingMain={setIsPlayingMain}
                          isPlayingParagraph={isPlayingParagraph}
                          setIsPlayingParagraph={setIsPlayingParagraph}
                          activeId={activeId}
                          setActiveId={setActiveId}
                          paragraph={{
                            uuid: paragraphToEdit?.uuid || '',
                            startTime: paragraphToEdit?.startTime,
                            endTime: paragraphToEdit?.endTime,
                          }}
                        />
                      </Box>
                    </Box>
                    <TimestampBlock>
                      <Box
                        display="flex"
                        sx={{ position: 'absolute', top: 0, left: 0 }}
                      >
                        <EllipseIcon />
                      </Box>
                      <DashedLine />
                      <Box
                        display="flex"
                        sx={{ position: 'absolute', bottom: 0, left: 0 }}
                      >
                        <EllipseIcon />
                      </Box>
                      <StartTime>
                        {formatTime(paragraphToEdit?.startTime || 0)}
                      </StartTime>
                      <EndTime>
                        {formatTime(paragraphToEdit?.endTime || 0)}
                      </EndTime>
                    </TimestampBlock>
                  </>
                )}
                <Box display="flex" flexDirection="column">
                  <TranscriptText>{paragraphToEdit?.cleanedOriginalText || ''}</TranscriptText>
                </Box>
              </Box>
            </Box>
            <Divider flexItem sx={{ borderColor: 'colors.gray03', marginBottom: '24px' }} />
            <Typography variant="subtitle1" mb="12px">
              Updated Transcription
            </Typography>
            <Box display="flex">
              <Controller
                control={control}
                name="paragraphText"
                render={({
                  field: {
                    onChange, value, name, ref,
                  },
                  fieldState: { error },
                }) => (
                  <EditTextField
                    fullWidth
                    minRows={4}
                    multiline
                    inputRef={ref}
                    name={name}
                    value={value}
                    error={!!error}
                    onChange={onChange}
                    variant="outlined"
                    placeholder="Enter your transcription here..."
                    inputProps={{ onKeyDown: handleKeyDown }}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
      </ScrollContainer>
      <FooterBlock>
        <UpdateTranscriptButton
          variant="contained"
          fullWidth
          // @ts-ignore
          onClick={handleSubmit(onSubmit)}
          disabled={
            isSubmitting
            || !dirtyFields.paragraphText
            || !!errors.paragraphText
            || paragraphToEdit?.text === watch('paragraphText')
            || updateParagraphLoading
          }
        >
          <CheckCircleIcon style={{ marginRight: '12px' }} />
          Update Transcript
        </UpdateTranscriptButton>
      </FooterBlock>
    </Drawer>
  );
};

export default EditTranscriptDrawer;
