/* eslint-disable no-unused-vars */
// * Hooks & Utils
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

// * Interfaces
import type { DropTargetMonitor } from 'react-dnd';

const { hostname } = window.location;

const SupportedMimeTypes = hostname === 'app-dev.encorecompliance.com' ? {
  txt: 'text/plain',
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc: 'application/msword',
  wav: 'audio/wav',
} : {
  txt: 'text/plain',
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  doc: 'application/msword',
  mp3: 'audio/mpeg',
  m4a: 'audio/x-m4a',
} as const;

export type FileExt = keyof typeof SupportedMimeTypes;

const useFileDrop = ({
  onDrop,
  extensions = ['pdf', 'docx', 'doc', 'txt', 'mp3', 'm4a'],
  disable = false,
}: {
  onDrop: (file: File) => void
  extensions: FileExt[]
  disable?: boolean
}) => {
  const validMimes: (string|undefined)[] = extensions.map(ext => SupportedMimeTypes[ext]);
  const [{
    isOver, offset, isDragging, canDrop, isEmpty,
  }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item: DataTransfer, _monitor) {
      if (item.files.length <= 0) return;
      onDrop(item.files[0]);
    },
    canDrop: (_, monitor) => {
      if (disable) return false;
      const dragging: DataTransfer = monitor.getItem();
      for (let i = 0; i < dragging.items.length; i += 1) {
        const thisType = dragging.items[i].type;
        const valid = validMimes.includes(thisType);
        if (!valid) return false;
      }

      return true;
    },
    collect: (monitor: DropTargetMonitor<DataTransfer>) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isDragging: !!monitor.getItem(),
      offset: monitor.getClientOffset(),
      isEmpty: monitor.getItem()?.items.length <= 0,
    }),
  });

  return {
    makeDropable: drop,
    offset,
    canDrop,
    isOver,
    isDragging,
    isEmpty,
  };
};

export default useFileDrop;
