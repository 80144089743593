import { styled } from '@mui/material/styles';
import {
  Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary, AccordionDetails as MuiAccordionDetails,
  Box, Drawer, IconButton, Tabs,
} from '@mui/material';
import palette from '../../theme/palette';

export const DrawerContainer = styled(Drawer)(({ theme }) => ({
  width: 850,
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: 850,
    boxSizing: 'border-box',
    boxShadow: '-11px 0px 60px rgba(0, 0, 0, 0.25)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    zIndex: 1100,
    '& .MuiDrawer-paper': {
      width: '100%',
      boxShadow: 'none',
    },
  },
}));

export const ScrollContainer = styled(Box)({
  height: '100%',
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const CloseButton = styled(IconButton)({
  padding: 0,
});

export const MoreBtn = styled(Box)({
  display: 'flex',
  cursor: 'pointer',
  marginLeft: '8px',
  '& svg path': {
    fill: palette.colors.gray06,
  },
});

export const DrawerTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0,
    padding: '16px 32px',
    color: palette.colors.gray07,
    [theme.breakpoints.down('md')]: {
      padding: '16px 12px',
    },
    '&.Mui-selected': {
      color: palette.primary.main,
    },
  },
  '& .MuiTabs-indicator': {
    height: '4px',
  },
}));

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '12px !important',
  '&:before': { display: 'none' },
  '&:last-of-type': {
    '& .MuiAccordionSummary-root': {
      borderRadius: '12px !important',
    },
  },
  '&:not(:last-child)': {
    marginBottom: '24px',
    [theme.breakpoints.down('md')]: {
      marginBottom: '20px',
    },
  },
}));

export const AccordionSummary = styled(MuiAccordionSummary)({
  minHeight: 54,
  '&.Mui-expanded': {
    minHeight: 54,
  },
  '& .MuiAccordionSummary-content': {
    flexDirection: 'column',
    margin: '16px 0',
    '&.Mui-expanded': {
      margin: '16px 0',
    },
  },
});

export const AccordionDetails = styled(MuiAccordionDetails)({
  '&:last-child': {
    borderRadius: '12px !important',
  },
});

export const moreDetailsA11yProps = (index: number) => ({
  id: `more-details-tab-${index}`,
  'aria-controls': `more-details-tabpanel-${index}`,
});
