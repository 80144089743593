import React, { useState } from 'react';

// * MUI
import {
  Box, Typography, TextField, IconButton, Tooltip,
} from '@mui/material';

// * Icons
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

// * Hooks & Utils
import { useMutation } from '@apollo/client';

// * Mutations
import { UPDATE_CALL_PROJECT_ID_MUTATION } from '../../queries/calls/mutations';

interface EditableProjectIdProps {
  callUuid: string
  initialProjectId: string
}

const EditableProjectId = ({
  callUuid,
  initialProjectId,
}: EditableProjectIdProps) => {
  const [updateCallProjectID] = useMutation(UPDATE_CALL_PROJECT_ID_MUTATION);

  const [isEditing, setIsEditing] = useState(false);
  const [projectId, setProjectId] = useState(initialProjectId);
  const [prevProjectId, setPrevProjectId] = useState(initialProjectId);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(
    null,
  );

  const handleEditClick = () => {
    setIsEditing(true);
    setPrevProjectId(projectId);
  };

  const handleSaveClick = async () => {
    if (projectId.length > 12) {
      setMessage('Project ID cannot exceed 12 characters.');
      setMessageType('error');
      setTimeout(() => setMessage(null), 3000);
      return;
    }

    setLoading(true);
    setMessage(null);

    try {
      await updateCallProjectID({ variables: { callUuid, projectId } });
      setIsEditing(false);
      setMessage('Project ID updated successfully!');
      setMessageType('success');

      setTimeout(() => setMessage(null), 3000);
    } catch (err) {
      setMessage('Failed to update Project ID. Please try again.');
      setMessageType('error');
      console.error(err);

      setProjectId(prevProjectId);
      setIsEditing(false);

      setTimeout(() => setMessage(null), 3000);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="caption" color="colors.gray06">
        Project ID
      </Typography>
      <Box display="flex" alignItems="center" minHeight={36}>
        {isEditing ? (
          <TextField
            variant="outlined"
            size="small"
            value={projectId}
            onChange={e => setProjectId(e.target.value)}
            placeholder="Enter Project ID"
            sx={{
              width: '160px',
              '& .MuiOutlinedInput-root': { height: '36px', borderRadius: '8px' },
            }}
            error={messageType === 'error'}
            inputProps={{ maxLength: 12 }}
          />
        ) : (
          <Typography
            variant="subtitle2"
            color="colors.gray08"
            sx={{ fontSize: '16px', textTransform: 'none' }}
          >
            {projectId || 'Not Available'}
          </Typography>
        )}
        <Tooltip title={isEditing ? 'Save Project ID' : 'Edit Project ID'}>
          <IconButton
            onClick={isEditing ? handleSaveClick : handleEditClick}
            size="small"
            sx={{ marginLeft: '4px', padding: '4px' }}
            disabled={loading}
          >
            {isEditing ? (
              <CheckIcon fontSize="small" />
            ) : (
              <EditIcon fontSize="small" />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {message && (
        <Typography
          variant="caption"
          color={messageType === 'success' ? 'green' : 'error'}
          sx={{ marginTop: '4px' }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default EditableProjectId;
