import * as React from 'react';
import { useMutation } from '@apollo/client';
import {
  Box, Dialog, Divider, Typography,
} from '@mui/material';
import { ReactComponent as QuestionCircleIcon } from '../../assets/icons/questionCircleIcon.svg';
import { ReactComponent as InfoCircleErrorIcon } from '../../assets/icons/infoCircleErrorIcon.svg';
import {
  CustomTooltip, SuspendButton, ResumeButton, ReviewBlock, CancelButton, SubmitButton,
} from './styledComponents';
import { RESUME_CALL_MUTATION, SUSPEND_CALL_MUTATION, TRANSCRIPT_QUERY } from '../../queries/transcript/transcript';
import { AppContext, useMe } from '../AppContextProvider';
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';
import isFutureDate from '../../shared/utils/functions';

const suspendText = 'Press “Suspend” to temporarily halt the bot from dialing into this scheduled call.';
const resumeText = 'Press “Resume” to enable the bot to dial into this scheduled call again.';

type SuspendRecordProps = {
  status: string;
  title: string;
  callDate: string;
  transcriptUuid: string;
};

const SuspendRecord = ({
  status,
  title,
  callDate,
  transcriptUuid,
}: SuspendRecordProps) => {
  const { me } = useMe();

  const [open, setOpen] = React.useState(false);
  const { refetchScheduledCalls } = React.useContext(AppContext);
  const [isDateInFuture, setIsDateInFuture] = React.useState(isFutureDate(callDate));

  React.useEffect(() => {
    // Функция для проверки, осталось ли до события больше минуты
    const checkDate = () => {
      setIsDateInFuture(isFutureDate(callDate));
    };

    checkDate();

    const intervalId = setInterval(checkDate, 20000); // проверка каждую минуту

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [callDate]);

  const [suspendCall] = useMutation(SUSPEND_CALL_MUTATION, {
    variables: { transcriptUuid },
    onCompleted: () => {
      if (refetchScheduledCalls) {
        refetchScheduledCalls();
      }
    },
    refetchQueries: [{ query: TRANSCRIPT_QUERY, variables: { uuid: transcriptUuid } }],
  });

  const [resumeCall] = useMutation(RESUME_CALL_MUTATION, {
    variables: { transcriptUuid },
    onCompleted: () => {
      if (refetchScheduledCalls) {
        refetchScheduledCalls();
      }
    },
    refetchQueries: [{ query: TRANSCRIPT_QUERY, variables: { uuid: transcriptUuid } }],
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSuspend = () => {
    suspendCall();
    handleClose();
  };

  const handleResume = () => {
    resumeCall();
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="overline"
        color="colors.gray08"
        sx={{ textTransform: 'none' }}
      >
        {status === 'Scheduled' ? 'Scheduled to Record' : 'Suspended from Record'}
      </Typography>
      {status === 'Scheduled' ? (
        <>
          <SuspendButton
            variant="outlined"
            onClick={handleOpen}
            disabled={!isDateInFuture}
          >
            Suspend
          </SuspendButton>
          <Dialog
            open={open}
            onClose={handleClose}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '55px 29px',
              }}
            >
              <Box display="flex" mb="8px">
                <InfoCircleErrorIcon />
              </Box>
              <Typography variant="h3" color="colors.gray10" textAlign="center" mb="25px">
                Suspend from Record
              </Typography>
              <ReviewBlock>
                <Typography variant="h5" color="colors.gray10" textAlign="center">
                  {title}
                </Typography>
                <Divider flexItem sx={{ margin: '12px 0', borderColor: 'colors.gray01' }} />
                <Typography variant="subtitle2" color="colors.gray08" textAlign="center" sx={{ fontWeight: 400 }}>
                  {formatDateTimeToUserTimezone(callDate, me.timezone)}
                </Typography>
              </ReviewBlock>
              <Typography variant="body1" textAlign="center" mt="25px" sx={{ fontWeight: 400 }}>
                This will temporarily halt the bot from dialing into this scheduled call.
              </Typography>
              <Box display="flex" justifyContent="center" mt="36px">
                <CancelButton variant="outlined" onClick={handleClose}>
                  Cancel
                </CancelButton>
                <SubmitButton variant="contained" onClick={handleSuspend}>
                  Yes, Suspend
                </SubmitButton>
              </Box>
            </Box>
          </Dialog>
        </>
      ) : (
        <ResumeButton
          variant="outlined"
          onClick={handleResume}
          disabled={!isDateInFuture}
        >
          Resume
        </ResumeButton>
      )}
      <CustomTooltip
        title={status === 'Scheduled' ? suspendText : resumeText}
        arrow
      >
        <QuestionCircleIcon />
      </CustomTooltip>
    </Box>
  );
};

export default SuspendRecord;
