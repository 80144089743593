import React, { useState } from 'react';
import { Box } from '@mui/material';
import { ReactComponent as EllipseIcon } from '../../assets/icons/ellipseIcon.svg';
import AudioPlayer from '../AudioPlayer';
import { identifySpeakers } from '../TranscriptView/utils';
import type { ParticipantType } from '../../shared/types/transcript';
import {
  Paragraph, TimestampBlock, DashedLine, StartTime, EndTime, TranscriptText, MemberTypography,
} from './styledComponents';

type ParagraphViewProps = {
  paragraphId: string;
  startTime: number;
  endTime: number;
  speaker: string;
  highlightedParagraph: React.ReactNode;
  isFileAText: boolean;
  audio: HTMLAudioElement | null;
  analysts: string[];
  experts: string[];
  participant: ParticipantType | null
  isPlayingMain: boolean;
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayingParagraph: boolean;
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>;
  isDocument: boolean;
};

const ParagraphView = ({
  paragraphId, startTime, endTime, speaker, participant, highlightedParagraph, isFileAText, audio,
  analysts, experts, isPlayingMain, setIsPlayingMain, isPlayingParagraph, setIsPlayingParagraph, isDocument,
}: ParagraphViewProps) => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const paragraphSpeaker = participant?.name || identifySpeakers(analysts, experts, speaker);

  return (
    <Paragraph>
      <Box display="flex">
        {!isDocument && (
          <Box minWidth="90px" maxWidth="90px" mr="4px">
            <MemberTypography variant="subtitle2">
              {paragraphSpeaker}
            </MemberTypography>
          </Box>
        )}
        {!isFileAText && (
          <>
            <Box sx={{ marginRight: '24px' }}>
              <AudioPlayer
                audio={audio}
                isPlayingMain={isPlayingMain}
                setIsPlayingMain={setIsPlayingMain}
                isPlayingParagraph={isPlayingParagraph}
                setIsPlayingParagraph={setIsPlayingParagraph}
                isPlaying={activeId === paragraphId}
                setIsPlaying={() => setActiveId(paragraphId)}
                setIsStopping={() => setActiveId(null)}
                startTime={startTime}
                endTime={endTime}
                playParagraph
              />
            </Box>
            <TimestampBlock>
              <Box display="flex" sx={{ position: 'absolute', top: 0, left: 0 }}>
                <EllipseIcon />
              </Box>
              <DashedLine />
              <Box display="flex" sx={{ position: 'absolute', bottom: 0, left: 0 }}>
                <EllipseIcon />
              </Box>
              <StartTime>
                {formatTime(startTime)}
              </StartTime>
              <EndTime>
                {formatTime(endTime)}
              </EndTime>
            </TimestampBlock>
          </>
        )}
        <TranscriptText>
          {highlightedParagraph}
        </TranscriptText>
      </Box>
    </Paragraph>
  );
};

export default ParagraphView;
