import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const MoreDetailsError = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      p={isMobile ? '40px 20px' : '48px'}
    >
      <ErrorOutlineIcon sx={{ fontSize: 60, color: 'colors.error03', marginBottom: '8px' }} />
      <Typography variant="h6" color="colors.error03" gutterBottom>
        Something went wrong
      </Typography>
      <Typography variant="subtitle2" textAlign="center">
        There was a problem generating the details.
      </Typography>
    </Box>
  );
};

export default MoreDetailsError;
