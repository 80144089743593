import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Chip } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import palette from '../../../theme/palette';

export const HighlightItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  left: 0,
  width: '100%',
  minWidth: 400,
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '12px',
  backgroundColor: palette.white,
  boxShadow: 'none',
  padding: '16px 20px',
  marginBottom: '12px',
  cursor: 'pointer',
  position: 'relative',
  transition: 'border 250ms, box-shadow 250ms, left 500ms ease-in',
  '&:hover': {
    borderColor: '#70BDFF',
    boxShadow: '0px 4px 13px rgba(188, 188, 188, 0.25)',
    '& .typo-on-hover': {
      display: 'none',
    },
    '& .buttons-on-hover': {
      display: 'flex',
    },
  },
  '&.selected-highlight-item': {
    borderColor: palette.colors.primary05,
  },
  '&.fade': {
    left: '100%',
  },
});

export const HighlightDot = styled(Box)({
  width: '10px',
  height: '10px',
  borderRadius: '50%',
});

export const HighlightStatus = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  marginLeft: '8px',
});

export const CustomChip = styled(Chip)({
  height: '26px',
  backgroundColor: palette.colors.primary05,
  marginRight: '8px',
  '& .MuiChip-label': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: palette.white,
    padding: '0 8px',
  },
});

export const ConfidenceTypo = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '22px',
  color: palette.colors.gray06,
  marginRight: '8px',
});

export const PercentTypo = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '22px',
  color: palette.colors.success04,
});

export const TranscriptText = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
});

export const Reason = styled(Box)({
  position: 'relative',
  display: '-webkit-box',
  '-webkit-line-clamp': 'initial',
  '-webkit-box-orient': 'vertical',
  whiteSpace: 'pre-wrap',
  overflow: 'hidden',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '20px',
  color: palette.colors.gray10,
});

export const ReasonTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: palette.colors.gray08,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.colors.gray08,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: palette.white,
    border: '1px solid',
    borderColor: palette.colors.gray08,
    borderRadius: '6px',
    padding: '5px 8px',
    maxWidth: 410,
  },
});

export const ReasonTitle = styled('span')({
  fontWeight: 700,
  marginRight: '4px',
});

export const ReadMore = styled(Box)({
  position: 'absolute',
  right: 0,
  top: 40,
  backgroundColor: palette.white,
  display: 'none',
  fontSize: '15px',
  fontWeight: 400,
  lineHeight: '20px',
  paddingLeft: '4px',
  color: palette.primary.main,
  cursor: 'pointer',
  textDecoration: 'underline',
});
