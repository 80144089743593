import React, { useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import ScrollToBottom from 'react-scroll-to-bottom';
import MessageBulb from './components/MessageBulb';
import {
  InterfaceContainer,
  InputField,
  // StopIcon,
  // StopButton,
  SendButton,
} from './styledComponents';
import {
  SEND_MESSAGE_TO_AI_CONVERSATION,
  TRANSCRIPT_AI_CONVERSATION,
} from '../../queries/transcript/transcript';
import { TranscriptAiConversationType } from '../../shared/types/transcript';

type ChatInterfaceProps = {
  conversationUuid: string;
  chatStyle: string;
}

const ChatInterface = ({ conversationUuid, chatStyle }: ChatInterfaceProps) => {
  const { register, handleSubmit, reset } = useForm();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [
    getConversation,
    { refetch: refetchConversation, data: { transcriptAiConversation = null } = {} },
  ] = useLazyQuery<{ transcriptAiConversation: TranscriptAiConversationType | null }>(TRANSCRIPT_AI_CONVERSATION, {
    variables: { uuid: conversationUuid },
  });

  // check for new messages every minute
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetchConversation();
    }, 1000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);

  const [sendMessage] = useMutation(SEND_MESSAGE_TO_AI_CONVERSATION);

  const onSubmit = async (data: any) => {
    reset();
    const { chatInput } = data;
    await sendMessage({
      variables: {
        conversationUuid,
        message: chatInput,
      },
    });
    refetchConversation();
  };

  useEffect(() => {
    if (!transcriptAiConversation) {
      getConversation();
    }
  // eslint-disable-next-line
  }, []);

  return (
    <Box display="flex" p={isMobile ? 0 : '0 12px'} m={isMobile ? '0 -8px' : 0}>
      <InterfaceContainer sx={{ padding: isMobile ? '20px' : '32px 20px' }}>
        <ScrollToBottom className={chatStyle}>
          {transcriptAiConversation?.messages?.map(message => (
            <MessageBulb
              key={message.createdAt}
              isUser={message.role === 'USER'}
              sentAt={message.createdAt}
              context={message.text}
            />
          ))}
          {!!transcriptAiConversation?.isWaitingForResponse && (
            <Box display="flex" alignItems="center" sx={{ minHeight: 20 }}>
              <CircularProgress size={14} />
              <Typography variant="caption" sx={{ fontWeight: 400, lineHeight: '14px', marginLeft: '6px' }}>
                Generating...
              </Typography>
            </Box>
          )}
        </ScrollToBottom>
        {/* <Box display="flex" justifyContent="center" width="100%">
          <StopButton variant="outlined" onClick={handleStopGenerating}>
            <Box display="flex" alignItems="center">
              <StopIcon />
              Stop Generating
            </Box>
          </StopButton>
        </Box> */}
        <Divider flexItem sx={{ margin: '12px -20px 16px', borderColor: 'colors.gray02' }} />
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" alignItems="center">
            <InputField
              id="ChatInputField"
              {...register('chatInput')}
              type="text"
              variant="outlined"
              placeholder="Type something.."
              disabled={!!transcriptAiConversation?.isWaitingForResponse}
            />
            <SendButton variant="contained" type="submit" disabled={!!transcriptAiConversation?.isWaitingForResponse}>
              Send
            </SendButton>
          </Box>
        </form>
      </InterfaceContainer>
    </Box>
  );
};

export default ChatInterface;
