import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Typography,
} from '@mui/material';
import { TranscriptType } from '../../../shared/types/transcript';
import palette from '../../../theme/palette';
import ScheduledCallItemCard from '../../../components/Cards/ScheduledCallItemCard';
import { formatDate } from '../../../shared/utils/dateHelpers';

const GroupedBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
});

const Ellipse = styled(Box)({
  display: 'flex',
  width: 13,
  height: 13,
  backgroundColor: palette.colors.gray04,
  border: '1px solid',
  borderColor: palette.colors.gray05,
  borderRadius: '50%',
  marginRight: '8px',
});

type ScheduledCallListProps = {
  transcripts: TranscriptType[];
  // eslint-disable-next-line
  onSelect: (uuid: string) => void;
  selectedTranscriptId?: string;
  isResearchAnalyst?: boolean;
  openDrawerMobile?: boolean;
  setOpenDrawerMobile?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScheduledCallList: React.FC<ScheduledCallListProps> = ({
  transcripts, onSelect, selectedTranscriptId, isResearchAnalyst,
  openDrawerMobile, setOpenDrawerMobile,
}) => {
  const groupedTranscripts: { [key: string]: Array<TranscriptType> } = {};
  transcripts.forEach(transcript => {
    const date = formatDate(transcript.call.datetime);
    if (!groupedTranscripts[date]) {
      groupedTranscripts[date] = [];
    }
    groupedTranscripts[date].push(transcript);
  });

  return (
    <>
      {Object.keys(groupedTranscripts).map(date => (
        <GroupedBox key={date} className="grouped-call-box">
          <Box display="flex" alignItems="center" mb="6px">
            <Ellipse />
            <Typography variant="subtitle2" color="colors.gray08">
              {date}
            </Typography>
          </Box>
          <Box pl="20px">
            {groupedTranscripts[date].map(transcript => (
              <ScheduledCallItemCard
                key={transcript.uuid}
                uuid={transcript.uuid}
                accountName={transcript.call.account.name}
                accountAvatarLogo={transcript.call.account.settings.avatarLogo}
                status={transcript.call.status.value}
                isInSample={transcript.call.inSample}
                title={transcript.call.name}
                datetime={transcript.call.datetime}
                network={transcript.call.network.value}
                onClick={() => onSelect(transcript.uuid)}
                selected={transcript.uuid === selectedTranscriptId}
                expertName={transcript.call.expertName}
                analystName={transcript.call.analystName}
                type={transcript.call.type}
                isResearchAnalyst={isResearchAnalyst}
                openDrawerMobile={openDrawerMobile}
                setOpenDrawerMobile={setOpenDrawerMobile}
              />
            ))}
          </Box>
        </GroupedBox>
      ))}
    </>
  );
};

ScheduledCallList.defaultProps = {
  selectedTranscriptId: undefined,
  isResearchAnalyst: false,
  openDrawerMobile: false,
  setOpenDrawerMobile: () => {},
};

export default ScheduledCallList;
