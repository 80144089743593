export const calculateWidth = () => {
  const callDetailsColumn = document.getElementById('CallDetailsColumn') as HTMLElement;
  const playerProgressBar = document.getElementById('PlayerProgressBar') as HTMLElement;
  if (callDetailsColumn) {
    const playerBlockWidth = callDetailsColumn.getBoundingClientRect().width;
    if (playerProgressBar) {
      playerProgressBar.style.width = `${playerBlockWidth - 300}px`;
    }
  }
};

export const handleResizeWindow = (): void => {
  calculateWidth();
};
