/* eslint-disable import/order */
import React, { useState } from 'react';

// * MUI
import { Box, Skeleton } from '@mui/material';

// * Components
import {
  Main, CardContainer, CustomTabs, CustomTab,
} from './styledComponents';
import HeaderBar from '../../components/HeaderBar';
import OrganizationBrandingSettingsForm from '../../components/OrganizationBrandingSettingsForm';
import OrganizationGeneralSettingsForm from '../../components/OrganizationGeneralSettingsForm';
import GoBackLink from '../../components/GoBackLink';

// * Hooks & Utils
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

// * Queries
import { MY_ACCOUNT_SETTINGS } from '../../queries/auth/account';

const OrganizationSettings = () => {
  const { data: { myAccountSettings: settings = {} } = {}, loading } = useQuery(
    MY_ACCOUNT_SETTINGS,
    {
      fetchPolicy: 'network-only',
    },
  );
  const refetchQueries = [{ query: MY_ACCOUNT_SETTINGS }];
  const [editMode, setEditMode] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  // const tabs = ['General', 'Branding'];

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <HeaderBar />
      <Main>
        <Box display="flex" justifyContent="center" flexGrow={1}>
          <Box maxWidth={1096} width="100%">
            {!loading && !editMode && <GoBackLink />}
            <CustomTabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {/*
              {tabs.map(tab => (
                <CustomTab key={tab} label={tab} disabled={editMode} />
              ))}
              */}
              <CustomTab key="General" label="General" disabled={editMode} />
            </CustomTabs>
            <CardContainer sx={{ marginTop: editMode ? '32px' : '28px' }}>
              {loading ? (
                <Skeleton
                  sx={{ height: 800, borderRadius: '32px' }}
                  animation="wave"
                  variant="rectangular"
                />
              ) : (
                <>
                  {selectedTab === 0 && !isEmpty(settings) && (
                    <OrganizationGeneralSettingsForm
                      settings={settings}
                      refetchQueries={refetchQueries}
                      editMode={editMode}
                      setEditMode={setEditMode}
                    />
                  )}
                  {selectedTab === 1 && !isEmpty(settings) && (
                    <OrganizationBrandingSettingsForm
                      settings={settings}
                      refetchQueries={refetchQueries}
                      editMode={editMode}
                      setEditMode={setEditMode}
                    />
                  )}
                </>
              )}
            </CardContainer>
          </Box>
        </Box>
      </Main>
    </Box>
  );
};

export default OrganizationSettings;
