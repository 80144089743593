import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography } from '@mui/material';
import palette from '../../theme/palette';

type CallDetailsOutlinedItemProps = {
  title: string;
  isTypography?: boolean;
  sx?: any;
  children: any;
};

const CallDetailsOutlinedItem = ({
  title, isTypography, sx, children,
}: CallDetailsOutlinedItemProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display="flex" flexDirection="column" width="100%" sx={sx}>
      <Typography
        variant="subtitle1"
        color="colors.gray08"
        sx={{ fontSize: isMobile ? '14px' : '16px', lineHeight: '22px', marginBottom: '8px' }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          border: '1px solid',
          borderColor: palette.colors.gray03,
          borderRadius: '12px',
          padding: isMobile ? '12px' : '16px',
        }}
      >
        {isTypography ? (
          <Typography
            variant="body1"
            color="colors.gray08"
            sx={{ fontSize: isMobile ? '14px' : '16px', fontWeight: 400, lineHeight: '22px' }}
          >
            {children}
          </Typography>
        ) : children}
      </Box>
    </Box>
  );
};

CallDetailsOutlinedItem.defaultProps = {
  isTypography: false,
  sx: null,
};

export default CallDetailsOutlinedItem;
