import React, { forwardRef } from 'react';

// * MUI
import { Box, Typography } from '@mui/material';

// * Icons
import CircleIcon from '@mui/icons-material/Circle';
import { ReactComponent as UploadAvatarIcon } from '../../assets/icons/uploadAvatarIcon.svg';

// * Components
import { EditBtn as UploadButton } from './styledComponents';
import palette from '../../theme/palette';

type LogoUploadProps = {
  label: string
  logo: string
  defaultLogo: string
  onUploadClick: () => void
  maxHeight: number
  maxWidth: number
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const LogoUpload = forwardRef<HTMLInputElement, LogoUploadProps>(
  (
    {
      label,
      logo,
      defaultLogo,
      onUploadClick,
      maxHeight,
      maxWidth,
      onChange,
      disabled,
    },
    ref,
  ) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: disabled ? 'flex-start' : 'center',
        width: disabled ? 'auto' : '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mb: '16px',
        }}
      >
        <Typography variant="body2">
          {label}
        </Typography>
        {!disabled && (
          <UploadButton
            variant="outlined"
            onClick={onUploadClick}
            sx={{
              '&:hover': {
                'svg path': {
                  stroke: 'white',
                },
              },
            }}
            startIcon={<UploadAvatarIcon />}
          >
            Upload
          </UploadButton>
        )}
      </Box>
      {!disabled && (
        <Box display="flex" flexDirection="column" width="100%" mb="16px">
          <Box display="flex" alignItems="center">
            <CircleIcon sx={{ color: palette.colors.gray05, height: 6, width: 6 }} />
            <Typography variant="body1" color="colors.gray05" ml="8px" sx={{ fontSize: '14px' }}>
              Supported type: .jpg, .jpeg or .png format only.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <CircleIcon sx={{ color: palette.colors.gray05, height: 6, width: 6 }} />
            <Typography variant="body1" color="colors.gray05" ml="8px" sx={{ fontSize: '14px' }}>
              Maximum file size allowed is 10MB.
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <CircleIcon sx={{ color: palette.colors.gray05, height: 6, width: 6 }} />
            <Typography variant="body1" color="colors.gray05" ml="8px" sx={{ fontSize: '14px' }}>
              {`Maximum Resolution: ${maxWidth}x${maxHeight} pixels.`}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: 'colors.gray01',
          border: '1px dashed',
          borderColor: 'colors.gray03',
          borderRadius: '16px',
          padding: '16px',
          width: `calc(${maxWidth}px + 34px)`,
          height: `calc(${maxHeight}px + 34px + ${label === 'Full Logo' ? '40px' : '0px'})`,
        }}
      >
        <Box
          component="img"
          src={logo || defaultLogo}
          alt={`${label}`}
          sx={{
            maxHeight: '100%',
            maxWidth: '100%',
            objectFit: 'contain',
          }}
        />
        <input
          ref={ref}
          type="file"
          hidden
          accept="image/*"
          onChange={onChange}
        />
      </Box>
    </Box>
  ),
);

LogoUpload.defaultProps = {
  disabled: false,
};

export default LogoUpload;
