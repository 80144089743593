import React from 'react';

// MUI Imports
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography, IconButton } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const MoreDetailsNotAvailable = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      alignItems="center"
      justifyContent="center"
      p={isMobile ? '40px 20px' : '48px'}
    >
      <IconButton color="error" size="large">
        <ErrorOutlineIcon fontSize="large" />
      </IconButton>
      <Typography variant="h6" color="text.primary" gutterBottom>
        Data Not Available
      </Typography>
    </Box>
  );
};

export default MoreDetailsNotAvailable;
