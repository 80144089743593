import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Avatar, Box, Button, Chip, Drawer, IconButton, Link, TextField, Typography,
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import palette from '../../theme/palette';

export const NotesTypo = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '40px',
  color: palette.primary.main,
  marginLeft: '4px',
});

export const CustomChip = styled(Chip)({
  height: '26px',
  backgroundColor: palette.colors.primary05,
  marginRight: '8px',
  '& .MuiChip-label': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    color: palette.white,
    padding: '0 8px',
  },
});

export const NotesButton = styled(Button)({
  borderColor: palette.primary.main,
  minWidth: 100,
  maxWidth: 160,
  height: 40,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: 0,
  padding: '10px 12px',
  marginLeft: '6px',
  '&:hover': {
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
    color: palette.white,
  },
});

export const CustomNoteButton = styled(Button)({
  borderColor: palette.colors.gray04,
  color: palette.colors.gray08,
  minWidth: 100,
  maxWidth: 110,
  height: 40,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: 0,
  padding: '10px 12px',
  marginRight: '4px',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: palette.colors.gray04,
    borderColor: palette.colors.gray04,
    color: palette.white,
  },
});

export const CopyButton = styled(Button)({
  borderColor: palette.colors.gray04,
  color: palette.colors.gray08,
  minWidth: 38,
  maxWidth: 38,
  height: 40,
  '&:hover': {
    backgroundColor: palette.colors.gray04,
    borderColor: palette.colors.gray04,
    color: palette.white,
  },
  '& .MuiButton-startIcon': {
    margin: 0,
  },
});

export const ResolveButton = styled(Button)({
  backgroundColor: palette.colors.success04,
  borderRadius: '4px',
  width: 150,
  height: 40,
  fontSize: '14px',
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: palette.colors.success03,
  },
});

export const ReopenButton = styled(Button)({
  backgroundColor: palette.primary.main,
  borderRadius: '4px',
  width: 150,
  height: 40,
  fontSize: '14px',
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: palette.primary.dark,
  },
});

export const DrawerContainer = styled(Drawer)({
  width: 850,
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: 850,
    boxSizing: 'border-box',
    boxShadow: '-11px 0px 60px rgba(0, 0, 0, 0.25)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
});

export const ScrollContainer = styled(Box)({
  height: '100%',
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const CloseButton = styled(IconButton)({
  padding: 0,
  marginRight: '20px',
});

export const HighlightDot = styled(Box)({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
});

export const HighlightStatus = styled(Typography)({
  textTransform: 'none',
  marginLeft: '5px',
});

export const FooterBlock = styled(Box)({
  borderTop: '1px solid',
  borderTopColor: palette.colors.gray03,
  backgroundColor: palette.colors.gray01,
  padding: '16px 32px',
});

export const ResolveFullButton = styled(Button)({
  backgroundColor: palette.colors.success03,
  borderRadius: '8px',
  height: 52,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '26px',
  letterSpacing: 0,
  padding: '12px',
  marginTop: '18px',
  '&:hover': {
    backgroundColor: palette.colors.success04,
  },
});

export const ReopenFullButton = styled(Button)({
  borderColor: palette.primary.main,
  borderRadius: '8px',
  height: 52,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '26px',
  letterSpacing: 0,
  padding: '12px',
  '& svg path': {
    fill: palette.primary.main,
  },
  '&:hover': {
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
    color: palette.white,
    '& svg path': {
      fill: palette.white,
    },
  },
});

export const NoteField = styled(TextField)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    padding: '0 12px 0 24px',
    fontSize: '14px',
    lineHeight: '26px',
    '& .MuiOutlinedInput-input': {
      padding: '12px 0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.colors.gray03,
    },
  },
});

export const Comment = styled(Box)({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
  color: palette.colors.gray05,
  padding: '0 31px',
});

export const AttachmentButton = styled(Button)({
  backgroundColor: palette.colors.gray07,
  borderColor: palette.colors.gray07,
  color: palette.white,
  borderRadius: '8px',
  minWidth: 165,
  height: 52,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '12px',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  marginLeft: '12px',
  '&:hover': {
    backgroundColor: palette.colors.gray08,
    borderColor: palette.colors.gray08,
    color: palette.white,
  },
});

export const NoteItem = styled(Box)({
  padding: '12px 4px',
});

export const NotesAvatar = styled(Avatar)({
  height: 24,
  width: 24,
  fontSize: '16px',
  marginRight: '7px',
});

export const Attachment = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: palette.colors.gray01,
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '12px',
  padding: '16px 17px',
  margin: '0 31px',
  maxWidth: '300px',
});

export const AttachmentName = styled(Typography)({
  marginLeft: '4px',
  marginRight: '50px',
  fontSize: '14px',
  fontWeight: 400,
  letterSpacing: '0.02em',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100% - 50px)',
});

export const Suggestion = styled(Box)({
  minWidth: 30,
  backgroundColor: palette.white,
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  color: palette.primary.main,
  padding: '11px 12px',
  marginRight: '8px',
  marginBottom: '8px',
  border: '1px solid',
  borderColor: palette.primary.main,
  borderRadius: '24px',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  '&.is-used': {
    backgroundColor: 'transparent',
    color: palette.colors.gray04,
    borderColor: palette.colors.gray04,
    cursor: 'default',
  },
});

export const PredictiveTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    color: palette.colors.gray08,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.colors.gray08,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    color: palette.white,
    border: '1px solid',
    borderColor: palette.colors.gray08,
    borderRadius: '6px',
    padding: '5px 8px',
    maxWidth: 410,
  },
});
