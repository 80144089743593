/* eslint-disable import/order, no-unused-vars, no-console */
import React, {
  useContext, useEffect, useState,
} from 'react';

// * MUI
import {
  Box,
  Divider,
  Typography,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// * Hooks & Utils
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { AppContext } from '../AppContextProvider';
import palette from '../../theme/palette';

// * Queries
import { SHARE_CONTENT_MUTATION } from '../../queries/transcript/shareContent';

// * Components
import {
  DrawerContainer,
  ScrollContainer,
  FooterBlock,
  ShareButton,
  CloseButton,
  ImportFullButton,
  TextInputField,
  CustomCheckbox,
} from './styledComponents';

// * Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ReactComponent as ProfileAddIcon } from '../../assets/icons/profileAddIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeIcon.svg';

interface ShareFormInput {
  email: string;
  subject: string;
  sendMeACopy: boolean;
  sendNotes: boolean;
  sendSummary: boolean;
  sendFullTranscript: boolean;
}

const FieldLabel = ({ title, required }: { title: string; required?: boolean; }) => (
  <Typography
    variant="body1"
    color="colors.gray08"
    mb="8px"
    sx={{ fontSize: '14px', fontWeight: 600, lineHeight: '18px' }}
  >
    {title}
    {required && <span style={{ color: palette.colors.error03 }}>*</span>}
  </Typography>
);

FieldLabel.defaultProps = {
  required: false,
};

const schema = yup
  .object()
  .shape({
    email: yup.string().email('Invalid email format').required(),
    sendMeACopy: yup.boolean(),
    subject: yup.string().required(),
    sendNotes: yup.boolean(),
    sendSummary: yup.boolean(),
    sendFullTranscript: yup.boolean(),
  })
  .required();

type ShareContentProps = ({
  defaultSubject: string,
  transcriptUuid: string,
});

const ShareContent = ({
  defaultSubject,
  transcriptUuid,
}: ShareContentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { refetchCompletedCalls, refetchReviewedCalls, refetchTranscript } = useContext(AppContext);
  const [shareContent] = useMutation(SHARE_CONTENT_MUTATION);
  const [openSidebar, setOpenSidebar] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting, dirtyFields, isValid },
  } = useForm<ShareFormInput>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
    // @ts-ignore
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      sendMeACopy: false,
      subject: defaultSubject,
      sendNotes: true,
      sendSummary: true,
      sendFullTranscript: true,
    },
  });

  const subjectField = watch('subject');
  const sendNotesField = watch('sendNotes');
  const sendSummaryField = watch('sendSummary');
  const sendFullTranscriptField = watch('sendFullTranscript');

  useEffect(() => {
    if (!openSidebar) {
      reset();
    }
  }, [openSidebar, reset]);

  const toggleDrawer = (open: boolean) => () => {
    setOpenSidebar(open);
  };

  const onSubmit = async (data: ShareFormInput) => {
    const {
      email,
      subject,
      sendMeACopy,
      sendNotes,
      sendSummary,
      sendFullTranscript,
    } = data;

    await shareContent({
      variables: {
        email,
        transcriptUuid,
        subject,
        sendMeACopy,
        sendNotes,
        sendSummary,
        sendFullTranscript,
      },
      onCompleted: shareContentData => {
        const { shareContent: shareContentResponse } = shareContentData;
        if (shareContentResponse.status === 'Error') {
          toast.error(shareContentResponse.message);
          return;
        }

        setOpenSidebar(false);
        refetchCompletedCalls?.();
        refetchReviewedCalls?.();
        refetchTranscript?.();
        toast.success(shareContentResponse.message);
      },
    });
  };

  return (
    <Box display="flex">
      <ShareButton
        id="share-button"
        variant="outlined"
        disableElevation
        onClick={toggleDrawer(true)}
        startIcon={<ProfileAddIcon />}
      >
        Share
      </ShareButton>
      <DrawerContainer
        anchor={isMobile ? 'bottom' : 'right'}
        open={openSidebar}
        onClose={toggleDrawer(isSubmitting)}
      >
        <ScrollContainer>
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center" sx={{ padding: '20px 36px' }}>
              <CloseButton onClick={toggleDrawer(false)}>
                <CloseIcon />
              </CloseButton>
              <Typography variant="h3" color="colors.gray10" sx={{ fontSize: '24px' }}>
                Share Content
              </Typography>
            </Box>
            <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display="flex" flexDirection="column" flexGrow={1} sx={{ padding: '24px 32px' }}>
                <Grid container rowSpacing="16px" columnSpacing="32px">
                  <Grid item xs={12}>
                    <FieldLabel title="Email Address" required />
                    <Controller
                      control={control}
                      name="email"
                      render={({
                        field: {
                          onChange, value, name, ref,
                        }, fieldState: { error },
                      }) => (
                        <TextInputField
                          fullWidth
                          inputRef={ref}
                          name={name}
                          value={value}
                          error={!!error}
                          onChange={onChange}
                          variant="outlined"
                          placeholder="Enter Email Address"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Controller
                        name="sendMeACopy"
                        control={control}
                        render={({ field: { onChange, value, ref } }) => (
                          <CustomCheckbox
                            onChange={onChange}
                            checked={value}
                            inputRef={ref}
                          />
                        )}
                      />
                      <Typography variant="body1" sx={{ fontWeight: 400 }}>
                        Send me a copy
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ marginTop: '16px' }}>
                    <FieldLabel title="Subject" required />
                    <Controller
                      control={control}
                      name="subject"
                      render={({
                        field: {
                          onChange, value, name, ref,
                        }, fieldState: { error },
                      }) => (
                        <TextInputField
                          fullWidth
                          inputRef={ref}
                          name={name}
                          value={value}
                          error={!!error}
                          onChange={onChange}
                          variant="outlined"
                          placeholder="Enter Email Subject"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FieldLabel title="Share" />
                    <Box
                      display="flex"
                      flexDirection={isMobile ? 'column' : 'row'}
                      alignItems={isMobile ? 'start' : 'center'}
                      columnGap="24px"
                      rowGap="16px"
                    >
                      <Box display="flex" alignItems="center" sx={{ minWidth: 134 }}>
                        <Controller
                          name="sendNotes"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <CustomCheckbox
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 400 }}>
                          Notes
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" sx={{ minWidth: 134 }}>
                        <Controller
                          name="sendSummary"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <CustomCheckbox
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 400 }}>
                          Summary
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center" sx={{ minWidth: 134 }}>
                        <Controller
                          name="sendFullTranscript"
                          control={control}
                          render={({ field: { onChange, value, ref } }) => (
                            <CustomCheckbox
                              onChange={onChange}
                              checked={value}
                              inputRef={ref}
                            />
                          )}
                        />
                        <Typography variant="body1" sx={{ fontWeight: 400 }}>
                          Full Transcript
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Box>
        </ScrollContainer>
        <FooterBlock>
          <ImportFullButton
            variant="contained"
            fullWidth
            onClick={handleSubmit(onSubmit)}
            disabled={
              isSubmitting
              || !isValid
              || !dirtyFields.email
              || subjectField === null
              || !(sendNotesField || sendSummaryField || sendFullTranscriptField)
            }
          >
            <CheckCircleIcon style={{ marginRight: '12px' }} />
            Share Content
          </ImportFullButton>
        </FooterBlock>
      </DrawerContainer>
    </Box>
  );
};

export default ShareContent;
