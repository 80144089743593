import { styled } from '@mui/material/styles';
import {
  Box, Button, IconButton, FormControl, Tabs,
} from '@mui/material';
import palette from '../../theme/palette';

const headerHeight = 72;

export const Main = styled('main')({
  flexGrow: 1,
  backgroundColor: palette.colors.gray01,
  marginTop: `${headerHeight}px`,
});

export const CardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  marginBottom: '32px',
  borderRadius: '32px',
  backgroundColor: palette.white,
  [theme.breakpoints.down('md')]: {
    borderRadius: '8px',
    marginLeft: '15px',
    marginRight: '15px',
  },
}));

export const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 48px',
  [theme.breakpoints.down('md')]: {
    padding: '16px 15px',
  },
}));

export const CancelBtn = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 250ms',
  '&:hover': {
    opacity: 0.85,
  },
});

export const UpdateBtn = styled(Button)({
  width: 178,
  backgroundColor: palette.colors.success03,
  '& .MuiButton-startIcon': {
    fontSize: '24px',
  },
  '&:hover': {
    backgroundColor: palette.colors.success04,
  },
  '&:active': {
    backgroundColor: palette.colors.success04,
  },
  '&:disabled': {
    backgroundColor: palette.colors.success03,
    opacity: '0.5',
  },
});

export const EditBtn = styled(Button)({
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '9px 10px',
  borderColor: palette.primary.main,
  borderRadius: '12px',
  '& .MuiButton-startIcon': {
    marginLeft: 0,
    '& svg': {
      fontSize: '24px',
    },
  },
  '&:hover': {
    color: palette.white,
    backgroundColor: palette.primary.main,
  },
  '&:active': {
    color: palette.white,
    backgroundColor: palette.primary.main,
  },
});

export const LabelBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  minWidth: 370,
  fontSize: '24px',
  fontWeight: 500,
  lineHeight: '28px',
  color: palette.colors.gray05,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'start',
    minWidth: 300,
    fontSize: '18px',
    lineHeight: '24px',
  },
}));

export const DataBox = styled(Box)(({ theme }) => ({
  display: 'block',
  minWidth: 370,
  maxWidth: 370,
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '28px',
  color: palette.colors.gray10,
  textAlign: 'left',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  [theme.breakpoints.down('md')]: {
    minWidth: 300,
    maxWidth: 300,
    fontSize: '18px',
    lineHeight: '24px',
  },
}));

export const CameraBtn = styled(IconButton)({
  position: 'absolute',
  right: 0,
  bottom: 0,
  padding: 0,
  opacity: 0.7,
  transition: 'all 250ms',
  '&:hover': {
    opacity: 1,
  },
});

export const SelectControl = styled(FormControl)({
  '& .MuiInputBase-input': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const CancelButton = styled(Button)(({ theme }) => ({
  borderColor: palette.colors.gray05,
  borderRadius: '15px',
  color: palette.colors.gray05,
  width: 200,
  height: 58,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '10px 12px',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: palette.colors.gray05,
    borderColor: palette.colors.gray05,
    color: palette.white,
  },
  [theme.breakpoints.down('md')]: {
    width: 250,
    marginRight: 0,
  },
}));

export const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: palette.primary.main,
  borderRadius: '15px',
  width: 200,
  height: 58,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '10px 12px',
  '&:hover': {
    backgroundColor: palette.primary.dark,
  },
  [theme.breakpoints.down('md')]: {
    width: 250,
    marginBottom: '16px',
  },
}));

export const UserTabs = styled(Tabs)({
  width: 148,
  marginRight: '48px',
  '& .MuiTab-root': {
    minHeight: 32,
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '18px',
    letterSpacing: 0,
    padding: 0,
    margin: '8px',
    color: palette.colors.gray07,
    '&.Mui-selected': {
      backgroundColor: palette.primary.main,
      color: palette.white,
      borderRadius: '16px',
    },
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
});
