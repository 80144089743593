import React, { useEffect } from 'react';

// * MUI
import { Box, FormHelperText } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface MessageDisplayProps {
  message:
    | {
        severity: 'error' | 'success'
        text: string
        timer?: number
      }
    | undefined
  clearMessage: () => void
}

const MessageDisplay = ({ message, clearMessage }: MessageDisplayProps) => {
  useEffect(() => {
    if (message?.timer) {
      const timerId = setTimeout(() => {
        clearMessage();
      }, message.timer);

      return () => clearTimeout(timerId);
    }

    return undefined;
  }, [message?.timer, clearMessage]);

  if (!message) {
    return null;
  }

  const Icon = message.severity === 'error' ? ReportIcon : CheckCircleIcon;
  const color = message.severity === 'error' ? 'error' : 'success';

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Icon fontSize="small" color={color} />
      <FormHelperText
        sx={{
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '22px',
          m: 0,
          color: `${color}.main`,
        }}
      >
        {message.text}
      </FormHelperText>
    </Box>
  );
};

export default MessageDisplay;
