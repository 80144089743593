import * as React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { ReactComponent as TranscriptionInProgressImg } from '../../assets/img/transcriptionInProgressImg.svg';
import { ReactComponent as TranscriptionFailedImg } from '../../assets/img/transcriptionFailedImg.svg';

type TranscriptionImageBlockProps = {
  failed: boolean;
};

const TranscriptionImageBlock = ({ failed }: TranscriptionImageBlockProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: '50px 0',
    }}
  >
    {failed ? <TranscriptionFailedImg /> : <TranscriptionInProgressImg />}
    <Typography
      variant="h5"
      sx={{ marginTop: '27px' }}
    >
      {failed ? 'Transcription Failed' : 'Processing…'}
    </Typography>
    {failed ? (
      <>
        <Typography
          variant="body1"
          color="colors.gray06"
          align="center"
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            marginTop: '8px',
          }}
        >
          <p>We apologies for your inconvenience.</p>
          <p>You can reach out to tech support to regenerate this transcription.</p>
        </Typography>
        <Link
          href="mailto:support@encorecompliance.com"
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            textTransform: 'none !important',
          }}
        >
          support@encorecompliance.com
        </Link>
      </>
    ) : (
      <Typography
        variant="body1"
        color="colors.gray06"
        sx={{
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '22px',
          marginTop: '8px',
        }}
      >
        This process usually takes 5-10 mins.
      </Typography>
    )}
  </Box>
);

export default TranscriptionImageBlock;
