import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import palette from '../../../theme/palette';

export const RelatedBox = styled(Box)({
  backgroundColor: palette.colors.gray01,
  borderRadius: '8px',
  marginTop: '16px',
  width: '100%',
});

export const SliderBtn = styled(Box)({
  cursor: 'pointer',
});

export const CallItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: 320,
  minWidth: 320,
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '12px',
  backgroundColor: palette.white,
  padding: '16px 20px',
  marginRight: '12px',
  cursor: 'pointer',
  position: 'relative',
  transition: 'all 250ms',
  '&:hover': {
    borderColor: '#70BDFF',
    boxShadow: '0px 4px 13px rgba(188, 188, 188, 0.25)',
  },
});

export const NetworkLabel = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});

export const NetworkValue = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700,
  height: '20px',
  maxHeight: '20px',
  lineHeight: '20px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const CallTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 600,
  height: '72px',
  maxHeight: '72px',
  lineHeight: '24px',
  display: '-webkit-box',
  '-webkit-line-clamp': '3',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
});
