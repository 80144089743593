/* eslint-disable no-unused-vars */
import React, { KeyboardEvent } from 'react';

// * MUI
import {
  IconButton,
  InputAdornment,
  SxProps,
  Theme,
} from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';
import DoneIcon from '@mui/icons-material/Done';
import { TopicTextField } from '../styledComponents';

interface EditModeTextFieldProps {
  editedName: string
  setEditedName: (name: string) => void
  handleKeyDown: (e: KeyboardEvent<HTMLDivElement>) => void
  handleSave: () => Promise<void>
  textFieldStyles: SxProps<Theme>
  placeholder: string
  loading: boolean
}

const EditModeTextField = ({
  editedName,
  setEditedName,
  handleKeyDown,
  handleSave,
  textFieldStyles,
  placeholder,
  loading,
}: EditModeTextFieldProps) => {
  const Icon = loading ? PendingIcon : DoneIcon;

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton edge="end" onClick={loading ? undefined : handleSave}>
          <Icon color="primary" />
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <TopicTextField
      value={editedName}
      required
      onChange={e => setEditedName(e.target.value)}
      onKeyDown={handleKeyDown}
      autoFocus
      placeholder={placeholder}
      InputProps={inputProps}
      sx={textFieldStyles}
    />
  );
};

export default EditModeTextField;
