import { styled } from '@mui/material/styles';
import {
  Avatar as MUIAvatar, Box, Checkbox, InputBase, Typography,
} from '@mui/material';
import palette from '../../theme/palette';

export const AvatarContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 36,
  minWidth: 36,
  height: 36,
});

export const Avatar = styled(MUIAvatar)({
  width: '30px',
  height: '30px',
  fontSize: '14px',
  color: palette.white,
  backgroundColor: palette.secondary.main,
  margin: '3px',
});

export const EmptyTypography = styled(Typography)({
  fontWeight: 600,
  lineHeight: '36px',
  textTransform: 'none',
  marginRight: '4px',
});

export const SelectedTypography = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  minHeight: 36,
  whiteSpace: 'normal',
  textTransform: 'none',
  marginRight: '4px',
});

export const CustomCheckbox = styled(Checkbox)({
  padding: 0,
  paddingRight: '6px',
  '&:hover': { backgroundColor: 'transparent' },
});

export const AnalystInput = styled(InputBase)({
  lineHeight: '22px',
  border: 'none',
  '& .MuiInputBase-input': { padding: 0 },
  '& .MuiSelect-icon': { color: palette.colors.gray06 },
});

export const NameContainer = styled(Typography)({
  fontSize: '14px',
  lineHeight: '32px',
  marginLeft: '4px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});
