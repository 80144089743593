import { styled } from '@mui/material/styles';
import { Button, Menu, SwipeableDrawer } from '@mui/material';
import palette from '../../theme/palette';

export const DropdownButton = styled(Button)({
  minWidth: '230px',
  justifyContent: 'space-between',
  color: palette.colors.gray04,
  '&:hover': {
    backgroundColor: 'transparent',
    color: palette.colors.gray04,
    textDecoration: 'none',
  },
  '&:active': {
    backgroundColor: 'transparent',
    color: palette.colors.gray04,
    textDecoration: 'none',
  },
});

export const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    padding: '12px 24px',
    border: '1px solid #E6EAEE',
    boxShadow: '0px 8px 9px rgba(61, 61, 61, 0.14)',
    borderRadius: '8px',
    minWidth: '180px',
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: '12px 0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
});

export const Drawer = styled(SwipeableDrawer)({
  zIndex: 1300,
});
