import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography } from '@mui/material';
import { TickerType } from '../../shared/types/transcript';

type RelatedCompaniesListProps = {
  tickers: TickerType[];
};

const RelatedCompaniesList = ({
  tickers,
}: RelatedCompaniesListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {tickers.map(ticker => {
        if (ticker.isPrimary) {
          return (
            <Box
              key={ticker.ticker.title}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mb="12px"
            >
              <Typography
                variant="body1"
                color="colors.gray08"
                sx={{ fontSize: isMobile ? '14px' : '16px', fontWeight: 400, lineHeight: '22px' }}
              >
                {ticker.ticker.companyName}
              </Typography>
              <Typography
                variant="body1"
                color="primary"
                sx={{ fontSize: isMobile ? '14px' : '16px', fontWeight: 400, lineHeight: '22px' }}
              >
                {ticker.ticker.exchange ? (
                  `${ticker.ticker.exchange}:${ticker.ticker.title}`
                ) : (
                  ticker.ticker.title
                )}
              </Typography>
            </Box>
          );
        }
        return null;
      })}
    </>
  );
};

export default RelatedCompaniesList;
