/* eslint-disable import/order */
import { useContext } from 'react';

// * MUI
import { Box, Grid } from '@mui/material';

// * Components
import { CallDetailsItem, ComplianceReviewer } from '../../components';

// * Hooks & Utilss
import { useMutation } from '@apollo/client';

// * Queries
import { UPDATE_REVIEWERS_MUTATION } from '../../queries/transcript/transcript';

// * Interfaces
import type { CallType, ReviewType } from '../../shared/types/transcript';
import type { UserType } from '../../shared/types/user';
import { AppContext } from '../../components/AppContextProvider';

interface ReviewFlagsProps {
  review: ReviewType
  disabledBtn: boolean
  me: UserType
  call: CallType
}

const ReviewFlags = ({
  review, disabledBtn, me, call,
}: ReviewFlagsProps) => {
  const [updateReviewers] = useMutation(UPDATE_REVIEWERS_MUTATION);
  const { refetchTranscript } = useContext(AppContext);

  const assignUser = async (flag: 'liveChaperoneReviewerUuid' | 'fullTranscriptReviewerUuid', { userUuid, instanceUuid }: { userUuid?: string, instanceUuid: string }) => {
    const variables: {
      reviewUuid: string
      liveChaperoneReviewerUuid?: string
      fullTranscriptReviewerUuid?: string
    } = {
      reviewUuid: instanceUuid,
      liveChaperoneReviewerUuid: '',
      fullTranscriptReviewerUuid: '',
    };

    if (flag === 'liveChaperoneReviewerUuid') {
      variables.liveChaperoneReviewerUuid = userUuid;
      variables.fullTranscriptReviewerUuid = review.fullTranscriptReviewer?.uuid;
    } else if (flag === 'fullTranscriptReviewerUuid') {
      variables.fullTranscriptReviewerUuid = userUuid;
      variables.liveChaperoneReviewerUuid = review.liveChaperoneReviewer?.uuid;
    }

    updateReviewers({
      variables,
      onCompleted: () => {
        refetchTranscript?.();
      },
    });
  };

  return (
    <Box sx={{ display: 'flex', gap: '16px' }}>
      <Box display={(!review.liveChaperoneReviewer?.uuid && disabledBtn) ? 'none' : 'flex'}>
        <Grid item xs={12} md={6}>
          <CallDetailsItem title="Live Chaperone">
            <ComplianceReviewer
              assignUser={(data: any) => assignUser('liveChaperoneReviewerUuid', data.variables)}
              instanceUuid={call.review.uuid}
              initialAssignee={call.review.liveChaperoneReviewer}
              signedInUserUuid={me.uuid}
              placeholder="Unassigned"
              disabled={false}
            />
          </CallDetailsItem>
        </Grid>
      </Box>
      <Box display={(!review.fullTranscriptReviewer?.uuid && disabledBtn) ? 'none' : 'flex'}>
        <Grid item xs={12} md={6}>
          <CallDetailsItem title="Full Transcript Review">
            <ComplianceReviewer
              assignUser={(data: any) => assignUser('fullTranscriptReviewerUuid', data.variables)}
              instanceUuid={call.review.uuid}
              initialAssignee={call.review.fullTranscriptReviewer}
              signedInUserUuid={me.uuid}
              placeholder="Unassigned"
              disabled={false}
            />
          </CallDetailsItem>
        </Grid>
      </Box>
    </Box>
  );
};

export default ReviewFlags;
