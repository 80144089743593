import React from 'react';

// * MUI
import { Box, Typography, Button } from '@mui/material';

// * Utils
import { useMutation, ApolloQueryResult } from '@apollo/client';

// * Icons
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// * Mutations
import { GENERATE_TOPIC_SUMMARY } from '../../../../queries/topics';

// * Interfaces
import type { Topic } from '../../../../components/TopicCallsDrawer/types';
import type { TopicType } from '../../../../shared/types/topics';

interface SummaryFailedProps {
  topic: Topic
  refetchTopic: () => Promise<ApolloQueryResult<{ topic: TopicType; }>>
}

const SummaryFailed = ({ topic, refetchTopic }: SummaryFailedProps) => {
  const [generateTopicSummary,
    { loading: generateTopicSummaryLoading },
  ] = useMutation<{ generateTopicSummary: boolean }>(GENERATE_TOPIC_SUMMARY);

  const generateSummary = async () => {
    await generateTopicSummary({
      variables: { uuid: topic.uuid },
    });
    await refetchTopic();
  };

  return (
    <Box textAlign="center" p={5}>
      <ErrorOutlineIcon sx={{ fontSize: 60, color: 'error.main' }} />
      <Typography variant="h6" color="error" gutterBottom>
        Summary Generation Failed
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        There was a problem generating the summary.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={generateSummary}
        disabled={generateTopicSummaryLoading}
      >
        Try Again
      </Button>
    </Box>
  );
};

export default SummaryFailed;
