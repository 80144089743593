import { styled } from '@mui/material/styles';
import {
  Box, Button, TextField, Checkbox, FormControl,
} from '@mui/material';
import palette from '../../theme/palette';

export const CardHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '32px 48px',
});

export const NotesField = styled(TextField)({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    borderRadius: '7px',
    padding: '0 12px',
    '& .MuiOutlinedInput-input': {
      padding: '12px 0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#C7CEFF',
    },
  },
});

export const UpdateBtn = styled(Button)({
  width: 178,
  backgroundColor: palette.colors.success03,
  '& .MuiButton-startIcon': {
    fontSize: '24px',
  },
  '&:hover': {
    backgroundColor: palette.colors.success04,
  },
  '&:active': {
    backgroundColor: palette.colors.success04,
  },
  '&:disabled': {
    backgroundColor: palette.colors.success03,
    opacity: '0.5',
  },
});

export const EditBtn = styled(Button)({
  fontSize: '16px',
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '9px 10px',
  borderColor: palette.primary.main,
  borderRadius: '12px',
  '& .MuiButton-startIcon': {
    marginLeft: 0,
    '& svg': {
      fontSize: '24px',
    },
  },
  '&:hover': {
    color: palette.white,
    backgroundColor: palette.primary.main,
  },
  '&:active': {
    color: palette.white,
    backgroundColor: palette.primary.main,
  },
});

export const CancelButton = styled(Button)({
  borderColor: palette.colors.gray05,
  borderRadius: '15px',
  color: palette.colors.gray05,
  width: 200,
  height: 58,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '10px 12px',
  marginRight: '20px',
  '&:hover': {
    backgroundColor: palette.colors.gray05,
    borderColor: palette.colors.gray05,
    color: palette.white,
  },
});

export const SubmitButton = styled(Button)({
  backgroundColor: palette.primary.main,
  borderRadius: '15px',
  width: 200,
  height: 58,
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '10px 12px',
  '&:hover': {
    backgroundColor: palette.primary.dark,
  },
});

export const CancelBtn = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'all 250ms',
  '&:hover': {
    opacity: 0.85,
  },
});

export const CustomCheckbox = styled(Checkbox)({
  padding: '4px',
  marginRight: '16px',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-checked.Mui-disabled': {
    color: palette.primary.main,
    opacity: 0.5,
  },
});

export const SelectControl = styled(FormControl)({
  '& .MuiInputBase-input': {
    display: 'flex',
    alignItems: 'center',
  },
});

export const DigitsField = styled(TextField)({
  maxWidth: 145,
  '& .MuiOutlinedInput-input': {
    textAlign: 'right',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  '& .MuiInputAdornment-positionEnd': {
    marginLeft: '5px',
  },
});

export const EmailBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 600,
  borderRadius: '15px',
  border: '1px solid',
  borderColor: palette.colors.gray01,
  padding: '14px 18px',
});

export const EmailItemBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '8px',
  padding: '0 6px',
  '& .deleteIcon': {
    display: 'none',
  },
  '&.active:hover': {
    backgroundColor: palette.colors.gray01,
    borderRadius: '6px',
    '& .deleteIcon': {
      display: 'block',
      cursor: 'pointer',
    },
  },
});
