import { styled } from '@mui/material/styles';
import {
  Box, Button, IconButton, SwipeableDrawer, TextField, Typography,
} from '@mui/material';
import palette from '../../theme/palette';

export const AudioBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  border: '1px solid',
  color: palette.primary.main,
  borderColor: palette.primary.main,
  borderRadius: '9px',
  padding: '12px',
  height: '64px',
  width: '100%',
  marginBottom: '18px',
});

export const Paragraph = styled(Box)({
  display: 'flex',
  minHeight: 78,
  border: '1px solid',
  borderColor: palette.white,
  borderRadius: '12px',
  padding: '14px 11px',
  margin: '5px 0',
  position: 'relative',
  transition: 'all 250ms',
  '&.editable-paragraph-item:hover': {
    borderColor: palette.colors.gray03,
    backgroundColor: palette.colors.gray01,
    '& .edit-button': {
      display: 'flex',
    },
  },
  '&.selected-paragraph-item': {
    borderColor: palette.colors.primary05,
    '&:hover': {
      borderColor: palette.colors.primary05,
    },
  },
});

export const TimestampBlock = styled(Box)({
  display: 'flex',
  position: 'relative',
  width: '73px',
  minWidth: '73px',
  marginRight: '32px',
});

export const DashedLine = styled(Box)({
  position: 'absolute',
  top: 16,
  bottom: 16,
  left: 7,
  borderRight: '1px dashed',
  borderRightColor: palette.colors.gray04,
});

export const StartTime = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 30,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '15px',
  color: palette.colors.gray09,
});

export const EndTime = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 30,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '15px',
  color: palette.colors.gray09,
});

export const Time = styled(Box)({
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '15px',
  color: palette.colors.gray09,
});

export const TranscriptText = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: palette.colors.gray09,
});

export const EditButton = styled(Button)({
  display: 'none',
  position: 'absolute',
  width: '74px',
  height: '32px',
  top: '-32px',
  right: '30px',
  backgroundColor: palette.colors.gray08,
  color: palette.white,
  padding: '4px 10px',
  borderRadius: '8px 8px 0 0',
  cursor: 'pointer',
  alignItems: 'center',
  fontSize: '14px',
  '& .MuiButton-startIcon': {
    marginRight: '5px',
  },
  '&:hover': {
    backgroundColor: palette.colors.gray07,
    color: palette.white,
    textDecoration: 'none',
  },
});

export const EditedByText = styled(Box)({
  fontSize: '12px',
  fontWeight: 400,
  color: palette.colors.gray05,
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginRight: '4px',
  },
  marginTop: '8px',
});

export const Drawer = styled(SwipeableDrawer)({
  width: 850,
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: 850,
    boxSizing: 'border-box',
    boxShadow: '-11px 0px 60px rgba(0, 0, 0, 0.25)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
});

export const UpdateTranscriptButton = styled(Button)({
  color: palette.white,
  borderRadius: '8px',
  height: 52,
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '29px',
  letterSpacing: 0,
  padding: '12px 16px',
});

export const EditTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.colors.gray03,
    },
  },
});

export const ScrollContainer = styled(Box)({
  height: '100%',
  overflowY: 'auto',
  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const FooterBlock = styled(Box)({
  borderTop: '1px solid',
  borderTopColor: palette.colors.gray03,
  backgroundColor: palette.colors.gray01,
  padding: '16px 32px',
});

export const CloseButton = styled(IconButton)({
  padding: 0,
  marginRight: '20px',
});

export const MemberTypography = styled(Typography)({
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  height: '44px',
  maxHeight: '44px',
});
