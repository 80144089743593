import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLazyQuery } from '@apollo/client';
import {
  Box, Divider, Tab, Typography,
} from '@mui/material';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { ReactComponent as CloseIcon } from '../../assets/icons/closeIcon.svg';
import { ReactComponent as ChevronRightCircleIcon } from '../../assets/icons/chevronRightCircleIcon.svg';
import { ReactComponent as AltArrowLeftIcon } from '../../assets/icons/altArrowLeftIcon.svg';
import MoreDetailsTabPanel from '../Tabs/MoreDetailsTabPanel';
import MoreDetailsError from './components/MoreDetailsError';
import MoreDetailsNotAvailable from './components/MoreDetailsNotAvailable';
import { MORE_DETAIL_ITEM_QUERY } from '../../queries/transcript/transcript';
import {
  DrawerContainer, CloseButton, ScrollContainer, MoreBtn, DrawerTabs,
  Accordion, AccordionDetails, moreDetailsA11yProps,
  AccordionSummary,
} from './styledComponents';
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';
import { useMe } from '../AppContextProvider';

type MoreDetailsDrawerProps = {
  type: string;
  modelName: string;
  modelId: number;
  children: React.ReactNode;
};

type MoreDetailsItems = {
  'More Details': string[];
  'Transcript Quotes': string[] | {
    'text': string;
    'datetime': Date;
    'expert': string;
    'topic': string;
  }[];
} | null;

const MoreDetailsDrawer = ({
  type, modelName, modelId, children,
}: MoreDetailsDrawerProps) => {
  const { me } = useMe();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [isOpenMoreDetailsSidebar, setIsOpenMoreDetailsSidebar] = useState(false);
  const [moreDetailsTabIndex, setMoreDetailsTabIndex] = React.useState(0);
  const singularType: Record<string, string> = { 'Key Insights': 'Key Insight', Opportunities: 'Opportunity' };

  const [
    getMoreDetailItem,
    { data: { moreDetailItem = null } = {} },
  ] = useLazyQuery<{
    moreDetailItem: { jsonValue: string } | null
  }>(MORE_DETAIL_ITEM_QUERY, {
    fetchPolicy: 'network-only',
  });

  const value: MoreDetailsItems = moreDetailItem && moreDetailItem.jsonValue
    ? JSON.parse(moreDetailItem.jsonValue) : null;

  const moreDetails = value ? value['More Details'] : [];
  const quotes = value ? value['Transcript Quotes'] : [];

  useEffect(() => {
    if (isOpenMoreDetailsSidebar) {
      getMoreDetailItem({ variables: { modelName, modelId, keyPhrase: children } });
    }
    // eslint-disable-next-line
  }, [isOpenMoreDetailsSidebar]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    event.stopPropagation();
    setIsOpenMoreDetailsSidebar(open);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setMoreDetailsTabIndex(newValue);
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Box>{children}</Box>
      <MoreBtn onClick={toggleDrawer(true)}>
        <ChevronRightCircleIcon />
      </MoreBtn>
      <DrawerContainer
        anchor="right"
        open={isOpenMoreDetailsSidebar}
        onClose={toggleDrawer(false)}
      >
        <ScrollContainer>
          <Box display="flex" flexDirection="column" height="100%">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={isMobile ? '88px 20px 16px' : '24px'}>
              <Box display="flex" alignItems="center">
                {isMobile && (
                  <CloseButton onClick={toggleDrawer(false)} sx={{ marginRight: '12px' }}>
                    <AltArrowLeftIcon />
                  </CloseButton>
                )}
                <Typography variant={isMobile ? 'h6' : 'h5'} color="colors.gray10">
                  More Details
                </Typography>
              </Box>
              {!isMobile && (
                <CloseButton onClick={toggleDrawer(false)} sx={{ marginLeft: '12px' }}>
                  <CloseIcon />
                </CloseButton>
              )}
            </Box>
            <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
            <Box display="flex" flexDirection="column" p={isMobile ? '20px' : '24px'}>
              <Typography variant="caption" color="colors.gray06" mb="8px" sx={{ fontWeight: isMobile ? 400 : 500 }}>
                {singularType[type]}
              </Typography>
              <Typography
                variant={isMobile ? 'subtitle2' : 'body1'}
                color="colors.gray08"
                p={isMobile ? 0 : '12px'}
                sx={{ fontWeight: 400 }}
              >
                {children}
              </Typography>
            </Box>
            <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
            <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'colors.gray03' }}>
              <DrawerTabs value={moreDetailsTabIndex} onChange={handleTabChange} aria-label="more-details-tabs">
                <Tab label="Quotes" {...moreDetailsA11yProps(0)} />
                <Tab label="More Details" {...moreDetailsA11yProps(1)} />
                {/*
                <Tab label="Expectation Variance" {...moreDetailsA11yProps(2)} />
                */}
              </DrawerTabs>
            </Box>
            <Box display="flex" flexDirection="column" flexGrow={1} p={isMobile ? '20px' : '24px'}>
              <MoreDetailsTabPanel value={moreDetailsTabIndex} index={1}>
                {value ? (
                  <Box display="flex" flexDirection="column" flexGrow="1">
                    {moreDetails ? (
                      <Box display="flex" flexDirection="column" flexGrow="1">
                        {Array.isArray(moreDetails) ? moreDetails.map(item => (
                          <Accordion key={item} elevation={0} defaultExpanded>
                            {/* <AccordionSummary id={`${item}-header`} expandIcon={<ExpandCircleDownOutlinedIcon />}>
                              <Typography variant="h6" mr="12px" sx={{ fontSize: isMobile ? '16px' : '18px' }}>
                                {item}
                              </Typography>
                            </AccordionSummary> */}
                            {/* <Divider flexItem sx={{ borderColor: 'colors.gray01', margin: '0 16px 8px' }} /> */}
                            {/* <AccordionDetails>
                              <List dense sx={{ listStyleType: 'disc', padding: '0 0 0 24px' }}>
                                {item.items?.map((subItem: string) => (
                                  <ListItem disableGutters key={subItem} sx={{ display: 'list-item' }}>
                                  <Typography variant="subtitle1" sx={{
                                    fontWeight: 400, fontSize: isMobile ? '14px' : '16px'
                                  }}>
                                      {subItem}
                                    </Typography>
                                  </ListItem>
                                ))}
                              </List>
                            </AccordionDetails> */}
                            <AccordionDetails>
                              <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: isMobile ? '14px' : '16px' }}>
                                {item}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        )) : (
                          <MoreDetailsError />
                        )}
                      </Box>
                    ) : (
                      <MoreDetailsNotAvailable />
                    )}
                  </Box>
                ) : (
                  <MoreDetailsNotAvailable />
                )}
              </MoreDetailsTabPanel>
              <MoreDetailsTabPanel value={moreDetailsTabIndex} index={0}>
                {value ? (
                  <Box display="flex" flexDirection="column" flexGrow="1">
                    {quotes ? (
                      <Box display="flex" flexDirection="column" flexGrow="1">
                        {Array.isArray(quotes) ? quotes.map(item => (
                          typeof item === 'string' ? (
                            <Accordion key={item} elevation={0} defaultExpanded>
                              <AccordionDetails>
                                <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: isMobile ? '14px' : '16px' }}>
                                  {item}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <Accordion key={item.datetime?.toString()} elevation={0} defaultExpanded>
                              <AccordionSummary id="transcript-header" expandIcon={<ExpandCircleDownOutlinedIcon />}>
                                <Typography
                                  variant="subtitle2"
                                  color="primary.main"
                                  m="0 12px 4px 0"
                                  sx={{ fontSize: isMobile ? '16px' : '18px' }}
                                >
                                  {item.topic}
                                </Typography>
                                <Box display="flex">
                                  <Typography variant="caption" color="colors.gray06" mr="12px">
                                    {formatDateTimeToUserTimezone(item.datetime, me.timezone)}
                                  </Typography>
                                  <Typography variant="caption" color="colors.gray06">
                                    Expert Name:
                                    {' '}
                                    {item.expert ? item.expert : 'N/A'}
                                  </Typography>
                                </Box>
                              </AccordionSummary>
                              <Divider flexItem sx={{ borderColor: 'colors.gray01', margin: '0 16px 8px' }} />
                              <AccordionDetails>
                                <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: isMobile ? '14px' : '16px' }}>
                                  {item.text}
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                          )
                        )) : (
                          <MoreDetailsError />
                        )}
                      </Box>
                    ) : (
                      <MoreDetailsNotAvailable />
                    )}
                  </Box>
                ) : (
                  <MoreDetailsNotAvailable />
                )}
              </MoreDetailsTabPanel>
              {/*
              <MoreDetailsTabPanel value={moreDetailsTabIndex} index={2}>
                Content 3
              </MoreDetailsTabPanel>
              */}
            </Box>
          </Box>
        </ScrollContainer>
      </DrawerContainer>
    </Box>
  );
};

export default MoreDetailsDrawer;
