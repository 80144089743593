import React, { useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Box, Typography,
} from '@mui/material';
import Slider from 'react-slick';
import { RelatedBox, SliderBtn } from './components/styledComponents';
import RelatedCallItem from './components/relatedCallItem';
import { RelatedCallType } from '../../shared/types/transcript';
import { ReactComponent as ChevronLeftCircleIcon } from '../../assets/icons/chevronLeftCircleIcon.svg';
import { ReactComponent as ChevronRightCircleIcon } from '../../assets/icons/chevronRightCircleIcon.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

type RelatedCallsProps = {
  relatedCalls: RelatedCallType[];
  // eslint-disable-next-line
  onSelect: (uuid: string) => void;
};

const RelatedCalls = ({
  relatedCalls, onSelect,
}: RelatedCallsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const sliderRef = useRef<Slider>(null);
  const next = () => {
    sliderRef.current?.slickNext();
  };
  const previous = () => {
    sliderRef.current?.slickPrev();
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  return (
    <RelatedBox sx={{ padding: isMobile ? '12px 8px' : '12px 16px' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb="8px">
        <Typography variant="caption" color="colors.gray06">
          Related Calls (
          {relatedCalls.length}
          )
        </Typography>
        <Box display="flex" alignItems="center">
          <SliderBtn display="flex" mr="4px" onClick={previous}>
            <ChevronLeftCircleIcon />
          </SliderBtn>
          <SliderBtn display="flex" onClick={next}>
            <ChevronRightCircleIcon />
          </SliderBtn>
        </Box>
      </Box>
      <Box>
        <Slider ref={sliderRef} {...settings}>
          {relatedCalls.map(relatedCall => (
            <RelatedCallItem
              key={relatedCall.transcript.uuid}
              type={relatedCall.type}
              uuid={relatedCall.transcript.uuid}
              name={relatedCall.name}
              dateTime={relatedCall.datetime}
              expertName={relatedCall.expertName}
              network={relatedCall.network}
              onClick={() => onSelect(relatedCall.transcript.uuid)}
            />
          ))}
        </Slider>
      </Box>
    </RelatedBox>
  );
};

export default RelatedCalls;
