/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';

// * MUI
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, InputAdornment } from '@mui/material';

// * Utils
import { debounce } from 'lodash';
import type { DebouncedFunc } from 'lodash';

// * Icons
import SearchIcon from '@mui/icons-material/Search';

// * Components
import { ContentHeaderContainer, SearchField } from '../styledComponents';

interface ContentHeaderPropsBase {
  title: string
  length: number
}

interface ContentHeaderPropsWithSearch extends ContentHeaderPropsBase {
  enableSearch: true
  onSearch: (query: string) => void
  disabled: boolean
}

interface ContentHeaderPropsWithoutSearch extends ContentHeaderPropsBase {
  enableSearch: false
}

type ContentHeaderProps =
  | ContentHeaderPropsWithSearch
  | ContentHeaderPropsWithoutSearch

const ContentHeader: React.FC<ContentHeaderProps> = props => {
  const {
    title, length, enableSearch,
  } = props;
  const debouncedSearchRef = useRef<DebouncedFunc<(query: string) => void>>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    debouncedSearchRef.current = debounce((query: string) => {
      if (enableSearch && 'onSearch' in props) {
        const { onSearch } = props;
        onSearch(query);
      }
    }, 300);

    return () => {
      debouncedSearchRef.current?.cancel();
    };
  }, [enableSearch, props]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearchRef.current?.(event.target.value);
  };

  return (
    <ContentHeaderContainer>
      <Typography variant={isMobile ? 'h6' : 'h5'} color="colors.gray10" component="div">
        {`${title} (${length})`}
      </Typography>
      {enableSearch && 'onSearch' in props && (
        <SearchField
          size="small"
          placeholder="Search"
          // eslint-disable-next-line react/destructuring-assignment
          disabled={'disabled' in props && props.disabled}
          onChange={handleSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
            style: { color: '#566E91', borderRadius: 4, maxWidth: isMobile ? 200 : 'auto' },
          }}
        />
      )}
    </ContentHeaderContainer>
  );
};

export default ContentHeader;
