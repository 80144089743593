import React from 'react';
import Box from '@mui/material/Box';

type TabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`sidebar-tabpanel-${index}`}
      aria-labelledby={`sidebar-tab-${index}`}
      {...other}
      display="flex"
      flexGrow={value !== index ? '0' : '1'}
    >
      {value === index && (
        <Box
          display="flex"
          flexDirection="column"
          flexGrow="1"
          pt="12px"
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default TabPanel;
