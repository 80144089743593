import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import FiltersDropdown from './FiltersDropdown';
import type { FilterProps } from '../filterLogic';

interface FiltersSectionProps extends FilterProps {
  tabIndex: number;
  showTopDivider?: boolean;
  sectionsTohide?: string[];
}

const FiltersSection = (props: FiltersSectionProps) => {
  const { showTopDivider, sectionsTohide = [] } = props;

  return (
    <>
      {showTopDivider && (
        <Divider flexItem sx={{ margin: '8px 0 6px', borderColor: 'colors.gray03' }} />
      )}
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" color="colors.gray06">
          Filters
        </Typography>
        <FiltersDropdown {...props} sectionsToHide={sectionsTohide} />
      </Box>
      <Divider flexItem sx={{ margin: '6px 0 0', borderColor: 'colors.gray03' }} />
    </>
  );
};

FiltersSection.defaultProps = {
  showTopDivider: true,
  sectionsTohide: [],
};

export default FiltersSection;
