import { gql } from '@apollo/client';

export const REVERSE_USER_ROLE_MUTATION = gql`
  mutation reverseUserRole {
    reverseUserRole {
        status
        message
    }
  }
`;

export default { REVERSE_USER_ROLE_MUTATION };
