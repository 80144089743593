/* eslint-disable import/order */
import React from 'react';

// * MUI
import { styled } from '@mui/material/styles';
import { Button, Menu } from '@mui/material';

// * Hooks & Utils
import palette from '../../theme/palette';

// * Interfaces
import type { MenuProps } from '@mui/material';

export const DownloadBtn = styled(Button)({
  borderColor: palette.primary.main,
  borderRadius: '6px',
  color: palette.primary.main,
  width: 128,
  height: 48,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '22px',
  letterSpacing: 0,
  padding: '12px 16px',
  whiteSpace: 'nowrap',
  '& svg path': { transition: '250ms' },
  '&:hover': {
    backgroundColor: palette.primary.main,
    borderColor: palette.primary.main,
    color: palette.white,
    '& svg path': { fill: palette.white },
  },
});

export const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    {...props}
  />
))({
  '& .MuiPaper-root': {
    marginTop: '4px',
    width: '220px',
    maxWidth: '100%',
    border: '1px solid #E6EAEE',
    boxShadow: '0px 8px 9px rgba(61, 61, 61, 0.14)',
    borderRadius: '8px',
    '& .MuiMenu-list': { padding: 0 },
    '& .MuiMenuItem-root': {
      padding: '12px 24px',
      '&:hover': { backgroundColor: palette.background.default },
    },
  },
});
