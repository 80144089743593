import React from 'react';

// * MUI
import {
  Box, CircularProgress, useMediaQuery, useTheme,
} from '@mui/material';

// * Utils
import { ApolloQueryResult } from '@apollo/client';

// * Components
import NoCalls from '../../layouts/ResearchApp/components/ResearchTopicDetails/NoCalls';
import RenderSummary from '../../layouts/ResearchApp/components/ResearchTopicDetails/RenderSummary';
import SummaryFailed from '../../layouts/ResearchApp/components/ResearchTopicDetails/SummaryFailed';
import SummaryInProcess from '../../layouts/ResearchApp/components/ResearchTopicDetails/SummaryInProcess';

// * Interfaces
import type { Topic } from '../../components/TopicCallsDrawer/types';
import type { TopicType } from '../../shared/types/topics';

type RenderTopicProps = {
  topic: Topic
  refetchTopic: () => Promise<ApolloQueryResult<{ topic: TopicType; }>>
}

const RenderTopic = ({ topic, refetchTopic }: RenderTopicProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const boxStyle = {
    maxWidth: isMobile ? '100%' : 'calc(100vw - 459px)',
    width: '100%',
    alignContent: 'center',
  };

  const renderContent = () => {
    if (topic.calls.length === 0) {
      return <NoCalls />;
    }

    switch (topic.status) {
      case 'summarization failed':
        return <SummaryFailed topic={topic} refetchTopic={refetchTopic} />;
      case 'summarization in process':
        return <SummaryInProcess />;
      case 'summarization completed':
        Object.assign(boxStyle, { width: 'auto', alignContent: 'normal' });
        return <RenderSummary topic={topic} />;
      default:
        return (
          <Box textAlign="center" p={5}>
            <CircularProgress size={60} sx={{ mb: 2 }} />
          </Box>
        );
    }
  };

  const content = renderContent();
  if (
    content.type === NoCalls
    || content.type === SummaryFailed
    || content.type === SummaryInProcess
    || content.type === RenderSummary
  ) {
    Object.assign(boxStyle, { width: '100%', alignContent: 'center' });
  }

  return <Box sx={boxStyle}>{content}</Box>;
};

export default RenderTopic;
