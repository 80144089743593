import React, { useEffect, useRef, useState } from 'react';

// * MUI
import {
  Box, Dialog, Divider, Typography, Snackbar, Alert, AlertTitle,
} from '@mui/material';

// * Components
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import { useMutation } from '@apollo/client';
import {
  CardHeader,
  UpdateBtn,
  EditBtn,
  CancelBtn,
  CancelButton,
  SubmitButton,
} from './styledComponents';
import LogoUpload from './LogoUpload';

// * Hooks & Utils
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';
import { useMe } from '../AppContextProvider';

// * Icons & Images
import { ReactComponent as ArrowLeftIcon } from '../../assets/icons/arrowLeftIcon.svg';
import { ReactComponent as InfoCircleIcon } from '../../assets/icons/infoCircleIcon.svg';
import DefaultAvatarLogoImg from '../../assets/img/defaultAvatarLogo.svg';
import DefaultFullLogoImg from '../../assets/img/defaultFullLogo.svg';

// * Mutations
import { UPLOAD_ACCOUNT_LOGOS } from '../../queries/auth/account';

// * Interfaces
import type { AccountSettingsType } from '../../shared/types/accountSettings';

export interface FormValues {
  allowSendTranscriptUponReview: boolean
}

type OrganizationBrandingSettingsProps = {
  settings: AccountSettingsType
  refetchQueries: any
  editMode: boolean
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>
}

const OrganizationBrandingSettingsForm = ({
  settings,
  refetchQueries,
  editMode,
  setEditMode,
}: OrganizationBrandingSettingsProps) => {
  const { me, refetchMe } = useMe();
  const [openDialog, setOpenDialog] = useState(false);
  const fullLogoInputRef = useRef<HTMLInputElement>(null);
  const avatarLogoInputRef = useRef<HTMLInputElement>(null);
  const [fullLogo, setFullLogo] = useState<string | null>(null);
  const [avatarLogo, setAvatarLogo] = useState<string | null>(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [textAlert, setTextAlert] = useState<string>('');

  const [uploadAccountLogos, { loading }] = useMutation(UPLOAD_ACCOUNT_LOGOS);

  const handleCancel = () => {
    setEditMode(false);
    setFullLogo(null);
    setAvatarLogo(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    if (fullLogoInputRef.current?.files?.[0]) {
      formData.append('fullLogo', fullLogoInputRef.current.files[0]);
    }

    if (avatarLogoInputRef.current?.files?.[0]) {
      formData.append('avatarLogo', avatarLogoInputRef.current.files[0]);
    }

    try {
      await uploadAccountLogos({
        variables: {
          fullLogo: formData.get('fullLogo'),
          avatarLogo: formData.get('avatarLogo'),
        },
        awaitRefetchQueries: true,
        refetchQueries,
      });
      refetchMe();
    } catch (error: any) {
      const errors = error.graphQLErrors;
      if (errors) {
        setTextAlert(errors[0].message);
        setOpenAlert(true);
      }
    }

    handleCancel();
    handleOpenDialog();
  };

  const handleFullLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setFullLogo(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleAvatarLogoChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files?.[0]) {
      setAvatarLogo(URL.createObjectURL(event.target.files[0]));
    }
  };

  const isUpdateButtonDisabled = () => loading || (!fullLogo && !avatarLogo);

  useEffect(
    () => () => {
      if (fullLogo) URL.revokeObjectURL(fullLogo);
      if (avatarLogo) URL.revokeObjectURL(avatarLogo);
    },
    [fullLogo, avatarLogo],
  );

  const handleClose = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <form>
      <Snackbar
        open={openAlert}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          <Box sx={{ fontSize: '14px' }}>{textAlert}</Box>
        </Alert>
      </Snackbar>
      <CardHeader>
        {editMode && (
          <CancelBtn onClick={handleCancel}>
            <ArrowLeftIcon />
            <Typography
              variant="h6"
              color="colors.gray07"
              sx={{ marginLeft: '12px' }}
            >
              Cancel
            </Typography>
          </CancelBtn>
        )}
        <Typography variant="h4" sx={{ fontSize: '24px', lineHeight: '32px' }}>
          Branding Settings
        </Typography>
        {editMode ? (
          <UpdateBtn
            variant="contained"
            startIcon={<CheckCircleIcon />}
            disabled={isUpdateButtonDisabled()}
            onClick={handleOpenDialog}
          >
            Update
          </UpdateBtn>
        ) : (
          <Box display="flex" alignItems="center">
            <Typography
              variant="subtitle2"
              color="colors.gray06"
              mr="12px"
              sx={{ fontWeight: 500 }}
            >
              Last updated at
              {' '}
              {formatDateTimeToUserTimezone(settings.updatedAt, me.timezone)}
            </Typography>
            <EditBtn
              variant="outlined"
              startIcon={<BorderColorOutlinedIcon />}
              onClick={handleEdit}
            >
              Edit
            </EditBtn>
          </Box>
        )}
        <Dialog open={openDialog} onClose={handleOpenDialog}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: '55px 29px',
            }}
          >
            <Box display="flex" mb="8px">
              <InfoCircleIcon />
            </Box>
            <Typography
              variant="h3"
              color="colors.gray10"
              textAlign="center"
              mb="25px"
            >
              Update Branding
            </Typography>
            <Typography
              variant="body1"
              color="colors.gray10"
              textAlign="center"
              sx={{ fontWeight: 400 }}
            >
              Are you sure you want to update the branding?
            </Typography>
            <Box display="flex" justifyContent="center" mt="36px">
              <CancelButton variant="outlined" onClick={handleOpenDialog}>
                Cancel
              </CancelButton>
              <SubmitButton
                variant="contained"
                type="submit"
                disabled={loading}
                onClick={handleSubmit}
              >
                Yes, Update
              </SubmitButton>
            </Box>
          </Box>
        </Dialog>
      </CardHeader>
      <Divider
        flexItem
        sx={{ margin: '0 0 32px', borderColor: 'colors.gray03' }}
      />
      <Box display="flex" flexDirection="column" padding="0 48px 48px">
        <Typography variant="h6" mb="16px">
          Logo
        </Typography>
        {editMode ? (
          <Box display="flex" mb="24px" justifyContent="space-between">
            <LogoUpload
              label="Full Logo"
              logo={fullLogo || settings.fullLogo}
              defaultLogo={DefaultFullLogoImg}
              onUploadClick={() => fullLogoInputRef.current?.click()}
              onChange={handleFullLogoChange}
              ref={fullLogoInputRef}
              maxWidth={375}
              maxHeight={160}
            />
            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ margin: '0 24px', borderColor: 'colors.gray03' }}
            />
            <LogoUpload
              label="Avatar Logo"
              logo={avatarLogo || settings.avatarLogo}
              defaultLogo={DefaultAvatarLogoImg}
              onUploadClick={() => avatarLogoInputRef.current?.click()}
              onChange={handleAvatarLogoChange}
              ref={avatarLogoInputRef}
              maxWidth={200}
              maxHeight={200}
            />
          </Box>
        ) : (
          <Box
            display="flex"
            mb="24px"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              '& > :not(style)': {
                marginRight: '24px',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'start',
                gap: '24px',
              }}
            >
              <LogoUpload
                label="Full Logo"
                logo={settings.fullLogo}
                defaultLogo={DefaultFullLogoImg}
                onUploadClick={() => {}}
                onChange={() => {}}
                ref={fullLogoInputRef}
                maxWidth={375}
                maxHeight={160}
                disabled
              />
              <LogoUpload
                label="Avatar Logo"
                logo={settings.avatarLogo}
                defaultLogo={DefaultAvatarLogoImg}
                onUploadClick={() => {}}
                onChange={() => {}}
                ref={avatarLogoInputRef}
                maxWidth={200}
                maxHeight={200}
                disabled
              />
            </Box>
          </Box>
        )}
      </Box>
    </form>
  );
};

export default OrganizationBrandingSettingsForm;
