import { styled } from '@mui/material/styles';
import {
  Box, Button, Menu, SwipeableDrawer,
} from '@mui/material';
import palette from '../../theme/palette';

export const DropdownButton = styled(Button)({
  minWidth: '230px',
  justifyContent: 'space-between',
  '&:hover': {
    backgroundColor: 'transparent',
    textDecoration: 'none',
  },
  '&:active': {
    backgroundColor: 'transparent',
    textDecoration: 'none',
  },
});

export const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    padding: '12px 24px',
    border: '1px solid #E6EAEE',
    boxShadow: '0px 8px 9px rgba(61, 61, 61, 0.14)',
    borderRadius: '8px',
    minWidth: '180px',
    '& .MuiMenu-list': {
      padding: 0,
    },
    '& .MuiMenuItem-root': {
      padding: '12px 0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
});

export const NavigationBtn = styled(Button)({
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '24px',
  letterSpacing: 0,
  padding: '24px 32px 19px',
  borderBottom: '5px solid',
  borderBottomColor: 'transparent',
  color: palette.colors.gray08,
  '& svg path': {
    transition: 'all 250ms',
    stroke: palette.colors.gray08,
  },
  '&.selected': {
    color: palette.colors.primary05,
    borderBottomColor: palette.colors.primary05,
    '& svg path': {
      stroke: palette.colors.primary05,
    },
  },
  '&:hover': {
    textDecoration: 'none',
    color: palette.colors.primary05,
    '& svg path': {
      stroke: palette.colors.primary05,
    },
  },
});

export const MobNavigationBtn = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '0 12px',
  '& svg path': {
    transition: 'all 250ms',
    stroke: palette.white,
  },
  '&.selected': {
    '& h6': {
      color: palette.colors.primary05,
    },
    '& svg path': {
      stroke: palette.colors.primary05,
    },
  },
});

export const Drawer = styled(SwipeableDrawer)({
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    minWidth: '85%',
    backgroundColor: palette.secondary.main,
  },
});
