import { gql } from '@apollo/client';

export const ACCOUNT_ANALYSTS_QUERY = gql`
  query accountAnalysts ($accountUuid: UUID!) {
    accountAnalysts(accountUuid: $accountUuid) {
      uuid
      name
      email
      ignore
    }
  }
`;

export default { ACCOUNT_ANALYSTS_QUERY };
