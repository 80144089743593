import * as React from 'react';
import { useMutation } from '@apollo/client';
import {
  Box, Typography,
} from '@mui/material';
import { ReactComponent as QuestionCircleIcon } from '../../assets/icons/questionCircleIcon.svg';
import {
  CustomTooltip, AddButton,
} from './styledComponents';
import { ADD_CALL_TO_SAMPLE_MUTATION } from '../../queries/transcript/transcript';
import { AppContext } from '../AppContextProvider';
import isFutureDate from '../../shared/utils/functions';

const tooltipText = 'Press “Add to sample” to enable this call for compliance sample.';

type AddToSampleProps = {
  transcriptUuid: string;
  callDate: string;
};

const AddToSample = ({
  transcriptUuid,
  callDate,
}: AddToSampleProps) => {
  const { refetchScheduledCalls, refetchTranscript } = React.useContext(AppContext);

  const [addToSample] = useMutation(ADD_CALL_TO_SAMPLE_MUTATION);

  const [isDateInFuture, setIsDateInFuture] = React.useState(isFutureDate(callDate));

  React.useEffect(() => {
    // Функция для проверки, осталось ли до события больше минуты
    const checkDate = () => {
      setIsDateInFuture(isFutureDate(callDate));
    };

    checkDate();

    const intervalId = setInterval(checkDate, 20000); // проверка каждую минуту

    // Очистка интервала при размонтировании компонента
    return () => clearInterval(intervalId);
  }, [callDate]);

  const handleAdd = () => {
    addToSample({
      variables: { transcriptUuid },
      onCompleted: () => {
        if (refetchScheduledCalls && refetchTranscript) {
          refetchScheduledCalls();
          refetchTranscript();
        }
      },
    });
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="overline"
        color="colors.gray08"
        sx={{ textTransform: 'none' }}
      >
        Not Included in Sample
      </Typography>
      <AddButton
        variant="outlined"
        onClick={handleAdd}
        disabled={!isDateInFuture}
      >
        Add to Sample
      </AddButton>
      <CustomTooltip
        title={tooltipText}
        arrow
      >
        <QuestionCircleIcon />
      </CustomTooltip>
    </Box>
  );
};

export default AddToSample;
