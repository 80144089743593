/* eslint-disable no-unused-vars */
import React from 'react';

// * Utils
import { escapeRegExp } from 'lodash';
import palette from '../../theme/palette';

// * Interfaces
import type { HighlightType } from '../../shared/types/transcript';
import { CallTabType } from '../AppContextProvider';

const statusList = [
  { status: 'pending', color: palette.colors.warning02 },
  { status: 'resolved', color: palette.colors.success02 },
  { status: 'ai resolved', color: palette.colors.gray03 },
];

type HighlightedTranscriptProps = {
  paragraph: string
  highlights: HighlightType[]
  handleSelectHighlight: (highlight: HighlightType) => void,
  selectedTabName: CallTabType,
}

const HighlightedTranscript = ({
  paragraph,
  highlights,
  handleSelectHighlight,
  selectedTabName,
}: HighlightedTranscriptProps) => {
  let parts: (string | JSX.Element)[] = [paragraph];
  highlights.forEach(highlight => {
    const statusItem = statusList.find(
      item => highlight.status.value.toLowerCase() === item.status,
    );
    let phraseColor = statusItem ? statusItem.color : 'transparent';
    // hide ai resolved in completed and reviewed tabs
    if (statusItem && statusItem.status === 'ai resolved' && (selectedTabName === 'completed' || selectedTabName === 'reviewed')) {
      phraseColor = 'transparent';
    }

    const regex = new RegExp(`(${escapeRegExp(highlight.phrase)})`, 'gi');
    const isAiResolvedAndCompleted = statusItem && statusItem.status === 'ai resolved' && (selectedTabName === 'completed' || selectedTabName === 'reviewed');
    const boxShadow = isAiResolvedAndCompleted ? 'none' : '0 1px 2px #C7C7C7';

    parts = parts.flatMap((part, partIndex) => (typeof part === 'string'
      ? part.split(regex).map((text, index) => (regex.test(text) && index % 2 === 1 ? (
        <span
          // eslint-disable-next-line react/no-array-index-key
          key={`${highlight.uuid}-${partIndex}-${index}`}
          id={`highlight-text-${highlight.uuid}`}
          style={{ backgroundColor: phraseColor, boxShadow, cursor: 'pointer' }}
          role="button"
          tabIndex={0}
          onClick={() => handleSelectHighlight(highlight)}
          onKeyDown={event => {
            if (event.key === 'Enter' || event.key === ' ') {
              event.preventDefault();
              handleSelectHighlight(highlight);
            }
          }}
        >
          {text}
        </span>
      ) : (
        text
      )))
      : part));
  });

  return <div>{parts}</div>;
};

export default HighlightedTranscript;
