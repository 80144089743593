/* eslint-disable no-unused-vars */
import React from 'react';

// * MUI
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { ReactComponent as EmptyNotesIcon } from '../../assets/icons/emptyNotesIcon.svg';
import { ReactComponent as AltNotesIcon } from '../../assets/icons/altNotesIcon.svg';
import { ReactComponent as AttachIcon } from '../../assets/icons/attachIcon.svg';
import { ReactComponent as PdfFileIcon } from '../../assets/icons/pdfFileIcon.svg';
import { ReactComponent as PostIcon } from '../../assets/icons/postIcon.svg';
import { ReactComponent as MoreCircleIcon } from '../../assets/icons/moreCircleIcon.svg';

// * Components
import {
  NoteField,
  Comment,
  NoteItem,
  NotesAvatar,
  Attachment,
  AttachmentName,
  Suggestion,
} from './styledComponents';

// * Hooks & Utils
import { formatDateTimeToUserTimezone } from '../../shared/utils/dateHelpers';
import { useMe } from '../AppContextProvider';
import paths from '../../paths/paths';

// * Interfaces
import type { HighlightType } from '../../shared/types/transcript';

type NotesProps = {
  highlight: HighlightType | null
  selectedFile: File | null
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>
  notes: string
  setNotes: React.Dispatch<React.SetStateAction<string>>
  handlePost: (text: string, file: File | null, resolve?: boolean) => void
  disabled?: boolean
}

const Notes = ({
  highlight,
  selectedFile,
  setSelectedFile,
  notes,
  setNotes,
  handlePost,
  disabled = false,
}: NotesProps) => {
  const { me } = useMe();

  const status = highlight?.status.value.toLocaleLowerCase();
  const notesCount = highlight?.highlightNotes.length;

  const appendSuggestionToNotes = (suggestion: string) => {
    setNotes(currentNotes => (currentNotes.length > 0 ? `${currentNotes}, ${suggestion}` : suggestion));
  };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const file = event.target.files?.[0];
    if (!file) return;

    setSelectedFile(file);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" alignItems="center" mb="24px">
        <AltNotesIcon />
        <Typography variant="subtitle1" color="colors.gray10" ml="12px">
          Notes
        </Typography>
      </Box>
      {(status === 'resolved' || status === 'ai resolved') && notesCount === 0 && (
        <Box display="flex" alignItems="center" mt="28px" flexDirection="column">
          <EmptyNotesIcon />
          No notes added
        </Box>
      )}
      {(status !== 'resolved' && status !== 'ai resolved') && (
        <Box display="flex">
          <form style={{ width: '100%' }}>
            <Box display="flex" alignItems="center" mb="4px" gap="12px">
              <NoteField
                id="NotesField"
                name="notes"
                value={notes}
                onChange={e => setNotes(e.target.value)}
                type="text"
                variant="outlined"
                placeholder="Add your comment here.."
                autoComplete="off"
                disabled={disabled}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <label htmlFor="upload-photo">
                        <input
                          style={{ display: 'none' }}
                          id="upload-photo"
                          name="upload-photo"
                          accept=".doc,.docx,.pdf"
                          type="file"
                          onChange={handleFileChange}
                          disabled={disabled}
                        />
                        <IconButton aria-label="attach file" component="span">
                          <AttachIcon />
                        </IconButton>
                      </label>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                startIcon={<PostIcon />}
                sx={{ width: 120, height: 52 }}
                disabled={(notes.length === 0 && !selectedFile) || disabled}
                onClick={() => handlePost(notes, selectedFile, false)}
              >
                Post
              </Button>
            </Box>
            <Box display="flex" flexWrap="wrap" mt="12px">
              {!!highlight && highlight.suggestions.map(suggestion => (
                <Suggestion key={suggestion} onClick={() => appendSuggestionToNotes(suggestion)}>
                  {suggestion}
                </Suggestion>
              ))}
            </Box>
            {selectedFile && (
              <Typography variant="caption" color="textSecondary" style={{ textTransform: 'none' }}>
                {selectedFile.name}
              </Typography>
            )}
          </form>
        </Box>
      )}
      {!!highlight && highlight.highlightNotes.map(note => (
        <>
          <Divider flexItem sx={{ borderColor: 'colors.gray03' }} />
          <NoteItem key={note.uuid}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                {!note.updatedBy ? (
                  <NotesAvatar alt="Encore AI" src="https://encorecompliance.s3.amazonaws.com/Images/emailLogoSmall.png" />
                ) : (
                  <NotesAvatar alt={`${note.updatedBy.firstName} ${note.updatedBy.lastName}`} src={paths.home.reverse()} />
                )}
                <Typography variant="subtitle1" color="colors.gray07" sx={{ fontSize: '14px', lineHeight: '24px', marginRight: '7px' }}>
                  {!note.updatedBy ? 'Encore AI' : `${note.updatedBy.firstName} ${note.updatedBy.lastName}`}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="colors.gray04" sx={{ fontSize: '10px', fontWeight: 500, lineHeight: '24px' }}>
                {formatDateTimeToUserTimezone(note.createdAt, me.timezone)}
              </Typography>
            </Box>
            <Comment>{note.text}</Comment>
            {!!note.filename && (
              <Attachment href={note.fileUrl} target="_blank">
                <PdfFileIcon />
                <AttachmentName variant="body1" color="colors.gray10" style={{ textTransform: 'none' }}>
                  {note.filename}
                </AttachmentName>
                <MoreCircleIcon />
              </Attachment>
            )}
          </NoteItem>
        </>
      ))}
    </Box>
  );
};

Notes.defaultProps = {
  disabled: false,
};

export default Notes;
