import { useState } from 'react';

// * Hooks & Utils
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';

// * Queries
import { ALL_ACA_IOI_ACCOUNTS } from '../../queries/account/allAcaIoiAccounts';
import { ALL_ANALYSTS_QUERY } from '../../queries/calls/allAnalysts';
import { ALL_NETWORKS_QUERY } from '../../queries/calls/allNetworks';
import { ALL_TICKERS_QUERY } from '../../queries/transcript/allTickers';

// * Interfaces
import type { AcaIoiAccount } from '../../shared/types/acaIoiAccounts';
import type { TickerType } from '../../shared/types/tickers';
import type { StringOptionType } from '../../shared/types/transcript';
import { ALL_COMPLIANCE_REVIEWERS_QUERY } from '../../queries/auth/account';
import { AllComplianceReviewersQueryType, UserType } from '../../shared/types/user';

export type FilterField = {
  uuid: string;
  label: string;
}

export type BooleanOptionType = boolean | undefined;

export type FilterProps = {
  acaIoiAccounts: FilterField[];
  setAcaIoiAccounts: React.Dispatch<React.SetStateAction<FilterField[]>>;
  allAcaIoiAccounts: AcaIoiAccount[];
  allAnalysts: string[];
  analysts: FilterField[];
  setAnalysts: React.Dispatch<React.SetStateAction<FilterField[]>>;
  complianceReviewers: FilterField[];
  setComplianceReviewers: React.Dispatch<React.SetStateAction<FilterField[]>>;
  allComplianceReviewers: UserType[];
  allNetworks: StringOptionType[];
  networks: FilterField[];
  setNetworks: React.Dispatch<React.SetStateAction<FilterField[]>>;
  allTickers: TickerType[];
  tickers: FilterField[];
  setTickers: React.Dispatch<React.SetStateAction<FilterField[]>>;
  displayInResearchPortal: boolean | undefined;
  setDisplayInResearchPortal: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  dateRangeStart?: Date;
  dateRangeEnd?: Date;
  setDateRange: React.Dispatch<React.SetStateAction<{ dateRangeStart?: Date; dateRangeEnd?: Date }>>;
  allFlags: { uuid: string, label: string }[]
  flags: FilterField[];
  setFlags: React.Dispatch<React.SetStateAction<FilterField[]>>;
}

const dateRangeInitial = {
  dateRangeStart: dayjs().utc().subtract(30, 'day').startOf('day')
    .toDate(),
  dateRangeEnd: dayjs().utc().endOf('day').toDate(),
};

const useFilterLogic = (): FilterProps => {
  const [acaIoiAccounts, setAcaIoiAccounts] = useState<FilterField[]>([]);
  const [analysts, setAnalysts] = useState<FilterField[]>([]);
  const [complianceReviewers, setComplianceReviewers] = useState<FilterField[]>([]);
  const [networks, setNetworks] = useState<FilterField[]>([]);
  const [tickers, setTickers] = useState<FilterField[]>([]);
  const [flags, setFlags] = useState<FilterField[]>([]);
  const [displayInResearchPortal, setDisplayInResearchPortal] = useState<boolean | undefined>(undefined);
  const [dateRange, setDateRange] = useState<{ dateRangeStart?: Date; dateRangeEnd?: Date }>(dateRangeInitial);

  const { data: { allAcaIoiAccounts = [] } = {} } = useQuery<{
    allAcaIoiAccounts: AcaIoiAccount[];
  }>(ALL_ACA_IOI_ACCOUNTS);
  const { data: { allAnalysts = [] } = {} } = useQuery<{ allAnalysts: string[] }>(ALL_ANALYSTS_QUERY);
  const {
    data: {
      allComplianceReviewers: {
        results: allComplianceReviewers = [],
      } = {},
    } = {},
  } = useQuery<AllComplianceReviewersQueryType>(ALL_COMPLIANCE_REVIEWERS_QUERY);
  const { data: { allNetworks = [] } = {} } = useQuery<{ allNetworks: StringOptionType[] }>(ALL_NETWORKS_QUERY);
  const { data: { allTickers = [] } = {} } = useQuery<{ allTickers: TickerType[] }>(ALL_TICKERS_QUERY);
  const allFlags = [{ uuid: 'liveChaperoneReviewer', label: 'Live Chaperone' }, { uuid: 'fullTranscriptReviewer', label: 'Full Transcript Review' }];

  return {
    acaIoiAccounts,
    setAcaIoiAccounts,
    allAcaIoiAccounts,
    allAnalysts,
    analysts,
    setAnalysts,
    complianceReviewers,
    setComplianceReviewers,
    allComplianceReviewers,
    allNetworks,
    networks,
    setNetworks,
    allTickers,
    tickers,
    setTickers,
    displayInResearchPortal,
    setDisplayInResearchPortal,
    dateRangeStart: dateRange.dateRangeStart,
    dateRangeEnd: dateRange.dateRangeEnd,
    setDateRange,
    allFlags,
    flags,
    setFlags,
  };
};

export default useFilterLogic;
