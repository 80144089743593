//* MUI
import { styled } from '@mui/material/styles';
import {
  SwipeableDrawer, Paper, TextField, Box, Typography, Button,
} from '@mui/material';
import palette from '../../../theme/palette';

export const Drawer = styled(SwipeableDrawer)(({ theme }) => ({
  width: '75%',
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: '75%',
    boxSizing: 'border-box',
    boxShadow: '-11px 0px 60px rgba(0, 0, 0, 0.25)',
  },
  '& .MuiBackdrop-root': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  },
}));

export const ContentHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: 48,
  marginBottom: '12px',
  [theme.breakpoints.down('md')]: {
    minHeight: 40,
    marginBottom: '8px',
  },
}));

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  padding: '16px 32px',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    padding: '12px 16px 16px',
  },
}));

export const SectionPaper = styled(Paper)({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

export const SearchField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '40px',
    alignItems: 'center',
    backgroundColor: '#E4E8EE',
    fontSize: '14px',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'transparent',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent',
    },
  },
  '& .MuiInputBase-input': {
    height: '40px',
    boxSizing: 'border-box',
    '&::placeholder': {
      color: '#566E91',
      opacity: 1,
    },
  },
});

export const Cell = styled(Typography)({
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: 0,
  minWidth: '160px',
  fontSize: '14px',
  lineHeight: '22px',
  color: palette.colors.gray08,
  boxSizing: 'border-box',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  paddingRight: '8px',
});

export const ListHeaderContainer = styled(Box)({
  display: 'flex',
  backgroundColor: palette.colors.gray02,
  borderRadius: '6px',
  padding: '4px 20px',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  marginBottom: '8px',
});

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  padding: '16px 32px',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
}));

export const TopicTextField = styled(TextField)(({ theme }) => ({
  minWidth: 380,
  '& .MuiOutlinedInput-root': {
    height: 40,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '40px',
    borderRadius: '8px',
    padding: 0,
    '& .MuiOutlinedInput-input': {
      height: 40,
      padding: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      boxShadow: 'none !important',
    },
  },
  [theme.breakpoints.down('md')]: {
    minWidth: 260,
    '& .MuiOutlinedInput-root': {
      fontSize: '16px',
      fontWeight: 600,
    },
  },
}));

export const TopicViewField = styled(Typography)(({ theme }) => ({
  cursor: 'text',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '380px',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '40px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 235,
    fontSize: '16px',
    fontWeight: 600,
  },
}));

export const FooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 32px',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    padding: '16px',
  },
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  paddingInline: theme.spacing(4),
  minWidth: 'fit-content',
  borderRadius: '12px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
  height: '40px',
  [theme.breakpoints.down('md')]: {
    paddingInline: theme.spacing(2),
  },
}));

export const EditBtn = styled(Box)({
  display: 'flex',
  border: '1px solid',
  borderColor: palette.colors.gray03,
  borderRadius: '8px',
  padding: '5px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: palette.colors.gray01,
  },
});

export const CallListCard = styled(Box)({
  display: 'flex',
  border: '1px solid',
  borderColor: palette.colors.gray03,
  backgroundColor: palette.colors.gray01,
  borderRadius: '8px',
  padding: '12px 16px',
  marginBottom: '12px',
});
