import React from 'react';

// * MUI
import {
  Box, Grid, Tab, useMediaQuery, useTheme,
} from '@mui/material';

// * Components
import MoreDetailsDrawer from '../../../../components/MoreDetailsDrawer';
import CallDetailsOutlinedItem from '../../../../components/CallDetailsOutlinedItem';
import CallDetailsOutlinedMoreItem from '../../../../components/CallDetailsOutlinedMoreItem';
import {
  MainContainer, ScrollContainer, DrawerTabs, topicsSummaryA11yProps,
} from '../../../../pages/ResearchAppCalls/components/styledComponents';
import RelatedCompaniesList from '../../../../components/RelatedCompaniesList';
import TopicsSummaryTabPanel from '../../../../components/Tabs/TopicsSummaryTabPanel';
import ChatInterface from '../../../../components/ChatInterface';

// * Interfaces
import type { Topic } from '../../../../components/TopicCallsDrawer/types';

type ObjectType = {
  [key: string]: string | string[] | ObjectType | ObjectType[]
}

function getCommaSeparatedString(obj: string | string[] | ObjectType | ObjectType[]): string {
  if (typeof obj === 'string') return obj;
  if (Array.isArray(obj)) return obj.join(', ');
  return 'Object';
}

type RenderSummaryProps = {
  topic: Topic
}

const RenderSummary = ({ topic }: RenderSummaryProps) => {
  const [topicsSummaryTabIndex, setTopicsSummaryTabIndex] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const summary = topic.jsonSummary ? JSON.parse(topic.jsonSummary) : null;
  const moreDetailsKeys = ['Key Insights', 'Opportunities', 'Challenges', 'Follow-up Ideas', 'Questions and Answers'];
  // const moreDetailsKeys = ['Fake Item'];

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTopicsSummaryTabIndex(newValue);
  };

  if (!summary) {
    return null;
  }

  return (
    <MainContainer>
      <ScrollContainer
        id="scrollableRightColumn"
        sx={{ padding: isMobile ? '0 8px 12px' : '0 12px 24px' }}
      >
        <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'colors.gray03', margin: isMobile ? '0 -8px' : '0 12px' }}>
          <DrawerTabs value={topicsSummaryTabIndex} onChange={handleTabChange} aria-label="topics-summary-tabs">
            <Tab label="Summary" {...topicsSummaryA11yProps(0)} />
            <Tab label="Chat" {...topicsSummaryA11yProps(1)} />
          </DrawerTabs>
        </Box>
        <TopicsSummaryTabPanel value={topicsSummaryTabIndex} index={0}>
          <Grid
            container
            rowSpacing={isMobile ? 0 : '6px'}
            columnSpacing={isMobile ? 0 : '28px'}
            sx={{ padding: '0 12px', marginBottom: '24px' }}
          >
            {Object.keys(summary).map(key => {
              if (Array.isArray(summary[key])) {
                return (
                  <Grid item xs={12} md={6} display="flex" key={key}>
                    <CallDetailsOutlinedItem
                      title={key}
                      sx={{ marginTop: '20px' }}
                      isTypography
                    >
                      {(summary[key] as string[] | ObjectType[]).map(
                        (item: string | ObjectType) => (
                          <CallDetailsOutlinedMoreItem key={typeof item === 'object' ? Object.keys(item)[0] : item}>
                            {typeof item === 'object' ? Object.keys(item).map(k => (
                              <Grid item xs={12} display="flex" key={k}>
                                <CallDetailsOutlinedItem title={k} sx={{ marginTop: '20px' }} isTypography>
                                  {item[k]}
                                </CallDetailsOutlinedItem>
                              </Grid>
                            )) : (
                              <Box>
                                {moreDetailsKeys.includes(key) ? (
                                  <MoreDetailsDrawer type={key} modelName="TOPIC" modelId={Number(topic.id)}>
                                    {item}
                                  </MoreDetailsDrawer>
                                ) : item}
                              </Box>
                            )}
                          </CallDetailsOutlinedMoreItem>
                        ),
                      )}
                    </CallDetailsOutlinedItem>
                  </Grid>
                );
              }

              if (typeof summary[key] === 'object' && summary[key] !== null) {
                return (
                  <Grid item xs={12} md={6} display="flex" key={key}>
                    <CallDetailsOutlinedItem
                      title={key}
                      sx={{ marginTop: '20px' }}
                      isTypography
                    >
                      {Object.keys(summary[key]).map(k => (
                        <Grid item xs={12} display="flex" key={k}>
                          <CallDetailsOutlinedItem
                            title={k}
                            sx={{ marginTop: '20px' }}
                            isTypography
                          >
                            {getCommaSeparatedString((summary[key] as ObjectType)[k])}
                          </CallDetailsOutlinedItem>
                        </Grid>
                      ))}
                    </CallDetailsOutlinedItem>
                  </Grid>
                );
              }

              return (
                <Grid item xs={12} md={6} display="flex" key={key}>
                  <CallDetailsOutlinedItem
                    title={key}
                    sx={{ marginTop: '20px' }}
                    isTypography
                  >
                    {summary[key]}
                  </CallDetailsOutlinedItem>
                </Grid>
              );
            })}

            <Grid item xs={12} md={6} display="flex">
              <CallDetailsOutlinedItem title="Related Companies" sx={{ marginTop: '20px' }}>
                <RelatedCompaniesList tickers={topic.relatedCompanies} />
              </CallDetailsOutlinedItem>
            </Grid>
          </Grid>
        </TopicsSummaryTabPanel>
        <TopicsSummaryTabPanel value={topicsSummaryTabIndex} index={1}>
          {/* @ts-ignore */}
          <ChatInterface conversationUuid={topic.conversation.uuid} chatStyle="topics-chat" />
        </TopicsSummaryTabPanel>
      </ScrollContainer>
    </MainContainer>
  );
};

export default RenderSummary;
