import { gql } from '@apollo/client';

export const FILTER_ACCOUNT_CALLS_BY_SEARCH_AND_TOPIC = gql`
  query filterAccountCallsBySearchAndTopic(
    $topicUuid: UUID!
    $first: Int
    $skip: Int
    $searchTerm: String
  ) {
    filterAccountCallsBySearchAndTopic(
      topicUuid: $topicUuid
      first: $first
      skip: $skip
      searchTerm: $searchTerm
    ) {
      totalCount
      results {
        id
        uuid
        datetime
        name
        projectId
        analystName
        expertName
        type
        network {
          value
        }
      }
    }
  }
`;

export default { FILTER_ACCOUNT_CALLS_BY_SEARCH_AND_TOPIC };
