import React from 'react';
import Box from '@mui/material/Box';

type CallsSummaryTabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
}

const CallsSummaryTabPanel = (props: CallsSummaryTabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`calls-summary-tabpanel-${index}`}
      aria-labelledby={`calls-summary-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>{children}</Box>
      )}
    </Box>
  );
};

export default CallsSummaryTabPanel;
