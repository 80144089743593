import { styled } from '@mui/material/styles';
import {
  Box, Typography,
} from '@mui/material';
import palette from '../../theme/palette';

export const Paragraph = styled(Box)({
  display: 'flex',
  minHeight: 48,
  marginTop: '16px',
});

export const TimestampBlock = styled(Box)({
  display: 'flex',
  position: 'relative',
  width: '73px',
  minWidth: '73px',
  marginRight: '32px',
});

export const DashedLine = styled(Box)({
  position: 'absolute',
  top: 16,
  bottom: 16,
  left: 7,
  borderRight: '1px dashed',
  borderRightColor: palette.colors.gray04,
});

export const StartTime = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 30,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '15px',
  color: palette.colors.gray09,
});

export const EndTime = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 30,
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '15px',
  color: palette.colors.gray09,
});

export const TranscriptText = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: palette.colors.gray09,
});

export const MemberTypography = styled(Typography)({
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  height: '44px',
  maxHeight: '44px',
});
