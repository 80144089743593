/* eslint-disable no-unused-vars */
import React from 'react';

// * MUI
import { Box } from '@mui/material';

// * Components
import {
  TranscriptText,
  Paragraph,
  TimestampBlock,
  DashedLine,
  StartTime,
  EndTime,
  EditButton,
  MemberTypography,
} from '../styledComponents';

// * Utils
import palette from '../../../theme/palette';

// * Icons
import { ReactComponent as EllipseIcon } from '../../../assets/icons/ellipseIcon.svg';
import { ReactComponent as PencilFilledIcon } from '../../../assets/icons/pencilFilledIcon.svg';

// * Interfaces
import type { ParagraphType, ParticipantType } from '../../../shared/types/transcript';

type RenderParagraphDesktopViewProps = {
  handleEditParagraph: () => void
  selectedParagraph: boolean
  isResearchAnalyst: boolean
  isTranslated: boolean
  originalText: string
  isEmptyParagraph: boolean
  disableAudio: boolean
  speaker: string
  startTime: string
  endTime: string
  participant: ParticipantType | null
  isFileAText: boolean
  AudioPlayer: JSX.Element
  transcriptContent: JSX.Element
  editedBy: JSX.Element | null
  paragraphId: string
  handleClickParagraph: (paragraph: ParagraphType, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  paragraph: ParagraphType
  isDocument: boolean
}

const RenderParagraphDesktopView = ({
  transcriptContent,
  handleEditParagraph,
  selectedParagraph,
  isResearchAnalyst,
  isTranslated,
  originalText,
  isEmptyParagraph,
  disableAudio,
  AudioPlayer,
  speaker,
  startTime,
  endTime,
  participant,
  isFileAText,
  editedBy,
  paragraphId,
  handleClickParagraph,
  paragraph,
  isDocument,
}: RenderParagraphDesktopViewProps) => (
  <Paragraph
    className={`${selectedParagraph ? 'selected-paragraph-item' : ''} ${!isResearchAnalyst ? 'editable-paragraph-item' : ''}`}
  >
    <EditButton
      className="edit-button"
      startIcon={<PencilFilledIcon />}
      onClick={() => handleEditParagraph()}
    >
      Edit
    </EditButton>
    <Box display="flex">
      {!isDocument && (
      <Box minWidth="90px" maxWidth="90px" mr="4px">
        <MemberTypography variant="subtitle2">
          {participant?.name || speaker}
        </MemberTypography>
      </Box>
      )}
      {(isResearchAnalyst && disableAudio) || isFileAText ? (
        <Box />
      ) : (
        <Box sx={{ marginRight: '24px' }}>
          <Box display="flex">{AudioPlayer}</Box>
        </Box>
      )}
      {!isFileAText && (
        <TimestampBlock>
          <Box display="flex" sx={{ position: 'absolute', top: 0, left: 0 }}>
            <EllipseIcon />
          </Box>
          <DashedLine />
          <Box display="flex" sx={{ position: 'absolute', bottom: 0, left: 0 }}>
            <EllipseIcon />
          </Box>
          <StartTime>{startTime}</StartTime>
          <EndTime>{endTime}</EndTime>
        </TimestampBlock>
      )}
      <Box display="flex" flexDirection="column">
        {isTranslated && !isEmptyParagraph && (
          <TranscriptText
            sx={{ marginBottom: '12px' }}
            id={paragraphId}
          >
            {originalText}
          </TranscriptText>
        )}
        <TranscriptText
          sx={{ color: isTranslated ? palette.primary.main : palette.colors.gray09 }}
          onClick={event => handleClickParagraph(paragraph, event)}
        >
          {transcriptContent}
        </TranscriptText>
        {editedBy}
      </Box>
    </Box>
  </Paragraph>
);

export default RenderParagraphDesktopView;
