import * as React from 'react';
import { useMutation } from '@apollo/client';
import {
  Box, FormGroup, FormControlLabel,
} from '@mui/material';
import {
  CustomCheckbox, CustomTooltip,
} from './styledComponents';
import { DISABLE_AUDIO_IN_RESEARCH_PORTAL_MUTATION } from '../../queries/transcript/transcript';
import { AppContext } from '../AppContextProvider';

const tooltipText = 'If is checked the play buttons and download MP3 will not be shown in Research Portal.';

type DisableAudioProps = {
  transcriptUuid: string,
  disableAudio: boolean;
};

const DisableAudio = ({
  transcriptUuid, disableAudio,
}: DisableAudioProps) => {
  const { refetchTranscript } = React.useContext(AppContext);
  const [disableAudioInResearch] = useMutation(DISABLE_AUDIO_IN_RESEARCH_PORTAL_MUTATION, {
    onCompleted: () => {
      refetchTranscript?.();
    },
  });

  // Local state to control checkbox value
  const [isDisableAudioChecked, setIsDisableAudioChecked] = React.useState(disableAudio);

  // Sync local state with prop changes
  React.useEffect(() => {
    setIsDisableAudioChecked(disableAudio);
  }, [disableAudio]);

  const handleCheckboxChange = (isChecked: boolean) => {
    setIsDisableAudioChecked(isChecked); // Update local state immediately for UI response

    disableAudioInResearch({
      variables: {
        transcriptUuid,
        disableAudio: isChecked,
      },
    });
  };

  return (
    <form>
      <FormGroup>
        <CustomTooltip
          title={tooltipText}
          arrow
        >
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              sx={{ marginLeft: 0, marginRight: '24px' }}
              control={(
                <CustomCheckbox
                  name="disableAudioCheckbox"
                  onChange={e => handleCheckboxChange(e.target.checked)}
                  checked={isDisableAudioChecked} // Use controlled component approach
                />
              )}
              label={(
                <Box display="flex" alignItems="center" sx={{ fontWeight: 400, whiteSpace: 'nowrap' }}>
                  Disable Audio
                </Box>
              )}
            />
          </Box>
        </CustomTooltip>
      </FormGroup>
    </form>
  );
};

export default DisableAudio;
