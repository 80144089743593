import React from 'react';
import Box from '@mui/material/Box';

type ContentTabPanelProps = {
  children: React.ReactNode;
  index: number;
  value: number;
}

const ContentTabPanel = (props: ContentTabPanelProps) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`content-tabpanel-${index}`}
      aria-labelledby={`content-tab-${index}`}
      {...other}
      display="flex"
      flexGrow={value !== index ? '0' : '1'}
    >
      {value === index && (
        <Box
          display="flex"
          flexDirection="column"
          flexGrow="1"
          pt="12px"
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default ContentTabPanel;
