import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, IconButton, Typography, Slider,
} from '@mui/material';
import palette from '../../theme/palette';
import { ReactComponent as PlayIcon } from '../../assets/icons/playIcon.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pauseIcon.svg';
import { ReactComponent as PlayBtnIcon } from '../../assets/icons/playBtnIcon.svg';
import { ReactComponent as PauseBtnIcon } from '../../assets/icons/pauseBtnIcon.svg';
import PlaybackRateButton from './components/PlaybackRateButton';

const LargeButton = styled(IconButton)({
  padding: 0,
  marginRight: '4px',
  '&:hover svg rect': {
    fill: palette.primary.dark,
  },
});

const SmallButton = styled(IconButton)({
  padding: 0,
  '&:hover svg rect': {
    fill: palette.primary.dark,
  },
});

const ProgressBar = styled(Slider)({
  color: palette.primary.main,
  height: 10,
  '& .MuiSlider-thumb': {
    width: 10,
    height: 10,
    '&.Mui-active': {
      width: 14,
      height: 14,
      boxShadow: '0 0 0 10px rgba(26, 115, 232, 0.16)',
    },
  },
  '& .MuiSlider-rail': {
    color: palette.colors.primary02,
  },
});

type AudioPlayerProps = {
  audio: HTMLAudioElement | null;
  isPlayingMain: boolean;
  setIsPlayingMain: React.Dispatch<React.SetStateAction<boolean>>;
  isPlayingParagraph: boolean;
  setIsPlayingParagraph: React.Dispatch<React.SetStateAction<boolean>>;
  isPlaying?: boolean;
  setIsPlaying?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsStopping?: React.Dispatch<React.SetStateAction<boolean>>;
  playParagraph: boolean;
  stickyPlayer?: boolean;
  startTime?: number;
  endTime?: number;
}

const AudioPlayer = ({
  audio, isPlayingMain, setIsPlayingMain, isPlayingParagraph, setIsPlayingParagraph, isPlaying,
  setIsPlaying, setIsStopping, playParagraph, stickyPlayer, startTime, endTime,
}: AudioPlayerProps) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const startTimePlay = startTime || 0;
  const endTimePlay = endTime || 0;

  const handleChangePlaybackRate = (rate: number) => {
    if (audio) {
      // eslint-disable-next-line no-param-reassign
      audio.playbackRate = rate;
    }
  };

  const handleClearAllTimeouts = () => {
    let id = window.setTimeout(() => {}, 0);
    // eslint-disable-next-line no-plusplus
    while (id--) {
      window.clearTimeout(id);
    }
  };

  const togglePlayback = () => {
    if (audio) {
      if (isPlayingMain) {
        audio.pause();
      } else {
        audio.play();
      }
      setIsPlayingMain(!isPlayingMain);
      setIsPlayingParagraph(false);
      if (setIsStopping) {
        setIsStopping(true);
      }
      handleClearAllTimeouts();
    }
  };

  const handleTimeUpdate = () => {
    if (audio) {
      setCurrentTime(audio.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audio) {
      setDuration(audio.duration);
    }
  };

  const handleAudioEnded = () => {
    if (audio) {
      audio.pause();
      // eslint-disable-next-line no-param-reassign
      audio.currentTime = 0;
      setIsPlayingMain(false);
    }
  };

  const seekTo = (time: number) => {
    if (audio) {
      // eslint-disable-next-line no-param-reassign
      audio.currentTime = time;
      setCurrentTime(time);
    }
  };

  const playFromTo = (start: number, end: number, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    if (audio) {
      if (isPlaying) {
        audio.pause();
        setIsPlayingMain(false);
        setIsPlayingParagraph(false);
        if (setIsStopping) {
          setIsStopping(true);
        }
        handleClearAllTimeouts();
        return;
      }
      // eslint-disable-next-line no-param-reassign
      audio.currentTime = start;
      audio.play();
      setIsPlayingMain(true);
      setIsPlayingParagraph(true);
      if (setIsPlaying) {
        setIsPlaying(true);
      }
      handleClearAllTimeouts();
      setTimeout(() => {
        audio.pause();
        setIsPlayingMain(false);
        setIsPlayingParagraph(false);
        if (setIsStopping) {
          setIsStopping(true);
        }
      }, (end - start) * 1000);
    }
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleProgressBarChange = (e: Event, value: number | number[]) => {
    const time = value as number;
    seekTo(time);
  };

  const renderPlayBtn = (isSticky: undefined | boolean, isPlay: boolean) => {
    let buttonView;
    if (isSticky) {
      buttonView = isPlay ? <PauseIcon /> : <PlayIcon />;
    } else {
      buttonView = isPlay ? <PauseBtnIcon /> : <PlayBtnIcon />;
    }

    return buttonView;
  };

  useEffect(() => {
    if (audio) {
      audio.addEventListener('ended', handleAudioEnded);
      audio.addEventListener('timeupdate', handleTimeUpdate);
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    }

    return () => {
      if (audio) {
        audio.removeEventListener('ended', handleAudioEnded);
        audio.removeEventListener('timeupdate', handleTimeUpdate);
        audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      }
    };
  });

  return (
    <Box display="flex">
      {playParagraph ? (
        <SmallButton onClick={event => playFromTo(startTimePlay, endTimePlay, event)}>
          {(isPlayingParagraph && isPlaying) ? <PauseIcon /> : <PlayIcon />}
        </SmallButton>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <LargeButton onClick={togglePlayback}>
              {renderPlayBtn(stickyPlayer, isPlayingMain)}
            </LargeButton>
            <PlaybackRateButton handleChangePlaybackRate={handleChangePlaybackRate} />
            <Typography variant="subtitle1" sx={{ fontSize: '14px', minWidth: 54 }}>
              {formatTime(currentTime)}
            </Typography>
            <Box display="flex" id={stickyPlayer ? 'StickyProgressBar' : 'PlayerProgressBar'} minWidth={130}>
              <ProgressBar
                aria-label="time-indicator"
                value={currentTime}
                min={0}
                step={1}
                max={duration}
                onChange={handleProgressBarChange}
              />
            </Box>
          </Box>
          <Typography variant="subtitle1" sx={{ fontSize: '14px', marginLeft: '15px' }}>
            {formatTime(duration)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

AudioPlayer.defaultProps = {
  stickyPlayer: false,
  isPlaying: false,
  setIsPlaying: false,
  setIsStopping: false,
  startTime: undefined,
  endTime: undefined,
};

export default AudioPlayer;
