/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';

// * MUI
import {
  Box, Typography, FormControl, Select,
} from '@mui/material';

// * Components
import {
  ReviewerInput,
  ReviewerMenuItem,
  UserAvatarContainer,
  UserNameContainer,
  UserAvatar,
} from './styledComponents';

// * Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// * Hooks & Utils
import { useQuery, MutationFunction } from '@apollo/client';
import palette from '../../theme/palette';
import paths from '../../paths/paths';

// * Queries
import { ALL_COMPLIANCE_REVIEWERS_QUERY } from '../../queries/auth/account';

// * Interfaces
import type { SelectChangeEvent } from '@mui/material/Select';
import type { AllComplianceReviewersQueryType, UserType } from '../../shared/types/user';

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '275px',
      width: '310px',
      border: '1px solid',
      borderColor: palette.primary.main,
      borderRadius: '8px',
      boxShadow: '0px 8px 9px rgba(226, 226, 226, 0.35)',
    },
  },
};

const QUERY_INITIAL_VALUE = { results: [], totalCount: 0 };
const SELECTED_USER_INITIAL = { value: '', displayValue: '' };

type ComplianceReviewerProps = {
  instanceUuid: string,
  disabled?: boolean,
  hideIfUnassigned?: boolean,
  initialAssignee: UserType | null
  placeholder: string;
  assignUser: MutationFunction | any;
  signedInUserUuid: string;
  inCard?: boolean;
};

const ComplianceReviewer = ({
  instanceUuid,
  disabled,
  hideIfUnassigned,
  initialAssignee,
  placeholder,
  assignUser,
  signedInUserUuid,
  inCard,
}: ComplianceReviewerProps) => {
  const [selectedUser, setSelectedUser] = useState<{value: string; displayValue: string;}>(SELECTED_USER_INITIAL);

  const {
    data: { allComplianceReviewers = QUERY_INITIAL_VALUE } = {},
  } = useQuery<AllComplianceReviewersQueryType>(ALL_COMPLIANCE_REVIEWERS_QUERY);

  useEffect(() => {
    if (initialAssignee && initialAssignee.uuid) {
      setSelectedUser({ value: initialAssignee?.uuid, displayValue: `${initialAssignee?.firstName} ${initialAssignee?.lastName}` });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialAssignee && initialAssignee.uuid) {
      setSelectedUser({
        value: initialAssignee.uuid,
        displayValue: `${initialAssignee.firstName} ${initialAssignee.lastName}`,
      });
    } else {
      setSelectedUser(SELECTED_USER_INITIAL);
    }
    // eslint-disable-next-line
  }, [initialAssignee]);

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'unassigned') {
      setSelectedUser(SELECTED_USER_INITIAL);
      assignUser({ variables: { userUuid: null, instanceUuid } });
      return;
    }

    const foundUser = allComplianceReviewers.results.find(e => e.uuid === event.target.value);
    if (!foundUser) return;

    setSelectedUser({ value: foundUser.uuid, displayValue: `${foundUser?.firstName} ${foundUser?.lastName}` });
    assignUser({ variables: { userUuid: event.target.value, instanceUuid } });
  };

  const sortedAllUsers = allComplianceReviewers
    ? [...allComplianceReviewers.results].sort((a, b) => {
      if (a.uuid === signedInUserUuid) return -1;
      if (b.uuid === signedInUserUuid) return 1;
      return 0;
    }) : [];

  return (
    <FormControl sx={{ minWidth: inCard ? '100px' : '165px' }}>
      <Select
        disabled={disabled}
        value={selectedUser.value}
        onChange={handleChange}
        input={<ReviewerInput />}
        displayEmpty
        autoFocus={false}
        inputProps={{ 'aria-label': 'Without label' }}
        IconComponent={ExpandMoreIcon}
        MenuProps={MenuProps}
        renderValue={selected => {
          if (selected.length === 0) {
            return (
              <Box display={hideIfUnassigned ? 'none' : 'flex'} alignItems="center">
                <AccountCircleIcon
                  style={{
                    ...(inCard ? {
                      width: '26px', height: '26px',
                    } : {
                      width: '36px', height: '36px',
                    }),
                    color: palette.secondary.main,
                    marginRight: '4px',
                  }}
                />
                <Typography
                  variant="overline"
                  sx={{
                    textTransform: 'none',
                    ...(inCard ? {
                      fontSize: '14px', fontWeight: '500', lineHeight: '26px',
                    } : {
                      marginRight: '6px',
                    }),
                  }}
                >
                  {placeholder}
                </Typography>
              </Box>
            );
          }
          return (
            <Box display="flex" alignItems="center">
              <UserAvatarContainer
                sx={{
                  ...(inCard ? {
                    width: '26px', height: '26px',
                  } : {
                    width: '36px', height: '36px',
                  }),
                  marginRight: '4px',
                }}
              >
                <UserAvatar
                  alt={selectedUser.displayValue}
                  src={paths.home.reverse()}
                  sx={{
                    ...(inCard ? {
                      width: '22px', height: '22px', fontSize: '12px', margin: '2px',
                    } : {
                      width: '30px', height: '30px', fontSize: '14px', margin: '3px',
                    }),
                  }}
                />
              </UserAvatarContainer>
              <Typography
                variant="overline"
                color="colors.gray08"
                sx={{
                  textTransform: 'none',
                  ...(inCard ? {
                    fontSize: '14px', fontWeight: '500', lineHeight: '26px',
                  } : {
                    marginRight: '6px',
                  }),
                }}
              >
                {selectedUser.displayValue}
              </Typography>
            </Box>
          );
        }}
      >
        <ReviewerMenuItem value="unassigned">
          <UserAvatarContainer sx={{ width: '36px', height: '36px' }}>
            <AccountCircleIcon
              style={{
                width: '30px', height: '30px', margin: '3px', fontSize: '14px',
              }}
            />
          </UserAvatarContainer>
          <UserNameContainer variant="body1" color="colors.gray08">
            Unassigned
          </UserNameContainer>
        </ReviewerMenuItem>
        {!!allComplianceReviewers.totalCount && sortedAllUsers.map(user => (
          <ReviewerMenuItem key={user.uuid} value={user.uuid}>
            <UserAvatarContainer sx={{ width: '36px', height: '36px' }}>
              <UserAvatar
                alt={`${user.firstName} ${user.lastName}`}
                src={paths.home.reverse()}
                sx={{
                  width: '30px', height: '30px', margin: '3px', fontSize: '14px',
                }}
              />
            </UserAvatarContainer>
            <UserNameContainer variant="body1" color="colors.gray08">
              {`${user.firstName} ${user.lastName}`}
            </UserNameContainer>
          </ReviewerMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ComplianceReviewer.defaultProps = {
  disabled: false,
  hideIfUnassigned: false,
  inCard: false,
};

export default ComplianceReviewer;
