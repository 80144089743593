import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import palette from '../../theme/palette';

const MoveToTopButton = () => {
  const scrollToTop = () => {
    const targetDiv = document.getElementById('scrollableRightColumn');
    if (targetDiv) {
      targetDiv.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Tooltip title="Immediately scroll to the top of page">
      <IconButton onClick={scrollToTop}>
        <ArrowUpwardIcon sx={{ color: palette.colors.gray05 }} />
      </IconButton>
    </Tooltip>
  );
};

export default MoveToTopButton;
